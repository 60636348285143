/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import { Copy } from '../../../components/common';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import {
  DateInputField,
  InputField,
  NumericField,
  PhoneField,
  SelectField
} from '../../../components/core/forms/fields';
import { DollarIcon } from '../../../components/core/icons';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { ISourceDimensions } from '../../../interfaces';
import { CompanyType, ILender, servicerOrLender } from '../../../interfaces/ILender';
import { ILoan } from '../../../interfaces/ILoan';
import useVerifiedLenders from '../../../queries/lenders/useVerifiedLenders';
import { createLoan, PERSON_LOANS_QUERY_KEY, updateLoan } from '../../../queries/people/usePersonLoans';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { phoneFormatter } from '../../../utils/formatter';
import { emailField, maxDate, oldDate, phoneField } from '../../../utils/yupRules';
import { buildCompanyOptions, buildVerifiedLendersOptions, officerValidation, processorValidation } from './helpers';

interface LoanEditorProps {
  personGid: string;
  assetGid?: string;
  loan?: ILoan;
  partner?: ISourceDimensions['partner'];
  cancelHandler: () => void;
  confirmHandler: () => void;
}

const SelectedVerifiedCompanyInfo = ({
  verifiedLenders,
  servicerGid,
  lenderGid
}: {
  verifiedLenders: ILender[] | undefined;
  servicerGid: string | undefined;
  lenderGid: string | undefined;
}) => {
  const selectedVerifiedCompany = verifiedLenders?.find(
    lender => lender.gid === servicerGid || lender.gid === lenderGid
  );

  if (!selectedVerifiedCompany) {
    return null;
  }

  const mortgageeClause = selectedVerifiedCompany.business_name;
  const mortgageeAddress = selectedVerifiedCompany.mortgagee_address;

  return (
    <Grid item>
      <FlexBox columnDirection mb={spacings.px16}>
        {!!mortgageeClause && (
          <FlexBox gap={spacings.px8}>
            <Text color={colors.grey60}>Mortgagee clause</Text>
            <Text>
              <Copy
                testId="mortgagee_clause"
                displayText={mortgageeClause}
                value={mortgageeClause}
                css={css`
                  margin-left: 4px;
                `}
              />
            </Text>
          </FlexBox>
        )}
        {!!mortgageeAddress && (
          <FlexBox gap={spacings.px8}>
            <Text color={colors.grey60}>Address</Text>
            <Text>
              <Copy
                testId="mortgagee_address_line1"
                displayText={`${mortgageeAddress.line1}, `}
                value={mortgageeAddress.line1}
                css={css`
                  margin-left: 4px;
                  white-space: nowrap;
                `}
              />
            </Text>
            {mortgageeAddress.line2 && (
              <Text>
                <Copy
                  testId="mortgagee_address_line2"
                  displayText={`${mortgageeAddress.line2}, `}
                  value={mortgageeAddress.line2}
                  css={css`
                    white-space: nowrap;
                  `}
                />
              </Text>
            )}
            <Text>
              <Copy
                testId="mortgagee_address_city"
                displayText={`${mortgageeAddress.city}, `}
                value={mortgageeAddress.city}
                css={css`
                  white-space: nowrap;
                `}
              />
            </Text>
            <Text>
              <Copy
                testId="mortgagee_address_state"
                displayText={`${mortgageeAddress.state}, `}
                value={mortgageeAddress.state}
                css={css`
                  white-space: nowrap;
                `}
              />
            </Text>
            <Text>
              <Copy
                testId="mortgagee_address_zip"
                displayText={mortgageeAddress.zip}
                value={mortgageeAddress.zip}
                css={css`
                  white-space: nowrap;
                `}
              />
            </Text>
          </FlexBox>
        )}
      </FlexBox>
    </Grid>
  );
};

const LoanEditor = ({
  personGid,
  assetGid,
  loan,
  partner,
  cancelHandler,
  confirmHandler
}: LoanEditorProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { data: verifiedLenders } = useVerifiedLenders();
  const currentCompany = servicerOrLender({ servicer: loan?.servicer, lender: loan?.lender });

  const verifiedLendersOptions = buildVerifiedLendersOptions({ verifiedLenders, partner });

  const companyOptions = buildCompanyOptions(verifiedLendersOptions, currentCompany);

  return (
    <Modal containerTitle="Loan details" size={ModalSize.large} showCancelButton={false} cancelHandler={cancelHandler}>
      <BaseForm
        enableReinitialize
        initialValues={{
          purpose: loan?.purpose || '',
          amount: loan?.amount || '',
          est_closing_date: loan?.est_closing_date || '',
          loan_number: loan?.loan_number || '',
          payment_method: loan?.payment_method || '',

          lender_gid: loan?.lender?.gid || '',
          servicer_gid: loan?.servicer?.gid || '',

          officer_first_name: loan?.officer?.first_name || '',
          officer_last_name: loan?.officer?.last_name || '',
          officer_email: loan?.officer?.email || '',
          officer_phone: phoneFormatter(loan?.officer?.phone) || '',

          processor_first_name: loan?.processor?.first_name || '',
          processor_last_name: loan?.processor?.last_name || '',
          processor_email: loan?.processor?.email || '',
          processor_phone: phoneFormatter(loan?.processor?.phone) || ''
        }}
        validationSchema={yup.object().shape({
          amount: yup.number().min(1, 'Please, enter correct amount'),
          est_closing_date: yup
            .date()
            .min(oldDate, "Date can't be earlier than 1990")
            .max(maxDate, "Date can't be later than 2100"),

          officer_email: emailField,
          officer_phone: phoneField({ loose: authInfo.features.loose_phone_validation }),

          processor_email: emailField,
          processor_phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
          loan_number: yup.string().matches(/^[a-zA-Z0-9]{5,}$/, 'Should be at least 5 alphanumeric characters')
        })}
        validate={values => ({
          ...officerValidation(values),
          ...processorValidation(values)
        })}
        onSubmit={values => {
          const createOrUpdateLoan = () => {
            if (loan?.gid) {
              return updateLoan({
                personGid,
                loanGid: loan.gid,
                updateLoanRequest: {
                  ...values,
                  officer_gid: loan.officer?.gid,
                  processor_gid: loan.processor?.gid
                }
              });
            } else {
              return createLoan({
                personGid,
                createLoanRequest: { ...values, asset_gid: assetGid! }
              });
            }
          };

          return createOrUpdateLoan()
            .then(() => confirmHandler())
            .then(() => {
              queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY] });
            });
        }}
        cancelHandler={cancelHandler}
        submitText="Submit"
        submitTestId="submit-loan-edit"
        pt={spacings.px16}
        pb={spacings.px16}
        pl={spacings.px16}
        pr={spacings.px16}
        renderForm={({ values }) => {
          return (
            <>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <Text bold>General info</Text>
                </Grid>
                <Grid item container xs={10} columnSpacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <SelectField
                      label="Purpose"
                      id="purpose"
                      name="purpose"
                      placeholder=""
                      options={Translations.loanPurposeOptions}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      label="Billing type"
                      id="payment_method"
                      name="payment_method"
                      placeholder=""
                      options={Translations.paymentMethodOptions}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <NumericField
                      label="Amount"
                      id="amount"
                      name="amount"
                      valueIsNumericString
                      prefixIcon={<DollarIcon />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateInputField label="Est. closing date" id="est_closing_date" name="est_closing_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField copyable label="Loan number" id="loan_number" name="loan_number" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <Text bold>Lender info</Text>
                </Grid>
                <Grid item container xs={10} columnSpacing={2} justifyContent="space-between">
                  {currentCompany?.type === CompanyType.Lender ? (
                    <Grid item xs={6}>
                      <SelectField
                        label="Lender"
                        id="lender_gid"
                        name="lender_gid"
                        placeholder=""
                        options={companyOptions}
                        ordered
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <SelectField
                        label="Lender"
                        id="servicer_gid"
                        name="servicer_gid"
                        placeholder=""
                        options={companyOptions}
                        ordered
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={2} />
                <Grid item container xs={10} columnSpacing={2} justifyContent="space-between">
                  <SelectedVerifiedCompanyInfo
                    verifiedLenders={verifiedLenders}
                    servicerGid={values.servicer_gid}
                    lenderGid={values.lender_gid}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <Text bold>Officer info</Text>
                </Grid>
                <Grid item container xs={10} columnSpacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <InputField label="First name" id="officer_first_name" name="officer_first_name" />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField label="Last name" id="officer_last_name" name="officer_last_name" />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField copyable label="Email" id="officer_email" name="officer_email" type="email" />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneField copyable label="Phone" id="officer_phone" name="officer_phone" placeholder="" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <Text bold>Processor info</Text>
                </Grid>
                <Grid item container xs={10} columnSpacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <InputField label="First name" id="processor_first_name" name="processor_first_name" />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField label="Last name" id="processor_last_name" name="processor_last_name" />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField copyable label="Email" id="processor_email" name="processor_email" type="email" />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneField copyable label="Phone" id="processor_phone" name="processor_phone" placeholder="" />
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default LoanEditor;
