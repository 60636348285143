import CollapsingContainer from '../../../components/core/CollapsingContainer';
import FlexBox from '../../../components/core/FlexBox';
import { IPerson } from '../../../interfaces';
import usePersonLeads from '../../../queries/people/usePersonLeads';
import { spacings } from '../../../theme/variables';
import { relativeTimeFormatter } from '../../../utils/formatter';
import LeadActivity from '../../Lead/LeadActivity';

const Activities = ({ person }: { person: IPerson }) => {
  const { data: leads } = usePersonLeads(person.gid);

  return (
    <FlexBox gap={spacings.px16} columnDirection>
      {leads?.map((lead, index) => (
        <CollapsingContainer
          iconPosition="left"
          containerTitle={`Lead: ${lead.id} (${relativeTimeFormatter(lead.created_at)})`}
          key={lead.id}
          openedByDefault={index === 0}
        >
          <FlexBox columnDirection>
            <LeadActivity leadId={lead.id} />
          </FlexBox>
        </CollapsingContainer>
      ))}
    </FlexBox>
  );
};

export default Activities;
