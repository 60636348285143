/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import queryString from 'query-string';
import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/common/Loader';
import { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../components/core/Container';
import DropDown from '../../components/core/DropDown/DropDown';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import HotKey from '../../components/core/HotKey';
import { CloseIcon } from '../../components/core/icons';
import PersonSearchInput from '../../components/core/inputs/PersonSearch';
import Text from '../../components/core/Text';
import { useCallState } from '../../contexts/CallContext';
import { useDocumentTitle } from '../../hooks';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import useEventListener from '../../hooks/useEventListener';
import { InsuranceCategory } from '../../interfaces/IPolicyType';
import { LicensingTypes } from '../../interfaces/IUser';
import usePartners from '../../queries/partners/usePartners';
import { useInfinitePeopleByTerm } from '../../queries/people/usePeopleByTerm';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import guidedSellingExperienceNavigation, {
  GuidedSellingExperienceOpenedFrom
} from '../GuidedSellingExperience/navigation';
import { LocationState, readQueryParams } from './navigation';
import Suggestion from './Suggestion';

const CreateLeadLink = ({ currentCallId, navigate }: { currentCallId: number | null; navigate: NavigateFunction }) => {
  const isUserLifeLicensed = authInfo.licensingTypes?.includes(LicensingTypes.Life);

  return (
    <Container borderBottom pv={spacings.px20} ph={spacings.px12}>
      <DropDown
        options={[
          {
            label: isUserLifeLicensed ? 'Lead(Personal lines)' : 'Lead',
            value: 'pl_lead',
            entity: 'lead',
            insuranceCategory: InsuranceCategory.PersonalLines
          },
          isUserLifeLicensed && {
            label: 'Lead(Life)',
            value: 'life_lead',
            entity: 'lead',
            insuranceCategory: InsuranceCategory.Life
          },
          authInfo.features.create_customer && {
            label: 'Customer',
            entity: 'customer',
            value: 'customer',
            insuranceCategory: undefined
          }
        ].filter(Boolean)}
        variant={ButtonVariant.Text}
        size={ButtonSize.Large}
        onSelected={selected => {
          navigate(
            `${currentCallId ? `/new_${selected.entity}?call_log_id=${currentCallId}` : `/new_${selected.entity}`}`,
            {
              state: { from: 'scouting_report_search', insuranceCategory: selected.insuranceCategory }
            }
          );
        }}
      >
        + Create
      </DropDown>
    </Container>
  );
};

const renderMatchedPeople = ({
  props,
  term,
  isFetching,
  currentCallId,
  navigate
}: {
  props: any;
  term: string;
  isFetching: boolean;
  currentCallId: number | null;
  navigate: NavigateFunction;
}) => {
  return (
    <div>
      {term && !isFetching && <CreateLeadLink currentCallId={currentCallId} navigate={navigate} />}
      <div {...props.containerProps}>{props.children}</div>
    </div>
  );
};

const PeopleSearch = (): JSX.Element => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryParams = readQueryParams(searchParams);
  const openedFrom = (state as LocationState)?.openedFrom;

  const { currentCallId } = useCallState();

  useDocumentTitle('Search');

  const [inputValue, setInputValue] = React.useState(queryParams.term || '');
  const [searchBy, setSearchBy] = React.useState(queryParams.search_by || 'all');
  const term = useDebouncedValue(inputValue.trim(), 500);

  const { data, hasNextPage, fetchNextPage, isFetching } = useInfinitePeopleByTerm({
    term,
    search_by: searchBy === 'all' ? undefined : searchBy
  });

  const peopleMatches = data?.pages.flatMap(({ people_matches }) => people_matches) || [];

  const { data: partners } = usePartners();

  const onClose = React.useCallback(() => {
    if (openedFrom) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [openedFrom, navigate]);

  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      onClose();
    }
  });

  React.useEffect(() => {
    navigate(
      {
        search: queryString.stringify(
          { term, search_by: searchBy, call_log_id: currentCallId },
          { skipNull: true, skipEmptyString: true }
        )
      },
      { replace: true, state: { openedFrom } }
    );
  }, [openedFrom, navigate, currentCallId, searchBy, term]);

  const renderNoDataPresent = (currentCallId: number | null) => {
    return (
      term &&
      !isFetching && (
        <>
          <CreateLeadLink currentCallId={currentCallId} navigate={navigate} />
          <FlexBox justifyCenter mt={spacings.px32}>
            <Text>No matching people found</Text>
          </FlexBox>
        </>
      )
    );
  };

  return (
    <Container
      fitParentHeight
      customCss={css`
        display: grid;
        grid-template-rows: auto 1fr;
      `}
    >
      <FlexBox p={spacings.px24} justifySpaceBetween alignItemsCenter>
        <FlexBox alignItemsCenter>
          <Heading type="h3">Search for a person</Heading>
          {isFetching && (
            <Loader
              width={24}
              height={24}
              customCss={css`
                margin-left: 16px;
              `}
            />
          )}
        </FlexBox>
        <FlexBox alignItemsCenter>
          <HotKey
            message="Press ESC"
            customCss={css`
              margin-right: 8px;
            `}
          />
          <CloseIcon
            width={40}
            height={40}
            color={colors.black}
            onClick={onClose}
            css={css`
              cursor: pointer;
            `}
          />
        </FlexBox>
      </FlexBox>
      <Container
        mt={spacings.px24}
        customCss={css`
          overflow-y: auto;

          padding-right: 120px;
          padding-left: 120px;
        `}
      >
        <Global
          styles={css`
            .react-autosuggest {
              &__suggestions-list {
                margin: 0;
                padding: 0;
                list-style-type: none;
              }
            }
          `}
        />
        <Autosuggest
          suggestions={peopleMatches || []}
          onSuggestionsFetchRequested={({ value }: { value: string }) => setInputValue(value)}
          onSuggestionsClearRequested={() => null}
          getSuggestionValue={suggestion => suggestion.person.name}
          onSuggestionSelected={() => null}
          focusInputOnSuggestionClick={false}
          renderSuggestion={suggestion => (
            <Suggestion
              personMatch={suggestion}
              partners={partners}
              fullScreen
              onCustomerPageSelected={() => navigate(`/people/${suggestion.person.gid}`)}
              onScoutingReportSelected={() => {
                const newUrl = guidedSellingExperienceNavigation.forSearchResult({
                  callLodId: currentCallId,
                  personMatch: suggestion
                });

                navigate(newUrl, { state: { openedFrom: GuidedSellingExperienceOpenedFrom.PeopleSearch } });
              }}
            />
          )}
          // @ts-expect-error: invalid component interface
          renderInputComponent={({ ref, key, ...inputProps }) => {
            return (
              <PersonSearchInput
                {...(inputProps as any)}
                key={key}
                inputRef={ref}
                autoFocus
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                className="fs-mask"
              />
            );
          }}
          renderSuggestionsContainer={(props: any) =>
            props.children
              ? renderMatchedPeople({ props, term, isFetching, currentCallId, navigate })
              : renderNoDataPresent(currentCallId)
          }
          alwaysRenderSuggestions
          inputProps={{
            value: inputValue,
            // @ts-expect-error: invalid component interface
            clearable: true,
            onClear: () => {
              setSearchBy('all');
              setInputValue('');
            },
            onChange: (event, { newValue }) => setInputValue(newValue),
            type: 'text',
            placeholder:
              'Search by email, phone number, loan number, customer name, address, vehicle model, vehicle VIN'
          }}
        />
        {!!peopleMatches.length && (
          <FlexBox justifyCenter p={spacings.px32}>
            <ButtonWithoutBorder onClick={() => fetchNextPage()} disabled={isFetching || !hasNextPage} textType="large">
              Show more
            </ButtonWithoutBorder>
          </FlexBox>
        )}
      </Container>
    </Container>
  );
};

export default PeopleSearch;
