import { css, SerializedStyles } from '@emotion/react';

import colors, { opacities } from '../../../theme/colors';
import { defaultFontCss } from '../../../theme/typography';
import { borderRadius } from '../../../theme/variables';
import { ButtonSize, buttonSizeCss, ButtonVariant, getActiveFocusColors, iconComponentSize } from '../buttons/Button';
import { DropDownPosition, pickOptionColors, pickOptionFontWeight, pickOptionHoverColors } from './DropDown.utils';

export const optionsCSS = (staticView: boolean, position: DropDownPosition): SerializedStyles => css`
  box-sizing: border-box;
  box-shadow: 0px 4px 0px ${colors.black}${opacities.opacity_10};
  border: 1px solid ${colors.grey30};
  border-radius: ${borderRadius}px;
  position: absolute;
  z-index: 1000;
  width: ${staticView ? '320px' : '200px'};
  max-height: ${staticView ? undefined : '340px'};
  overflow: auto;
  ${position === DropDownPosition.Top
    ? css`
        margin-bottom: 56px;
      `
    : css`
        margin-top: 8px;
      `}
`;

export const optionCSS = (
  variant: ButtonVariant,
  optionsSize: ButtonSize,
  isOptionDisabled: boolean
): SerializedStyles => css`
  ${defaultFontCss}
  ${buttonSizeCss(optionsSize)};
  ${pickOptionColors(variant)}
  font-weight: ${pickOptionFontWeight(variant)};
  &:first-of-type {
    border-top-left-radius: ${borderRadius}px;
    border-top-right-radius: ${borderRadius}px;
  }
  &:last-of-type {
    border-bottom-right-radius: ${borderRadius}px;
    border-bottom-left-radius: ${borderRadius}px;
  }
  &:hover {
    ${pickOptionHoverColors(variant)}
    a {
      ${pickOptionHoverColors(variant)}
    }
  }
  &:active,
  &:focus {
    ${getActiveFocusColors(variant)}
  }
  &:disabled:hover {
    cursor: not-allowed;
  }

  list-style-type: none;
  cursor: ${isOptionDisabled ? 'not-allowed' : 'pointer'};
`;

export const wrapperCSS = (position: DropDownPosition): SerializedStyles => css`
  position: relative;
  ${position === DropDownPosition.Top &&
  css`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  `}
`;

export const iconCSS = (size: ButtonSize): SerializedStyles => css`
  height: ${iconComponentSize(size)};
  width: ${iconComponentSize(size)};
  margin-left: 8px;
`;
