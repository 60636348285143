import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import React, { ReactNode } from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import selectEvent from 'react-select-event';

function renderWithRouter(
  ui: any,
  {
    route = '/',
    path = '/*',
    initialEntries = [],
    history = createMemoryHistory({ initialEntries: [...initialEntries, route] }),
    ...renderOptions
  } = {}
) {
  function Wrapper({ children }: { children: ReactNode }) {
    return (
      <Router location={history.location} navigator={history}>
        <Routes>
          <Route path={path} element={children} />
        </Routes>
      </Router>
    );
  }

  return {
    ...render(ui, { wrapper: Wrapper, ...renderOptions }),
    // adding `history` to the returned utilities to allow us
    // to reference it in our tests (just try to avoid using
    // this to test implementation details).
    history
  };
}

const flushPromises = () => new Promise(resolve => setTimeout(() => resolve(''), 0));

export const queryClient = new QueryClient();
export const ReactQueryWrapper = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

// re-export everything
export * from '@testing-library/react';

export { flushPromises, renderWithRouter, selectEvent, userEvent };
