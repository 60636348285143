/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import useAssignees from '../../../../queries/assignees/useAssignees';
import useCreateTask, { TaskPayload } from '../../../../queries/people/person_tasks/useCreateTask';
import usePersonTasks from '../../../../queries/people/person_tasks/usePersonTasks';
import {
  useActiveScheduledInteractions,
  useCompletedScheduledInteractions
} from '../../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import TaskEditor from '../../../Lead/LeadTasks/TaskEditor';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import Call from './Call';
import { sortTasksByDueDate } from './helpers';
import Task from './Task';

const TasksTab = () => {
  const [addTask, toggleAddTask] = useToggle(false);
  const { personGid, leadGid, person, lead, candidateGid } = useGuidedSellingExperienceContext();
  const { data: tasks, isPending: isPendingTasks } = usePersonTasks(personGid);
  const { data: activeCalls, isPending: isPendingActiveCalls } = useActiveScheduledInteractions(personGid);
  const { data: completedCalls, isPending: isPendingCompletedCalls } = useCompletedScheduledInteractions(personGid);
  const { data: assignees, isPending: isPendingAssignees } = useAssignees();
  const { mutate: createTask } = useCreateTask({ onSuccess: () => toggleAddTask() });

  if (!personGid) {
    return null;
  }

  const uncompletedTasks = tasks?.filter(task => !task.completed);
  const uncompletedTasksAndCalls = sortTasksByDueDate([...(activeCalls || []), ...(uncompletedTasks || [])]);

  const completedTasks = tasks?.filter(task => task.completed);
  const completedTasksAndCalls = sortTasksByDueDate([...(completedCalls || []), ...(completedTasks || [])]);

  const enabledAddTask = assignees && person;
  const isPending = isPendingTasks || isPendingActiveCalls || isPendingCompletedCalls || isPendingAssignees;

  return (
    <>
      <VerticalTabHeader>
        <FlexBox gap={spacings.px8} alignItemsBaseline>
          <Paragraph type="large">Tasks</Paragraph>
          <ButtonWithoutBorder
            data-testid="add-task-button"
            onClick={() => {
              toggleAddTask();
              analytics.track('Add task clicked', {
                lead_gid: leadGid,
                place: 'guided_selling_side_panel'
              });
            }}
            disabled={!enabledAddTask}
          >
            Add
          </ButtonWithoutBorder>
        </FlexBox>
      </VerticalTabHeader>
      <VerticalTabContent ph={spacings.px0}>
        <>
          <>
            <Paragraph bold type="large" p={spacings.px12}>
              Active tasks
            </Paragraph>
            {isPending ? (
              <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
            ) : (
              <>
                {uncompletedTasksAndCalls.length ? (
                  uncompletedTasksAndCalls.map((task, index) => {
                    return 'completed' in task ? (
                      <Task
                        personGid={personGid}
                        task={task}
                        key={task.id}
                        assignees={assignees}
                        testId={`active-task-${index}`}
                      />
                    ) : (
                      <Call
                        call={task}
                        completed={false}
                        key={task.id}
                        personGid={personGid}
                        candidateGid={candidateGid}
                        leadGid={leadGid}
                        testId={`active-task-${index}`}
                      />
                    );
                  })
                ) : (
                  <Paragraph
                    color={colors.grey60}
                    customCss={css`
                      text-align: center;
                    `}
                  >
                    There are no active tasks
                  </Paragraph>
                )}
              </>
            )}
          </>
          <>
            <Paragraph bold type="large" p={spacings.px12}>
              Completed tasks
            </Paragraph>
            {isPending ? (
              <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
            ) : (
              <>
                {completedTasksAndCalls.length ? (
                  completedTasksAndCalls.map((task, index) => {
                    return 'completed' in task ? (
                      <Task personGid={personGid} task={task} key={task.id} testId={`completed-task-${index}`} />
                    ) : (
                      <Call
                        testId={`completed-task-${index}`}
                        completed
                        call={task}
                        key={task.id}
                        personGid={personGid}
                        candidateGid={candidateGid}
                        leadGid={leadGid}
                      />
                    );
                  })
                ) : (
                  <Paragraph
                    color={colors.grey60}
                    customCss={css`
                      text-align: center;
                    `}
                  >
                    There are no completed tasks yet
                  </Paragraph>
                )}
              </>
            )}
          </>
        </>
      </VerticalTabContent>
      {addTask && enabledAddTask && (
        <TaskEditor
          open={addTask}
          assignees={assignees}
          cancelBtnHandler={toggleAddTask}
          confirmBtnHandler={data => createTask({ personGid, data: { ...data, lead_id: lead?.id } as TaskPayload })}
          person={person}
          leadId={lead?.id}
        />
      )}
    </>
  );
};

export default TasksTab;
