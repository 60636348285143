import Modal from '../../../components/core/Modal';
import useDeleteProductAnnouncement from '../../../queries/product_announcements/useDeleteProductAnnouncement';

interface CancelAnnouncementModalProps {
  productAnnouncementId: number;
  onCancel: () => void;
  onDeleted: () => void;
}

const CancelAnnouncementModal = ({ productAnnouncementId, onCancel, onDeleted }: CancelAnnouncementModalProps) => {
  const { mutateAsync: deleteProductAnnouncement, isPending: isDeletingAnnouncement } = useDeleteProductAnnouncement();

  return (
    <Modal
      containerTitle="Cancel announcement"
      confirmText="Cancel"
      cancelText="Discard"
      dangerModal
      cancelHandler={() => onCancel()}
      confirmationInProgress={isDeletingAnnouncement}
      confirmHandler={async () => {
        await deleteProductAnnouncement(productAnnouncementId);

        onDeleted();
      }}
    >
      Are you sure you want to cancel an announcement?
    </Modal>
  );
};

export default CancelAnnouncementModal;
