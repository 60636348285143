/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import { InfoIcon } from '../../../../../components/core/icons';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import { IPerson, isPersonMarried } from '../../../../../interfaces/IPerson';
import { InsurableInterest, PolicyType } from '../../../../../interfaces/IPolicyType';
import usePersonAllPolicies from '../../../../../queries/people/person_policies/usePersonAllPolicies';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import {
  IOpportunityFormValues,
  IQuotingConfigurationFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';
import { PAF_TIP, STUB_PAF_OPPORTUNITY_ID } from '../_utils';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import StubPAFCoverage from './StubPAFCoverage';

export const stubOpportunityInitialValues = (isMarried = false): IOpportunityFormValues => ({
  enabled: isMarried,
  insured_gids: undefined,
  assets_gids: undefined,
  agent_selected_coverages: [
    {
      asset_gid: null,
      coverages_tier: OpportunityCoveragesTier.Quoted,
      coverages: null
    }
  ],
  primary_insured_gid: '',
  insurable_interest: InsurableInterest.PersonalBelongings
});

const StubPAFOpportunity = ({ person }: { person: IPerson }) => {
  const { values } = useFormikContext<IQuotingConfigurationFormValues>();
  const opportunityValues = values.opportunities[STUB_PAF_OPPORTUNITY_ID]!;

  const isOpportunityEnabled = opportunityValues.enabled;

  const { data: policies, refetch: refetchCurrentPolicies } = usePersonAllPolicies({
    personGid: person?.gid,
    filters: { policy_types: [PolicyType.PAF] }
  });

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px12}
      justifySpaceBetween
      data-testid="stub-paf-opportunity"
    >
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox>
          <CheckboxField
            inline
            key={`opportunities.${STUB_PAF_OPPORTUNITY_ID}.enabled`}
            id={`opportunities.${STUB_PAF_OPPORTUNITY_ID}.enabled`}
            name={`opportunities.${STUB_PAF_OPPORTUNITY_ID}.enabled`}
            label={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Text bold type="large">
                  PAF
                </Text>
                {isPersonMarried(person.marital_status) && (
                  <>
                    <Tag
                      data-for="paf-recommendation"
                      data-tip={PAF_TIP}
                      label={
                        <FlexBox gap={spacings.px8}>
                          Recommended
                          <InfoIcon height={spacings.px16} width={spacings.px16} />
                        </FlexBox>
                      }
                      transparent
                      backgroundColor={colors.statusGreen}
                      textColor={colors.statusGreen}
                    />
                    <Tooltip id="paf-recommendation" />
                  </>
                )}
              </FlexBox>
            }
          />
        </FlexBox>
        {isOpportunityEnabled && <StubPAFCoverage />}
      </FlexBox>
      <OpportunityCurrentInsurance
        person={person}
        opportunity={
          {
            policy_type: PolicyType.PAF,
            latestPolicy: policies?.[0]
          } as IQuotingConfigurationOpportunity
        }
        refetchCurrentPolicies={refetchCurrentPolicies}
      />
    </FlexBox>
  );
};

export default StubPAFOpportunity;
