/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { DispositionType } from '../../../interfaces/IDisposition';
import useAssignees from '../../../queries/assignees/useAssignees';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import authInfo from '../../../services/authInfo';
import { defaultFontValues } from '../../../theme/typography';
import { requiredField } from '../../../utils/yupRules';
import { ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import FormModal from '../../core/FormModal';
import { ModalSize } from '../../core/Modal';

interface NotQualifiedProps {
  leadId: string | number;
  closeModal: () => void;
  onDispositionUpdated?: (dispositionType: DispositionType) => void;
}

const NotQualifiedModal: React.FC<NotQualifiedProps> = ({ leadId, closeModal, onDispositionUpdated }) => {
  const { data: assignees, isFetching: isFetchingAssignees } = useAssignees();
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  return (
    <FormModal
      returnFocusAfterClose={false}
      initialValues={{
        assignee: authInfo.currentUserId,
        reason: ''
      }}
      validationSchema={yup.object().shape({
        assignee: requiredField,
        reason: requiredField
      })}
      confirmHandler={values => {
        return updateDisposition({
          leadId,
          disposition_type: DispositionType.NotQualified,
          disposition_reason: values.reason,
          agent_id: values.assignee || undefined
        }).then(() => {
          closeModal();
          onDispositionUpdated?.(DispositionType.NotQualified);
        });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      containerTitle='Confirm a "Not Qualified" reason'
      confirmText="Confirm"
      size={ModalSize.medium}
      confirmationInProgress={isFetchingAssignees}
      renderForm={({ errors, touched }) => (
        <div
          css={css`
            ${defaultFontValues}
          `}
        >
          <ErrorCol
            style={{ marginTop: '12px' }}
            name="assignee"
            error={errors['assignee']}
            when={Boolean(errors['assignee'] && touched['assignee'])}
          >
            {!authInfo.features.edit_lead_assignee ? (
              <Field
                name="assignee"
                component={Select}
                options={[{ key: authInfo.currentUserId, value: 'Assign to me' }]}
                title="Assignee"
                placeholder="Please select an assignee"
                data-testid="select-assignee"
              />
            ) : (
              <Field
                name="assignee"
                component={Select}
                options={assignees}
                title="Assignee"
                placeholder="Please select an assignee"
                labelName="name"
                valueName="id"
                data-testid="select-assignee"
              />
            )}
          </ErrorCol>
          <ErrorCol
            style={{ marginTop: '12px' }}
            name="reason"
            error={errors['reason']}
            when={Boolean(errors['reason'] && touched['reason'])}
          >
            <Field
              name="reason"
              component={Select}
              options={Translations.notQualifiedReasonOptions}
              title="Reason"
              placeholder="Please select a reason"
              labelName="value"
              valueName="key"
              data-testid="select-not-qualified-reason"
            />
          </ErrorCol>
        </div>
      )}
    />
  );
};

export default NotQualifiedModal;
