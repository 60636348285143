/** @jsxImportSource @emotion/react */
import React from 'react';

import Modal, { ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import { IAddress } from '../../../../interfaces';
import { getFullAddress } from '../../../../interfaces/IAddress';
import { useReplaceOpportunityAsset } from '../../../../queries/leads/data_collection/useDataCollectionHomes';
import { useReconcileOpportunities } from '../../../../queries/leads/data_collection/useDataCollectionOpportunities';

interface IReplaceHomeModal {
  leadId: number;
  opportunityId: number;
  replacementHomeGid: string;
  cancelHandler: () => void;
  confirmHandler: () => void;
  originalAddress: IAddress;
  replacementAddress: IAddress;
}
const ReplaceHomeModal = ({
  leadId,
  opportunityId,
  replacementHomeGid,
  cancelHandler,
  confirmHandler,
  originalAddress,
  replacementAddress
}: IReplaceHomeModal): JSX.Element => {
  const { mutateAsync: replaceHome, isPending: isReplacingHome } = useReplaceOpportunityAsset();
  const { mutateAsync: reconcileOpportunities, isPending: isReconciling } = useReconcileOpportunities();

  return (
    <Modal
      containerTitle="Property Swap Confirmation"
      confirmText="Confirm Swap"
      size={ModalSize.large}
      cancelHandler={cancelHandler}
      confirmHandler={async () => {
        await replaceHome({ leadId, replacementHomeGid, opportunityId });
        await reconcileOpportunities({
          leadId,
          assetsGids: [replacementHomeGid]
        });
        confirmHandler();
      }}
      confirmationInProgress={isReplacingHome || isReconciling}
      warningIcon={true}
    >
      <Paragraph>
        You&apos;ve chosen an address that&apos;s already used for another property owned by this customer. If you
        continue, it will replace all information about the current property,{' '}
        <strong>{getFullAddress(originalAddress)}</strong>, with new details from the chosen property,{' '}
        <strong>{getFullAddress(replacementAddress)}</strong>.
      </Paragraph>
    </Modal>
  );
};

export default ReplaceHomeModal;
