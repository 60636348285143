/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { IDisposition } from '../../../interfaces/IDisposition';
import useRollbackDisposition from '../../../queries/leads/dispositions/useRollbackDisposition';
import colors, { opacities } from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import FlexBox from '../../core/FlexBox';
import Modal, { ModalSize } from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

interface RollbackDispositionProps {
  leadId: string | number;
  currentDisposition: IDisposition;
  priorDisposition: IDisposition;
  closeModal: () => void;
  onDispositionUpdated?: () => void;
}

const currentStateLabel = (text: string) => (
  <Text
    type="small"
    lineThrough
    p={spacings.px4}
    ml={spacings.px8}
    customCss={css`
      background-color: ${colors.statusRed}${opacities.opacity_16};
    `}
  >
    {text}
  </Text>
);

const arrow = () => (
  <Text type="small" ml={spacings.px8}>
    &#8594;
  </Text>
);

const newStateLabel = (text: string) => (
  <Text
    type="small"
    p={spacings.px4}
    ml={spacings.px8}
    customCss={css`
      background-color: ${colors.statusGreen}${opacities.opacity_16};
    `}
  >
    {text}
  </Text>
);

const changeOrCurrentValue = (currentValue: string, newValue: string) => {
  if (currentValue === newValue) {
    return <Text type="small" ml={spacings.px8}>{`${currentValue} (no change)`}</Text>;
  }

  return (
    <>
      {currentStateLabel(currentValue)}
      {arrow()}
      {newStateLabel(newValue)}
    </>
  );
};

const RollbackDispositionModal: React.FC<RollbackDispositionProps> = ({
  leadId,
  currentDisposition,
  priorDisposition,
  closeModal,
  onDispositionUpdated
}) => {
  const { mutateAsync: rollbackDisposition, isPending: isRollBackingDisposition } = useRollbackDisposition();

  return (
    <Modal
      size={ModalSize.large}
      containerTitle="You're going to delete the record"
      confirmText="Delete the record"
      cancelHandler={closeModal}
      confirmHandler={() =>
        rollbackDisposition({ leadId }).then(() => {
          closeModal();
          onDispositionUpdated?.();
        })
      }
      confirmationInProgress={isRollBackingDisposition}
      dangerModal
    >
      <Paragraph>
        Are you sure you want to delete the record? This action will affect company&apos;s data and will be reflected in
        the activity log.
      </Paragraph>
      <Paragraph bold>Your change</Paragraph>
      <FlexBox alignItemsCenter>
        <Text type="small">Disposition status change:</Text>
        {changeOrCurrentValue(currentDisposition.label, priorDisposition.label)}
      </FlexBox>
      <FlexBox mt={spacings.px16} alignItemsCenter>
        <Text
          type="small"
          customCss={css`
            margin-right: 52px;
          `}
        >
          Assignee change:
        </Text>
        {changeOrCurrentValue(
          currentDisposition.assignee?.name || 'no assignee',
          priorDisposition.assignee?.name || 'no assignee'
        )}
      </FlexBox>
    </Modal>
  );
};

export default RollbackDispositionModal;
