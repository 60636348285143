/** @jsxImportSource @emotion/react */
import React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import usePerson from '../../../../queries/people/usePerson';
import usePersonAddresses from '../../../../queries/people/usePersonAddresses';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import { spacings } from '../../../../theme/variables';
import TabSkeleton from '../../_components/VerticalTabs/TabSkeleton';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import CustomerProfile from './CustomerProfile';
import CustomerProfileForm from './CustomerProfileForm';

const PeopleTab = ({ personGid }: { personGid: string }) => {
  const { data: person } = usePerson(personGid);
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);
  const { data: personAddresses } = usePersonAddresses(personGid);
  const [editCustomerProfile, setEditCustomerProfile] = React.useState(false);

  const isLoadingCustomerProfile = !person || !personAddresses || !relatedPeople;

  return (
    <FlexBox columnDirection>
      <VerticalTabHeader>
        <Paragraph type="large">People</Paragraph>
      </VerticalTabHeader>

      <VerticalTabContent>
        {isLoadingCustomerProfile && <TabSkeleton ph={spacings.px12} pb={spacings.px12} />}
        {!isLoadingCustomerProfile && !editCustomerProfile && (
          <CustomerProfile
            person={person}
            relatedPeople={relatedPeople || []}
            onEdit={() => setEditCustomerProfile(true)}
          />
        )}
        {!isLoadingCustomerProfile && editCustomerProfile && (
          <CustomerProfileForm
            person={person}
            relatedPeople={relatedPeople || []}
            personAddresses={personAddresses}
            onClose={() => setEditCustomerProfile(false)}
            onSubmit={async () => {
              setEditCustomerProfile(false);
            }}
          />
        )}
      </VerticalTabContent>
    </FlexBox>
  );
};

export default PeopleTab;
