/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { Translations } from '../../constants';
import { ILoan } from '../../interfaces';
import { servicerOrLender } from '../../interfaces/ILender';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../utils/formatter';
import Container from '../core/Container';
import Copy from '../core/Copy';
import CopyableAddress from '../core/CopyableAddress';
import DescriptionList from '../core/DescriptionList';
import FlexBox from '../core/FlexBox';
import Text from '../core/Text';
import EmptyAnswer from '../UIFlow/EmptyAnswer';
import LoanPersonInfo from './LoanPersonInfo';

const LoanInfo = ({ loan, isLoanPrimary }: { loan: ILoan; isLoanPrimary: boolean }) => {
  const company = servicerOrLender({ servicer: loan.servicer, lender: loan.lender });

  if (
    !loan.loan_number &&
    !loan.payment_method &&
    !loan.amount &&
    !loan.purpose &&
    !loan.est_closing_date &&
    !company &&
    !loan.processor &&
    !loan.officer
  ) {
    return null;
  }

  const lenderName = company?.name;
  const mortgageeClause = company?.business_name;
  const mortgageeAddress = company?.mortgagee_address;

  return (
    <>
      <Container>
        <Container mb={spacings.px12}>
          {!!lenderName && !mortgageeClause && (
            <DescriptionList
              term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Lender name</Text>}
              details={
                <Copy
                  testId="lender_name"
                  value={lenderName}
                  css={css`
                    margin-left: ${spacings.px4}px;
                  `}
                >
                  <Text color={isLoanPrimary ? colors.black : colors.grey30}>{lenderName}</Text>
                </Copy>
              }
            />
          )}
          {!!mortgageeClause && (
            <DescriptionList
              term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Mortgagee clause</Text>}
              details={
                <Copy testId="mortgagee_clause" value={mortgageeClause}>
                  <Text color={isLoanPrimary ? colors.black : colors.grey30}>{mortgageeClause}</Text>
                </Copy>
              }
            />
          )}
          {!!mortgageeAddress && (
            <DescriptionList
              term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Address</Text>}
              details={
                <CopyableAddress address={mortgageeAddress} textColor={isLoanPrimary ? colors.black : colors.grey30} />
              }
            />
          )}
        </Container>

        <Text bold ml={spacings.px4} color={isLoanPrimary ? colors.black : colors.grey30}>
          Loan details
        </Text>
        <FlexBox
          alignItemsCenter
          css={css`
            flex-wrap: wrap;
          `}
          justifySpaceBetween
        >
          <DescriptionList
            width={200}
            term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Loan number</Text>}
            details={
              <>
                {loan.loan_number ? (
                  <Copy testId="loan_number" value={loan.loan_number} className="fs-mask">
                    <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                      {loan.loan_number}
                    </Text>
                  </Copy>
                ) : (
                  <EmptyAnswer />
                )}
              </>
            }
          />

          <DescriptionList
            width={200}
            term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Amount</Text>}
            details={
              <>
                {loan.amount ? (
                  <Copy testId="amount" value={loan.amount.toString()}>
                    <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                      {moneyFormatter(loan.amount, true)}
                    </Text>
                  </Copy>
                ) : (
                  <EmptyAnswer />
                )}
              </>
            }
          />
        </FlexBox>
        <FlexBox
          alignItemsCenter
          css={css`
            flex-wrap: wrap;
          `}
          justifySpaceBetween
        >
          <DescriptionList
            width={200}
            term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Purpose</Text>}
            details={
              <>
                {loan.purpose ? (
                  <Copy testId="purpose" value={Translations.loanPurpose(loan.purpose)}>
                    <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                      {Translations.loanPurpose(loan.purpose)}
                    </Text>
                  </Copy>
                ) : (
                  <EmptyAnswer />
                )}
              </>
            }
          />

          <DescriptionList
            width={200}
            term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Billing type</Text>}
            details={
              <>
                {loan.payment_method ? (
                  <Copy testId="payment_method" value={Translations.paymentMethod(loan.payment_method)}>
                    <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                      {Translations.paymentMethod(loan.payment_method)}
                    </Text>
                  </Copy>
                ) : (
                  <EmptyAnswer />
                )}
              </>
            }
          />
        </FlexBox>
        <FlexBox
          alignItemsCenter
          css={css`
            flex-wrap: wrap;
          `}
          justifySpaceBetween
        >
          <DescriptionList
            width={200}
            term={<Text color={isLoanPrimary ? colors.grey60 : colors.grey30}>Est closing</Text>}
            details={
              <>
                {loan.est_closing_date ? (
                  <Copy testId="est_closing_date" value={dateFormatter(loan.est_closing_date)}>
                    <Text singleLine color={isLoanPrimary ? colors.black : colors.grey30}>
                      {dateFormatter(loan.est_closing_date)}
                    </Text>
                  </Copy>
                ) : (
                  <EmptyAnswer />
                )}
              </>
            }
          />
        </FlexBox>

        {!!loan.officer && (
          <Container mt={spacings.px12}>
            <LoanPersonInfo title="Officer info" person={loan.officer} isLoanPrimary={isLoanPrimary} />
          </Container>
        )}
        {!!loan.processor && (
          <Container mt={spacings.px12}>
            <LoanPersonInfo title="Processor info" person={loan.processor} isLoanPrimary={isLoanPrimary} />
          </Container>
        )}
      </Container>
    </>
  );
};

export default LoanInfo;
