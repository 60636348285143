/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import { CopyIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../components/core/PolicyTypeIcon';
import Text from '../../../components/core/Text';
import { IMaticPolicy } from '../../../interfaces';
import { findPolicyType, PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../interfaces/IVehicle';
import { usePolicyDeliveries } from '../../../queries/people/person_policies/usePolicyDeliveries';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../../utils/formatter';
import CollectedDocuments from './CollectedDocuments';
import PolicyLoans from './PolicyLoans';

const PolicyDetails = ({ policy, personGid }: { policy: IMaticPolicy; personGid: string }) => {
  const { data: deliveries } = usePolicyDeliveries({ personGid, policyId: policy.id });

  return (
    <FlexBox justifySpaceBetween gap={spacings.px40}>
      <FlexBox
        border
        roundBorder
        columnDirection
        p={spacings.px12}
        gap={spacings.px12}
        css={css`
          max-width: 784px;
          min-width: 784px;
        `}
      >
        <FlexBox gap={spacings.px8} columnDirection>
          <FlexBox alignItemsBaseline gap={spacings.px4} fitParentWidth>
            <Text bold>{findPolicyType(policy.policy_type)?.name} policy for</Text>
            <Text className="fs-mask">
              {policy.policy_type === PolicyType.Auto
                ? generateAutoDescription(policy.assets as IVehicle[])
                : policy.assets?.[0]?.address.full}
            </Text>
          </FlexBox>
          <FlexBox gap={spacings.px12} alignItemsCenter border p={spacings.px12} roundBorder justifySpaceBetween>
            <FlexBox
              gap={spacings.px12}
              alignItemsCenter
              customCss={css`
                max-width: 40%;
              `}
            >
              <Container
                border
                roundBorder
                p={spacings.px4}
                css={css`
                  height: fit-content;
                `}
              >
                <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
                  <PolicyTypeIcon policyType={policy.policy_type} color={colors.azure50} height={24} width={24} />
                </Container>
              </Container>
              <FlexBox
                columnDirection
                gap={spacings.px8}
                customCss={css`
                  max-width: 80%;
                `}
              >
                <FlexBox alignItemsCenter gap={spacings.px8}>
                  <Text singleLine type="small">
                    {findPolicyType(policy.policy_type)?.name} policy
                  </Text>
                </FlexBox>

                <Paragraph type="small" color={colors.grey60} singleLine className="fs-mask">
                  {policy.policy_type === PolicyType.Auto
                    ? generateAutoDescription(policy.assets as IVehicle[])
                    : policy.assets?.[0]?.address.full}
                </Paragraph>
              </FlexBox>
            </FlexBox>

            <FlexBox gap={spacings.px24}>
              <FlexBox columnDirection gap={spacings.px8}>
                <Text type="small" color={colors.grey60}>
                  Carrier
                </Text>
                <Text type="small">{policy.carrier.name}</Text>
              </FlexBox>
              <FlexBox columnDirection gap={spacings.px8}>
                <Text type="small" color={colors.grey60}>
                  Effective
                </Text>
                <Text type="small">{dateFormatter(policy.effective_date)}</Text>
              </FlexBox>
              <FlexBox columnDirection gap={spacings.px8}>
                <Text type="small" color={colors.grey60}>
                  Premium
                </Text>
                <Text type="small">{moneyFormatter(policy.premium, true)}</Text>
              </FlexBox>
            </FlexBox>
            <Copy value={policy.policy_number} withIcon={false}>
              <FlexBox alignItemsCenter gap={spacings.px4} p={spacings.px4}>
                <Text bold type="small" className="fs-mask">
                  {policy.policy_number}
                </Text>
                <CopyIcon />
              </FlexBox>
            </Copy>
          </FlexBox>
        </FlexBox>

        <Container
          fitParentWidth
          backgroundColor={colors.grey10}
          customCss={css`
            height: 1px;
          `}
        />
        <CollectedDocuments deliveries={deliveries} policy={policy} personGid={personGid} />
        <PolicyLoans personGid={personGid} asset={policy.assets?.[0]} policy={policy} deliveries={deliveries} />
      </FlexBox>
    </FlexBox>
  );
};

export default PolicyDetails;
