/** @jsxImportSource @emotion/react */
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Button from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import { isLeadContactMade, isLeadTransferred } from '../../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { firstUncompletedDataCollectionPage, IDataCollectionPageType } from '../../../../interfaces/IDataCollection';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { DataCollection } from '../../../../queries/leads/data_collection/useDataCollection';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import analytics from '../../../../services/analytics';
import authInfo, { isISR } from '../../../../services/authInfo';
import { spacings } from '../../../../theme/variables';
import FinalizeLead from '../../_components/FinalizeLead';
import { isCustomerDataCompletenessFull } from '../../_helpers';
import { useCurrentDispositionSubscriptionContext, useDisplayCrossSellWarning } from '../../_hooks';
import { DataCollectionStepper } from '../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import { GuidedSellingRoutes } from '../../navigation';
import FooterSkeleton from './FooterSkeleton';

interface ILeadFooter {
  dataCollection: DataCollection | undefined;
  dataCollectionStepper: DataCollectionStepper;
}

const LeadFooter = ({ dataCollection, dataCollectionStepper }: ILeadFooter) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { personGid, leadGid, candidateGid, lead, customerDataCompleteness } = useGuidedSellingExperienceContext();

  const { leadDispositions, updateDisposition, isUpdatingDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: opportunities } = usePersonOpportunities({ personGid, leadGid, candidateGid });
  const primaryOpportunity = opportunities?.find(({ primary }) => primary);

  const { displayWarning } = useDisplayCrossSellWarning();

  if (!primaryOpportunity || !lead || !dataCollection) {
    return <FooterSkeleton />;
  }

  const completedDataControls = !!lead && !isISR() && isCustomerDataCompletenessFull(customerDataCompleteness);
  const uncompletedPage = firstUncompletedDataCollectionPage(dataCollection);
  const lastPage = dataCollection.pages[dataCollection.pages.length - 1];

  const trackSummaryCompleted = () => {
    analytics.track('Data collection step submitted', {
      person_gid: personGid,
      lead_gid: lead.gid,
      step_key: 'summary',
      opportunities_count: null,
      disabled_opportunities_count: null
    });
  };

  return (
    <FlexBox pv={spacings.px24} ph={spacings.px24} gap={spacings.px12} justifyRight border>
      {personGid && featureFlags.newQuotesUI && <FinalizeLead leadId={lead.id} personGid={personGid} />}
      {completedDataControls && (
        <Button
          data-testid="collect-secondary-opportunity-button"
          loading={isUpdatingDisposition}
          onClick={async () => {
            analytics.track('Collect secondary opportunity clicked', {
              lead_gid: leadGid,
              person_gid: personGid
            });

            if (!isLeadTransferred(leadDispositions!.dispositions)) {
              await updateDisposition({
                leadId: lead.id,
                disposition_type: DispositionType.Pipeline,
                agent_id: authInfo.currentUserId!
              });
            }

            trackSummaryCompleted();
            dataCollectionStepper.moveToPage(uncompletedPage?.page_type || IDataCollectionPageType.CustomerProfile);

            navigate(
              dataCollection?.completed
                ? `${GuidedSellingRoutes.Quotes}${search}`
                : `${GuidedSellingRoutes.DataCollection}${search}`
            );
          }}
        >
          {dataCollection?.completed ? 'See quotes' : uncompletedPage?.action_label}
        </Button>
      )}
      {!completedDataControls && isISR() && (
        <div data-tip="Read the warning message at the top of the page" data-for="isr-warning">
          <Button
            data-testid="start-data-collection-button"
            loading={isUpdatingDisposition}
            disabled={displayWarning}
            onClick={async () => {
              if (!isLeadContactMade(leadDispositions?.dispositions || [])) {
                await updateDisposition({
                  leadId: lead.id,
                  disposition_type: DispositionType.ContactMade,
                  agent_id: authInfo.currentUserId!
                });
              }

              trackSummaryCompleted();
              dataCollectionStepper.moveToPage(
                uncompletedPage?.page_type || lastPage?.page_type || IDataCollectionPageType.CustomerProfile
              );

              navigate(`${GuidedSellingRoutes.DataCollection}${search}`);
            }}
          >
            {dataCollection?.completed ? 'Review collected data' : uncompletedPage?.action_label}
          </Button>
          {displayWarning && <Tooltip id="isr-warning" />}
        </div>
      )}
      {!completedDataControls && !isISR() && (
        <Button
          data-testid="start-data-collection-button"
          loading={isUpdatingDisposition}
          onClick={async () => {
            if (!isLeadContactMade(leadDispositions?.dispositions || [])) {
              await updateDisposition({
                leadId: lead.id,
                disposition_type: DispositionType.ContactMade,
                agent_id: authInfo.currentUserId!
              });
            }

            trackSummaryCompleted();
            dataCollectionStepper.moveToPage(uncompletedPage?.page_type || IDataCollectionPageType.CustomerProfile);

            navigate(
              dataCollection?.completed
                ? `${GuidedSellingRoutes.Quotes}${search}`
                : `${GuidedSellingRoutes.DataCollection}${search}`
            );
          }}
        >
          {dataCollection?.completed ? 'See quotes' : uncompletedPage?.action_label}
        </Button>
      )}
    </FlexBox>
  );
};

export default LeadFooter;
