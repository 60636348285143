import { IOpportunity, IOpportunityPolicies, IOpportunityPremiumPrediction, IQuote } from '.';
import { AssetType } from './IPersonAsset';
import { PolicyType } from './IPolicyType';

export enum QuoteGroupingOption {
  Bundle = 'bundle',
  Asset = 'asset',
  Carrier = 'carrier'
}

export enum QuoteOrderingOption {
  CheapestFirst = 'cheapest',
  PriciestFirst = 'priciest',
  HigherCoveragesFirst = 'coverages',
  MpdBundlesFirst = 'bundles',
  CarriersAlphabetical = 'az',
  CarriersWithMpdFirst = 'mpd'
}

export interface IQuotesGroup {
  quotes: IQuote[];
}

export interface IAssetsQuotesGroup {
  quotes: IQuote[];
  opportunity: IOpportunity & IOpportunityPolicies & IOpportunityPremiumPrediction;
}

export interface IGroupedQuotes<QuotesGroupType = IQuotesGroup> {
  created_at: string;
  finished_at: string | null;
  quoting_progress: number; // 0..100
  groups: QuotesGroupType[];
  assets: { gid: string; label: string; type: AssetType.Home | AssetType.Auto }[];
  policy_types: { id: PolicyType; label: string }[];
  pages: number;
  groups_total: number | null;
}
