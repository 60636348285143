/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import { InfoIcon } from '../../../../../components/core/icons';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { IHome } from '../../../../../interfaces';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import { IPerson } from '../../../../../interfaces/IPerson';
import { InsurableInterest, PolicyType } from '../../../../../interfaces/IPolicyType';
import usePersonAllPolicies from '../../../../../queries/people/person_policies/usePersonAllPolicies';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import {
  IOpportunityFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';
import { FLOOD_TIP, STUB_FLOOD_PREFIX } from '../_utils';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';

export const stubFloodOpportunityInitialValues = ({
  assets_gids,
  enabled
}: {
  assets_gids: string[];
  enabled: boolean;
}): IOpportunityFormValues => ({
  enabled,
  insured_gids: undefined,
  assets_gids,
  agent_selected_coverages: [
    {
      asset_gid: assets_gids[0]!,
      coverages_tier: OpportunityCoveragesTier.MatchRCE,
      coverages: null
    }
  ],
  primary_insured_gid: '',
  insurable_interest: InsurableInterest.RealProperty
});

const StubFloodOpportunity = ({
  index,
  person,
  opportunity,
  onMoveToOpportunity
}: {
  index: number;
  person: IPerson;
  opportunity: IQuotingConfigurationOpportunity;
  onMoveToOpportunity: ConfigurationComponentProps['onMoveToOpportunity'];
}) => {
  const { data: policies = [], refetch: refetchCurrentPolicies } = usePersonAllPolicies({
    personGid: person.gid,
    filters: { policy_types: [PolicyType.Flood] }
  });

  const correctPolicy =
    policies.find(p => (p.assets as IHome[])[0]?.gid === (opportunity.assets as IHome[])[0]!.gid) || policies[0];

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px12}
      justifySpaceBetween
      data-testid={`stub-flood-opportunity-${index}`}
    >
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter gap={spacings.px12}>
          <CheckboxField
            inline
            key={`opportunities.${STUB_FLOOD_PREFIX}-${opportunity.id}.enabled`}
            id={`opportunities.${STUB_FLOOD_PREFIX}-${opportunity.id}.enabled`}
            name={`opportunities.${STUB_FLOOD_PREFIX}-${opportunity.id}.enabled`}
            disabled={!opportunity.is_data_collection_completed}
            label={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Text bold type="large">
                  Flood
                </Text>

                <Tag
                  data-for="flood-recommendation"
                  data-tip={FLOOD_TIP}
                  label={
                    <FlexBox gap={spacings.px8}>
                      Recommended
                      <InfoIcon height={spacings.px16} width={spacings.px16} />
                    </FlexBox>
                  }
                  transparent
                  backgroundColor={colors.statusGreen}
                  textColor={colors.statusGreen}
                />
                <Tooltip id="flood-recommendation" />
              </FlexBox>
            }
          />
          {opportunity.assets?.[0] && (
            <Text className="fs-mask" color={colors.grey60}>
              {opportunity.assets[0].address.full}
            </Text>
          )}
        </FlexBox>
        {!opportunity.is_data_collection_completed && (
          <FlexBox mt={spacings.px24} columnDirection>
            <Text>Information about home was not collected</Text>
            <Container mt={spacings.px8}>
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  analytics.track('Collect home profile clicked', {
                    person_gid: person.gid,
                    place: 'guided_selling_experience',
                    page: 'quoting_configuration'
                  });

                  return onMoveToOpportunity(opportunity);
                }}
              >
                Collect home profile
              </Button>
            </Container>
          </FlexBox>
        )}
      </FlexBox>

      <OpportunityCurrentInsurance
        person={person}
        opportunity={
          {
            policy_type: PolicyType.Flood,
            latestPolicy: correctPolicy
          } as IQuotingConfigurationOpportunity
        }
        refetchCurrentPolicies={refetchCurrentPolicies}
      />
    </FlexBox>
  );
};

export default StubFloodOpportunity;
