/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { components } from 'react-select';

import emptyImage from '../../assets/img/empty.svg';
import { Link } from '../../components/common';
import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import FlexBox from '../../components/core/FlexBox';
import { DateInputField, SelectField, TextareaField } from '../../components/core/forms/fields';
import FormError from '../../components/core/forms/fields/FormError';
import { ArrowRight2Icon, CalendarIcon, CarIcon, HomeIcon, TickIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import SwitchToggle from '../../components/core/SwitchToggle';
import Text from '../../components/core/Text';
import { useConstant, useDebouncedValue } from '../../hooks';
import { IPerson } from '../../interfaces';
import { InsurableInterest } from '../../interfaces/IPolicyType';
import { IProposal } from '../../interfaces/IProposal';
import { IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { usePersonProposals } from '../../queries/people/proposals/usePersonProposal';
import { PERSON_QUERY_KEY } from '../../queries/people/usePerson';
import useUpdatePersonPhone from '../../queries/people/useUpdatePersonPhone';
import { TimeSpot, usePersonTimeSpots } from '../../queries/scheduled_calls/useScheduledInteractions';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { getTimezoneAbbreviation, phoneFormatter, timeFormatter, userTimezone } from '../../utils/formatter';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';
import { createProposalOptions, ProposalCustomOption } from '../ProposalBuilder/_components';
import { quoteOptionFormatting } from '../ProposalBuilder/_helpers';
import { retrieveFollowupFromLocalStorage, useDraftFollowup } from './helpers';
import PhoneEditor from './PhoneEditor';

export interface FormValues {
  cadence: ScheduledInteractionFlow;
  date: string;
  run_at: string;
  phone: string;
  note: string;
  proposal: string;
  include_proposal: boolean;
}

type IFollowupFormProps = Omit<FormikProps<FormValues>, 'setFieldValue'> & {
  scheduledCall: IScheduledCall | undefined;
  person: IPerson;
  leadGid: string;
  editPhones: boolean;
  setEditPhones: (b: boolean) => void;
  onDiscard: () => void;
  setFieldValue: (field: keyof FormValues, value: any, shouldValidate?: boolean) => void;
};

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
`;

const animationCss = (includeProposal: boolean) => css`
  animation: ${includeProposal ? fadeInAnimation : fadeOutAnimation} 0.5s forwards;
`;

const ProposalSharing = ({
  values,
  setFieldValue,
  proposals
}: {
  values: IFollowupFormProps['values'];
  setFieldValue: IFollowupFormProps['setFieldValue'];
  proposals: IProposal[] | undefined;
}) => {
  const { search } = useLocation();
  const proposalsOptions = createProposalOptions(proposals || []);
  const selectedProposal = proposals?.find(proposal => proposal.gid === values.proposal);
  const displayCreateProposal = proposalsOptions.length === 0;

  return (
    <>
      <Paragraph bold>Proposal sharing</Paragraph>
      {displayCreateProposal && (
        <FlexBox columnDirection alignItemsCenter gap={spacings.px8}>
          <img src={emptyImage} alt="Empty" width={225} />
          <Paragraph>No proposal was built</Paragraph>
          <Paragraph bold>
            <Link to={`${GuidedSellingPathnames.Proposal}${search}`}>Create proposal</Link>
          </Paragraph>
        </FlexBox>
      )}
      {!displayCreateProposal && (
        <FlexBox alignItemsCenter gap={spacings.px4} justifySpaceBetween>
          <Paragraph>Add this proposal to the follow-up</Paragraph>
          <SwitchToggle
            checked={values.include_proposal}
            onChange={() => {
              setFieldValue('include_proposal', !values.include_proposal);
            }}
            disabled={false}
          />
        </FlexBox>
      )}
      <FlexBox columnDirection gap={spacings.px24} customCss={animationCss(values.include_proposal)}>
        {!displayCreateProposal && (
          <SelectField
            preserveErrorSpace={false}
            required
            id="proposal"
            key="proposal"
            name="proposal"
            label="Proposal version"
            isLoading={!proposals}
            options={proposalsOptions}
            disabled={!values.include_proposal}
            customComponents={{ Option: ProposalCustomOption as unknown as React.ReactNode }}
          />
        )}
        {selectedProposal && (
          <FlexBox columnDirection border borderColor={colors.grey10} roundBorder p={spacings.px12} gap={spacings.px12}>
            {selectedProposal.proposal_options?.[0]?.quotes.map(quote => (
              <FlexBox
                key={quote.gid}
                alignItemsCenter
                justifySpaceBetween
                customCss={css`
                  flex: 1;
                  &:not(:last-child) {
                    border-bottom: 1px solid rgba(102, 102, 102, 0.4);
                    padding-bottom: ${spacings.px12}px;
                  }
                `}
              >
                <FlexBox alignItemsCenter gap={spacings.px16}>
                  {quote.attributes.insurable_interest === InsurableInterest.RealProperty ? <HomeIcon /> : <CarIcon />}
                  <div
                    css={css`
                      background-position: left;
                      background-repeat: no-repeat;
                      background-size: contain;
                      height: 30px;
                      width: 80px;
                      ${quote.attributes.carrier.logo ? `background-image: url(${quote.attributes.carrier.logo})` : ''};
                    `}
                    title={quote.attributes.carrier.name}
                  />
                </FlexBox>
                <Text bold color={colors.black}>
                  {quoteOptionFormatting({
                    premium: quote.attributes.premium || 0,
                    term_months: quote.attributes.payment_options?.[0]?.term_months as 6 | 12 | undefined
                  })}
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

const mapReasonToExtras = (spot: TimeSpot) => {
  if ('reason' in spot) {
    switch (spot.reason) {
      case 'past_time':
      case 'customer_timezone':
      case 'off_time':
        return { description: 'Off time', isDisabled: true };
      case 'lunch':
        return { description: 'Lunch', isDisabled: false };
      case 'meeting':
        return { description: 'Meeting', isDisabled: true };
      case 'another_call_scheduled':
        return { description: 'Scheduled', isDisabled: true };
    }
  }
};

export const TimeSpotOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];

  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      {children}
      <FlexBox gap={spacings.px4} alignItemsCenter>
        {option.description && (
          <Text color={rest.isSelected ? colors.white : colors.grey60} type="tiny" italic>
            {option.description}
          </Text>
        )}
        {rest.isSelected && <TickIcon color={colors.white} />}
      </FlexBox>
    </components.Option>
  );
};
const DateTimeSelects = ({
  date,
  personGid,
  leadGid,
  initialTime,
  initialDate,
  setFieldValue,
  scheduledCall
}: {
  date: string;
  personGid: string;
  leadGid: string;
  initialTime: string;
  initialDate: string;
  setFieldValue: IFollowupFormProps['setFieldValue'];
  scheduledCall: IFollowupFormProps['scheduledCall'];
}) => {
  const todayDate = useConstant(() => new Date());
  const debouncedDate = useDebouncedValue(date, 500);
  const isValidDate = new Date(debouncedDate).toString() !== 'Invalid Date';

  const { data, isFetching } = usePersonTimeSpots({
    date: isValidDate ? new Date(debouncedDate).toISOString() : '',
    person_gid: personGid,
    engagement_gid: leadGid,
    spot_duration: 30
  });

  const personTimezoneAbbreviation = data?.engagement_timezone ? getTimezoneAbbreviation(data.engagement_timezone) : '';

  const formatOptionValue = (time: string, isDisabled?: boolean) => {
    const localTimeString = timeFormatter(time, 'h:mm A');
    const personTimeZone = data?.engagement_timezone || 'UTC';
    const personTimeString = new Intl.DateTimeFormat('en-US', {
      timeZone: personTimeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(new Date(time));

    return (
      <span>
        {`${localTimeString}(${userTimezone.abbreviation})`}{' '}
        <span
          css={
            !isDisabled &&
            css`
              color: ${colors.grey30};
            `
          }
        >
          {`${personTimeString}(${personTimezoneAbbreviation})`}
        </span>
      </span>
    );
  };

  const regularTimeOptions = Object.keys(data?.time_spots || {}).map(timeKey => {
    const spot = data!.time_spots[timeKey]!;
    const isoTime = new Date(timeKey).toISOString();
    const extras = mapReasonToExtras(spot);

    return {
      key: isoTime,
      value: formatOptionValue(timeKey, extras?.isDisabled),
      ...extras
    };
  });

  const concatSavedTimeWithOptions = (options: any[], initialTime: string) => {
    if (initialTime && !options.some(option => option.key === initialTime)) {
      const normalizedTime = new Date(scheduledCall ? initialTime : `${debouncedDate} ${initialTime}`).toISOString();

      return [
        {
          key: initialTime,
          value: formatOptionValue(normalizedTime),
          isDisabled: false
        },
        ...options
      ];
    }

    return options;
  };

  const timeOptions =
    initialDate === date ? concatSavedTimeWithOptions(regularTimeOptions, initialTime) : regularTimeOptions;

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
      `}
    >
      <DateInputField
        preserveErrorSpace={false}
        prefixIcon={<CalendarIcon />}
        required
        key="date"
        label="Date"
        name="date"
        id="date"
        minDate={todayDate}
        onInputChange={() => {
          setFieldValue('run_at', '');
        }}
      />
      <SelectField
        preserveErrorSpace={false}
        menuMinWidth="0px"
        isLoading={isFetching}
        required
        id="run_at"
        label="Time"
        name="run_at"
        key="run_at"
        createOptionFromSearch
        options={timeOptions}
        showResetButton
        customComponents={{ Option: TimeSpotOption as unknown as React.ReactNode }}
        customStyles={{
          menu: (base: any) => ({
            ...base,
            borderRadius: '6px',
            border: `1px solid ${colors.grey30}`,
            background: colors.white,
            zIndex: '999999',
            minWidth: '350px',
            right: '0px'
          })
        }}
      />
    </div>
  );
};

const FollowupForm = ({
  person,
  editPhones,
  leadGid,
  setEditPhones,
  setFieldValue,
  values,
  onDiscard,
  isSubmitting,
  submitCount,
  errors,
  initialValues,
  scheduledCall
}: IFollowupFormProps) => {
  const [step, setStep] = React.useState<1 | 2>(() => retrieveFollowupFromLocalStorage(leadGid)?.step || 1);
  useDraftFollowup({ leadGid, values, step });

  const queryClient = useQueryClient();
  const { mutateAsync: updatePhone } = useUpdatePersonPhone(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_QUERY_KEY, person.gid] });
  });

  const phoneOptions = [
    { key: person.phone, value: phoneFormatter(person.phone), postfix: 'Primary' },
    { key: person.secondary_phone, value: phoneFormatter(person.secondary_phone), postfix: 'Secondary' },
    { key: person.business_phone, value: phoneFormatter(person.business_phone), postfix: 'Business' }
  ].filter(item => !!item.key);

  const { data: proposals } = usePersonProposals({
    personGid: person.gid,
    engagementGid: leadGid
  });

  React.useEffect(() => {
    if (values.include_proposal && !values.proposal && proposals?.[0]) {
      setFieldValue('proposal', proposals[0].gid);
    }
  }, [setFieldValue, values.include_proposal, proposals, values.proposal]);

  React.useEffect(() => {
    if (proposals && proposals.length === 0) {
      setFieldValue('include_proposal', false);
    }
  }, [setFieldValue, proposals]);

  const submitCountRef = React.useRef(submitCount);

  React.useEffect(() => {
    const errorsFromFirstStep = Object.keys(errors).some(key =>
      (['date', 'run_at', 'note', 'phone', 'cadence'] satisfies (keyof FormValues)[]).includes(key)
    );

    if (submitCount > submitCountRef.current && errorsFromFirstStep) {
      setStep(1);
      submitCountRef.current = submitCount;
    }
  }, [submitCount, errors]);

  return (
    <>
      {editPhones && (
        <PhoneEditor
          person={person}
          cancelBtnHandler={() => {
            setEditPhones(false);
          }}
          confirmBtnHandler={values =>
            updatePhone({
              personGid: person.gid,
              phone: values.phone,
              secondary_phone: values.secondary_phone,
              business_phone: values.business_phone
            }).then(() => {
              setEditPhones(false);
              setFieldValue('phone', '');
            })
          }
        />
      )}

      <FlexBox
        p={spacings.px24}
        columnDirection
        backgroundColor={colors.white}
        roundBorder
        border
        boxShadow
        customCss={css`
          width: 400px;
        `}
        gap={spacings.px24}
      >
        <FlexBox justifySpaceBetween alignItemsCenter>
          <FlexBox gap={spacings.px12} alignItemsCenter>
            {step === 2 && <IconButton color={colors.black} icon={ButtonIcons.ArrowLeft2} onClick={() => setStep(1)} />}
            <Paragraph type="large" bold>
              Schedule follow-up
            </Paragraph>
          </FlexBox>
          <Paragraph type="small" color={colors.grey60}>
            Step {step}/2
          </Paragraph>
        </FlexBox>
        {step === 1 && (
          <SelectField
            preserveErrorSpace={false}
            required
            id="cadence"
            key="cadence"
            name="cadence"
            label="Cadence"
            options={[
              { label: 'Low priority (sms/emails only)', key: ScheduledInteractionFlow.PipelineLowPriority },
              { label: 'High priority', key: ScheduledInteractionFlow.PipelineHighPriority }
            ]}
          />
        )}
        {values.cadence === ScheduledInteractionFlow.PipelineLowPriority && !values.phone && (
          <FormError id="custom-phone" error="Phone is missing" hasError />
        )}
        {values.cadence === ScheduledInteractionFlow.PipelineHighPriority && step === 1 && (
          <>
            <DateTimeSelects
              personGid={person.gid}
              date={values.date}
              leadGid={leadGid}
              initialTime={initialValues.run_at}
              initialDate={initialValues.date}
              setFieldValue={setFieldValue}
              scheduledCall={scheduledCall}
            />
            <SelectField
              preserveErrorSpace={false}
              required
              id="phone"
              key="phone"
              name="phone"
              label="Phone"
              options={phoneOptions}
              menuOptions={
                <>
                  <hr
                    css={css`
                      margin: 0 10px 10px 10px;
                    `}
                  />
                  <Button
                    onClick={() => setEditPhones(true)}
                    variant={ButtonVariant.Text}
                    data-testid="modify-phones-button"
                    css={css`
                      margin: 0 5px 10px 10px;
                      text-align: left;
                      width: 100%;
                    `}
                  >
                    Modify phones
                  </Button>
                </>
              }
            />
          </>
        )}
        {step === 2 && <ProposalSharing values={values} setFieldValue={setFieldValue} proposals={proposals} />}
        {step === 1 && (
          <TextareaField
            preserveErrorSpace={false}
            required
            id="note"
            key="note"
            name="note"
            label="Note"
            placeholder="Note here"
          />
        )}
        <FlexBox columnDirection gap={spacings.px12}>
          <FlexBox justifyRight gap={spacings.px24}>
            <ButtonWithoutBorder
              disabled={isSubmitting}
              customCss={css`
                color: ${colors.black};
              `}
              onClick={onDiscard}
            >
              Discard
            </ButtonWithoutBorder>
            <Button
              size={ButtonSize.Small}
              key={step}
              loading={isSubmitting}
              type={step === 2 ? 'submit' : 'button'}
              onClick={() => {
                step === 1 && setStep(2);
              }}
            >
              {step === 1 ? (
                <FlexBox alignItemsCenter gap={spacings.px12}>
                  Proceed <ArrowRight2Icon />
                </FlexBox>
              ) : (
                'Schedule follow-up'
              )}
            </Button>
          </FlexBox>
          <Paragraph
            type="tiny"
            color={colors.grey60}
            customCss={css`
              text-align: center;
            `}
          >
            Your progress will be saved unless you discard or close the lead
          </Paragraph>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default FollowupForm;
