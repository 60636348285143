/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const CustomerProfileSkeleton = () => {
  return (
    <FlexBox columnDirection border roundBorder boxShadow boxHoverShadow p={spacings.px8} gap={spacings.px8}>
      <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
        <FlexBox alignItemsCenter gap={spacings.px8} mb={spacings.px4}>
          <Container>
            <Skeleton
              circle
              style={{
                width: '20px',
                height: '20px'
              }}
            />
          </Container>
          <Container>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '140px'
              }}
            />
          </Container>
          <Container>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '140px'
              }}
            />
          </Container>
        </FlexBox>
      </SkeletonTheme>
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        {[1, 2, 3].map(i => (
          <FlexBox alignItemsCenter gap={spacings.px8} key={i}>
            <Container
              customCss={css`
                width: 20px;
              `}
            />
            <Container
              customCss={css`
                width: 140px;
              `}
            >
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px',
                  width: '60px'
                }}
              />
            </Container>
            <Container>
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px',
                  width: '140px'
                }}
              />
            </Container>
          </FlexBox>
        ))}
      </SkeletonTheme>
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <FlexBox alignItemsCenter gap={spacings.px8}>
          <Container>
            <Skeleton
              circle
              style={{
                width: '20px',
                height: '20px'
              }}
            />
          </Container>
          <Container
            customCss={css`
              width: 140px;
            `}
          >
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '60px'
              }}
            />
          </Container>
          <Container>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '140px'
              }}
            />
          </Container>
        </FlexBox>
      </SkeletonTheme>
    </FlexBox>
  );
};

export default CustomerProfileSkeleton;
