import { css } from '@emotion/react';
import React, { useState } from 'react';

import AutoEditor from '../../../components/Assets/AutoEditor';
import HomeDeleteModal from '../../../components/Assets/HomeDeleteModal';
import HomeEditor from '../../../components/Assets/HomeEditor';
import VehicleDeleteModal from '../../../components/Assets/VehicleDeleteModal';
import { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import DropDown from '../../../components/core/DropDown/DropDown';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { SORT_ORDER_DESC } from '../../../constants';
import { IHome, IVehicle } from '../../../interfaces';
import { createPersonVehicle, updatePersonVehicle, VehiclePayload } from '../../../queries/people/personVehicles';
import { createHome, HomePayload, updateHome } from '../../../queries/people/usePersonHomes';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';
import usePersonAssetsData from './_hooks/usePersonAssetsData';
import HomesList from './HomesList';
import AutosList from './VehiclesList';

const CustomerAssets = ({ personGid }: { personGid: string }) => {
  const [openHomeEditor, setOpenHomeEditor] = useState(false);
  const [openAutoEditor, setOpenAutoEditor] = useState(false);
  const [editedHome, setEditedHome] = useState<IHome>();
  const [editedVehicle, setEditedVehicle] = useState<IVehicle>();
  const [homeToDelete, setHomeToDelete] = useState<string>();
  const [vehicleToDelete, setVehicleToDelete] = useState<string>();

  const closeHomeEditor = () => {
    setOpenHomeEditor(false);
    setEditedHome(undefined);
  };

  const closeVehicleEditor = () => {
    setOpenAutoEditor(false);
    setEditedVehicle(undefined);
  };

  const { person, homes, vehicles, personAddresses, isFetching, refetch } = usePersonAssetsData(personGid);

  const onSubmitHome = (home: HomePayload) => {
    if (editedHome?.gid) {
      return updateHome({ personGid, homeGid: editedHome.gid, data: home }).then(() => {
        refetch();
        closeHomeEditor();
      });
    } else {
      return createHome({ personGid, data: home }).then(({ home }) => {
        analytics.track('Home added', {
          lead_gid: undefined,
          person_gid: personGid,
          place: 'old_customer_profile_ui',
          home_gid: home.gid
        });
        refetch();
        closeHomeEditor();
      });
    }
  };

  const onSubmitVehicle = (vehicle: VehiclePayload) => {
    if (editedVehicle?.gid) {
      return updatePersonVehicle({ personGid, vehicleGid: editedVehicle.gid, data: vehicle }).then(() => {
        refetch();
        closeVehicleEditor();
      });
    } else {
      return createPersonVehicle({ personGid, data: vehicle }).then(({ vehicle }) => {
        analytics.track('Vehicle added', {
          lead_gid: undefined,
          person_gid: personGid,
          place: 'old_customer_profile_ui',
          source: 'manual',
          vehicle_gid: vehicle.gid
        });
        refetch();
        closeVehicleEditor();
      });
    }
  };

  return isFetching ? (
    <TablePlaceholder testId="lead-assets-loader" />
  ) : (
    <Container fitParentWidth>
      <FlexBox pb={spacings.px24} justifySpaceBetween>
        <Text type="large" bold>
          Homes
        </Text>
        <div className="d-flex flex-row-reverse">
          <DropDown
            testId="add_asset"
            options={[
              { label: 'Add home', value: 'add_home', action: () => setOpenHomeEditor(true) },
              { label: 'Add auto', value: 'add_auto', action: () => setOpenAutoEditor(true) }
            ]}
            onSelected={selectedOption => {
              selectedOption.action && selectedOption.action();
            }}
            size={ButtonSize.Small}
            customOptionsCss={css`
              width: 140px;
            `}
          >
            + Add Asset
          </DropDown>
        </div>
      </FlexBox>
      <HomesList
        homes={homes || []}
        onEditHome={home => {
          setEditedHome(home);
          setOpenHomeEditor(true);
        }}
        onDeleteHome={home => setHomeToDelete(home.gid)}
        defaultSorting={{ sort_column: 'id', sort_order: SORT_ORDER_DESC }}
        testId="customer-home-assets"
        personGid={personGid}
      />
      <FlexBox pt={spacings.px24} pb={spacings.px24}>
        <Text type="large" bold>
          Autos
        </Text>
      </FlexBox>
      <AutosList
        vehicles={vehicles || []}
        onEditVehicle={vehicle => {
          setEditedVehicle(vehicle);
          setOpenAutoEditor(true);
        }}
        onDeleteVehicle={vehicle => setVehicleToDelete(vehicle.gid)}
        defaultSorting={{ sort_column: 'id', sort_order: SORT_ORDER_DESC }}
      />
      {openHomeEditor && (
        <HomeEditor
          open
          home={{
            ...editedHome,
            kind: editedHome?.garages?.[0]?.kind,
            car_count: editedHome?.garages?.[0]?.car_count
          }}
          cancelBtnHandler={() => closeHomeEditor()}
          confirmBtnHandler={(home: any) => {
            const { address_attributes, ...rest } = home;
            const homeWithAddress = { ...rest, address: address_attributes };

            return onSubmitHome(homeWithAddress);
          }}
          person={person!}
          customerAddresses={personAddresses}
        />
      )}
      {openAutoEditor && (
        <AutoEditor
          open
          auto={editedVehicle}
          cancelBtnHandler={() => closeVehicleEditor()}
          person={person}
          confirmBtnHandler={(vehicle: any) => onSubmitVehicle({ ...vehicle, address: vehicle.address_attributes })}
          customerAddresses={personAddresses}
        />
      )}
      {homeToDelete && (
        <HomeDeleteModal
          cancelBtnHandler={() => setHomeToDelete(undefined)}
          confirmBtnHandler={homeGid => {
            analytics.track('Home removed', {
              lead_gid: undefined,
              person_gid: personGid,
              place: 'old_customer_profile_ui',
              home_gid: homeGid
            });
            return refetch();
          }}
          personGid={personGid}
          homeGid={homeToDelete}
          leadId={undefined}
        />
      )}
      {vehicleToDelete && (
        <VehicleDeleteModal
          cancelBtnHandler={() => setVehicleToDelete(undefined)}
          confirmBtnHandler={vehicleGid => {
            analytics.track('Vehicle removed', {
              lead_gid: undefined,
              person_gid: personGid,
              place: 'old_customer_profile_ui',
              vehicle_gid: vehicleGid
            });
            return refetch();
          }}
          personGid={personGid}
          vehicleGid={vehicleToDelete}
          leadId={undefined}
        />
      )}
    </Container>
  );
};

export default CustomerAssets;
