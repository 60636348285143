/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import SimpleSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useDeepEqualMemo } from '../../../../hooks';
import colors from '../../../../theme/colors';
import { borderRadius, InputSize } from '../../../../theme/variables';
import { except } from '../../../../utils/object';
import FlexBox from '../../FlexBox';
import { CloseIcon } from '../../icons';
import Text from '../../Text';
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  fontValues,
  IndicatorSeparator,
  inlineSelectStyles,
  Input,
  Menu,
  SelectProps,
  styles
} from '../Select';

interface MultiSelectProps extends SelectProps {
  valuesSeparator?: boolean;
}

const emptyArray = [] as any[];

const Option = ({ children, data: option, ...rest }: any) => (
  <components.Option {...rest}>
    <FlexBox columnDirection>
      {children}
      {option.description && (
        <div>
          <Text color={colors.grey60} type="tiny">
            {option.description}
          </Text>
        </div>
      )}
    </FlexBox>
    <input
      type="checkbox"
      checked={rest.isSelected}
      disabled={rest.isDisabled}
      readOnly
      css={css`
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
      `}
    />
  </components.Option>
);

const MultiValueRemove = (props: any) => (
  <div
    {...props.innerProps}
    aria-label={`Remove ${props.data.label}`}
    css={css`
      z-index: 99999;
    `}
  >
    <CloseIcon />
  </div>
);

const MultiValue = ({ children, ...rest }: any) => {
  const innerProps = { ...rest.innerProps, 'data-testid': 'select-multi-value' };
  const isMasked = rest.selectProps['fsMask'];
  return (
    <components.MultiValue {...rest} innerProps={innerProps}>
      <p
        className={isMasked ? 'fs-mask' : ''}
        css={css`
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
      >
        {children}
      </p>
    </components.MultiValue>
  );
};

const multiSelectCustomStyles = ({
  inline,
  pickedOptionsLength,
  inputSize,
  valuesSeparator
}: {
  inline: boolean;
  pickedOptionsLength: number;
  inputSize: InputSize;
  valuesSeparator: boolean;
}) => {
  return {
    ...styles,
    control: (_provided: any, state: any) => ({
      color: colors.black,
      height: 'auto',
      borderRadius: `${borderRadius}px`,
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      ...fontValues(inputSize),
      ...(inline
        ? inlineSelectStyles(state)
        : {
            backgroundColor: state.selectProps.isDisabled ? colors.grey10 : colors.white,
            border: `1px solid ${state.selectProps.hasError ? colors.statusRed : colors.black}`
          })
    }),
    option: (
      base: any,
      { isSelected, isFocused, isDisabled }: { isSelected: boolean; isFocused: boolean; isDisabled: boolean }
    ) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...fontValues(inputSize),
      '&:first-of-type': {
        borderTopLeftRadius: `${borderRadius}px`,
        borderTopRightRadius: `${borderRadius}px`
      },
      '&:last-of-type': {
        borderBottomLeftRadius: `${borderRadius}px`,
        borderBottomRightRadius: `${borderRadius}px`
      },
      backgroundColor: isFocused ? colors.grey10 : isSelected && colors.white,
      color: isDisabled ? colors.grey60 : colors.black,
      cursor: isDisabled ? 'not-allowed' : ''
    }),
    multiValue: (base: any, state: any) => {
      const { withValueRemove } = state.selectProps;

      if (withValueRemove) {
        return { ...base, backgroundColor: 'transparent', ...fontValues(inputSize) };
      }

      const valueSeparatorStyle = valuesSeparator
        ? {
            'p:after': {
              content: '","'
            }
          }
        : {};

      return {
        ...base,
        ...fontValues(inputSize),
        ...valueSeparatorStyle,
        backgroundColor: 'transparent',
        [`&:nth-of-type(${pickedOptionsLength})`]: {
          //it's removing coma from last element
          'p::after': {
            content: '""',
            position: 'relative',
            top: '3px',
            right: '2.5px'
          }
        },
        p: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'block'
        }
      };
    },
    multiValueRemove: (base: any) => ({
      ...base,
      ...fontValues(inputSize),
      '&:hover': {
        backgroundColor: colors.white
      }
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      ...fontValues(inputSize),
      color: colors.black,
      paddingLeft: 0
    }),
    menu: (base: any) => ({
      ...base,
      ...fontValues(inputSize),
      borderRadius: `${borderRadius + 2}px`,
      border: `1px solid ${colors.grey30}`,
      background: colors.white,
      zIndex: '999999'
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: '7px;' // makes it even with simple select
    }),
    container: (base: any, state: any) => ({
      ...base,
      ...fontValues(inputSize),
      pointerEvents: 'auto',
      cursor: state.selectProps.isDisabled ? 'not-allowed' : 'pointer',
      '.select-dropdown-indicator': {
        visibility: 'hidden',
        opacity: 0
      },
      '&:hover': {
        '.select-dropdown-indicator': {
          visibility: 'visible',
          opacity: 1
        }
      }
    })
  };
};

const MultiSelect = ({
  options = emptyArray,
  labelName = 'value',
  valueName = 'key',
  name = '',
  placeholder,
  disabled = false,
  ordered = false,
  className = '',
  showResetButton = false,
  onChange,
  value,
  inputSize = InputSize.Medium,
  createOptionFromSearch,
  valuesSeparator = true,
  ...props
}: MultiSelectProps): JSX.Element => {
  const [selectOptions, setSelectOptions] = React.useState(emptyArray);
  const [createdOptions, setCreatedOptions] = React.useState<{ label: string; value: string }[]>(emptyArray);

  // memoize large options in parent
  const providedOptions = useDeepEqualMemo(options);

  React.useEffect(() => {
    const freshOptions = providedOptions
      .map(option => ({
        label: option[labelName],
        value: option[valueName],
        ...except(option, 'key', 'value')
      }))
      .concat(createdOptions);

    if (ordered) {
      freshOptions.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    }

    setSelectOptions(freshOptions);
  }, [createdOptions, providedOptions, labelName, valueName, ordered]);

  const onValueChange = (items: any) => {
    if (name) {
      onChange({ target: { name, value: items.map((item: any) => item.value) } });
    } else {
      onChange(items.map((item: any) => item.value));
    }
  };

  const createFromSearch = (inputValue: string) => {
    setCreatedOptions(current => [...current, { label: inputValue, value: inputValue }]);

    onValueChange((value || []).map((i: any) => ({ value: i })).concat({ value: inputValue }));
  };

  const availableOptions = selectOptions.filter(
    (option: any) => typeof option.unavailable === 'undefined' || !option.unavailable
  );

  const pickedOption = value ? selectOptions.filter((option: any) => value.indexOf(option.value) >= 0) : [];

  const SelectComponent = createOptionFromSearch ? CreatableSelect : SimpleSelect;

  // @ts-expect-error whatever
  SelectComponent.displayName = createOptionFromSearch ? 'CreatableMultiSelect' : 'MultiSelect';

  return (
    <SelectComponent
      {...props}
      {...(createOptionFromSearch ? { onCreateOption: createFromSearch } : {})}
      css={props.customCss}
      styles={multiSelectCustomStyles({
        inline: !!props.inline,
        pickedOptionsLength: pickedOption.length,
        inputSize,
        valuesSeparator
      })}
      placeholder={placeholder || (props.inline ? '—' : 'Select multiple options')}
      value={pickedOption}
      onChange={onValueChange}
      options={availableOptions}
      className={className}
      components={{
        Control,
        ClearIndicator,
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        Input,
        Menu,
        MultiValueRemove: props.withValueRemove ? MultiValueRemove : () => null,
        MultiValue,
        ...props.customComponents
      }}
      isClearable={showResetButton}
      backspaceRemovesValue={false}
      isDisabled={disabled}
      isMulti={true}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.azure50,
          primary25: colors.grey10
        }
      })}
    />
  );
};

export default MultiSelect;
