import React from 'react';

import { InsurableInterest, PolicyType } from '../../../../interfaces/IPolicyType';
import { ConfigurationComponentProps } from './_interfaces/IConfigurationComponentProps';
import AutoOpportunity from './AutoOpportunity';
import MotoOpportunity from './MotoOpportunity';
import PAFOpportunity from './PAFOpportunity';
import PetOpportunity from './PetOpportunity';
import RealPropertyOpportunity from './RealPropertyOpportunity';
import RentersOpportunity from './RentersOpportunity';
import RVOpportunity from './RVOpportunity';
import UmbrellaOpportunity from './UmbrellaOpportunity';

const OpportunityConfigurationComponent = ({
  index,
  person,
  leadId,
  opportunity,
  quotesRequests,
  refetchCurrentPolicies,
  onMoveToOpportunity
}: ConfigurationComponentProps) => {
  if (opportunity.policy_type === PolicyType.Umbrella) {
    return (
      <UmbrellaOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.policy_type === PolicyType.PAF) {
    return (
      <PAFOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.policy_type === PolicyType.Moto) {
    return (
      <MotoOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.policy_type === PolicyType.RV) {
    return (
      <RVOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.policy_type === PolicyType.Pet) {
    return (
      <PetOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.policy_type === PolicyType.Renters) {
    return (
      <RentersOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.insurable_interest === InsurableInterest.RealProperty) {
    return (
      <RealPropertyOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  if (opportunity.insurable_interest === InsurableInterest.Vehicle) {
    return (
      <AutoOpportunity
        index={index}
        person={person}
        leadId={leadId}
        opportunity={opportunity}
        quotesRequests={quotesRequests}
        refetchCurrentPolicies={refetchCurrentPolicies}
        onMoveToOpportunity={onMoveToOpportunity}
      />
    );
  }

  return null;
};

export default OpportunityConfigurationComponent;
