const colors = {
  black: '#000000',
  white: '#FFFFFF',
  cornflower: '#66A4F7',
  azure50: '#156EEA',
  denim: '#1362D1',
  sun: '#F9E14B',
  aqua: '#6EE4D6',
  cherise: '#EA4D72',
  cherise50: '#E41B4A',
  violet: '#5920CE',
  grey80: '#333333',
  grey60: '#666666',
  grey32: '#525252',
  grey30: '#B3B3B3',
  grey10: '#E6E6E6',
  grey5: '#F2F2F2',
  grey3: '#FAFAFA',
  statusRed: '#C80000',
  statusGreen: '#2F8802',
  statusOrange: '#FC951D',
  warningBackground: '#FFF3E5',
  violetBackground: '#EBE4F9',
  redBackground: '#FFE0E0',
  greenBackground: '#F5FFF0',
  blueHoverLink: '#00598C',
  ghostWhite: '#F6F9FE',
  azure95: '#E5F1FF',
  yellow200: '#FEF08A',
  yellow800: '#854D0E'
};

export const opacities = {
  opacity_5: '0D', // 5%
  opacity_10: '1A', // 10%
  opacity_16: '29' // 16%
};

export default colors;
