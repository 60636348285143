import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCallState } from '../../contexts/CallContext';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { CurrentDispositionSubscriptionContextProvider } from './_hooks/useCurrentDispositionSubscription';
import KnownPersonPage from './KnownPersonPage';
import LoadingPage from './LoadingPage';
import guidedSellingExperienceNavigation, { LocationState } from './navigation';
import UnknownPersonPage from './UnknownPersonPage';

const GuidedSellingExperience = () => {
  const { isLoading, person, callLogId } = useGuidedSellingExperienceContext();

  const navigate = useNavigate();
  const { search, state } = useLocation();
  const openedFrom = (state as LocationState)?.openedFrom;

  const { currentCallId } = useCallState();

  React.useEffect(() => {
    if (!callLogId && !!currentCallId) {
      const redirectUrl = guidedSellingExperienceNavigation.forCallWithCurrentSearchParams({
        search,
        call_log_id: currentCallId
      });

      // eslint-disable-next-line no-console
      console.log('No call_log_id url param detected while user is on a call, redirecting to', redirectUrl);

      navigate(redirectUrl, {
        replace: true,
        state: { openedFrom }
      });
    }
  }, [callLogId, currentCallId, navigate, openedFrom, search]);

  const pageForState = () => {
    if (isLoading) {
      // We should use useRouteMatch here to use new Skeleton only for Summary page
      // as it is not designed for other pages
      return <LoadingPage />;
    } else if (person) {
      return (
        <CurrentDispositionSubscriptionContextProvider>
          <KnownPersonPage />
        </CurrentDispositionSubscriptionContextProvider>
      );
    }

    return <UnknownPersonPage />;
  };

  return pageForState();
};

export default GuidedSellingExperience;
