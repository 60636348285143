/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Button from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import Heading from '../../../components/core/Heading';
import CancelTrailingScheduledCallModal from '../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { ILoan, IOpportunity } from '../../../interfaces';
import { DispositionType, ICurrentDisposition } from '../../../interfaces/IDisposition';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { IMaticPolicy, PolicyPaymentMethod } from '../../../interfaces/IPolicy';
import {
  findPolicyType,
  isNonLifeAncillaryPolicyType,
  originAssetTypeForPolicyType
} from '../../../interfaces/IPolicyType';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { updateOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import useUpdatePolicy, { UpdatePolicyRequest } from '../../../queries/people/person_policies/useUpdatePolicy';
import { PERSON_TASKS_QUERY_KEY } from '../../../queries/people/person_tasks/usePersonTasks';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import { useActiveScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';
import { GuidedSellingPathnames } from '../../GuidedSellingExperience/navigation';
import { usePolicyAndMissingDocumentTypeTuple } from '../_hooks';
import PolicyDetails from './PolicyDetails';

const CLOSE_OPPORTUNITIES_TIP = 'You need to close opportunities to proceed.';
const CONFIRM_TIP_ID = 'confirm-button';

const loanTipTemplate = (policy: IMaticPolicy, text: string) =>
  `${policy.payment_method === PolicyPaymentMethod.Escrow ? 'You chose the Escrow billing type. ' : ''}${
    text
  } ${findPolicyType(policy.policy_type)?.name || policy.policy_type} policy - ${policy.policy_number}.`;

const documentTipTemplate = (policy: IMaticPolicy, documentTitle: string) =>
  `You didn't include the required document. Please add ${documentTitle} document for ` +
  `${findPolicyType(policy.policy_type)?.name || policy.policy_type} policy - ${policy.policy_number}.`;

const SubmitButtonWithTips = ({
  closeOpportunitiesTip,
  missingLoanEscrowPolicy,
  missingLoanNumberPolicy,
  policyAndMissingDocTypeTuple,
  disabledSubmit,
  isSubmitting
}: {
  disabledSubmit: boolean;
  isSubmitting: boolean;
  closeOpportunitiesTip: boolean;
  missingLoanEscrowPolicy: IMaticPolicy | undefined;
  missingLoanNumberPolicy: IMaticPolicy | undefined;
  policyAndMissingDocTypeTuple: ReturnType<typeof usePolicyAndMissingDocumentTypeTuple>;
}) => {
  const tipText = (() => {
    switch (true) {
      case closeOpportunitiesTip:
        return CLOSE_OPPORTUNITIES_TIP;
      case !!policyAndMissingDocTypeTuple:
        return documentTipTemplate(policyAndMissingDocTypeTuple[0], policyAndMissingDocTypeTuple[1].title);
      case !!missingLoanEscrowPolicy:
        return loanTipTemplate(missingLoanEscrowPolicy, 'Please add a loan for');
      case !!missingLoanNumberPolicy:
        return loanTipTemplate(missingLoanNumberPolicy, 'Please add a loan number for');
      default:
        return '';
    }
  })();

  return (
    <>
      <Button
        data-for={CONFIRM_TIP_ID}
        data-tip={tipText}
        disabled={disabledSubmit || isSubmitting}
        loading={isSubmitting}
        type="submit"
        customCss={css`
          min-width: 320px;
        `}
      >
        Proceed
      </Button>
      {tipText && <Tooltip id={CONFIRM_TIP_ID} />}
    </>
  );
};

const filterLoansByPolicies = (loans: ILoan[], policies: IMaticPolicy[]) => {
  return loans.filter(loan =>
    policies.some(policy => {
      const assetType = originAssetTypeForPolicyType(policy.policy_type);

      if (!assetType) {
        return false;
      }

      return policy.assets?.[0]?.gid === loan[assetType]?.gid;
    })
  );
};

const ReviewForm = ({
  opportunities,
  currentDisposition,
  isDataEditingForbidden,
  onSubmitCallback
}: {
  isDataEditingForbidden: boolean;
  opportunities: IOpportunity[];
  currentDisposition: ICurrentDisposition | undefined;
  onSubmitCallback?: () => void;
}): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { lead, person } = useGuidedSellingExperienceContext();
  const { data: policies = [], isFetchedAfterMount: isPoliciesFetched } = usePersonMaticPolicies({
    personGid: person!.gid,
    filters: { lead_id: lead!.id }
  });
  const { mutateAsync: updatePolicy } = useUpdatePolicy();
  const { data: loans = [] } = usePersonLoans({ personGid: person!.gid });
  const [openCancelCallModal, setOpenCancelCallModal] = React.useState<boolean>(false);
  const { data: scheduledCalls, isFetchedAfterMount: isScheduledCallsFetched } = useActiveScheduledInteractions(
    person!.gid
  );

  const policyAndMissingDocTypeTuple = usePolicyAndMissingDocumentTypeTuple({ personGid: person!.gid, policies });

  const isDataLoaded = isPoliciesFetched && isScheduledCallsFetched && currentDisposition && lead && person;

  const uncompletedCall = scheduledCalls?.[0];

  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const opportunitiesToConfirm = opportunities.filter(
    opportunity =>
      !isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened && opportunity.is_data_collection_enabled
  );

  const opportunitiesToAutoclose = opportunities
    .filter(
      opportunity =>
        (isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened) || !opportunity.is_data_collection_enabled
    )
    .map(opportunity => ({ ...opportunity, reason: opportunity.reason || 'not_interested' }));

  const sortedPolicies = policies.sort((a, b) => {
    const aIndex = opportunities.findIndex(opp => opp.policy_type === a.policy_type);
    const bIndex = opportunities.findIndex(opp => opp.policy_type === b.policy_type);

    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  });

  const escrowPolicies = sortedPolicies.filter(policy => policy.payment_method === PolicyPaymentMethod.Escrow);
  const directBillPolicies = sortedPolicies.filter(policy => policy.payment_method === PolicyPaymentMethod.DirectBill);

  const escrowPolicyWithoutLoan = escrowPolicies.find(policy => {
    const assetType = originAssetTypeForPolicyType(policy.policy_type);

    if (!assetType) {
      return false;
    }

    return !loans.find(loan => loan[assetType]?.gid === policy.assets?.[0]?.gid);
  });

  const loansForEscrowPolicies = filterLoansByPolicies(loans, escrowPolicies);
  const loansForDirectBillPolicies = filterLoansByPolicies(loans, directBillPolicies);

  const loanWithMissingNumber =
    loansForDirectBillPolicies.find(loan => !loan.loan_number) ||
    loansForEscrowPolicies.find(loan => !loan.loan_number);

  const policyForLoanWithMissingNumber = sortedPolicies.find(policy => {
    if (!loanWithMissingNumber) {
      return false;
    }

    return (
      loanWithMissingNumber.home?.gid === policy.assets?.[0]?.gid ||
      loanWithMissingNumber.vehicle?.gid === policy.assets?.[0]?.gid
    );
  });

  const applyHardStopFeature =
    !!escrowPolicyWithoutLoan ||
    !!policyForLoanWithMissingNumber ||
    Boolean(featureFlags.hardStopForMissingDocuments && policyAndMissingDocTypeTuple);

  const disabledSubmit = !!opportunitiesToConfirm.length || isDataEditingForbidden || applyHardStopFeature;

  if (!isDataLoaded) {
    return (
      <Container p={spacings.px24}>
        <FormLoader />
      </Container>
    );
  }

  return (
    <BaseForm
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      customControls
      initialValues={{}}
      onSubmit={async () => {
        setIsSubmitting(true);

        currentDisposition.disposition_type !== DispositionType.PolicySold &&
          (await Promise.all(
            opportunitiesToAutoclose.map(opp =>
              updateOpportunity({
                leadId: lead.id,
                opportunityId: opp.id,
                params: { status: OpportunityStatus.Lost, reason: opp.reason || 'not_interested' }
              })
            )
          )
            .then(() => {
              return updateDisposition({
                leadId: lead.id,
                disposition_type: sortedPolicies.length ? DispositionType.PolicySold : DispositionType.Lost
              });
            })
            .catch(() => {
              setIsSubmitting(false);
            }));
        analytics.track('Final look confirm button clicked');

        policies.forEach(policy => {
          const { id, ...rest } = policy;
          const policyLoanGid = loans.find(
            loan => loan.home?.gid === policy.assets?.[0]?.gid || loan.vehicle?.gid === policy.assets?.[0]?.gid
          )?.gid;

          !policy.primary_loan_gid &&
            policyLoanGid &&
            updatePolicy({
              person_gid: person.gid,
              policy_id: policy.id,
              carrier_id: policy.carrier.id,
              ...rest,
              primary_loan_gid: policyLoanGid
            } as unknown as UpdatePolicyRequest);
        });

        if (uncompletedCall) {
          setOpenCancelCallModal(true);
        } else {
          setIsSubmitting(false);
          onSubmitCallback ? onSubmitCallback() : navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
        }
        return queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY, person.gid] });
      }}
      renderForm={() => (
        <FlexBox
          columnDirection
          justifySpaceBetween
          customCss={css`
            flex: 1;
          `}
        >
          <FlexBox columnDirection gap={spacings.px24}>
            <Heading>Review</Heading>

            <FlexBox columnDirection gap={spacings.px40}>
              {sortedPolicies.map(policy => (
                <PolicyDetails personGid={person.gid} policy={policy} key={policy.policy_number} />
              ))}
            </FlexBox>

            {openCancelCallModal && uncompletedCall && (
              <CancelTrailingScheduledCallModal
                assignee={currentDisposition.assignee}
                personGid={person.gid}
                scheduledCall={uncompletedCall}
                onCloseModal={() => {
                  setOpenCancelCallModal(false);

                  setIsSubmitting(false);
                  navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
                }}
              />
            )}
          </FlexBox>
          <FlexBox justifyRight>
            <SubmitButtonWithTips
              disabledSubmit={disabledSubmit}
              isSubmitting={isSubmitting}
              closeOpportunitiesTip={!!opportunitiesToConfirm.length}
              missingLoanEscrowPolicy={escrowPolicyWithoutLoan}
              missingLoanNumberPolicy={policyForLoanWithMissingNumber}
              policyAndMissingDocTypeTuple={
                featureFlags.hardStopForMissingDocuments ? policyAndMissingDocTypeTuple : null
              }
            />
          </FlexBox>
        </FlexBox>
      )}
    />
  );
};

export default ReviewForm;
