import { useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import Link from '../../../../components/common/Link/Link';
import Container from '../../../../components/core/Container';
import FormModal from '../../../../components/core/FormModal';
import { MultiSelectField, SelectField } from '../../../../components/core/forms/fields';
import { ModalSize } from '../../../../components/core/Modal';
import Text from '../../../../components/core/Text';
import {
  areManyAssetsSupported,
  isOnlyOneAssetSupported,
  PolicyType,
  PolicyTypes
} from '../../../../interfaces/IPolicyType';
import { createOpportunity } from '../../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonAssets from '../../../../queries/people/person_assets/usePersonAssets';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { REQUIRED_MESSAGE, requiredField } from '../../../../utils/yupRules';

const NotifyPolicyTypeUpdated = ({
  onPolicyTypeUpdated
}: {
  onPolicyTypeUpdated: (policyType: PolicyType) => void;
}) => {
  const {
    values: { policy_type }
  } = useFormikContext<{ policy_type: PolicyType }>();

  React.useEffect(() => {
    onPolicyTypeUpdated(policy_type);
  }, [onPolicyTypeUpdated, policy_type]);

  return null;
};

const CreateOpportunityModal = ({
  leadId,
  personGid,
  cancelHandler,
  confirmHandler
}: {
  leadId: number;
  personGid: string;
  cancelHandler: () => void;
  confirmHandler: () => void;
}) => {
  const [selectedPolicyType, setSelectedPolicyType] = React.useState<PolicyType>();
  const { data: assetsForNewOpportunity, isFetching } = usePersonAssets({
    personGid,
    policyType: selectedPolicyType,
    enabled: !!selectedPolicyType
  });

  const areManyAssetsRequired = !!selectedPolicyType && areManyAssetsSupported(selectedPolicyType);
  const isOnlyOneAssetRequired = !!selectedPolicyType && isOnlyOneAssetSupported(selectedPolicyType);

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.medium}
      initialValues={{ policy_type: '', asset_gids: [], asset_gid: '' }}
      confirmHandler={({ policy_type, asset_gids, asset_gid }) => {
        let assetGids;

        if (areManyAssetsRequired) {
          assetGids = asset_gids;
        } else if (isOnlyOneAssetRequired) {
          assetGids = [asset_gid];
        } else {
          assetGids = undefined;
        }

        createOpportunity({ leadId, payload: { policy_type, assets_gids: assetGids } }).then(() => confirmHandler());
      }}
      cancelHandler={cancelHandler}
      confirmText="Add"
      containerTitle="Add opportunity"
      validationSchema={yup.object().shape({
        policy_type: requiredField,
        ...(areManyAssetsRequired ? { asset_gids: yup.array().min(1, REQUIRED_MESSAGE) } : {}),
        ...(isOnlyOneAssetRequired ? { asset_gid: requiredField } : {})
      })}
      disabled={isFetching}
      renderForm={() => (
        <Container m={spacings.px16}>
          <NotifyPolicyTypeUpdated onPolicyTypeUpdated={setSelectedPolicyType} />
          <SelectField
            required
            label="Type"
            id="policy_type"
            name="policy_type"
            options={PolicyTypes.map(({ key, name }) => ({ key, value: name }))}
          />
          {areManyAssetsRequired && (
            <MultiSelectField
              required
              placeholder=""
              label="Assets"
              id="asset_gids"
              name="asset_gids"
              testId="assets-select"
              options={
                assetsForNewOpportunity?.map(({ gid, description }) => ({
                  key: gid,
                  value: description
                })) || []
              }
            />
          )}
          {isOnlyOneAssetRequired && (
            <SelectField
              required
              placeholder=""
              label="Asset"
              id="asset_gid"
              name="asset_gid"
              testId="assets-select"
              options={
                assetsForNewOpportunity?.map(({ gid, description }) => ({
                  key: gid,
                  value: description
                })) || []
              }
            />
          )}

          {(areManyAssetsRequired || isOnlyOneAssetRequired) && (
            <Container mt={spacings.px8}>
              <Link to={`/leads/${leadId}/assets`} data-testid="create-new-asset">
                <Text color={colors.azure50} bold>
                  Or create new asset
                </Text>
              </Link>
            </Container>
          )}
        </Container>
      )}
    />
  );
};

export default CreateOpportunityModal;
