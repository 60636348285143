import { useQuery } from '@tanstack/react-query';

import IPlannedCallLog from '../../interfaces/IPlannedCallLog';
import api from '../../services/api';
export const PLANNED_CALL_LOG_QUERY_KEY = 'planned_call_log';

export const getPlannedCallLog = (): Promise<{ planned_call_log: IPlannedCallLog | undefined }> =>
  api.get('/api/frontend/planned_call_logs/pending');

const usePlannedCallLog = () =>
  useQuery({
    queryKey: [PLANNED_CALL_LOG_QUERY_KEY],
    queryFn: () => getPlannedCallLog(),
    staleTime: Infinity
  });

export default usePlannedCallLog;
