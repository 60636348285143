import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import FlexBox from '../core/FlexBox';
import Modal, { ModalSize } from '../core/Modal';
import Text from '../core/Text';

interface IDeleteDocument<T> {
  document: T;
  onDelete: (document: T) => Promise<unknown>;
  toggleDeleteDocument: () => void;
  opportunityLabel?: string;
  policyLabel?: string;
}

const DeleteDocumentModal = <T extends { name: string; document_type_title: string | null }>({
  document,
  onDelete,
  toggleDeleteDocument,
  opportunityLabel,
  policyLabel
}: IDeleteDocument<T>) => {
  return (
    <Modal
      confirmText="Delete"
      dangerModal
      confirmHandler={async () => {
        await onDelete(document);
        toggleDeleteDocument();
      }}
      size={policyLabel ? ModalSize.large : ModalSize.medium}
      cancelHandler={toggleDeleteDocument}
      containerTitle="You are going to delete the file"
    >
      <FlexBox columnDirection gap={spacings.px20}>
        <FlexBox gap={spacings.px12}>
          <Text color={colors.grey60}>File</Text>
          <Text className="fs-mask">{document.name}</Text>
        </FlexBox>
        <FlexBox gap={spacings.px12}>
          <Text color={colors.grey60}>File type</Text>
          <Text>{document.document_type_title || 'Unknown'}</Text>
        </FlexBox>
        {opportunityLabel && (
          <FlexBox gap={spacings.px12}>
            <Text color={colors.grey60}>Related to opportunity</Text>
            <Text>{opportunityLabel}</Text>
          </FlexBox>
        )}
        {policyLabel && (
          <FlexBox gap={spacings.px12}>
            <Text color={colors.grey60}>Related to policy</Text>
            <Text>{policyLabel}</Text>
          </FlexBox>
        )}
      </FlexBox>
    </Modal>
  );
};

export default DeleteDocumentModal;
