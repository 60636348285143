/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PopperPlacementType } from '@mui/material';
import * as React from 'react';

import { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import DropDown from '../../../../components/core/DropDown/DropDown';
import FlexBox from '../../../../components/core/FlexBox';
import { FilterIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import { IInteraction } from '../../../../interfaces/IPersonInteraction';
// eslint-disable-next-line max-len
import useInfinitePersonOmniChannelInteractions from '../../../../queries/people/omni_channel_interactions/useInfinitePersonOmniChannelInteractions';
import usePerson from '../../../../queries/people/usePerson';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import Interaction from './Interaction';
import useAudioUrl from './useAudioUrl';

const filterOptions = [
  { value: 'all', label: 'All' },
  { value: 'only_yours', label: 'Only yours' }
] as const;

interface CommunicationHistoryTabProps {
  personGid: string | undefined;
  showClose?: boolean;
  showOpenLead?: boolean;
  popperPlacement?: PopperPlacementType;
  onOpenContactBook?: () => void;
}

const CommunicationHistoryTab = ({
  personGid,
  showClose = true,
  showOpenLead = false,
  popperPlacement,
  onOpenContactBook
}: CommunicationHistoryTabProps) => {
  const [filter, setFilter] = React.useState<(typeof filterOptions)[number]>(filterOptions[0]);

  const { data: person } = usePerson(personGid);
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);

  const agentIds = filter.value === 'only_yours' ? [authInfo.currentUserId!] : undefined;
  const { data, isFetched, hasNextPage, fetchNextPage, isFetching } = useInfinitePersonOmniChannelInteractions({
    personGid,
    params: {
      newest_first: true,
      agent_ids: agentIds
    }
  });

  const { url, setAudioUrl } = useAudioUrl();
  React.useEffect(() => {
    return () => {
      setAudioUrl('');
    };
  }, [setAudioUrl]);

  const interactions = data?.pages.flatMap(({ interactions }) => interactions) || [];

  return (
    <>
      <VerticalTabHeader showClose={showClose}>
        <FlexBox fitParentWidth alignItemsCenter gap={spacings.px4} justifySpaceBetween>
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <Paragraph type="large">Activity feed: {filter.label}</Paragraph>
            <DropDown
              options={filterOptions}
              onSelected={selectedOption => setFilter(selectedOption as (typeof filterOptions)[number])}
              variant={ButtonVariant.Text}
              size={ButtonSize.Small}
              optionsSize={ButtonSize.Small}
              noArrow
              customOptionsCss={css`
                width: 140px;
              `}
            >
              <FilterIcon
                css={css`
                  position: relative;
                  bottom: 1px;
                `}
              />
            </DropDown>
          </FlexBox>
          <FlexBox gap={spacings.px4}>
            {onOpenContactBook && <IconButton icon={ButtonIcons.ContactCalendar} onClick={() => onOpenContactBook()} />}
            {showOpenLead && (
              <IconButton
                icon={ButtonIcons.OpenInNew}
                onClick={() => window.open(`${window.location.origin}/people/${personGid}/last_lead`, '_blank')}
              />
            )}
          </FlexBox>
        </FlexBox>
      </VerticalTabHeader>
      <VerticalTabContent ph={spacings.px0} pv={spacings.px0}>
        {url && (
          <Container fitParentWidth ph={spacings.px4}>
            <audio
              key={url}
              autoPlay
              controls
              controlsList={authInfo.features.download_call_recordings ? undefined : 'nodownload'}
              css={css`
                width: 100%;
              `}
            >
              <source src={url} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          </Container>
        )}
        {isFetched ? (
          <>
            {interactions.map((interaction: IInteraction) => (
              <Interaction
                key={interaction.id}
                interaction={interaction}
                relatedPeople={relatedPeople}
                person={person}
                popperPlacement={popperPlacement}
              />
            ))}
            {!interactions.length && (
              <Paragraph
                mt={spacings.px24}
                color={colors.grey60}
                customCss={css`
                  text-align: center;
                `}
              >
                No history here yet...
              </Paragraph>
            )}
            {!!interactions.length && hasNextPage && (
              <FlexBox justifyCenter p={spacings.px12}>
                <ButtonWithoutBorder onClick={() => fetchNextPage()} loading={isFetching}>
                  Show the early history
                </ButtonWithoutBorder>
              </FlexBox>
            )}
          </>
        ) : (
          <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
        )}
      </VerticalTabContent>
    </>
  );
};

export default CommunicationHistoryTab;
