/** @jsxImportSource @emotion/react */
import moment, { Moment } from 'moment';
import * as React from 'react';

import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import { IScheduledCall, ITask } from '../../../../interfaces';
import colors from '../../../../theme/colors';
import {
  DASHBOARD_DATE_TIME_FORMAT,
  dateFormatter,
  dateTimeFormatter,
  fixTZName,
  userTimezone
} from '../../../../utils/formatter';

export const DueDate = ({
  completed,
  dueTime,
  dueDate,
  decoration = true
}: {
  completed: boolean;
  dueTime: string | Moment | null;
  decoration?: boolean;
  dueDate?: string;
}): JSX.Element => {
  const now = moment();
  const isOutstanding = dueTime ? moment(dueTime).diff(now) < 0 : moment(dueDate).endOf('day').diff(now) < 0;

  const getColor = () => {
    if (isOutstanding && decoration && !completed) {
      return colors.statusRed;
    }
    if (decoration) {
      return colors.grey60;
    }
    return colors.black;
  };

  const date = dueTime ? dateTimeFormatter(dueTime, DASHBOARD_DATE_TIME_FORMAT) : dateFormatter(dueDate);

  return (
    <Paragraph type={ParagraphType.Small} color={getColor()} crossed={completed && decoration}>
      {`due ${date} ${dueTime ? userTimezone.abbreviation || fixTZName(userTimezone.name) : ''}`}
    </Paragraph>
  );
};

export const sortTasksByDueDate = (tasks: (ITask | IScheduledCall)[]) =>
  tasks.sort((a, b) => {
    const getDueTime = (task: ITask | IScheduledCall) =>
      'completed' in task ? task.due_time || task.due_date : task.run_at;

    return moment(getDueTime(a)).diff(moment(getDueTime(b)));
  });
