/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Button, { ButtonVariant } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import CollapsingContainer from '../../components/core/CollapsingContainer';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import { ArrowDownIcon, CalendarIcon, ChatIcon, TurnIcon, UserIcon } from '../../components/core/icons';
import Note from '../../components/core/Note';
import Paragraph, { ParagraphType } from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import featureFlags from '../../constants/featureFlags';
import { usePopper } from '../../hooks';
import { IScheduledCall } from '../../interfaces';
import { presentPersonPhone } from '../../interfaces/IPerson';
import { isDataLead } from '../../interfaces/ISourceDimensions';
import { UserRoleCategory } from '../../interfaces/IUser';
import useEngagement from '../../queries/engagements/useEngagement';
import usePerson from '../../queries/people/usePerson';
import usePersonTimezone from '../../queries/people/usePersonTimezone';
import useCallNotes from '../../queries/scheduled_calls/useCallNotes';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DISPLAY_TIME_FORMAT, getOrdinal, userTimezone } from '../../utils/formatter';
import { getCallSource, useScheduledCallActions } from './helpers';

const CallPopper = ({
  scheduledCall,
  personGid,
  engagementGid,
  children,
  disabled = false
}: {
  scheduledCall: IScheduledCall;
  personGid: string | undefined;
  engagementGid: string | undefined;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  const { data: person } = usePerson(personGid);
  const { data: personTimezone } = usePersonTimezone({ personGid, engagementGid, phone: presentPersonPhone(person) });
  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    placement: 'bottom',
    disablePortal: true,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const { data: notes, isSuccess } = useCallNotes(anchorEl ? personGid : undefined, scheduledCall.created_at);

  const { setCancelledScheduledCall, setEditedScheduledCall } = useScheduledCallActions();
  const { data: engagement } = useEngagement(anchorEl ? engagementGid : undefined);

  const time = moment(scheduledCall.run_at);
  const calendarTime = time.calendar({
    sameDay: '[Call today at] hh:mm a',
    nextDay: '[Call tomorrow at] hh:mm a',
    lastDay: '[Call yesterday at] hh:mm a',
    nextWeek: '[Call on] dddd [at] hh:mm a',
    lastWeek: '[Call last week] dddd [at] hh:mm a',
    sameElse: '[Call on] L [at] hh:mm a'
  });

  const isDPLinkInSMSMessageShown =
    scheduledCall.assignor?.role_category === UserRoleCategory.ISR &&
    engagement?.type === 'lead' &&
    isDataLead(engagement.lead.source_dimensions.lead_capture_method);

  return (
    <Container p={spacings.px4} innerRef={elementRef}>
      <ButtonWithoutBorder onClick={triggerPopper}>
        <>
          <FlexBox
            gap={spacings.px8}
            alignItemsCenter
            customCss={css`
              color: ${colors.black};
              &:hover {
                opacity: 0.8;
              }
            `}
          >
            <Text
              bold
              color="inherit"
              customCss={css`
                white-space: nowrap;
              `}
            >
              {calendarTime} ({userTimezone.abbreviation})
            </Text>
            <ArrowDownIcon
              css={css`
                ${anchorEl && 'transform: rotate(180deg)'};
                transition: transform 0.2s ease-in-out;
              `}
            />
          </FlexBox>
        </>
      </ButtonWithoutBorder>
      {children}
      <PopperComponent {...popperProps}>
        <FlexBox
          ph={spacings.px24}
          pv={spacings.px12}
          columnDirection
          backgroundColor={colors.white}
          roundBorder
          border
          boxShadow
          customCss={css`
            min-width: 500px;
          `}
          gap={spacings.px8}
        >
          {!isSuccess ? (
            <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
              <Heading type="h1">
                <Skeleton />
              </Heading>
              <Heading type="h1">
                <Skeleton />
              </Heading>
              <Heading type="h1">
                <Skeleton />
              </Heading>
            </SkeletonTheme>
          ) : (
            <>
              <Paragraph bold type={ParagraphType.Large}>
                Call {notes.length > 1 ? 're-' : ''}scheduled {getCallSource(scheduledCall)}
              </Paragraph>
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <CalendarIcon />
                <Paragraph type={ParagraphType.Small}>Scheduled</Paragraph>
                <Paragraph type={ParagraphType.Small} bold>
                  {calendarTime} {userTimezone.abbreviation}
                </Paragraph>
                {personTimezone && (
                  <Paragraph bold type={ParagraphType.Small} color={colors.grey60}>
                    (Customer time{' '}
                    {moment(time.format())
                      .utc(false)
                      .add(personTimezone.utc_offset_hours, 'hours')
                      .format(DISPLAY_TIME_FORMAT)}
                    )
                  </Paragraph>
                )}
              </FlexBox>
              {notes.length > 1 && (
                <FlexBox gap={spacings.px8} alignItemsCenter>
                  <TurnIcon />
                  <Paragraph type={ParagraphType.Small}>
                    Re-scheduled {notes.length - 1}
                    {getOrdinal(notes.length - 1)} time
                  </Paragraph>
                </FlexBox>
              )}
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <UserIcon />
                <Paragraph type={ParagraphType.Small}>Assignee</Paragraph>
                <Paragraph type={ParagraphType.Small} bold>
                  {scheduledCall.assignee?.name || '-'}
                </Paragraph>
              </FlexBox>
              {isDPLinkInSMSMessageShown && (
                <FlexBox gap={spacings.px8} alignItemsCenter>
                  <ChatIcon />
                  <Paragraph type={ParagraphType.Small}>Data collections link</Paragraph>
                  <Paragraph type={ParagraphType.Small} bold>
                    Sent
                  </Paragraph>
                </FlexBox>
              )}
              <FlexBox gap={spacings.px8} columnDirection>
                {notes[0] && <Note note={notes[0]} />}
                {notes.length > 1 && (
                  <CollapsingContainer
                    openedByDefault={false}
                    iconPosition="left"
                    containerTitle="Previous followup notes"
                    titleSize={ParagraphType.Small}
                  >
                    <FlexBox columnDirection gap={spacings.px8}>
                      {notes.map((n, index) => {
                        if (index === 0) {
                          return null;
                        }
                        return <Note note={n} key={n.created_at} />;
                      })}
                    </FlexBox>
                  </CollapsingContainer>
                )}
              </FlexBox>
              <FlexBox gap={spacings.px8} justifyRight>
                {!featureFlags.scheduleFollowUpUI && (
                  <Button
                    disabled={disabled}
                    variant={ButtonVariant.SecondaryDanger}
                    onClick={() => {
                      setCancelledScheduledCall(scheduledCall);
                      setAnchorEl(null);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  disabled={disabled}
                  variant={ButtonVariant.Secondary}
                  onClick={() => {
                    setEditedScheduledCall(scheduledCall);
                    setAnchorEl(null);
                  }}
                >
                  Reschedule
                </Button>
              </FlexBox>
            </>
          )}
        </FlexBox>
      </PopperComponent>
    </Container>
  );
};

export default CallPopper;
