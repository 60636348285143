import React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity } from '../../../../../interfaces';
import { SourceProducts } from '../../../../../interfaces/ISourceDimensions';
import useOpportunitiesWithPolicies from '../../../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
// eslint-disable-next-line max-len
import useOpportunitiesWithPremiumPredictions from '../../../../../queries/leads/opportunities/useOpportunitiesWithPremiumPredictions';
import usePersonAssets from '../../../../../queries/people/person_assets/usePersonAssets';
import useQuotesRequests from '../../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateTimeFormatter } from '../../../../../utils/formatter';
import { findLatestCxQuotesRequest } from '../../../GuidedQuoting/Quotes/_helpers';
import useCustomerChoices from '../../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';
import { QuotesModalViewContext } from '../../../GuidedQuoting/Quotes/_hooks/useModalView';
import QuotesRequest from '../../../GuidedQuoting/Quotes/QuotesRequest';

interface ICurrentQuotes {
  opportunity: IOpportunity;
  customerQuotesOnly?: boolean;
  onClose: () => void;
}

const QuotesModal = ({ opportunity, customerQuotesOnly = false, onClose }: ICurrentQuotes) => {
  const { personGid, leadGid, lead } = useGuidedSellingExperienceContext();

  const { data: assets } = usePersonAssets({ personGid });
  const { opportunitiesWithPolicies } = useOpportunitiesWithPolicies({
    personGid,
    opportunities: [opportunity]
  });
  const { opportunitiesWithPremiumPredictions } = useOpportunitiesWithPremiumPredictions({ opportunitiesWithPolicies });
  const { data: quotesRequests } = useQuotesRequests({ personGid, leadGid });
  const quotesRequest = customerQuotesOnly ? findLatestCxQuotesRequest(quotesRequests) : quotesRequests?.[0];

  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });

  if (!quotesRequest || !quotesRequests) {
    return null;
  }

  return (
    <QuotesModalViewContext.Provider value={{ quotesModalView: true }}>
      <Modal
        header={
          <>
            <Text type="large" bold>{`Quotes for ${opportunity.title.toLowerCase()}`}</Text>
            <Text type="large" ml={spacings.px4} className="fs-mask">
              {opportunity?.assets?.[0]?.address?.full}
            </Text>
          </>
        }
        size={ModalSize.extra}
        cancelHandler={() => onClose()}
        showCancelButton={false}
        returnFocusAfterClose={false}
      >
        <FlexBox alignItemsCenter mb={spacings.px8}>
          <Text bold>Customer’s quotes</Text>
          <Text bold color={colors.grey30} ml={spacings.px4}>
            {dateTimeFormatter(quotesRequest.created_at)}
          </Text>
          {quotesRequest.source_product !== SourceProducts.AgentManual && (
            <Tag
              label="Customer requested"
              transparent
              textColor={colors.violet}
              backgroundColor={colors.violet}
              ml={spacings.px8}
            />
          )}
        </FlexBox>
        <QuotesRequest
          key={quotesRequest.gid}
          quotesRequests={quotesRequests}
          leadId={lead?.id}
          leadGid={leadGid}
          personGid={personGid!}
          selectedQuotesRequest={quotesRequest}
          customerChoices={customerChoices}
          assets={assets || []}
          opportunitiesWithPremiumPredictions={opportunitiesWithPremiumPredictions}
          refetchCurrentPolicies={() => {}}
          opportunities={[opportunity]}
          refetchQuotesRequests={(() => {}) as any}
          applyAppointedOnlyFilter
        />
      </Modal>
    </QuotesModalViewContext.Provider>
  );
};

export default QuotesModal;
