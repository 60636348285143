/* eslint-disable indent */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table-6';
import ReactTooltip from 'react-tooltip';

import { Link, Pagination, Table } from '../../components/common';
import Checkbox from '../../components/common/Checkbox/Checkbox';
import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Tooltip from '../../components/common/Tooltip/NewTooltip';
import { NoteIcon } from '../../components/core/icons';
import { RED_ERROR } from '../../constants/colors';
import featureFlags from '../../constants/featureFlags';
import { useDocumentTitle } from '../../hooks';
import { CallStatus, IScheduledCall } from '../../interfaces/IScheduledCall';
import useScheduledInteractions, {
  DashboardFilter,
  Filter as CallFilter,
  SCHEDULED_INTERACTIONS_QUERY_KEY,
  scheduledInteractionsQueryFn
} from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import {
  capitalize,
  DASHBOARD_DATE_TIME_FORMAT,
  dateFormatter,
  dateTimeFormatter,
  INTERNAL_DATE_FORMAT,
  phoneFormatter
} from '../../utils/formatter';
import EditScheduledCall from '../ScheduleCall/EditScheduledCall';
import { isCallCompleted, isCallFailed } from '../ScheduleCall/helpers';
import Description from './Description';
import Filter from './Filter';
import PersonRedirect from './PersonRedirect';

const containerCss = css`
  display: flex;
  flex: 1 1;
  flex-direction: column;
`;

const noop = () => undefined;

const getDefaultState = () => ({
  assignees_emails: [authInfo.currentUserEmail as unknown as string],
  min_run_at: dateFormatter(new Date(), INTERNAL_DATE_FORMAT),
  max_run_at: dateFormatter(new Date(), INTERNAL_DATE_FORMAT),
  page: 1,
  statuses: [],
  flows: undefined
});

const useCustomState = () => {
  const queryClient = useQueryClient();

  const calculatedFilter = () => {
    // TODO: use local storage to store the filter state implicitly on filter change on dashboard
    const cachedQueries = queryClient.getQueryCache().findAll({ queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY] });
    const lastQuery = cachedQueries[cachedQueries.length - 1];
    const lastQueryFilter = lastQuery?.queryKey[1] as CallFilter | undefined;

    return lastQueryFilter
      ? ({ ...lastQueryFilter, page: 1, flows: undefined } satisfies DashboardFilter)
      : getDefaultState();
  };

  return React.useState<DashboardFilter>(calculatedFilter);
};

const DeprecatedScheduledCalls = (): JSX.Element => {
  const queryClient = useQueryClient();
  const [filter, setFilter] = useCustomState();
  const { data, isLoading, isFetching, isPlaceholderData } = useScheduledInteractions(filter);
  useDocumentTitle('Scheduled calls');

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        Cell: ({
          original: { last_lead_id, engagement_gid, person_name, person_gid }
        }: {
          original: IScheduledCall;
        }) => {
          const Component = last_lead_id || person_gid || engagement_gid ? Link : 'span';
          const leadNavigationUrl = featureFlags.scoutingReportRedirect
            ? `/scouting_report?lead_gid=${engagement_gid}`
            : `/leads/${last_lead_id}/summary`;
          const personNavigationUrl = `/people/${person_gid}`;

          return (
            <Component
              to={last_lead_id ? leadNavigationUrl : personNavigationUrl}
              className="fs-mask"
              data-testid="name"
            >
              {person_name}
            </Component>
          );
        },
        headerClassName: 'unsortable'
      },
      {
        Header: 'Assignee',
        Cell: ({ original: { assignee } }: { original: IScheduledCall }) => (
          <span data-testid="assignee">{assignee ? assignee.name : 'Unknown user'}</span>
        ),
        headerClassName: 'unsortable'
      },
      {
        Header: 'Phone',
        className: 'fs-mask',
        Cell: ({ original: { phone } }: { original: IScheduledCall }) => (
          <span data-testid="phone">{phoneFormatter(phone)}</span>
        ),
        headerClassName: 'unsortable'
      },
      {
        Header: 'Due date (your time)',
        Cell: ({ original }: { original: IScheduledCall }) => {
          const callTime = dateTimeFormatter(original.run_at, DASHBOARD_DATE_TIME_FORMAT);

          return isCallFailed(original) ? (
            <span
              data-testid="due-time"
              data-tip={dateTimeFormatter(original.run_at, DASHBOARD_DATE_TIME_FORMAT)}
              data-for="scheduled-call-status"
              css={css`
                color: ${RED_ERROR};
                width: fit-content;
                text-decoration: underline dotted;
              `}
            >
              {capitalize(original.status)}
            </span>
          ) : (
            <span data-testid="due-time">{callTime}</span>
          );
        },
        headerClassName: 'unsortable'
      },
      {
        Header: 'Completed',
        Cell: ({ original }: { original: IScheduledCall }) => {
          return (
            !isCallFailed(original) && (
              <div
                css={css`
                  width: 24px;
                  height: 24px;
                `}
              >
                <Checkbox
                  data-tip='Scheduled call is marked as "completed" automatically after call is finished'
                  data-for="scheduled-call-status"
                  data-testid="completed"
                  name=""
                  value={isCallCompleted(original)}
                  onChange={noop}
                  disabled
                />
              </div>
            )
          );
        },
        headerClassName: 'unsortable'
      },
      {
        Header: 'Note',
        expander: true,
        Expander: () => (
          <NoteIcon
            data-testid="show-notes"
            css={css`
              align-self: center;
              width: 16px;

              &:hover {
                opacity: 0.8;
              }
            `}
          />
        ),
        headerClassName: 'unsortable'
      },
      {
        Cell: ({ original }: { original: IScheduledCall }) => {
          const activeCallStatuses = [CallStatus.Ready, CallStatus.InDialer, CallStatus.Scheduled];
          return (
            <>
              {activeCallStatuses.includes(original.status) && (
                <EditScheduledCall scheduledCall={original} leadGid={undefined} candidateGid={undefined} />
              )}
            </>
          );
        },
        headerClassName: 'unsortable',
        maxWidth: 50
      }
    ],
    []
  );

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [data?.scheduled_interactions]);

  React.useEffect(() => {
    if ((data?.total_pages || 0) > filter.page && !isPlaceholderData) {
      const nextFilter = { ...filter, page: filter.page + 1 };
      queryClient.prefetchQuery({
        queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY, nextFilter],
        queryFn: scheduledInteractionsQueryFn(nextFilter)
      });
    }
  }, [data?.total_pages, filter, queryClient, isPlaceholderData]);

  return (
    <div>
      <FiltersSubHeader isOpen headerText="Scheduled calls">
        <Filter onFilterSubmit={setFilter} onFilterReset={() => setFilter(getDefaultState())} initialValues={filter} />
      </FiltersSubHeader>
      <div css={containerCss}>
        <Table
          loading={(isPlaceholderData && isFetching) || isLoading}
          data={data?.scheduled_interactions}
          columns={columns}
          noDataMessage="No calls found"
          manual={false}
          sortable={false}
          SubComponent={({ original: { person_gid, created_at } }: { original: IScheduledCall }) => (
            <Description gid={person_gid} created_at={created_at} />
          )}
          expanderDefaults={{ ...ReactTableDefaults.expanderDefaults, width: 50 }}
        />
        <Pagination
          current={filter.page - 1}
          pages={data?.total_pages}
          onPageChange={({ selected }) => setFilter(filter => ({ ...filter, page: selected + 1 }))}
        />
      </div>
      <Tooltip id="scheduled-call-status" />
      <Routes>
        <Route path="redirect/:gid" element={<PersonRedirect />} />
      </Routes>
    </div>
  );
};

export default DeprecatedScheduledCalls;
