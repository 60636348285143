/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { ColumnGenerator, Copy, Table } from '../../../components/common';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { EditIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { SORT_ORDER_DESC } from '../../../constants';
import { ILoan } from '../../../interfaces';
import { spacings } from '../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';
import LenderNameColumn from './LenderNameColumn';
import MortgageeClauseColumn from './MortgageeClauseColumn';

const DEFAULT_SORTING = { sort_column: 'id', sort_order: SORT_ORDER_DESC };

const LoansList = ({
  loans,
  onEditLoan,
  onCreateLoan,
  createLoanEnabled
}: {
  loans: ILoan[];
  onEditLoan: (loan: ILoan) => void;
  onCreateLoan: () => void;
  createLoanEnabled?: boolean;
}) => {
  const renderLoanNumber = ({ original: { loan_number } }: { original: ILoan }) => (
    <Text>{loan_number ? <Copy value={loan_number} testId="loan_number" /> : '-'}</Text>
  );

  const renderClosingDate = ({ original: { est_closing_date } }: { original: ILoan }) => (
    <Text data-testid="closing-date">{dateFormatter(est_closing_date) || '-'}</Text>
  );

  const renderLoanContact = (type: string, contact: ILoan['officer'] | ILoan['processor']) =>
    contact ? (
      <FlexBox className="fs-mask" columnDirection>
        <div data-testid={`${type}-name`}>{`${contact.first_name} ${contact.last_name}`}</div>
        {contact.email && (
          <Text>
            <Copy value={contact.email} testId={`${type}-email`} />
          </Text>
        )}
        {contact.phone && (
          <Text>
            <Copy value={contact.phone} displayText={phoneFormatter(contact.phone)} testId={`${type}-phone`} />
          </Text>
        )}
      </FlexBox>
    ) : (
      <Text>-</Text>
    );

  const renderEdit = ({ original }: { original: ILoan }) => (
    <div
      onClick={() => onEditLoan(original)}
      data-testid="edit-loan"
      css={css`
        cursor: pointer;
      `}
    >
      <EditIcon />
    </div>
  );

  const renderAssetDescription = ({ original: { home, vehicle } }: { original: ILoan }) => {
    return <Text data-testid="asset-description">{home?.description || vehicle?.description || '-'}</Text>;
  };

  const renderLenderName = ({ original: { lender, servicer } }: { original: ILoan }) => {
    return <LenderNameColumn servicer={servicer} lender={lender} />;
  };

  const renderMortgageeClause = ({ original: { lender, servicer } }: { original: ILoan }) => {
    return <MortgageeClauseColumn servicer={servicer} lender={lender} />;
  };

  const loanColumns = [
    ColumnGenerator.stretchable({
      accessor: 'asset.description',
      Header: 'Asset details',
      className: 'fs-mask',
      Cell: renderAssetDescription
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Closing date',
      assessor: 'est_closing_date',
      Cell: renderClosingDate
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Loan #',
      className: 'fs-mask',
      accessor: 'loan_number',
      Cell: renderLoanNumber
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Lender',
      accessor: 'lender_name',
      Cell: renderLenderName
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XXL,
      sortable: false,
      Header: 'Mortgagee clause',
      accessor: 'business_name',
      Cell: renderMortgageeClause
    }),
    ColumnGenerator.stretchable({
      Header: 'Officer',
      sortable: false,
      Cell: ({ original: { officer } }: { original: ILoan }) => renderLoanContact('officer', officer)
    }),
    ColumnGenerator.stretchable({
      Header: 'Processor',
      sortable: false,
      Cell: ({ original: { processor } }: { original: ILoan }) => renderLoanContact('processor', processor)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      sortable: false,
      Cell: renderEdit
    })
  ];

  return (
    <>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Loans</Heading>
        <Button
          data-testid="create_loan"
          size={ButtonSize.Small}
          onClick={() => onCreateLoan()}
          disabled={!createLoanEnabled}
        >
          + Add Loan
        </Button>
      </FlexBox>
      <Container mt={spacings.px24}>
        <Table
          defaultSorting={DEFAULT_SORTING}
          columns={loanColumns}
          data={loans}
          manual={false}
          noDataMessage="No loans found"
          testId="lead-loans"
        />
      </Container>
    </>
  );
};

export default LoansList;
