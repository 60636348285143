/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import alert from '../../../../components/core/Alert';
import FlexBox from '../../../../components/core/FlexBox';
import { DeleteIcon, EditIcon } from '../../../../components/core/icons';
import Select from '../../../../components/core/inputs/Select';
// eslint-disable-next-line max-len
import CancelTrailingScheduledCallModal from '../../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import ContactMadeModal from '../../../../components/DispositionsModals/ContactMadeModal';
import CouldNotContactModal from '../../../../components/DispositionsModals/CouldNotContactModal';
import {
  dispositionAssigneeLabel,
  isDispositionLostOrSold,
  isEndDisposition,
  useLeadDispositionsOptions,
  validateLeadDispositionChange
} from '../../../../components/DispositionsModals/dispositionsHelper';
import EditAssigneeModal from '../../../../components/DispositionsModals/EditAssigneeModal';
import EditDispositionModal from '../../../../components/DispositionsModals/EditDispositionModal';
import NotQualifiedModal from '../../../../components/DispositionsModals/NotQualifiedModal';
import PipelineModal from '../../../../components/DispositionsModals/PipelineModal';
import QualifiedModal from '../../../../components/DispositionsModals/QualifiedModal';
import RollbackDispositionModal from '../../../../components/DispositionsModals/RollbackDispositionModal';
import TrailingOpportunitiesModal from '../../../../components/DispositionsModals/TrailingOpportunitiesModal';
import { Translations } from '../../../../constants';
import {
  DispositionSource,
  DispositionType,
  ICurrentDisposition,
  IDisposition
} from '../../../../interfaces/IDisposition';
// eslint-disable-next-line max-len
import useReconcileOpportunitiesWithPolicies from '../../../../queries/leads/current_opportunities/useReconcileOpportunitiesWithPolicies';
import useLeadDispositions from '../../../../queries/leads/dispositions/useLeadDispositions';
import useUpdateLeadDisposition from '../../../../queries/leads/dispositions/useUpdateLeadDisposition';
import useLeadOpportunities from '../../../../queries/leads/opportunities/useLeadOpportunities';
import useLead from '../../../../queries/leads/useLead';
import usePersonMaticPolicies from '../../../../queries/people/person_policies/usePersonMaticPolicies';
import { useActiveScheduledInteractions } from '../../../../queries/scheduled_calls/useScheduledInteractions';
import { Subscription } from '../../../../services/ActionCable';
import colors from '../../../../theme/colors';
import { ActionCableContext } from '../../../../utils/actioncable';
import {
  dispositionInputStyle,
  navigation,
  navigationIndicator,
  reasonPipeHorizontalStyle,
  reasonPipeVerticalStyle
} from './DispositionSteps.style';

enum ModalNames {
  ContactMade = 'ContactMade',
  CouldNotContact = 'CouldNotContact',
  Qualified = 'Qualified',
  NotQualified = 'NotQualified',
  Pipeline = 'Pipeline',
  TrailingOpportunities = 'TrailingOpportunities'
}

const AdminLeadDispositions = ({
  personGid,
  leadId,
  leadGid
}: {
  personGid: string;
  leadId: number;
  leadGid: string;
}): JSX.Element => {
  const { data: policies } = usePersonMaticPolicies({
    personGid,
    filters: { lead_id: leadId }
  });

  const {
    data: opportunities,
    isFetching: isFetchingOpportunities,
    refetch: refetchOpportunities
  } = useLeadOpportunities({ leadId });
  const {
    data: leadDispositions,
    refetch: refetchDispositions,
    isFetching: isFetchingDispositions
  } = useLeadDispositions(leadId);

  const { dispositionsOptions, isLoading: isLoadingDispositionsOptions } = useLeadDispositionsOptions(
    leadId,
    leadDispositions?.current_disposition?.disposition_type || DispositionType.NewRequest
  );

  const { mutate: updateLeadDisposition, isPending: isUpdatingDisposition } = useUpdateLeadDisposition();

  const { mutateAsync: reconcileOpportunitiesWithPolicies, isPending: isReconcilingOpportunities } =
    useReconcileOpportunitiesWithPolicies();

  const isPending =
    isFetchingOpportunities ||
    isFetchingDispositions ||
    isLoadingDispositionsOptions ||
    isUpdatingDisposition ||
    isReconcilingOpportunities;

  const hideNextAction =
    !dispositionsOptions.length || isEndDisposition(leadDispositions?.current_disposition?.disposition_type);

  const onValidationSuccess = (newDispositionType: string) => {
    return updateLeadDisposition({
      leadId,
      disposition_type: newDispositionType,
      agent_id: leadDispositions?.current_disposition?.assignee?.id
    });
  };

  const [currentModal, setCurrentModal] = React.useState<ModalNames>();
  const [closingDisposition, setClosingDisposition] = React.useState<DispositionType>();
  const [editedDisposition, setEditedDisposition] = React.useState<IDisposition>();
  const [priorDisposition, setPriorDisposition] = React.useState<IDisposition>();
  const [openCancelCallModal, setOpenCancelCallModal] = React.useState<boolean>(false);

  const { data: lead } = useLead(leadGid);

  const { data: scheduledCalls } = useActiveScheduledInteractions(lead?.person_gid);

  const validateDispositionChange = (currentDisposition: ICurrentDisposition, newDispositionType: DispositionType) =>
    validateLeadDispositionChange({
      currentDisposition,
      newDispositionType,
      opportunities,
      policies,
      openContactMadeModal: () => setCurrentModal(ModalNames.ContactMade),
      openCouldNotContactModal: () => setCurrentModal(ModalNames.CouldNotContact),
      openQualifiedModal: () => setCurrentModal(ModalNames.Qualified),
      openNotQualifiedModal: () => setCurrentModal(ModalNames.NotQualified),
      openPipelineModal: () => setCurrentModal(ModalNames.Pipeline),
      openTrailingOpportunitiesModal: closingDisposition => {
        setClosingDisposition(closingDisposition);
        setCurrentModal(ModalNames.TrailingOpportunities);
      },
      openCancelCallModal: () => setOpenCancelCallModal(true),
      onPolicyRequiredToCloseLead: () =>
        alert({ message: 'In order to mark this lead as sold you must first add a policy to the lead.' }).error(),
      onSuccess: onValidationSuccess
    });

  const cable = React.useContext(ActionCableContext);

  React.useEffect(() => {
    let summaryChannel: Subscription;
    if (leadId && cable) {
      summaryChannel = cable.subscriptions.create(
        {
          channel: 'LeadSummaryChannel',
          lead_id: leadId
        },
        {
          received: () => {
            refetchDispositions();
          }
        }
      );
    }

    return () => {
      if (leadId && cable) {
        summaryChannel.unsubscribe();
      }
    };
  }, [cable, leadId, refetchDispositions]);

  const uncompletedCall = scheduledCalls?.[0];
  const showCancelCallModal = openCancelCallModal && uncompletedCall && lead?.person_gid;

  return (
    <div>
      <div
        css={css`
          font-weight: bold;
          color: ${colors.black};
        `}
      >
        Disposition statuses
      </div>
      {leadDispositions?.dispositions &&
        leadDispositions.dispositions.map((disposition, index) => {
          const canRollbackDisposition = index > 0 && leadDispositions.dispositions.length - 1 === index;

          return (
            <div css={navigation} key={disposition.disposition_type}>
              <div
                css={navigationIndicator(
                  false,
                  true,
                  !!disposition.disposition_reason && disposition.disposition_type !== DispositionType.Pipeline
                )}
              />
              <div>
                <FlexBox justifySpaceBetween alignItemsCenter customCss={dispositionInputStyle}>
                  <div>{disposition.label}</div>
                  {disposition.assignee && (
                    <span
                      css={css`
                        display: flex;
                        flex: 1 1 0%;
                        align-items: center;
                        min-width: 0px;
                      `}
                    >
                      <div
                        css={css`
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          margin-left: 4px;
                          color: ${colors.grey30};
                          font-size: 14px;
                          padding-top: 2px;
                        `}
                      >
                        {dispositionAssigneeLabel(disposition)}
                      </div>
                    </span>
                  )}

                  <FlexBox
                    customCss={css`
                      margin-left: 2px;
                      visibility: hidden;
                    `}
                  >
                    {disposition.assignee && disposition.source !== DispositionSource.AUTOMATIC_LEAD_CLOSER && (
                      <EditIcon
                        css={css`
                          cursor: pointer;
                          color: black;
                        `}
                        onClick={() => setEditedDisposition(disposition)}
                      />
                    )}
                    {canRollbackDisposition && (
                      <DeleteIcon
                        color={colors.statusRed}
                        css={css`
                          margin-left: 8px;
                          cursor: pointer;
                        `}
                        onClick={() => setPriorDisposition(leadDispositions.dispositions[index - 1])}
                      />
                    )}
                  </FlexBox>
                </FlexBox>
                {disposition.disposition_reason && (
                  <div
                    css={reasonPipeHorizontalStyle(
                      disposition.disposition_type === DispositionType.Pipeline ? colors.statusGreen : colors.statusRed
                    )}
                  >
                    <div
                      css={reasonPipeVerticalStyle(
                        disposition.disposition_type === DispositionType.Pipeline
                          ? colors.statusGreen
                          : colors.statusRed
                      )}
                    >
                      <div
                        css={css`
                          margin-left: 16px;
                        `}
                      >
                        Reason: {Translations.dispositionReason(disposition.disposition_reason)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      {leadDispositions && !hideNextAction && (
        <div css={navigation}>
          <div css={navigationIndicator(true, false, false)} />
          <Select
            css={css`
              margin-top: 8px;
            `}
            small
            hideSelectedOptions
            disabled={isPending}
            value={leadDispositions?.current_disposition.next_action}
            labelName="label"
            valueName="value"
            onChange={value => {
              const dispositionType = value as DispositionType;

              if (isDispositionLostOrSold(dispositionType)) {
                reconcileOpportunitiesWithPolicies({ leadId })
                  .then(() => refetchOpportunities())
                  .then(() => validateDispositionChange(leadDispositions.current_disposition, dispositionType));
              } else {
                validateDispositionChange(leadDispositions.current_disposition, dispositionType);
              }
            }}
            options={[
              ...dispositionsOptions,
              {
                label: leadDispositions.current_disposition.next_action,
                value: leadDispositions.current_disposition.next_action
              }
            ]}
            controlTestId="admin-lead-disposition-select"
          />
        </div>
      )}
      {currentModal === ModalNames.ContactMade && (
        <ContactMadeModal leadId={leadId} closeModal={() => setCurrentModal(undefined)} />
      )}
      {currentModal === ModalNames.CouldNotContact && (
        <CouldNotContactModal
          leadId={leadId}
          closeModal={() => setCurrentModal(undefined)}
          onDispositionUpdated={() => {
            setOpenCancelCallModal(true);
          }}
        />
      )}
      {currentModal === ModalNames.Qualified && (
        <QualifiedModal leadId={leadId} closeModal={() => setCurrentModal(undefined)} />
      )}
      {currentModal === ModalNames.NotQualified && (
        <NotQualifiedModal
          leadId={leadId}
          closeModal={() => setCurrentModal(undefined)}
          onDispositionUpdated={() => {
            setOpenCancelCallModal(true);
          }}
        />
      )}
      {currentModal === ModalNames.Pipeline && (
        <PipelineModal leadId={leadId} closeModal={() => setCurrentModal(undefined)} />
      )}
      {currentModal === ModalNames.TrailingOpportunities && (
        <TrailingOpportunitiesModal
          leadId={leadId}
          opportunities={opportunities!}
          dispositionType={closingDisposition as DispositionType}
          closeModal={() => {
            setClosingDisposition(undefined);
            setCurrentModal(undefined);
          }}
          onDispositionUpdated={() => {
            refetchOpportunities();
            setOpenCancelCallModal(true);
          }}
        />
      )}
      {priorDisposition && leadDispositions && (
        <RollbackDispositionModal
          leadId={leadId}
          currentDisposition={leadDispositions?.current_disposition}
          priorDisposition={priorDisposition}
          closeModal={() => {
            setPriorDisposition(undefined);
          }}
        />
      )}
      {editedDisposition &&
        (editedDisposition.disposition_reason ? (
          <EditDispositionModal
            leadId={leadId}
            disposition={editedDisposition}
            closeModal={() => {
              setEditedDisposition(undefined);
            }}
          />
        ) : (
          <EditAssigneeModal
            leadId={leadId}
            disposition={editedDisposition}
            closeModal={() => {
              setEditedDisposition(undefined);
            }}
          />
        ))}
      {showCancelCallModal && (
        <CancelTrailingScheduledCallModal
          assignee={leadDispositions?.current_disposition?.assignee}
          personGid={lead.person_gid}
          scheduledCall={uncompletedCall}
          onCloseModal={() => setOpenCancelCallModal(false)}
        />
      )}
    </div>
  );
};

export default AdminLeadDispositions;
