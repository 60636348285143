/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import { NumericField } from '../../../../../components/core/forms/fields';
import { DollarIcon } from '../../../../../components/core/icons';
import Select from '../../../../../components/core/inputs/Select';
import Label from '../../../../../components/core/Label';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Text from '../../../../../components/core/Text';
import {
  OpportunityCoveragesTier,
  realPropertyCoverageTierOptions,
  RealPropertyOpportunity
} from '../../../../../interfaces/IOpportunity';
import { IPerson } from '../../../../../interfaces/IPerson';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { sizes, spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import {
  IQuotingConfigurationFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';

const LEFT_COLUMN_SIZE = sizes.mediumLabelSize;
const GAP_SIZE = spacings.px12;
const SELECT_PADDINGS = 11;

const RealPropertyCoverage = ({
  opportunity,
  person
}: {
  person: IPerson;
  opportunity: IQuotingConfigurationOpportunity;
}) => {
  const [editorOpened, setEditorOpened] = React.useState(false);

  const { values, setFieldValue } = useFormikContext<IQuotingConfigurationFormValues>();
  const selectedCoverages = values.opportunities[opportunity.id]!
    .agent_selected_coverages as RealPropertyOpportunity['agent_selected_coverages'];
  const selectedHomeCoverages = selectedCoverages?.[0];

  const home = opportunity.assets![0]!;

  // Home opportunity will always have only one set of coverages because it has only one asset
  const selectedTier = selectedHomeCoverages?.coverages_tier;
  const latestPolicy = opportunity.latestPolicy;
  const latestPolicyDwellingNode = coverageByKey(latestPolicy?.coverages, PolicyCoverageKey.Dwelling);
  const customDwellingNode = coverageByKey(selectedHomeCoverages?.coverages, PolicyCoverageKey.Dwelling);

  return (
    <Container pv={spacings.px4}>
      <Text bold>Quoting coverage</Text>
      <FlexBox mt={spacings.px12} columnDirection>
        <FlexBox ml={spacings['-px4']} alignItemsCenter gap={GAP_SIZE}>
          <Label
            htmlFor={`${opportunity.id}-coverage-level`}
            pl={spacings.px4}
            customCss={css`
              width: ${LEFT_COLUMN_SIZE}px;
            `}
          >
            Coverage level
          </Label>
          <Select
            inline
            inputId={`${opportunity.id}-coverage-level`}
            name={`${opportunity.id}-coverage-level`}
            value={selectedTier}
            options={realPropertyCoverageTierOptions}
            onChange={({ target }) => {
              if (target.value === OpportunityCoveragesTier.Custom) {
                setEditorOpened(true);
              } else {
                setFieldValue(`opportunities.${opportunity.id}.agent_selected_coverages`, [
                  {
                    asset_gid: home.gid,
                    coverages_tier: target.value,
                    coverages: null
                  }
                ] satisfies ConfigurationComponentProps['opportunity']['agent_selected_coverages']);

                analytics.track('Home coverages updated', {
                  person_gid: person.gid,
                  place: 'guided_selling_experience',
                  page: 'quoting_configuration'
                });
              }
            }}
            css={css`
              width: ${sizes.mediumFormInputWidth}px;
            `}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        mt={spacings.px16}
        customCss={css`
          flex: 1;
        `}
      >
        <div
          css={css`
            min-width: ${LEFT_COLUMN_SIZE + GAP_SIZE + SELECT_PADDINGS}px;
          `}
        />
        <FlexBox
          customCss={css`
            flex: 1;
          `}
        >
          <Grid container columnSpacing={1} rowSpacing={1} columns={5}>
            <Grid item xs={1}>
              <FlexBox gap={spacings.px4} columnDirection>
                <Text type="small" color={colors.grey60}>
                  Dwelling
                </Text>
                {(!selectedTier || selectedTier === OpportunityCoveragesTier.MatchCurrentInsurance) && (
                  <Text type="small">{moneyFormatter(latestPolicyDwellingNode?.limit_value, true) || '—'}</Text>
                )}
                {selectedTier === OpportunityCoveragesTier.Custom && (
                  <Text type="small">{moneyFormatter(customDwellingNode?.limit_value, true) || '—'}</Text>
                )}
                {selectedTier === OpportunityCoveragesTier.MatchRCE && <Text type="small">-</Text>}
              </FlexBox>
            </Grid>

            <Grid item xs={1}>
              <FlexBox gap={spacings.px4} columnDirection>
                <Text type="small" color={colors.grey60}>
                  Deductible
                </Text>
                {(!selectedTier || selectedTier === OpportunityCoveragesTier.MatchCurrentInsurance) && (
                  <Text type="small">{moneyFormatter(latestPolicyDwellingNode?.deductible_value, true) || '—'}</Text>
                )}
                {selectedTier === OpportunityCoveragesTier.Custom && (
                  <Text type="small">{moneyFormatter(customDwellingNode?.deductible_value, true) || '—'}</Text>
                )}
                {selectedTier === OpportunityCoveragesTier.MatchRCE && <Text type="small">-</Text>}
              </FlexBox>
            </Grid>

            <Grid item xs={1}>
              <IconButton
                onClick={() => setEditorOpened(true)}
                focus={editorOpened}
                icon={ButtonIcons.Edit}
                data-testid="edit-property-coverage"
                customCss={css`
                  width: ${spacings.px32}px;
                `}
              />
            </Grid>
          </Grid>
        </FlexBox>
        {editorOpened && (
          <Modal
            containerTitle="Set custom coverage for home"
            size={ModalSize.large}
            returnFocusAfterClose={false}
            showCancelButton={false}
            cancelHandler={() => setEditorOpened(false)}
          >
            <BaseForm
              enableReinitialize
              initialValues={{
                dwelling: customDwellingNode?.limit_value || latestPolicyDwellingNode?.limit_value || '',
                deductible: customDwellingNode?.deductible_value || latestPolicyDwellingNode?.deductible_value || ''
              }}
              validationSchema={yup.object().shape({
                dwelling: yup.number().min(1, 'must be greater than zero').max(2147483647, 'is too large'),
                deductible: yup.number().min(1, 'must be greater than zero')
              })}
              pt={0}
              pb={0}
              pl={spacings.px8}
              pr={spacings.px8}
              submitText="Set coverage"
              onSubmit={({ dwelling, deductible }) => {
                setFieldValue(`opportunities.${opportunity.id}.agent_selected_coverages`, [
                  {
                    asset_gid: home.gid,
                    coverages_tier: OpportunityCoveragesTier.Custom,
                    coverages: [
                      {
                        key: PolicyCoverageKey.Dwelling,
                        limit_value: dwelling,
                        deductible_value: deductible,
                        limit_option_gid: null
                      }
                    ]
                  }
                ] satisfies ConfigurationComponentProps['opportunity']['agent_selected_coverages']);

                analytics.track('Home coverages updated', {
                  person_gid: person.gid,
                  place: 'guided_selling_experience',
                  page: 'quoting_configuration'
                });

                setEditorOpened(false);
              }}
              cancelHandler={() => setEditorOpened(false)}
              renderForm={() => (
                <FlexBox gap={spacings.px24}>
                  <div
                    css={css`
                      width: ${sizes.mediumFormInputWidth}px;
                    `}
                  >
                    <NumericField
                      label="Dwelling"
                      id="dwelling"
                      name="dwelling"
                      prefixIcon={<DollarIcon />}
                      valueIsNumericString
                    />
                  </div>
                  <div
                    css={css`
                      width: ${sizes.mediumFormInputWidth}px;
                    `}
                  >
                    <NumericField
                      label="Deductible"
                      id="deductible"
                      name="deductible"
                      prefixIcon={<DollarIcon />}
                      valueIsNumericString
                    />
                  </div>
                </FlexBox>
              )}
            />
          </Modal>
        )}
      </FlexBox>
    </Container>
  );
};

export default RealPropertyCoverage;
