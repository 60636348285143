import { IMaticPolicy } from '../../../interfaces';
import { DeliverableDocumentStatus, IDeliverableDocument } from '../../../interfaces/IDocumentDelivery';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import { usePoliciesDeliveries } from '../../../queries/people/person_policies/usePolicyDeliveries';

const isMissedDocumentByAgent = (doc: IDeliverableDocument) =>
  doc.status === DeliverableDocumentStatus.MissedDocument && doc.responsible === 'agent';

const policyIdAndDocumentTypeTuple = (
  data: ReturnType<typeof usePoliciesDeliveries>
): [IMaticPolicy['id'], string] | null => {
  for (const [key, items] of Object.entries(data)) {
    const itemWithMissedDoc = items.find(item => item.deliverable_documents.some(isMissedDocumentByAgent));

    if (itemWithMissedDoc) {
      const missedDoc = itemWithMissedDoc.deliverable_documents.find(isMissedDocumentByAgent);

      if (missedDoc) {
        return [Number(key) as IMaticPolicy['id'], missedDoc.document_type];
      }
    }
  }
  return null;
};

const usePolicyAndMissingDocumentTypeTuple = ({
  personGid,
  policies
}: {
  personGid: string;
  policies: IMaticPolicy[];
}) => {
  const policiesDeliveries = usePoliciesDeliveries({ personGid, policies });
  const { data } = useDocumentTypes();

  const policyIdAndMissedDocumentKey = policyIdAndDocumentTypeTuple(policiesDeliveries);
  const policy = policies.find(policy => policy.id === policyIdAndMissedDocumentKey?.[0]);
  const documentType = data?.document_types?.find(docType => docType.key === policyIdAndMissedDocumentKey?.[1]);
  if (policy && documentType) {
    return [policy, documentType] as const;
  }

  return null;
};

export default usePolicyAndMissingDocumentTypeTuple;
