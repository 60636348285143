import { useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import { Link } from '../../../components/common';
import Container from '../../../components/core/Container';
import BaseForm from '../../../components/core/forms/BaseForm';
import { MultiSelectField, SelectField } from '../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Text from '../../../components/core/Text';
import { IPersonAsset } from '../../../interfaces/IPersonAsset';
import {
  areManyAssetsSupported,
  isOnlyOneAssetSupported,
  PolicyType,
  PolicyTypes
} from '../../../interfaces/IPolicyType';
import usePersonAssets from '../../../queries/people/person_assets/usePersonAssets';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';

interface PolicyAssetsPickerProps {
  personGid: string;
  cancelHandler: () => void;
  confirmHandler: ({ policyType, personAssets }: { policyType: PolicyType; personAssets: IPersonAsset[] }) => void;
  leadId?: number;
}

const NotifyPolicyTypeUpdated = ({
  onPolicyTypeUpdated
}: {
  onPolicyTypeUpdated: (policyType: PolicyType) => void;
}) => {
  const {
    values: { policy_type }
  } = useFormikContext<{ policy_type: PolicyType }>();

  React.useEffect(() => {
    onPolicyTypeUpdated(policy_type);
  }, [onPolicyTypeUpdated, policy_type]);

  return null;
};

const PolicyAssetsPicker = ({
  leadId,
  personGid,
  cancelHandler,
  confirmHandler
}: PolicyAssetsPickerProps): JSX.Element => {
  const [selectedPolicyType, setSelectedPolicyType] = React.useState<PolicyType>();
  const { data: assetsForNewPolicy, isFetching } = usePersonAssets({
    personGid,
    policyType: selectedPolicyType,
    enabled: !!selectedPolicyType
  });

  const areManyAssetsRequired = !!selectedPolicyType && areManyAssetsSupported(selectedPolicyType);
  const isOnlyOneAssetRequired = !!selectedPolicyType && isOnlyOneAssetSupported(selectedPolicyType);

  const isOnlyOneAssetAvailable = assetsForNewPolicy?.length === 1;

  return (
    <Modal
      containerTitle="Create policy"
      size={ModalSize.medium}
      showCancelButton={false}
      cancelHandler={cancelHandler}
    >
      <BaseForm
        enableReinitialize
        initialValues={{
          policy_type: selectedPolicyType || '',
          asset_gids: isOnlyOneAssetAvailable ? assetsForNewPolicy?.map(({ gid }) => gid) || [] : [],
          asset_gid: isOnlyOneAssetAvailable ? assetsForNewPolicy?.[0]?.gid || '' : ''
        }}
        validationSchema={yup.object().shape({
          policy_type: requiredField,
          ...(areManyAssetsRequired ? { asset_gids: yup.array().min(1, REQUIRED_MESSAGE) } : {}),
          ...(isOnlyOneAssetRequired ? { asset_gid: requiredField } : {})
        })}
        onSubmit={({ policy_type, asset_gids, asset_gid }) => {
          let assetGids: string[] | undefined;

          if (areManyAssetsRequired) {
            assetGids = asset_gids;
          } else if (isOnlyOneAssetRequired) {
            assetGids = [asset_gid];
          } else {
            assetGids = undefined;
          }

          confirmHandler({
            policyType: policy_type as PolicyType,
            personAssets: assetsForNewPolicy?.filter(({ gid }) => assetGids?.some(assetGid => assetGid === gid)) || []
          });
        }}
        cancelHandler={cancelHandler}
        pt={spacings.px16}
        pb={spacings.px16}
        pl={spacings.px16}
        pr={spacings.px16}
        loading={isFetching}
        renderForm={() => {
          return (
            <>
              <NotifyPolicyTypeUpdated onPolicyTypeUpdated={setSelectedPolicyType} />
              <SelectField
                required
                label="Policy type"
                id="policy_type"
                name="policy_type"
                testId="policy-type-select"
                options={PolicyTypes.map(({ key, name }) => ({ key, value: name }))}
              />
              {areManyAssetsRequired && (
                <MultiSelectField
                  required
                  placeholder=""
                  label="Assets"
                  id="asset_gids"
                  name="asset_gids"
                  testId="assets-select"
                  options={
                    assetsForNewPolicy?.map(({ gid, description }) => ({
                      key: gid,
                      value: description
                    })) || []
                  }
                />
              )}
              {isOnlyOneAssetRequired && (
                <SelectField
                  required
                  placeholder=""
                  label="Asset"
                  id="asset_gid"
                  name="asset_gid"
                  testId="assets-select"
                  options={
                    assetsForNewPolicy?.map(({ gid, description }) => ({
                      key: gid,
                      value: description
                    })) || []
                  }
                />
              )}
              {(areManyAssetsRequired || isOnlyOneAssetRequired) && (
                <Container mt={spacings.px8}>
                  <Link
                    to={leadId ? `/leads/${leadId}/assets` : `/people/${personGid}/assets`}
                    data-testid="create-new-asset"
                  >
                    <Text color={colors.azure50} bold>
                      Or create new asset
                    </Text>
                  </Link>
                </Container>
              )}
            </>
          );
        }}
      />
    </Modal>
  );
};

export default PolicyAssetsPicker;
