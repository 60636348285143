/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import featureFlags from '../../constants/featureFlags';
import useCreateContactAttempt, { MediaType } from '../../queries/contact_attempts/useCreateContactAttempt';
import { getLastLeadId } from '../../queries/people/useLastLead';
import { spacings } from '../../theme/variables';
import DropDown from '../core/DropDown/DropDown';
import alert from './Alert';
import { ButtonSize, ButtonVariant } from './buttons/Button';
import DropDownWithPopper from './DropDown/DropDownWithPopper';
import FlexBox from './FlexBox';
import { ChatIcon, PhoneIcon } from './icons/index';
import TelephonyActions from './TelephonyActions';

interface ITelephonyTooltip {
  phone: string | null;
  personGid: string;
  children: React.ReactNode;
  shouldRenderSimplified?: boolean;
}

const TelephonyTooltip = ({ phone, personGid, children, shouldRenderSimplified = false }: ITelephonyTooltip) => {
  const { mutate: createContactAttempt, isPending: isContactAttemptCreating } = useCreateContactAttempt({
    onSuccess: () => alert({ message: 'Request successfully passed to Twilio' }).success()
  });

  if (!shouldRenderSimplified && featureFlags.clickToTwilioEmail) {
    return (
      <TelephonyActions phone={phone} personGid={personGid}>
        {children}
      </TelephonyActions>
    );
  }

  return (
    <>
      {phone && (
        <>
          {shouldRenderSimplified ? (
            <DropDownWithPopper
              testId="telephony-phone"
              options={[
                {
                  label: (
                    <FlexBox gap={spacings.px8} alignItemsCenter data-testid="call-button">
                      <PhoneIcon width={spacings.px16} height={spacings.px16} />
                      <div>Call</div>
                    </FlexBox>
                  ),
                  value: MediaType.Call
                },
                {
                  label: (
                    <FlexBox gap={spacings.px8} alignItemsCenter data-testid="send-sms-button">
                      <ChatIcon width={spacings.px16} height={spacings.px16} />
                      <div>Send SMS</div>
                    </FlexBox>
                  ),
                  value: MediaType.SMS
                }
              ]}
              onSelected={selectedOption => {
                getLastLeadId(personGid).then(({ lead }) => {
                  if (lead) {
                    createContactAttempt({
                      phone,
                      personGid,
                      leadGid: lead.gid,
                      mediaType: selectedOption.value
                    });
                  } else {
                    alert({ message: 'No lead associated with this customer' }).error();
                  }
                });
              }}
              size={ButtonSize.Small}
              optionsSize={ButtonSize.Small}
              customOptionsCss={css`
                width: 140px;
              `}
              variant={ButtonVariant.PlainText}
              loading={isContactAttemptCreating}
              personGid={personGid}
            >
              {children}
            </DropDownWithPopper>
          ) : (
            <DropDown
              testId="telephony-phone"
              options={[
                {
                  label: (
                    <FlexBox gap={spacings.px8} alignItemsCenter data-testid="call-button">
                      <PhoneIcon width={spacings.px16} height={spacings.px16} />
                      <div>Call</div>
                    </FlexBox>
                  ),
                  value: MediaType.Call
                },
                {
                  label: (
                    <FlexBox gap={spacings.px8} alignItemsCenter data-testid="send-sms-button">
                      <ChatIcon width={spacings.px16} height={spacings.px16} />
                      <div>Send SMS</div>
                    </FlexBox>
                  ),
                  value: MediaType.SMS
                }
              ]}
              onSelected={selectedOption => {
                getLastLeadId(personGid).then(({ lead }) => {
                  if (lead) {
                    createContactAttempt({
                      phone,
                      personGid,
                      leadGid: lead.gid,
                      mediaType: selectedOption.value
                    });
                  } else {
                    alert({ message: 'No lead associated with this customer' }).error();
                  }
                });
              }}
              size={ButtonSize.Small}
              optionsSize={ButtonSize.Small}
              customOptionsCss={css`
                width: 140px;
              `}
              variant={ButtonVariant.PlainText}
              loading={isContactAttemptCreating}
              personGid={personGid}
            >
              {children}
            </DropDown>
          )}
        </>
      )}
    </>
  );
};

export default TelephonyTooltip;
