/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { CheckboxField, labelCss, NumericField, RadioGroupField } from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import { DollarIcon, WarningIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { IHome, IOpportunity, IPerson, IPersonAddress, IRelatedPerson, IVehicle } from '../../../interfaces';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { IUserLicense } from '../../../interfaces/IUserLicenses';
import { isAutoAssetNotQuotable } from '../../../interfaces/IVehicle';
import { Disclosure, useAcceptDisclosures } from '../../../queries/disclosures/useDisclosure';
import { LEAD_OPPORTUNITIES_QUERY_KEY } from '../../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import Header from '../_components/Header';
import StepsBar from '../_components/StepsBar';
import { SelectedHome, SelectedVehicle } from '../_interfaces/ISelectedAsset';
import {
  createPafOpportunity,
  initialValues,
  submitSelectedAssets,
  submitVehiclesFormData,
  validateQuotingAvailability,
  validateVehiclesAddressConsistency,
  validations
} from './formHelpers';
import HomeQuotingOptions from './HomeQuotingOptions';
import VehicleEditor from './VehicleEditor';

interface AssetsPickerProps {
  person: IPerson;
  leadId: string | undefined;
  fcraDisclosure: Disclosure;
  isFcraDisclosureAccepted: boolean | undefined;
  opportunities: IOpportunity[] | undefined;
  personAddresses: IPersonAddress[] | undefined;
  relatedPeople: IRelatedPerson[] | undefined;
  userLicenses: IUserLicense[] | undefined;
  homes: IHome[] | undefined;
  vehicles: IVehicle[] | undefined;
  selectedHomes: SelectedHome[] | undefined;
  selectedVehicles: SelectedVehicle[] | undefined;
  selectedJewelry: number | null | undefined;
  onHomesSelected: (homes?: SelectedHome[]) => void;
  onVehiclesSelected: (vehicles?: SelectedVehicle[]) => void;
  onCancel: () => void;
  onSubmit: (
    selectedHomes: SelectedHome[],
    selectedVehicles: SelectedVehicle[],
    selectedJewelry: number | null
  ) => void;
}

const WARNING_MESSAGE = "You still can gather information about the vehicle, but it won't be included in quoting";
const NOT_LICENSED_MESSAGE = 'You cannot provide a quote because you do not have the required license in the state.';
const INVALID_TO_QU0TE_MESSAGE = 'invalid to quote in AMP';
const NOT_LICENSED_TO_QU0TE_MESSAGE = 'Not licensed to quote';
export const PAF_CHECKBOX_ID = 'jewelry_mutual_checkbox_id';
export const PAF_INPUT_FIELD_ID = 'jewelry_mutual_id_input';
const PAF_RANGES_HINT =
  'Without estimated value we will quote ranges: up to $5,000; $5,000-12,500; $12,500-20,000 and above $20,000';

const AssetsPicker = ({
  person,
  leadId,
  fcraDisclosure,
  isFcraDisclosureAccepted,
  opportunities,
  personAddresses,
  homes,
  vehicles,
  relatedPeople,
  userLicenses,
  selectedHomes,
  selectedVehicles,
  selectedJewelry,
  onCancel,
  onSubmit
}: AssetsPickerProps) => {
  const pafOpportunity = opportunities?.find(op => op.policy_type === PolicyType.PAF);
  const isPafOpportunity = !!pafOpportunity;

  const queryClient = useQueryClient();
  const { mutateAsync: acceptDisclosures } = useAcceptDisclosures();
  const licensedToQuote = (state: string | undefined | null) => {
    if (!state) {
      return true;
    }
    return userLicenses?.find(el => el.state === state)?.enabled;
  };

  return (
    <BaseForm
      type="fullPage"
      pt={spacings.px12}
      pb={spacings.px12}
      pl={spacings.px24}
      pr={spacings.px24}
      enableReinitialize
      cancelWithEscape
      header={<Header person={person} leadId={leadId} />}
      footer={({ values }) =>
        (vehicles || []).filter(({ gid }) => values[gid])[0] ? <StepsBar activeStepOrder={1} /> : null
      }
      disabled={({ values }) => {
        const selectedHomes = (homes || []).filter(({ gid }) => values[gid]);
        const selectedVehicles = (vehicles || []).filter(({ gid }) => values[gid]);
        const selectedJewelry = values[PAF_CHECKBOX_ID];

        if (selectedJewelry && values[PAF_INPUT_FIELD_ID]) {
          return false;
        } else {
          return !(selectedHomes.length || selectedVehicles.length);
        }
      }}
      initialValues={initialValues({
        isFcraDisclosureAccepted,
        homes,
        vehicles,
        selectedHomes,
        selectedVehicles,
        isPafOpportunity,
        selectedJewelry,
        relatedPeople
      })}
      validationSchema={validations({ isFcraDisclosureAccepted, homes, vehicles })}
      validate={values => {
        const selectedVehicles = (vehicles || []).filter(({ gid }) => values[gid]);

        return validateVehiclesAddressConsistency(selectedVehicles, values);
      }}
      onSubmit={async (values, { setErrors }) => {
        const selectedHomes = (homes || []).filter(({ gid }) => values[gid]);
        const selectedVehicles = (vehicles || []).filter(({ gid }) => values[gid]);
        const selectedJewelry = (values[PAF_CHECKBOX_ID] ? values[PAF_INPUT_FIELD_ID] : null) as number | null;

        await (isFcraDisclosureAccepted
          ? Promise.resolve()
          : acceptDisclosures({ personGid: person.gid, disclosures: [fcraDisclosure] }));
        await submitVehiclesFormData({ personGid: person.gid, selectedVehicles, values });

        if (!isPafOpportunity && leadId && values[PAF_CHECKBOX_ID]) {
          createPafOpportunity(leadId).then(() =>
            queryClient.invalidateQueries({ queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY, leadId], refetchType: 'none' })
          );
        }

        const validationErrors = await validateQuotingAvailability({ selectedHomes, selectedVehicles, values });

        setErrors(validationErrors);

        if (Object.values(validationErrors).length === 0) {
          return submitSelectedAssets({
            selectedHomes,
            selectedVehicles,
            selectedJewelry,
            values,
            onSubmit
          });
        }
      }}
      submitText={({ values }) => ((vehicles || []).filter(({ gid }) => values[gid])[0] ? 'Next' : 'Get quotes')}
      cancelHandler={onCancel}
      cancelVariant={ButtonVariant.Secondary}
      renderForm={({ values, setValues }) => {
        return (
          <>
            {fcraDisclosure && !isFcraDisclosureAccepted && (
              <RadioGroupField
                required
                label={({ required }) => (
                  <Text type="large" bold customCss={labelCss(required)}>
                    {fcraDisclosure.prompt}
                  </Text>
                )}
                id="fcra"
                name="fcra"
                options={[
                  { id: 'no', value: 'no', label: 'No' },
                  { id: 'yes', value: 'yes', label: 'Yes' }
                ]}
              />
            )}
            {!!homes?.length && (
              <Heading type="h4" mb={spacings.px12}>
                Homes
              </Heading>
            )}
            {homes?.map(({ gid, description, address }) => (
              <div key={gid}>
                <FlexBox>
                  <CheckboxField id={gid} name={gid} label={description} disabled={!licensedToQuote(address.state)} />
                  {!licensedToQuote(address.state) && (
                    <Container
                      data-tip={NOT_LICENSED_MESSAGE}
                      data-place="top"
                      data-for="not-licensed-home-tooltip-text"
                      ml={spacings.px4}
                    >
                      <WarningIcon color={colors.statusOrange} />
                      <Tooltip id="not-licensed-home-tooltip-text" />
                      <Text ml={spacings.px4} color={colors.statusOrange} type="small" bold>
                        {NOT_LICENSED_TO_QU0TE_MESSAGE}
                      </Text>
                    </Container>
                  )}
                </FlexBox>
                {!!values[gid] && (
                  <HomeQuotingOptions relatedPeople={relatedPeople} personGid={person.gid} homeGid={gid} />
                )}
              </div>
            ))}
            {!!vehicles?.length && (
              <Container mb={spacings.px12}>
                <Heading type="h4">Autos</Heading>
              </Container>
            )}
            {vehicles?.map(({ gid, description, kind, address }) => (
              <div key={gid}>
                <FlexBox>
                  <CheckboxField
                    key={gid}
                    id={gid}
                    name={gid}
                    label={description}
                    disabled={isAutoAssetNotQuotable(kind) || !licensedToQuote(address.state)}
                  />
                  {isAutoAssetNotQuotable(kind) && (
                    <Container
                      data-tip={WARNING_MESSAGE}
                      data-place="top"
                      data-for="not-quotable-auto-tooltip-text"
                      ml={spacings.px4}
                    >
                      <WarningIcon color={colors.statusOrange} />
                      <Tooltip id="not-quotable-auto-tooltip-text" />
                      <Text ml={spacings.px4} color={colors.statusOrange} type="small" bold>
                        {INVALID_TO_QU0TE_MESSAGE}
                      </Text>
                    </Container>
                  )}
                  {!licensedToQuote(address.state) && (
                    <Container
                      data-tip={NOT_LICENSED_MESSAGE}
                      data-place="top"
                      data-for="not-licensed-auto-tooltip-text"
                      ml={spacings.px4}
                    >
                      <WarningIcon color={colors.statusOrange} />
                      <Tooltip id="not-licensed-auto-tooltip-text" />
                      <Text ml={spacings.px4} color={colors.statusOrange} type="small" bold>
                        {NOT_LICENSED_TO_QU0TE_MESSAGE}
                      </Text>
                    </Container>
                  )}
                </FlexBox>
                {!!values[gid] && (
                  <VehicleEditor
                    vehicleGid={gid}
                    formikValues={values}
                    setValues={setValues}
                    personAddresses={personAddresses}
                  />
                )}
              </div>
            ))}
            <Heading type="h4" pb={spacings.px12}>
              Cross sale
            </Heading>
            <FlexBox columnDirection maxWidth={sizes.mediumFormInputWidth}>
              <CheckboxField id={PAF_CHECKBOX_ID} name={PAF_CHECKBOX_ID} label="Jewelry Insurance" />
              <Text
                mb={spacings.px12}
                mt={spacings['-px32']}
                customCss={css`
                  white-space: nowrap;
                `}
                color={colors.grey60}
                type="small"
              >
                {PAF_RANGES_HINT}
              </Text>
              {!!values[PAF_CHECKBOX_ID] && (
                <NumericField
                  width={sizes.mediumFormInputWidth}
                  label="Estimated value"
                  id={PAF_INPUT_FIELD_ID}
                  name={PAF_INPUT_FIELD_ID}
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                />
              )}
            </FlexBox>
          </>
        );
      }}
    />
  );
};

export default AssetsPicker;
