import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { IDashboardLead } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface LeadsDashboardSorting {
  sort_column: string;
  sort_order: string;
}

export interface LeadsDashboardFilters {
  dispositions?: string[];
  disposition_reasons?: string[];
  savings?: string[];
  residential_states?: string[];
  tasks?: string[];
  agent_ids?: number[];
  time?: string;
  created_from?: string;
  created_to?: string;
  page?: number;
  test_mode?: boolean;
  lead_mode?: string[];
  business_types?: string[];
  partners?: string[];
  source_products?: string[];
  lead_capture_methods?: string[];
  attributed_marketing_mediums?: string[];
  primary_opportunities?: string[];
  apic_created_from?: string;
  apic_created_to?: string;
}

type LeadsDashboardQuery = LeadsDashboardSorting & LeadsDashboardFilters;

export interface LeadsDashboardResponse {
  leads: IDashboardLead[];
  pages: number;
  count: number;
}

const fetchLeads = (query?: LeadsDashboardQuery): Promise<LeadsDashboardResponse> => {
  const queryString = toQueryParams(query);
  return api.get(`/api/frontend/dashboard_leads?${queryString}`);
};

const useLeads = (query?: LeadsDashboardQuery) => {
  return useQuery({
    queryKey: ['leads', query],
    queryFn: () => fetchLeads(query)
  });
};

export const useLeadsVirtualized = (query?: LeadsDashboardQuery) => {
  return useInfiniteQuery({
    queryKey: ['leads', query],
    queryFn: () => fetchLeads(query),
    initialPageParam: 1,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData
  });
};

export default useLeads;
