import { SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';

import colors from '../../../theme/colors';
import { buttonColors, ButtonSize, ButtonVariant, getHoverColors } from '../buttons/Button';

export interface DropDownOption {
  value: string | number;
  label: ReactNode;
  disabled?: boolean;
  action?: () => void;
  description?: string;
  customCss?: SerializedStyles;
  personGid?: string;
}

export enum DropDownPosition {
  Top = 'top',
  Bottom = 'bottom'
}

export interface DropDownProps<TOption> {
  children: React.ReactNode;
  options: readonly TOption[];
  onSelected: (selectedOption: TOption) => void;
  size?: ButtonSize;
  optionsSize?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  customCss?: SerializedStyles;
  customOptionsCss?: SerializedStyles;
  variant?: ButtonVariant;
  noArrow?: boolean;
  iconChildren?: boolean;
  testId?: string;
  staticView?: boolean;
  iconChildrenActiveProperty?: { [key: string]: string };
  customButtonCss?: SerializedStyles;
  position?: DropDownPosition;
  personGid?: string;
}

export const pickOptionColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors(colors.white, colors.white, colors.azure50);
    case ButtonVariant.PlainText:
      return buttonColors(colors.white, colors.white, colors.black);
    case ButtonVariant.Simple:
      return buttonColors(colors.white, colors.grey5, colors.white);
    default:
      return buttonColors(colors.white, colors.white, colors.black);
  }
};

export const pickOptionHoverColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors(colors.azure50, colors.azure50, colors.white);
    case ButtonVariant.PlainText:
      return buttonColors(colors.azure50, colors.azure50, colors.white);
    case ButtonVariant.Simple:
      return buttonColors(colors.grey5, colors.grey5, colors.white);
    default:
      return getHoverColors(variant);
  }
};

export const pickOptionFontWeight = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.Text:
      return 700;
    default:
      return 400;
  }
};
