/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { ArrowDownIcon, AttachmentIcon, CalendarIcon, ChannelsIcon, UserIcon } from '../../components/core/icons';
import Paragraph, { ParagraphType } from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { Configuration } from '../../constants';
import { useCallState } from '../../contexts/CallContext';
import { usePopper } from '../../hooks';
import { IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import useCallLog from '../../queries/call_logs/useCallLog';
import { INFINITE_NOTES_QUERY_KEY } from '../../queries/people/person_notes/useInfiniteNotes';
import usePerson from '../../queries/people/usePerson';
import {
  PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY,
  SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY,
  useActiveScheduledInteractions,
  useCreateScheduledInteractions,
  useUpdateScheduledInteractions
} from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { userTimezone } from '../../utils/formatter';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';
import FollowupForm from './FollowupForm';
import {
  buildFollowupLocalStorageKey,
  FLOW_MAPPINGS,
  resetIfCallIsAboutToHappen,
  retrieveFollowupFromLocalStorage
} from './helpers';
import useCallCountdown from './useCallCountdown';

const FollowUpView = ({
  scheduledCall,
  calendarTime,
  onReschedule,
  onGotIt,
  disabled
}: {
  scheduledCall: IScheduledCall;
  calendarTime: string;
  onReschedule: () => void;
  onGotIt: () => void;
  disabled: boolean;
}) => {
  return (
    <FlexBox
      p={spacings.px24}
      columnDirection
      backgroundColor={colors.white}
      roundBorder
      border
      boxShadow
      customCss={css`
        width: 400px;
      `}
      gap={spacings.px24}
    >
      <Paragraph bold type={ParagraphType.Large}>
        ✅ Follow-up Scheduled
      </Paragraph>
      <div
        css={css`
          display: grid;
          grid-template-columns: 120px 1fr;
          gap: ${spacings.px12}px;
        `}
      >
        <FlexBox gap={spacings.px8}>
          <CalendarIcon />
          <Paragraph type={ParagraphType.Small}>Date and time</Paragraph>
        </FlexBox>
        <Paragraph type={ParagraphType.Small}>
          {calendarTime} {userTimezone.abbreviation}
        </Paragraph>
        <FlexBox gap={spacings.px8}>
          <UserIcon />
          <Paragraph type={ParagraphType.Small}>Assignee</Paragraph>
        </FlexBox>
        <Paragraph type={ParagraphType.Small}>{scheduledCall.assignee?.name || '-'}</Paragraph>
        <FlexBox gap={spacings.px8}>
          <ChannelsIcon />
          <Paragraph type={ParagraphType.Small}>Cadence Type</Paragraph>
        </FlexBox>
        <Paragraph type={ParagraphType.Small}>{FLOW_MAPPINGS[scheduledCall.flow] || '-'}</Paragraph>
        <FlexBox gap={spacings.px8}>
          <AttachmentIcon />
          <Paragraph type={ParagraphType.Small}>Proposal</Paragraph>
        </FlexBox>
        <Paragraph type={ParagraphType.Small}>
          {scheduledCall.proposal_builder_url ? 'Attached' : 'Not attached'}
        </Paragraph>
      </div>
      <FlexBox gap={spacings.px16} justifyRight mt={spacings.px24}>
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Secondary}
          onClick={() => {
            onReschedule();
          }}
          disabled={disabled}
        >
          Reschedule
        </Button>
        <Button size={ButtonSize.Small} variant={ButtonVariant.Default} onClick={() => onGotIt()}>
          Got it
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

const FollowupPopper = ({
  personGid,
  disabled = false,
  leadGid,
  isEndDisposition
}: {
  personGid: string;
  leadGid: string;
  disabled: boolean;
  isEndDisposition: boolean;
}) => {
  const { currentCallId } = useCallState();
  const { data: currentCallLog } = useCallLog(currentCallId || undefined);

  const [editPhones, setEditPhones] = React.useState(false);
  const [rescheduleMode, setRescheduleMode] = React.useState(false);
  const { displayTimer, formattedTime } = useCallCountdown({ personGid });
  const { data: person } = usePerson(personGid);

  const { setAnchorEl, anchorEl, PopperComponent, popperProps, elementRef } = usePopper({
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [INFINITE_NOTES_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY] });
  };

  const { mutateAsync: createScheduledInteraction } = useCreateScheduledInteractions({
    throwOnError: false,
    onSuccess: () => invalidateQueries()
  });

  const { mutateAsync: updateScheduledInteraction } = useUpdateScheduledInteractions({
    throwOnError: false,
    onSuccess: () => invalidateQueries()
  });

  const { data: scheduledCalls } = useActiveScheduledInteractions(personGid);

  let scheduledCall = scheduledCalls?.[0];

  if (currentCallLog && scheduledCall) {
    scheduledCall = resetIfCallIsAboutToHappen(currentCallLog, scheduledCall);
  }

  const time = moment(scheduledCall?.run_at);
  const calendarTime = time.calendar({
    sameDay: '[Today at] hh:mm a',
    nextDay: '[Tomorrow at] hh:mm a',
    lastDay: '[Yesterday at] hh:mm a',
    nextWeek: '[On] dddd [at] hh:mm a',
    lastWeek: '[Last week] dddd [at] hh:mm a',
    sameElse: '[On] L [at] hh:mm a'
  });

  if (!person) {
    return null;
  }

  const buildInitialValues = () => {
    if (scheduledCall && rescheduleMode) {
      const proposalGid = scheduledCall.proposal_builder_url?.split('/').pop() || '';

      return {
        cadence: scheduledCall.flow,
        date: scheduledCall.run_at ? moment(scheduledCall.run_at).format('YYYY-MM-DD') : '',
        run_at: scheduledCall.run_at || '',
        include_proposal: true,
        proposal: proposalGid,
        phone: scheduledCall.phone,
        note: ''
      };
    }
    const followupData = retrieveFollowupFromLocalStorage(leadGid);

    if (followupData) {
      return {
        cadence: followupData.cadence,
        date: followupData.date,
        include_proposal: followupData.include_proposal,
        proposal: followupData.proposal,
        run_at: followupData.run_at,
        phone: followupData.phone,
        note: followupData.note
      };
    }

    return {
      cadence: ScheduledInteractionFlow.PipelineLowPriority,
      date: '',
      include_proposal: true,
      proposal: '',
      run_at: '',
      phone: person.phone || '',
      note: ''
    };
  };

  const disableClickOutside = editPhones;
  const getBackgroundColor = () => {
    if (displayTimer) {
      return colors.violetBackground;
    }
    if (scheduledCall) {
      return colors.greenBackground;
    }
    return colors.grey5;
  };

  if (!scheduledCall && isEndDisposition) {
    return null;
  }

  return (
    <Container p={spacings.px4} innerRef={disableClickOutside ? null : elementRef}>
      <Button
        onClick={e => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        size={ButtonSize.Small}
        variant={ButtonVariant.Simple}
        disabled={disabled}
        customCss={css`
          border-color: ${scheduledCall && !displayTimer ? colors.greenBackground : colors.grey5};
          background-color: ${getBackgroundColor()};
        `}
      >
        <FlexBox gap={spacings.px4} alignItemsCenter>
          {scheduledCall && !displayTimer ? <CalendarIcon color={colors.statusGreen} /> : null}
          {displayTimer && (
            <FlexBox gap={spacings.px4} alignItemsCenter>
              <CalendarIcon color={colors.violet} />
              <Text bold>Call in {formattedTime} min</Text>
            </FlexBox>
          )}
          {!displayTimer && (
            <Text
              bold
              color="inherit"
              customCss={css`
                white-space: nowrap;
              `}
            >
              {scheduledCall ? calendarTime : 'Schedule follow-up'}
            </Text>
          )}
          <ArrowDownIcon
            css={css`
              ${anchorEl && 'transform: rotate(180deg)'};
              transition: transform 0.2s ease-in-out;
            `}
          />
        </FlexBox>
      </Button>
      <PopperComponent {...popperProps}>
        {scheduledCall && !rescheduleMode && (
          <FollowUpView
            scheduledCall={scheduledCall}
            calendarTime={calendarTime}
            onReschedule={() => {
              setRescheduleMode(true);
            }}
            onGotIt={() => {
              setAnchorEl(null);
            }}
            disabled={displayTimer}
          />
        )}
        {(!scheduledCall || rescheduleMode) && (
          <BaseForm
            pb={spacings.px0}
            pl={spacings.px0}
            pr={spacings.px0}
            pt={spacings.px0}
            customControls
            validationSchema={yup.object().shape({
              cadence: requiredField,
              note: requiredField,
              phone: requiredField,

              date: yup.string().when('cadence', {
                is: (cadence: string) => cadence === ScheduledInteractionFlow.PipelineHighPriority,
                then: schema => schema.required(REQUIRED_MESSAGE),
                otherwise: undefined
              }),
              run_at: yup.string().when('cadence', {
                is: (cadence: string) => cadence === ScheduledInteractionFlow.PipelineHighPriority,
                then: schema =>
                  schema
                    .required(REQUIRED_MESSAGE)
                    .test('is-valid-time', 'Invalid time format. Expected format: "HH:MM AM/PM"', value => {
                      const timeRegex = /^\d{1,2}:\d{2}\s[AP]M$/;
                      const isISODate = (dateString: string) => {
                        const date = new Date(dateString);
                        return !isNaN(date.getTime()) && dateString === date.toISOString();
                      };
                      return timeRegex.test(value) || isISODate(value);
                    }),
                otherwise: undefined
              }),
              proposal: yup.string().when('include_proposal', {
                is: (include_proposal: boolean) => include_proposal,
                then: schema => schema.required(REQUIRED_MESSAGE),
                otherwise: undefined
              })
            })}
            initialValues={buildInitialValues()}
            onSubmit={values => {
              const normalizedRunAt = (() => {
                if (values.cadence === ScheduledInteractionFlow.PipelineLowPriority) {
                  return new Date().toISOString();
                }

                const isValidRunAt = new Date(values.run_at).toString() !== 'Invalid Date';
                if (isValidRunAt) {
                  return values.run_at;
                }

                return new Date(`${values.date} ${values.run_at}`).toISOString();
              })();

              if (scheduledCall) {
                return updateScheduledInteraction({
                  personGid: person.gid,
                  scheduledCallId: scheduledCall.id,
                  params: {
                    run_at: normalizedRunAt,
                    phone: values.phone,
                    note: values.note,
                    assignee_id: authInfo.currentUserId!,
                    flow_key: values.cadence,
                    lead_gid: leadGid,
                    proposal_url: values.include_proposal
                      ? `${Configuration.customerPortalPublicUrl}/proposal/${values.proposal}`
                      : undefined
                  }
                }).then(() => {
                  setRescheduleMode(false);
                  localStorage.removeItem(buildFollowupLocalStorageKey(leadGid));
                });
              }

              return createScheduledInteraction({
                personGid: person!.gid,
                params: {
                  run_at: normalizedRunAt,
                  phone: values.phone,
                  note: values.note,
                  assignee_id: authInfo.currentUserId!,
                  flow_key: values.cadence,
                  lead_gid: leadGid,
                  proposal_url: values.include_proposal
                    ? `${Configuration.customerPortalPublicUrl}/proposal/${values.proposal}`
                    : undefined
                }
              }).then(() => {
                localStorage.removeItem(buildFollowupLocalStorageKey(leadGid));
              });
            }}
            renderForm={formikProps => {
              const formProps = {
                scheduledCall,
                editPhones,
                setEditPhones,
                person,
                leadGid,
                onDiscard: () => {
                  setRescheduleMode(false);
                  setAnchorEl(null);
                  localStorage.removeItem(buildFollowupLocalStorageKey(leadGid));
                },
                ...formikProps
              } satisfies React.ComponentProps<typeof FollowupForm>;

              return <FollowupForm {...formProps} />;
            }}
          />
        )}
      </PopperComponent>
    </Container>
  );
};

export default FollowupPopper;
