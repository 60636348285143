/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import DropDown from '../../../../components/core/DropDown/DropDown';
import { ClockwiseClockIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import useRecentLeads from '../../../../queries/leads/useRecentLeads';
import RecentLeadManager from '../../../../services/RecentLeadManager';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';

const RecentLeads = () => {
  const [enableApiCall, setEnableApiCall] = React.useState(false);
  const navigate = useNavigate();
  const onSelected = (lead_gid: string) => navigate(`/scouting_report?lead_gid=${lead_gid}`);
  const fiveRecentLeads = enableApiCall ? RecentLeadManager.list.slice(1, 6) : [];
  const { data: recentLeads, isSuccess } = useRecentLeads(fiveRecentLeads);

  const options = recentLeads?.map(lead => {
    return {
      label: (
        <Text>
          <Text bold className="fs-mask">
            {lead.customer_name}
          </Text>{' '}
          in {capitalize(lead.disposition_type)}
        </Text>
      ),
      value: lead.lead_gid,
      description: `ID: ${lead.lead_id}`,
      disabled: false,
      customCss: css``
    };
  });

  const loadingOptions = Array(5)
    .fill({})
    .map((item, index) => {
      return { label: <Skeleton />, value: index.toString(), description: '', disabled: true, customCss: css`` };
    });
  const dropDownOptions = isSuccess ? options : loadingOptions;

  dropDownOptions?.unshift({
    label: <Text bold>Recently interacted leads</Text>,
    value: 'recent_leads',
    description: '',
    disabled: true,
    customCss: css`
      &:hover {
        background-color: ${colors.white};
      }
      cursor: default;
    `
  });

  return (
    <DropDown
      options={dropDownOptions!}
      onSelected={option => onSelected(option.value)}
      iconChildren
      staticView
      variant={ButtonVariant.Simple}
      iconChildrenActiveProperty={{ backgroundColor: colors.grey5 }}
    >
      <Container
        p={spacings.px8}
        roundBorder
        onClick={() => setEnableApiCall(true)}
        customCss={css`
          &:hover {
            background-color: ${colors.grey5};
            cursor: pointer;
          }
        `}
      >
        <ClockwiseClockIcon color={colors.black} />
      </Container>
    </DropDown>
  );
};

export default RecentLeads;
