import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../components/common/Tooltip/NewTooltip';
import Container from '../../components/core/Container';
import Heading from '../../components/core/Heading';
import VirtualizedTable from '../../components/core/VirtualizedTable/VirtualizedTable';
import TablePlaceholder from '../../components/TablePlaceholder';
import featureFlags from '../../constants/featureFlags';
import { useDocumentTitle } from '../../hooks';
import useRecentLeads from '../../queries/leads/useRecentLeads';
import useUpdateLeadStarred from '../../queries/leads/useUpdateLeadStarred';
import usePartners from '../../queries/partners/usePartners';
import RecentLeadManager from '../../services/RecentLeadManager';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { columns } from '../DashboardTable/DashboardTableColumns.util';
import DashboardLeadsList from './DashboardLeadsList';

const RecentLeadsDashboard = () => {
  const { data: recentLeads, isLoading, refetch: refetchRecentLeads } = useRecentLeads(RecentLeadManager.list);
  const { mutate: updateLeadStarred } = useUpdateLeadStarred(() => refetchRecentLeads());
  const { data: partners } = usePartners();

  useDocumentTitle('Recent leads');

  const noop = () => {};
  const isPipelineVersion = featureFlags.leadsPipelineDashboard;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container fitParentWidth>
      <Container p={spacings.px24} borderBottom borderColor={colors.grey10}>
        <Heading type={isPipelineVersion ? 'h4' : 'h3'}>Recent Leads</Heading>
      </Container>
      <Container fitParentWidth mt={spacings.px8} ph={isPipelineVersion ? spacings.px12 : spacings.px8}>
        {isPipelineVersion ? (
          <>
            <VirtualizedTable
              data={recentLeads || []}
              columns={columns(partners)}
              testId="leads"
              isManualSortingDisabled
              fetchMoreOnBottomReached={noop}
              onSortingChange={noop}
              sorting={{
                sort_column: '',
                sort_order: ' '
              }}
            />
            <Tooltip id="dashboard-tooltip" />
          </>
        ) : (
          <>
            {isLoading ? (
              <TablePlaceholder testId="recent-leads-dashboard-loader" />
            ) : (
              <DashboardLeadsList
                leads={recentLeads}
                onStarredChange={(leadId: number, starred: boolean) => updateLeadStarred({ leadId, starred })}
                manual={false}
              />
            )}
          </>
        )}
      </Container>
    </Container>
  );
};

export default RecentLeadsDashboard;
