import './assets/styles/main.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { injectStyle } from 'react-toastify/dist/inject-style';

import { getEntraAuthInstance } from './auth/entraAuthConfig';
import EntraLoginPage from './auth/EntraLoginPage';
import EntraRequiredAuth from './auth/EntraRequiredAuth';
import EntraUserAuthenticationState from './auth/EntraUserAuthenticationState';
import DeprecatedNamespaceRedirect from './components/DeprecatedNamespaceRedirect';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import LastLeadRedirect from './components/LastLeadRedirect';
import LeadGidRedirect from './components/LeadGidRedirect';
import PersonGidRedirect from './components/PersonGidRedirect';
import TwilioFlexUrlRouter from './components/TwilioFlexUrlRouter';
import ZendeskUserPersonRedirect from './components/ZendeskUserPersonRedirect';
import featureFlags from './constants/featureFlags';
import AppRoot from './pages/AppRoot';
import CoveragesMappings from './pages/CoveragesMappings';
import Customer from './pages/Customer';
import CustomerGetQuotes from './pages/Customer/CustomerGetQuotes';
import CustomerView from './pages/CustomerView';
import DashboardTable from './pages/DashboardTable/index';
import ScheduledCalls from './pages/DeprecatedScheduledCalls';
import DeprecatedTasksDashboard from './pages/DeprecatedTasksDashboard';
import EntraExtrasLayoutRoute from './pages/ExtrasLayoutRoute/EntraExtrasLayoutRoute';
import FeatureFlags from './pages/FeatureFlags';
import GuidedSellingExperience from './pages/GuidedSellingExperience';
import Lead from './pages/Lead';
import LeadGetQuotes from './pages/Lead/LeadGetQuotes';
import LeadsDashboard from './pages/LeadsDashboard';
import RecentLeadsDashboard from './pages/LeadsDashboard/RecentLeadsDashboard';
import MetricsDashboards from './pages/MetricsDashboards';
import NewCustomer from './pages/NewCustomer';
import NewLead from './pages/NewLead';
import PeopleDashboard from './pages/PeopleDashboard';
import PeopleSearch from './pages/PeopleSearch';
import PersonCommunicationHistory from './pages/PersonCommunicationHistory';
import PoliciesDashboard from './pages/PoliciesDashboard';
import ProductAnnouncements from './pages/ProductAnnouncements';
import ProductAnnouncementEditor from './pages/ProductAnnouncements/_components/ProductAnnouncementEditor';
import ProductAnnouncementPage from './pages/ProductAnnouncements/_components/ProductAnnouncementPage';
import ScoutingReportScripts from './pages/ScoutingReportScripts';
import ScoutingReportScriptPreview from './pages/ScoutingReportScripts/ScoutingReportScriptPreview';
import ScoutingSteps from './pages/ScoutingReportScripts/ScoutingSteps';
import TasksDashboard from './pages/TasksDashboard';
import authInfo from './services/authInfo';

injectStyle();

const AppWithEntra = () => {
  const [isAuthenticatedToBackend, setIsAuthenticatedToBackend] = React.useState(false);
  const [entraInstance, setEntra] = React.useState<IPublicClientApplication>();

  React.useEffect(() => {
    getEntraAuthInstance().then(instance => {
      setEntra(instance);
    });
  }, []);

  if (!entraInstance) {
    return null;
  }

  return (
    <MsalProvider instance={entraInstance}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <EntraUserAuthenticationState
          isAuthenticatedToBackend={isAuthenticatedToBackend}
          setIsAuthenticatedToBackend={setIsAuthenticatedToBackend}
        />
        <Routes>
          <Route path="/crm/*" element={<DeprecatedNamespaceRedirect />} />
          <Route path="/login" element={<EntraLoginPage />} />
          <Route element={<EntraRequiredAuth isAuthenticatedToBackend={isAuthenticatedToBackend} />}>
            <Route element={<EntraExtrasLayoutRoute />}>
              <Route path="max_client_request" element={<TwilioFlexUrlRouter />} />
              <Route path="leads/gid/:gid" element={<LeadGidRedirect />} />
              <Route path="customers/:customerId/*" element={<PersonGidRedirect />} />
              {/* Used by Zendesk app */}
              <Route path="zendesk_users/:userId/*" element={<ZendeskUserPersonRedirect />} />
              <Route path="people/:personGid/communication_history" element={<PersonCommunicationHistory />} />
              <Route path="people/:personGid/last_lead" element={<LastLeadRedirect />} />
              <Route element={<AppRoot />}>
                <Route
                  path="scouting_report/*" // TODO: rename path to guided_selling_experience
                  element={<GuidedSellingExperience />}
                />
                <Route path="people_search" element={<PeopleSearch />} />
                <Route path="new_lead" element={<NewLead />} />
                <Route path="new_customer" element={<NewCustomer />} />
                <Route path="people/:personGid/quotes/request" element={<LeadGetQuotes />} />
                {authInfo.features.customer_profile_quoting && !featureFlags.hideCustomerQuotesTab && (
                  <Route path="people/:personGid/quotes/new" element={<CustomerGetQuotes />} />
                )}
                <Route path="leads/recent" element={<RecentLeadsDashboard />} />
                <Route path="leads/:id/*" element={<Lead />} />
                <Route
                  path="leads"
                  element={featureFlags.leadsPipelineDashboard ? <DashboardTable /> : <LeadsDashboard />}
                />
                <Route path="policies" element={<PoliciesDashboard />} />
                <Route
                  path="tasks"
                  element={featureFlags.newTasksDashboard ? <TasksDashboard /> : <DeprecatedTasksDashboard />}
                />
                <Route path="people/:gid/*" element={featureFlags.customerView ? <CustomerView /> : <Customer />} />
                <Route path="customers" element={<PeopleDashboard />} />
                <Route path="scheduled_calls/*" element={<ScheduledCalls />} />
                {authInfo.features.amp_ui_product_tabs && (
                  <>
                    <Route path="dashboards" element={<MetricsDashboards />} />
                    <Route path="feature_flags" element={<FeatureFlags />} />
                    <Route path="coverages_mappings" element={<CoveragesMappings />} />
                  </>
                )}
                <Route
                  path="/"
                  element={featureFlags.leadsPipelineDashboard ? <DashboardTable /> : <LeadsDashboard />}
                />
                <Route path="scouting_steps" element={<ScoutingSteps />} />
                <Route path="scouting_steps/:stepId" element={<ScoutingSteps />} />
                <Route path="scouting_report_scripts" element={<ScoutingReportScripts />} />
                <Route path="scouting_report_scripts/:strategyId" element={<ScoutingReportScripts />} />
                <Route path="scouting_report_scripts/:configKey/preview" element={<ScoutingReportScriptPreview />} />
                <Route path="product_announcements" element={<ProductAnnouncements />} />
                <Route path="product_announcements/:productAnnouncementId" element={<ProductAnnouncementPage />} />
                <Route path="product_announcements_admin" element={<ProductAnnouncements adminView />} />
                <Route
                  path="product_announcements_admin/:productAnnouncementId"
                  element={<ProductAnnouncementPage adminView />}
                />
                <Route
                  path="product_announcements_admin/:productAnnouncementId/edit"
                  element={<ProductAnnouncementEditor />}
                />
                <Route path="new_product_announcement" element={<ProductAnnouncementEditor />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Sentry.ErrorBoundary>
    </MsalProvider>
  );
};

export default AppWithEntra;
