/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import HomeLinks from '../../../../components/common/HomeLinks';
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { StarIcon } from '../../../../components/core/icons';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import PriorPolicyEditor from '../../../../components/PriorPolicyEditor';
import PriorPolicyWithHomeForm from '../../../../components/PriorPolicyEditor/PriorPolicyWithHomeForm';
import PriorPolicyWithVehiclesForm from '../../../../components/PriorPolicyEditor/PriorPolicyWithVehiclesForm';
import { Translations } from '../../../../constants';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { IHome, IOpportunity } from '../../../../interfaces';
import {
  opportunityDescription,
  OpportunitySource,
  OpportunityStatus as IOpportunityStatus
} from '../../../../interfaces/IOpportunity';
import {
  isInsurableInterestRealProperty,
  isRequirementNonEssential,
  PolicyType
} from '../../../../interfaces/IPolicyType';
import { useSuspensePersonOpportunities } from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import { useSuspenseQuotesRequests } from '../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import FloodRiskTag from '../../_components/FloodRiskTag';
import { isOpportunityQuoted, secondaryCoreBundleOpportunity } from '../../_helpers';
import usePersonCoreBundleInterest from '../../_hooks/usePersonCoreBundleInterest';
import { usePriorPolicyFormUtils } from '../../_hooks/usePriorPolicyFormUtils';
import QuotesModal from '../Comparison/QuotesModal';

export const OpportunityTitle = ({ personGid, opportunity }: { personGid: string; opportunity: IOpportunity }) => (
  <FlexBox alignItemsCenter gap={spacings.px4}>
    <Text>{opportunity.title}</Text>
    {opportunity.primary && (
      <>
        <StarIcon data-for="primary-opp-tip" data-tip="Primary opportunity" />
        <Tooltip id="primary-opp-tip" />
      </>
    )}
    {isInsurableInterestRealProperty(opportunity.policy_type) && opportunity.assets?.[0] && (
      <HomeLinks homeGid={opportunity.assets[0].gid} personGid={personGid} />
    )}
  </FlexBox>
);

export const OpportunityStatus = ({
  opportunity,
  isOpportunityQuoted
}: {
  opportunity: IOpportunity;
  isOpportunityQuoted: boolean;
}) => {
  if (opportunity.status === IOpportunityStatus.Sold) {
    return (
      <Tag
        label="Sold"
        textType="tiny"
        textColor={colors.white}
        backgroundColor={colors.statusGreen}
        borderRadius={borderRadius}
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
        `}
      />
    );
  }

  if (opportunity.status === IOpportunityStatus.Lost) {
    const configuredReason = Translations.opportunityLostReason(opportunity.reason);
    const preparedReason = configuredReason ? `Lost due to ${configuredReason.toLowerCase()}` : 'Lost';

    return (
      <Tag
        data-tip={configuredReason}
        data-for="opportunity-eligibility-tooltip"
        label={preparedReason}
        textType="tiny"
        textColor={colors.white}
        backgroundColor={colors.statusRed}
        borderRadius={borderRadius}
        singleLine
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
          max-width: 240px;
        `}
      />
    );
  }

  if (opportunity.status === IOpportunityStatus.NotQualified) {
    return (
      <Tag
        data-tip={opportunity.disqualification_message}
        data-for="opportunity-eligibility-tooltip"
        label="Disqualified due to underwriting"
        textType="tiny"
        textColor={colors.white}
        backgroundColor={colors.statusRed}
        borderRadius={borderRadius}
        singleLine
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
          max-width: 240px;
        `}
      />
    );
  }

  if (isOpportunityQuoted) {
    return (
      <Tag
        label="Quoted"
        textType="tiny"
        backgroundColor={colors.aqua}
        textColor={colors.grey80}
        borderRadius={borderRadius}
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
        `}
      />
    );
  }

  if (!opportunity.is_data_collection_enabled) {
    return (
      <Tag
        label="Skipped"
        textType="tiny"
        textColor={colors.white}
        backgroundColor={colors.statusOrange}
        borderRadius={borderRadius}
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
        `}
      />
    );
  }

  if (
    isRequirementNonEssential(opportunity.policy_type) &&
    opportunity.source === OpportunitySource.RecommendationsEngine
  ) {
    return (
      <Tag
        label="AMP identified"
        textType="tiny"
        textColor={colors.white}
        backgroundColor={colors.grey60}
        borderRadius={borderRadius}
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
        `}
      />
    );
  }

  return (
    <Tag
      label="New opportunity"
      textType="tiny"
      textColor={colors.white}
      backgroundColor={colors.grey60}
      borderRadius={borderRadius}
      customCss={css`
        padding: 2px ${spacings.px8}px;
        height: fit-content;
      `}
    />
  );
};

const Opportunities = ({ displayQuotes = true }: { displayQuotes?: boolean }) => {
  const [selectedOpportunity, setSelectedOpportunity] = React.useState<IOpportunity>();

  const { personGid, person, leadGid, lead, candidateGid } = useGuidedSellingExperienceContext();
  const { data: opportunities } = useSuspensePersonOpportunities({
    personGid: personGid!,
    leadGid,
    candidateGid
  });
  const { data: quotesRequests } = useSuspenseQuotesRequests({
    personGid,
    leadGid,
    enabled: displayQuotes
  });

  const { isPersonInterestedInCoreBundle } = usePersonCoreBundleInterest({ personGid: personGid! });
  const secondaryOpportunity = secondaryCoreBundleOpportunity({ opportunities });

  const {
    selectedPolicyOptions,
    addHomeFlow,
    addVehiclesFlow,
    priorPolicyEditorCommonProps,
    policiesOptions,
    onSelected,
    isDataEditingForbidden
  } = usePriorPolicyFormUtils({ opportunities, uiSource: 'summary' });

  return (
    <FlexBox columnDirection gap={spacings.px12}>
      {opportunities.map(opportunity => {
        const opportunityQuoted = isOpportunityQuoted({ opportunity, quotesRequests });
        const isPersonInterestedInOpportunity =
          isPersonInterestedInCoreBundle && secondaryOpportunity?.id === opportunity.id;

        const policyOption =
          policiesOptions.find(({ value }) => value === opportunity.id) ||
          policiesOptions.find(({ value }) => value === opportunity.policy_type);

        return (
          <FlexBox key={opportunity.id} gap={spacings.px16} alignItemsBaseline>
            <Container
              customCss={css`
                min-width: 250px;
                width: 250px;
              `}
            >
              <OpportunityTitle personGid={personGid!} opportunity={opportunity} />
              <Text
                type="tiny"
                color={colors.grey60}
                className={isInsurableInterestRealProperty(opportunity.policy_type) ? 'fs-mask' : ''}
              >
                {opportunityDescription(opportunity)}
              </Text>
              {isPersonInterestedInOpportunity && !opportunity.assets?.length && (
                <Container
                  customCss={css`
                    margin-left: -${spacings.px6}px;
                  `}
                >
                  <ButtonWithoutBorder
                    disabled={isDataEditingForbidden || !policyOption}
                    textType="small"
                    onClick={() => onSelected(policyOption!)}
                  >
                    Select vehicles
                  </ButtonWithoutBorder>
                </Container>
              )}
            </Container>
            <FlexBox gap={spacings.px16} alignItemsBaseline>
              <FlexBox gap={spacings.px8}>
                <OpportunityStatus opportunity={opportunity} isOpportunityQuoted={opportunityQuoted} />
                {isPersonInterestedInOpportunity && (
                  <Tag
                    label="Interested"
                    textType="tiny"
                    backgroundColor={colors.violet}
                    borderRadius={borderRadius}
                    customCss={css`
                      padding: 2px ${spacings.px8}px;
                      height: fit-content;
                    `}
                  />
                )}
                {opportunity.policy_type === PolicyType.Flood && (
                  <FloodRiskTag floodRisk={(opportunity.assets?.[0] as IHome | undefined)?.flood_risk || null} />
                )}
              </FlexBox>
              {opportunityQuoted && displayQuotes && (
                <ButtonWithoutBorder onClick={() => setSelectedOpportunity(opportunity)}>
                  See latest quotes
                </ButtonWithoutBorder>
              )}
            </FlexBox>
          </FlexBox>
        );
      })}
      <Tooltip id="opportunity-eligibility-tooltip" />
      {selectedOpportunity && (
        <QuotesModal opportunity={selectedOpportunity} onClose={() => setSelectedOpportunity(undefined)} />
      )}
      {personGid && selectedPolicyOptions && (
        <>
          {!addVehiclesFlow && !addHomeFlow && (
            <PriorPolicyEditor {...priorPolicyEditorCommonProps} personGid={personGid} />
          )}
          {addHomeFlow && (
            <PriorPolicyWithHomeForm {...priorPolicyEditorCommonProps} personGid={personGid} leadGid={lead?.gid} />
          )}
          {addVehiclesFlow && person && (
            <PriorPolicyWithVehiclesForm {...priorPolicyEditorCommonProps} person={person} leadGid={lead?.gid} />
          )}
        </>
      )}
    </FlexBox>
  );
};

export default Opportunities;
