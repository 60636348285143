import { useQuery } from '@tanstack/react-query';

import { ITask, IUserWithRole } from '../../interfaces';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { TaskKind } from '../../interfaces/ITask';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const GROUPED_TASKS_QUERY_KEY = 'grouped_tasks';

export enum TasksGroupBy {
  TaskType = 'task_type',
  Customer = 'customer',
  DueDate = 'due_date'
}

export enum DateGroupName {
  Resolved = 'resolved',
  Overdue = 'overdue',
  Future = 'future',
  Today = 'today'
}

export enum ItemStatus {
  ToDo = 'to_do',
  InProgress = 'in_progress',
  Attempt1 = 'attempt_1',
  Attempt2 = 'attempt_2',
  Attempt3 = 'attempt_3',
  Attempt4 = 'attempt_4',
  Attempt5 = 'attempt_5',
  LastAttempt = 'last_attempt',
  Completed = 'completed',
  Rescheduled = 'rescheduled',
  Cancelled = 'cancelled',
  Overdue = 'overdue'
}

export interface TasksDashboardQuery {
  kinds?: string[];
  completed?: boolean;
  date_from?: string;
  date_to?: string;
  page?: number;
  per_page?: number;
  flows?: ScheduledInteractionFlow[];
  group_by?: TasksGroupBy;
  agent_ids?: number[];
  sort_column?: string;
  sort_order?: string;
}

export interface ITaskDashboardTask extends ITask {
  status: ItemStatus;
  customer_name: string;
}

export interface ITaskDashboardContactFlow {
  id: number;
  lead_id: number | null;
  agent_id: number | null;
  assignee: IUserWithRole | null;
  completed: boolean;
  description: string;
  person_gid: string;
  status: ItemStatus;
  due_date: string;
  created_at: string;
  updated_at: string;
  due_time: string;
  priority: string;
  contact_flow: string;
  customer_name: string;
  kind: ScheduledInteractionFlow;
}

export interface TaskDashboardItems {
  group_name: DateGroupName | (TaskKind & 'contact_flow') | string;
  items: (ITaskDashboardTask | ITaskDashboardContactFlow)[];
}

interface TasksDashboardResponse {
  items: TaskDashboardItems[];
}

const useGroupedTasks = (query?: TasksDashboardQuery) =>
  useQuery({
    queryKey: [GROUPED_TASKS_QUERY_KEY, query],
    queryFn: (): Promise<TasksDashboardResponse> => api.get(`/api/frontend/tasks/grouped?${toQueryParams(query)}`),
    select: data => data.items
  });

export default useGroupedTasks;
