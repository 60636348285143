/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { FormikComputedProps, FormikHelpers } from 'formik';
import * as React from 'react';

import { useToggle } from '../../hooks';
import { ILoan, IMaticPolicy } from '../../interfaces';
import { PERSON_LOANS_QUERY_KEY } from '../../queries/people/usePersonLoans';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import { EditIcon, PartnerIcon } from '../core/icons';
import Tag from '../core/Tag';
import Text from '../core/Text';
import LoanForm from './LoanForm';
import LoanInfo from './LoanInfo';

type FormRef = FormikComputedProps<unknown> & FormikHelpers<unknown>;

const Loan = ({
  loan,
  personGid,
  disabled = false,
  isLoanPrimary,
  policy
}: {
  loan: ILoan;
  personGid: string | undefined;
  disabled?: boolean;
  isLoanPrimary: boolean;
  policy?: IMaticPolicy;
}) => {
  const queryClient = useQueryClient();
  const [editLoan, toggleEditLoan] = useToggle(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formRef = React.useRef<FormRef>();

  return (
    <Container>
      <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <PartnerIcon color={isLoanPrimary ? colors.black : colors.grey30} />
          <Text bold color={isLoanPrimary ? colors.black : colors.grey30} singleLine>
            {loan.servicer?.business_name || loan.lender?.business_name}
          </Text>
        </FlexBox>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <Tag
            transparent
            textType="small"
            textColor={colors.azure50}
            backgroundColor={colors.azure50}
            border
            label={isLoanPrimary ? 'Primary loan' : 'Inactive loan'}
            customCss={css`
              padding: 0 ${spacings.px8}px;
            `}
          />

          {editLoan ? (
            <FlexBox gap={spacings.px4}>
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Small}
                onClick={toggleEditLoan}
                data-testid={`${loan.gid}-cancel-loan-info-panel-form`}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                size={ButtonSize.Small}
                onClick={async () => {
                  setIsSubmitting(true);
                  if (formRef.current?.dirty) {
                    await formRef.current.submitForm().then(() => {
                      queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY] });
                    });
                  }
                  setIsSubmitting(false);
                  if (formRef.current?.isValid) {
                    toggleEditLoan();
                  }
                }}
                type="submit"
                data-testid={`${loan.gid}-submit-loan-info-form`}
                loading={isSubmitting}
              >
                Save
              </Button>
            </FlexBox>
          ) : (
            <FlexBox gap={spacings.px8}>
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Simple}
                data-testid={`${loan.gid}-edit-loan`}
                onClick={() => {
                  toggleEditLoan();
                }}
              >
                <FlexBox gap={spacings.px4} alignItemsCenter>
                  <EditIcon width={16} height={16} />
                  <Text type="small" bold>
                    Edit
                  </Text>
                </FlexBox>
              </Button>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>

      {editLoan ? (
        <LoanForm
          loan={loan}
          personGid={personGid!}
          formRef={formRef}
          disableEditing={disabled}
          isLoanPrimary={isLoanPrimary}
          policy={policy}
        />
      ) : (
        <LoanInfo loan={loan} isLoanPrimary={isLoanPrimary} />
      )}
    </Container>
  );
};

export default Loan;
