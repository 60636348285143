import * as React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import useBrand from '../../../../queries/partners/useBrand';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const HomeFirstLeadBrandInfo = ({ brandKey }: { brandKey: string }) => {
  const { brand } = useBrand(brandKey);

  if (!brand) {
    return null;
  }

  return (
    <Container data-testid="brand">
      <Text type="large" bold>
        Partner
      </Text>
      <FlexBox mt={spacings.px12}>
        <FlexBox mr={spacings.px6}>
          <Text>{brand.name}</Text>
        </FlexBox>
        {brand?.website && (
          <a href={brand.website} rel="noreferrer" target="_blank">
            <OpenInNewIcon color={colors.azure50} />
          </a>
        )}
      </FlexBox>
      {brand?.description && (
        <FlexBox mt={spacings.px12}>
          <Text color={colors.grey60}>{brand.description}</Text>
        </FlexBox>
      )}
    </Container>
  );
};

export default HomeFirstLeadBrandInfo;
