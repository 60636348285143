import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { IUser } from '../../../interfaces';
import { IScheduledCall } from '../../../interfaces/IScheduledCall';
import Description from '../../../pages/DeprecatedScheduledCalls/Description';
import useCancelScheduledInteraction from '../../../queries/scheduled_calls/useCancelScheduledInteraction';
import { PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY } from '../../../queries/scheduled_calls/useScheduledInteractions';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import Container from '../../core/Container';
import DescriptionList from '../../core/DescriptionList';
import Modal, { ModalSize } from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

interface CancelTrailingScheduledCallModalProps {
  onCloseModal: () => void;
  scheduledCall: IScheduledCall;
  personGid: string;
  assignee: IUser | null | undefined;
}

const CancelTrailingScheduledCallModal = ({
  personGid,
  onCloseModal,
  scheduledCall,
  assignee
}: CancelTrailingScheduledCallModalProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { mutate: cancelCall, isPending: isCancellingCall } = useCancelScheduledInteraction(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY, personGid] });
    onCloseModal();
  });

  const callTime = scheduledCall.run_at;

  return (
    <Modal
      size={ModalSize.large}
      containerTitle="Do you want to cancel a call with this customer?"
      confirmText="Cancel call"
      cancelText="Keep call"
      cancelHandler={() => onCloseModal()}
      confirmHandler={() => {
        cancelCall({
          personGid,
          contactFlowId: scheduledCall.id
        });
        onCloseModal();
      }}
      confirmationInProgress={isCancellingCall}
    >
      <Paragraph m={spacings.px8}>
        We will try to reach out to this customer unless you cancel the scheduled call. Please review call details.
      </Paragraph>
      <Container
        backgroundColor={colors.grey10}
        roundBorder
        m={spacings.px8}
        pt={spacings.px4}
        ph={spacings.px12}
        pb={spacings.px12}
      >
        <DescriptionList
          term={<Text>Assignee</Text>}
          details={<Text>{scheduledCall.assignee?.name || assignee?.name || '-'}</Text>}
        />
        <DescriptionList term={<Text>Due date</Text>} details={<Text>{callTime}</Text>} />
        <DescriptionList
          term={<Text>Note</Text>}
          details={<Description gid={scheduledCall.person_gid} created_at={scheduledCall.created_at} />}
        />
      </Container>
    </Modal>
  );
};

export default CancelTrailingScheduledCallModal;
