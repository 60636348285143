/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import * as React from 'react';

import BaseForm from '../../../../../components/core/forms/BaseForm';
import { SelectField } from '../../../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import {
  AutoCoveragesOptions,
  mainAutoCoverageOptionDefinitions
} from '../../../../../interfaces/IAutoCoveragesOptions';
import { ISelectedAssetCoverage } from '../../../../../interfaces/IOpportunity';
import { IVehicle } from '../../../../../interfaces/IVehicle';
import { spacings } from '../../../../../theme/variables';
import { uiDataForCoverageOption } from '../../../../../utils/coveragesFormatter';
import {
  initialValues,
  validateVehiclesCoverages,
  validations
} from '../../../../GetQuotes/VehiclesCoveragesPicker/formHelpers';

const AutoCoveragesEditor = ({
  vehicle,
  onCancel,
  onSetCoverages,
  coveragesOptions,
  elementRef
}: {
  vehicle: IVehicle;
  onCancel: () => void;
  onSetCoverages: (coverages: ISelectedAssetCoverage[]) => void;
  coveragesOptions: AutoCoveragesOptions;
  elementRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { gid: vehicleGid } = vehicle;

  const mainCoverages = Object.keys(mainAutoCoverageOptionDefinitions)
    .map(key => coveragesOptions.find(options => options.values[0]!.key === key))
    .filter(Boolean);
  const additionalCoverages = coveragesOptions.filter(
    options => !Object.keys(mainAutoCoverageOptionDefinitions).includes(options.values[0]!.key)
  );

  return (
    <Modal
      containerTitle="Set custom coverage for vehicle"
      size={ModalSize.extra}
      returnFocusAfterClose={false}
      showCancelButton={false}
      cancelHandler={() => onCancel()}
    >
      <div ref={elementRef}>
        <BaseForm
          enableReinitialize
          initialValues={initialValues({
            recentPolicy: undefined,
            selectedVehicles: [vehicle],
            coverages: { [vehicleGid]: coveragesOptions }
          })}
          validationSchema={validations()}
          pt={0}
          pb={0}
          pl={spacings.px8}
          pr={spacings.px8}
          validateOnBlur={false}
          validateOnMount={false}
          validate={values => validateVehiclesCoverages({ selectedVehicles: [vehicle], values })}
          submitText="Set coverage"
          onSubmit={values => {
            const selectedVehicleCoveragesHash = values[vehicleGid].coverages;
            const selectedVehicleCoverages = Object.keys(selectedVehicleCoveragesHash).map(key => ({
              key,
              limit_option_gid: selectedVehicleCoveragesHash[key],
              limit_value: null,
              deductible_value: null
            }));

            onSetCoverages(selectedVehicleCoverages);
          }}
          cancelHandler={() => onCancel()}
          renderForm={() => (
            <Grid container columnSpacing={2} columns={4}>
              {[...mainCoverages, ...additionalCoverages].map(coverageOptions => {
                const { key, coverageLabel } = uiDataForCoverageOption(coverageOptions.values[0]!);

                const selectOptions = coverageOptions!.values.map(coverageOption => {
                  const { limitsLabel } = uiDataForCoverageOption(coverageOption!);

                  return { key: coverageOption.gid, value: limitsLabel };
                });

                return (
                  <Grid item xs={1} key={key}>
                    <SelectField
                      required
                      requireTouchToShowError={false}
                      label={coverageLabel}
                      id={`${vehicleGid}.coverages.${key}`}
                      name={`${vehicleGid}.coverages.${key}`}
                      options={selectOptions}
                      customLabelCss={css`
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 260px;
                      `}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        />
      </div>
    </Modal>
  );
};

export default AutoCoveragesEditor;
