/** @jsxImportSource @emotion/react */
import './styles.scss';

import { css } from '@emotion/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Link, StarIndicator } from '../../../components/common';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import FlexBox from '../../../components/core/FlexBox';
import Modal from '../../../components/core/Modal';
import Tag from '../../../components/core/Tag';
import { isEndDisposition } from '../../../components/DispositionsModals/dispositionsHelper';
import { useDNCTip, useDocumentTitle, useToggle } from '../../../hooks';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import useDuplicateLead from '../../../queries/leads/duplicate/useDuplicateLead';
import useLead from '../../../queries/leads/useLead';
import useUpdateLeadStarred from '../../../queries/leads/useUpdateLeadStarred';
import usePerson from '../../../queries/people/usePerson';
import usePersonActiveLead from '../../../queries/people/usePersonActiveLead';
import useRateReviewSubscription from '../../../queries/people/useRateReviewSubscription';
import { useActiveScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import Indicator from '../../ScheduleCall/Indicator';

interface LeadHeaderProps {
  personGid: string;
  leadGid: string;
  leadId: number;
}

const LeadHeader = ({ personGid, leadGid, leadId }: LeadHeaderProps) => {
  const { data: person, isFetching: isPersonFetching } = usePerson(personGid);
  const { data: lead, refetch: refetchLead } = useLead(leadGid);
  const { data: leadDispositions } = useLeadDispositions(leadId);
  const { data: rateReviewSubscription } = useRateReviewSubscription(personGid);
  const { mutate: updateLeadStarred } = useUpdateLeadStarred(() => refetchLead());

  useDocumentTitle(person?.name || `Lead ${leadId}`);

  const navigate = useNavigate();

  const { DNCTip, labelProps, doNotContact } = useDNCTip({ personGid });

  const currentDispositionType = leadDispositions?.current_disposition?.disposition_type;

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  const { mutate: duplicateLead, isPending: isLeadDuplicating } = useDuplicateLead({
    onSuccess: data => {
      analytics.track('New Lead was created', { from: 'lead_summary_page_duplicate', lead_gid: data.lead.gid });
      navigate(`/leads/${data.lead.id}/summary`);
    }
  });

  const duplicateConfirmHandler = () => {
    duplicateLead({ leadGid });
    toggleShowConfirmationModal();
  };

  const { data: activeLead, isSuccess: activeLeadIsSuccess } = usePersonActiveLead({
    personGid,
    insuranceCategory: lead?.insurance_category
  });

  const showActiveLead =
    isEndDisposition(currentDispositionType) && activeLead?.id && activeLead?.id !== leadId && activeLeadIsSuccess;

  const showDuplicateButton = currentDispositionType && isEndDisposition(currentDispositionType) && !showActiveLead;

  const { data: scheduledCalls, isFetching } = useActiveScheduledInteractions(person?.gid);

  const showCallButton =
    !showDuplicateButton && scheduledCalls?.length === 0 && !isFetching && !isPersonFetching && !showActiveLead;

  return (
    <div className="lead-header">
      <FlexBox justifySpaceBetween>
        <FlexBox gap={spacings.px12} alignItemsCenter>
          <h3>
            {'Editing Lead ID '}
            <span>
              <Link to={{ pathname: `/leads/${leadId}` }}>{leadId}</Link>
            </span>
            {person && (
              <>
                {', '}
                <span className="fs-mask">
                  Customer:&nbsp;
                  <Link to={{ pathname: `/people/${person.gid}` }}>
                    {person.first_name} {person.last_name}
                  </Link>
                </span>
              </>
            )}
          </h3>
          {rateReviewSubscription?.enabled && (
            <div className="label-wrapper">
              <Tag label="Ratereview" textColor={colors.white} backgroundColor={colors.violet} />
            </div>
          )}
          {lead?.test_mode && (
            <div className="label-wrapper">
              <Tag label="Test Lead" textColor={colors.white} backgroundColor={colors.cherise} />
            </div>
          )}
          <StarIndicator
            className="star-icon"
            starred={lead?.starred}
            onChange={(starred: boolean) => updateLeadStarred({ leadId, starred })}
          />
        </FlexBox>
        <FlexBox gap={spacings.px12} alignItemsCenter>
          {showDuplicateButton && (
            <div className="duplicate-lead">
              <Button onClick={toggleShowConfirmationModal} size={ButtonSize.Small} disabled={isLeadDuplicating}>
                Duplicate lead
              </Button>
            </div>
          )}
          {showActiveLead && (
            <div className="one-active-lead">
              <Link to={`/leads/${activeLead.id}`}>
                <Button size={ButtonSize.Small}>Open active lead</Button>
              </Link>
            </div>
          )}
          {showCallButton && (
            <>
              <Link className="schedule-call" to="schedule_call" {...labelProps}>
                <Button disabled={!!doNotContact} size={ButtonSize.Small}>
                  Schedule call
                </Button>
              </Link>
              <DNCTip />
            </>
          )}
          <Indicator
            leadGid={leadGid}
            personGid={personGid}
            candidateGid={undefined}
            containerCss={css`
              margin-left: auto;
            `}
          />
        </FlexBox>
      </FlexBox>
      {showConfirmationModal && (
        <Modal
          confirmHandler={duplicateConfirmHandler}
          cancelHandler={toggleShowConfirmationModal}
          containerTitle="Confirmation"
          confirmText="Confirm"
        >
          Are you sure you want to duplicate this lead?
        </Modal>
      )}
    </div>
  );
};

export default LeadHeader;
