/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { IDocument, IMaticPolicy, IPerson } from '../../interfaces';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import { DocumentWithTickIcon } from '../core/icons';
import Tag from '../core/Tag';
import Text from '../core/Text';
import { IChannel } from './Document';
import DocumentStatus from './DocumentStatus';
import GenerateInvoiceOnPolicy from './GenerateInvoiceOnPolicy';
import UploadPolicyDocument from './UploadPolicyDocument';

const MiniDocument = ({
  person,
  doc,
  documentType,
  channels,
  policy,
  docTypes,
  isDeliveryDocument,
  onDocumentExpand
}: {
  person: IPerson;
  doc: IDocument | null;
  documentType?: string | null;
  channels?: IChannel[];
  policy?: IMaticPolicy;
  docTypes: DocumentType[] | undefined;
  isDeliveryDocument?: boolean;
  onDocumentExpand?: () => void;
}) => {
  const documentTypeTitle = docTypes?.find(({ key }) => key === documentType)?.title;

  return (
    <FlexBox
      css={css`
        width: 200px;
        min-height: 138px;
      `}
      alignItemsFlexStart
      columnDirection
      border
      roundBorder
      gap={spacings.px8}
      mt={spacings.px12}
      p={spacings.px8}
      justifySpaceBetween
      data-testid={onDocumentExpand && doc ? `Expand ${doc.name}` : ''}
      onClick={() => {
        onDocumentExpand?.();
      }}
      customCss={css`
        cursor: ${onDocumentExpand && doc ? 'pointer' : 'default'};
      `}
    >
      <FlexBox
        gap={spacings.px12}
        alignItemsFlexStart
        columnDirection
        fitParentWidth
        customCss={css`
          overflow: hidden;
        `}
      >
        <FlexBox fitParentWidth justifySpaceBetween gap={spacings.px8} fitParentHeight>
          <FlexBox>
            <Container border roundBorder p={spacings.px4}>
              <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
                <DocumentWithTickIcon color={doc ? colors.statusGreen : colors.grey30} height={24} width={24} />
              </Container>
            </Container>
          </FlexBox>

          <FlexBox fitParentHeight>
            {!doc && documentType !== 'invoice' && docTypes && documentType && policy && (
              <UploadPolicyDocument
                personGid={person.gid}
                docTypes={docTypes}
                documentTypeKey={documentType}
                policyId={policy.id}
              />
            )}
            {documentType === 'invoice' && !doc && policy && (
              <GenerateInvoiceOnPolicy policy={policy} person={person} />
            )}
            {doc && (
              <Tag
                transparent
                label={documentTypeTitle || 'Unknown'}
                textColor={documentTypeTitle ? colors.violet : colors.grey60}
                backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
                border
                textType="tiny"
                customCss={css`
                  padding: 2px 4px;
                `}
              />
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox fitParentWidth>
          {doc ? (
            <FlexBox columnDirection fitParentWidth gap={spacings.px8}>
              <FlexBox alignItemsCenter gap={spacings.px8} fitParentWidth>
                <Text className="fs-mask" singleLine title={doc.name} type="small">
                  {doc.name}
                </Text>
              </FlexBox>
            </FlexBox>
          ) : (
            <Text type="small" color={colors.grey80}>
              {documentTypeTitle || 'Unknown'}
            </Text>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox alignItemsCenter gap={spacings.px16}>
        {isDeliveryDocument && <DocumentStatus channels={channels} document={doc} withDetails={false} />}
      </FlexBox>
    </FlexBox>
  );
};

export default MiniDocument;
