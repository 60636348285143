import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { create } from 'zustand';

import { INote } from '../../../interfaces';
import { POLICY_DELIVERIES_QUERY_KEY } from '../../../queries/people/person_policies/usePolicyDeliveries';
import {
  PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY,
  SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY,
  SCHEDULED_INTERACTIONS_QUERY_KEY
} from '../../../queries/scheduled_calls/useScheduledInteractions';
import { GROUPED_TASKS_QUERY_KEY } from '../../../queries/tasks/useGroupedTasks';
import analytics from '../../../services/analytics';
import authInfo from '../../../services/authInfo';
import { ActionCableContext } from '../../../utils/actioncable';

interface PersonMergedResponse {
  message: 'person_merged';
  payload: {
    active_lead_gid: string | null;
    merged_person_gid: string;
  };
}

interface NoteCreatedResponse {
  message: 'note_created';
  payload: INote;
}

interface DeliveryUpdatedResponse {
  message: 'delivery_updated';
  payload: {
    person_gid: string;
    policy_id: number;
  };
}

interface ScheduledCallCanceledResponse {
  message: 'scheduled_call_canceled';
  payload: {
    person_gid: string;
  };
}

type EventResponse =
  | PersonMergedResponse
  | NoteCreatedResponse
  | DeliveryUpdatedResponse
  | ScheduledCallCanceledResponse;

interface EventState {
  personMerged: PersonMergedResponse['payload'] | null;
  createdNote: INote | null;
  setPersonMerged: (personMerged: PersonMergedResponse['payload']) => void;
  resetPersonMerged: () => void;
  setCreatedNote: (note: INote) => void;
  resetCreatedNote: () => void;
  resetEventState: () => void;
}

export const useCustomerEventsState = create<EventState>(set => ({
  personMerged: null,
  createdNote: null,
  setPersonMerged: (personMerged: PersonMergedResponse['payload']) => set({ personMerged }),
  resetPersonMerged: () => set({ personMerged: null }),
  setCreatedNote: note => set({ createdNote: note }),
  resetCreatedNote: () => set({ createdNote: null }),
  resetEventState: () => set({ personMerged: null, createdNote: null })
}));

const useCustomerEventsChannel = (personGid: string) => {
  const cable = React.useContext(ActionCableContext);

  const { setPersonMerged, setCreatedNote, resetEventState } = useCustomerEventsState();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    const customerEventChannel = cable?.subscriptions?.create(
      { channel: 'CustomerEventsChannel', person_gid: personGid },
      {
        received: ({ message, payload }: EventResponse) => {
          switch (message) {
            case 'person_merged': {
              analytics.track('Person Merged Event', {
                active_lead_gid: payload.active_lead_gid || '',
                merged_person_gid: payload.merged_person_gid || ''
              });
              return setPersonMerged(payload);
            }
            case 'delivery_updated':
              return queryClient.invalidateQueries({
                queryKey: [POLICY_DELIVERIES_QUERY_KEY, payload.person_gid, payload.policy_id]
              });
            case 'note_created': {
              if (payload.agent?.id !== authInfo.currentUserId) {
                setCreatedNote(payload);
              }

              break;
            }
            case 'scheduled_call_canceled': {
              queryClient.invalidateQueries({
                queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY, payload.person_gid]
              });
              queryClient.invalidateQueries({
                queryKey: [SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY]
              });
              queryClient.invalidateQueries({
                queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY]
              });
              queryClient.invalidateQueries({
                queryKey: [GROUPED_TASKS_QUERY_KEY]
              });

              break;
            }
          }
        }
      }
    );

    return () => {
      customerEventChannel?.unsubscribe();
      resetEventState();
    };
  }, [cable, personGid, setPersonMerged, setCreatedNote, resetEventState, queryClient]);
};

export default useCustomerEventsChannel;
