import { spacings } from '../../theme/variables';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import RadioInput from '../core/inputs/Radio';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph from '../core/Paragraph';

interface LeadClassificationCheckModalProps {
  createJohnDoeLead: () => Promise<unknown>;
  cancel: () => void;
}

const LEAD_CLASSIFICATION_INPUT_NAME = 'lead_classification_input';

const LeadClassificationCheckModal = ({ createJohnDoeLead, cancel }: LeadClassificationCheckModalProps) => {
  return (
    <Modal
      containerTitle="Lead Classification Check"
      size={ModalSize.large}
      cancelHandler={cancel}
      confirmHandler={async () => {
        const leadClassification = document.querySelector<HTMLInputElement>(
          `input[name="${LEAD_CLASSIFICATION_INPUT_NAME}"]:checked`
        )?.value;
        if (leadClassification === 'yes') {
          await createJohnDoeLead();
        } else {
          cancel();
        }
      }}
      confirmText="Confirm"
    >
      <Paragraph>Just wrapped up a chat with a customer not in our system? Let’s categorize it together.</Paragraph>
      <Container pl={spacings.px24} mt={spacings.px8}>
        <Paragraph bold>Was this lead a John Doe?</Paragraph>
        <Paragraph type="tiny" mt={spacings.px8}>
          Choosing ”Yes” means this lead was unable to provide contact information or was not looking for Matic
          Services. This is a SAL but we’ll take care of the lead for you.
        </Paragraph>
        <FlexBox mt={spacings.px8} gap={spacings.px20}>
          <RadioInput
            defaultChecked={false}
            name={LEAD_CLASSIFICATION_INPUT_NAME}
            id="yes"
            label="Yes, it’s John Doe"
            value="yes"
          />
          <RadioInput
            defaultChecked={true}
            name={LEAD_CLASSIFICATION_INPUT_NAME}
            id="no"
            label="No, Continue as Normal"
            value="no"
          />
        </FlexBox>
      </Container>
    </Modal>
  );
};

export default LeadClassificationCheckModal;
