export enum CallLogStatus {
  Pending = 'pending',
  Completed = 'completed'
}

export interface IPlannedCallLog {
  external_id: number;
  person_gid?: string;
  lead_gid?: string;
  status: CallLogStatus;
  preview_timestamp: string;
  user_id: number;
}

export default IPlannedCallLog;
