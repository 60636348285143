import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { ITask } from '../../../interfaces/ITask';
import useAssignees from '../../../queries/assignees/useAssignees';
import useCreateTask, { TaskPayload } from '../../../queries/people/person_tasks/useCreateTask';
import usePersonTasks from '../../../queries/people/person_tasks/usePersonTasks';
import useUpdateTask from '../../../queries/people/person_tasks/useUpdateTask';
import usePerson from '../../../queries/people/usePerson';
import { usePersonScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import { TASK_REMINDERS_QUERY_KEY } from '../../../queries/tasks/useTaskReminders';
import { spacings } from '../../../theme/variables';
import TaskEditor from './TaskEditor';
import TaskList from './TasksList';

const LeadTasks = ({ personGid, leadGid, leadId }: { personGid: string; leadGid?: string; leadId?: number }) => {
  const { data: person } = usePerson(personGid);

  const [editedTask, setEditedTask] = useState<null | ITask>();
  const [createNewTask, setCreateNewTask] = useState(false);

  const queryClient = useQueryClient();
  const { data: tasks, isFetching: fetchingTasks, refetch: refetchTasks } = usePersonTasks(personGid);
  const { data: assignees, isPending: isPendingAssignees } = useAssignees();
  const { mutate: createTask, isPending: creatingTask } = useCreateTask({ onSuccess: () => setCreateNewTask(false) });
  const { mutate: updateTask, isPending: updatingTask } = useUpdateTask(() => {
    setEditedTask(null);
    refetchTasks().then(() => queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] }));
  });
  const { data: scheduledInteractions, isFetching: fetchingInteractions } = usePersonScheduledInteractions(personGid);

  const isPending = fetchingTasks || isPendingAssignees || creatingTask || updatingTask || fetchingInteractions;

  if (isPending) {
    return <TablePlaceholder testId="lead-tasks-loader" />;
  }

  return (
    <Container fitParentWidth>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Tasks</Heading>
        <Button size={ButtonSize.Small} onClick={() => setCreateNewTask(true)}>
          + Add task
        </Button>
      </FlexBox>
      {tasks && (
        <Container mt={spacings.px24}>
          <TaskList
            isLoading={isPending}
            leadGid={leadGid}
            tasks={tasks}
            scheduledInteractions={scheduledInteractions || []}
            onTaskEditorOpen={(task: ITask) => setEditedTask(task)}
            onTaskComplete={(data: ITask) => {
              data.completed = !data.completed;
              updateTask({ personGid, taskId: data.id, data: data as TaskPayload });
            }}
          />
        </Container>
      )}
      {assignees && person && (
        <TaskEditor
          open={!!editedTask || createNewTask}
          assignees={assignees}
          cancelBtnHandler={() => {
            setEditedTask(null);
            setCreateNewTask(false);
          }}
          confirmBtnHandler={data => {
            const params = { ...data, lead_id: leadId };
            data.id
              ? updateTask({ personGid, taskId: data.id, data: params as TaskPayload })
              : createTask({ personGid, data: params as TaskPayload });
          }}
          task={editedTask}
          person={person}
          leadId={leadId}
        />
      )}
    </Container>
  );
};

export default LeadTasks;
