import * as React from 'react';

import { spacings } from '../../../theme/variables';
import Container, { IContainer } from '../Container';

const VerticalTabContent = ({ children, ...rest }: IContainer) => {
  return (
    <Container ph={spacings.px12} pb={spacings.px12} {...rest}>
      {children}
    </Container>
  );
};

export default VerticalTabContent;
