/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { IMaticPolicy, IPersonAsset } from '../../interfaces';
import { AssetType } from '../../interfaces/IPersonAsset';
import { PolicyType } from '../../interfaces/IPolicyType';
import { buildVehicleTitle, IVehicle } from '../../interfaces/IVehicle';
import usePersonLoans from '../../queries/people/usePersonLoans';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { capitalize } from '../../utils/formatter';
import FlexBox from '../core/FlexBox';
import PolicyTypeIcon from '../core/PolicyTypeIcon';
import Text from '../core/Text';
import Loan from './Loan';

const AssetLoans = ({
  personGid,
  asset,
  policy
}: {
  personGid: string;
  asset: IPersonAsset;
  policy?: IMaticPolicy;
}) => {
  const { data: loans } = usePersonLoans({ personGid, assetGid: asset.gid });
  const sortedLoans = loans?.sort(a => (a.gid === policy?.primary_loan_gid ? -1 : 1));

  return (
    <FlexBox columnDirection gap={spacings.px20}>
      <FlexBox columnDirection gap={spacings.px8}>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <PolicyTypeIcon policyType={(policy?.policy_type || asset.asset_name) as PolicyType} />
          <Text bold>{capitalize(policy?.policy_type || asset.asset_name)}</Text>
          {loans && loans.length > 0 && (
            <Text
              type="tiny"
              ph={spacings.px4}
              color={colors.grey60}
              customCss={css`
                border-radius: 4px;
                border: 1px solid ${colors.grey30};
                background-color: ${colors.grey5};
                line-height: 1.5;
              `}
            >
              {loans.length}
            </Text>
          )}
        </FlexBox>

        <Text ml={spacings.px28}>
          {asset.asset_name === AssetType.Auto ? buildVehicleTitle(asset as IVehicle) : asset.address.full}
        </Text>
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px20}>
        {sortedLoans?.map((loan, index) => (
          <Loan
            key={loan.gid}
            loan={loan}
            personGid={personGid}
            disabled={!!policy}
            isLoanPrimary={index === 0}
            policy={policy}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
};
export default AssetLoans;
