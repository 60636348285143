/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';
import * as yup from 'yup';

import Blockquote from '../../components/core/Blockquote';
import Button, { ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import InputField from '../../components/core/forms/fields/InputField';
import SelectField from '../../components/core/forms/fields/SelectField';
import TextareaField from '../../components/core/forms/fields/TextareaField';
import Heading from '../../components/core/Heading';
import { CalendarIcon } from '../../components/core/icons';
import Note from '../../components/core/Note';
import Paragraph from '../../components/core/Paragraph';
import { isActiveLeadDisposition, isLeadContactMade } from '../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../constants/featureFlags';
import { DispositionType } from '../../interfaces/IDisposition';
import { IScheduledCall } from '../../interfaces/IScheduledCall';
import { isDataLead } from '../../interfaces/ISourceDimensions';
import useUpdateLeadAssignee from '../../queries/leads/assignees/useUpdateLeadAssignee';
import useLeadDispositions from '../../queries/leads/dispositions/useLeadDispositions';
import useUpdateLeadDisposition from '../../queries/leads/dispositions/useUpdateLeadDisposition';
import { INFINITE_NOTES_QUERY_KEY } from '../../queries/people/person_notes/useInfiniteNotes';
import usePersonTimezone from '../../queries/people/usePersonTimezone';
import useUpdatePersonPhone from '../../queries/people/useUpdatePersonPhone';
import {
  PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY,
  SCHEDULED_INTERACTIONS_QUERY_KEY,
  useCreateScheduledInteractions,
  useUpdateScheduledInteractions
} from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo, { isISR } from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DISPLAY_DATE_TIME_FORMAT, phoneFormatter } from '../../utils/formatter';
import { requiredField } from '../../utils/yupRules';
import CalendarModal from './CalendarModal';
import { AGENT_SPOT_DURATION, ISR_LINK_MESSAGE, useScheduleCallData } from './helpers';
import PhoneEditor from './PhoneEditor';

interface ScheduleProps {
  scheduledCall?: IScheduledCall;
  personGid: string;
  leadGid?: string;
  candidateGid?: string | null;
  cancelHandler: () => void;
  afterSubmitHandler?: () => void;
  leadId?: number;
}

const FormRenderer = ({
  props,
  spotTimeRef,
  scheduleCallData: {
    refetchPerson,
    lead,
    person,
    possibleAssignees,
    leadGid,
    callNotes,
    isPendingAssignees,
    isPersonFetching
  },
  scheduledCall,
  isCurrentAgentLicensed
}: {
  props: FormikProps<{
    datetime: string;
    note: string;
    phone: string;
    assignee_id: string;
    assignee_email: string;
  }>;
  spotTimeRef: React.MutableRefObject<string>;
  scheduleCallData: ReturnType<typeof useScheduleCallData>;
  scheduledCall?: IScheduledCall;
  isCurrentAgentLicensed: boolean;
}) => {
  const [editPhones, setEditPhones] = React.useState(false);
  const [showPicker, setShowPicker] = React.useState(false);

  const { data: personTimezone, isPending: isPendingTimezone } = usePersonTimezone({
    personGid: person?.gid,
    engagementGid: leadGid,
    phone: props.values.phone
  });

  const { mutateAsync: updatePhone } = useUpdatePersonPhone(() => {
    refetchPerson();
  });

  const phoneOptions = [
    { key: person?.phone || undefined, value: phoneFormatter(person?.phone), postfix: 'Primary' },
    { key: person?.secondary_phone || undefined, value: phoneFormatter(person?.secondary_phone), postfix: 'Secondary' },
    { key: person?.business_phone || undefined, value: phoneFormatter(person?.business_phone), postfix: 'Business' }
  ].filter(item => !!item.key);

  const assigneeOptions = possibleAssignees?.map(assignee => ({
    key: assignee.id.toString(),
    value: assignee.name
  }));

  const isISRRole = isISR();

  const isDPLinkInSMSMessageShown = isISRRole && isDataLead(lead?.source_dimensions.lead_capture_method);

  return (
    <>
      {showPicker && (
        <Modal
          size="lg"
          isOpen
          toggle={() => setShowPicker(false)}
          returnFocusAfterClose={false}
          style={{ minWidth: '650px' }}
        >
          <CalendarModal
            onChange={(datetime: string) => {
              props.setFieldValue('datetime', moment.parseZone(datetime).format(DISPLAY_DATE_TIME_FORMAT));
              spotTimeRef.current = datetime;
              setShowPicker(false);
            }}
            value={(props.values.datetime as any) || undefined}
            assigneeEmail={props.values.assignee_email}
            personTimezone={personTimezone}
          />
        </Modal>
      )}

      {editPhones && person && (
        <PhoneEditor
          person={person}
          cancelBtnHandler={() => {
            setEditPhones(false);
          }}
          confirmBtnHandler={values =>
            updatePhone({
              personGid: person.gid,
              phone: values.phone,
              secondary_phone: values.secondary_phone,
              business_phone: values.business_phone
            }).then(() => {
              setEditPhones(false);
              props.setValues(values => ({
                ...values,
                phone: '',
                datetime: ''
              }));
            })
          }
        />
      )}

      <Global
        styles={css`
          .react-calendar {
            border: none;
          }
          .react-select {
            margin-bottom: 0 !important;
          }
        `}
      />
      <div
        css={css`
          display: grid;
          max-width: calc(2 * 420px + 2 * 24px);
          grid-template-columns: repeat(auto-fit, 420px);
          gap: 24px 24px;
        `}
      >
        <SelectField
          label="Phone"
          required
          id="phone"
          name="phone"
          options={phoneOptions}
          isLoading={isPersonFetching}
          disabled={isPersonFetching}
          onChange={({ target }) => {
            props.setValues(values => ({
              ...values,
              phone: target.value,
              datetime: ''
            }));
          }}
          menuOptions={
            <>
              <hr
                css={css`
                  margin: 0 10px 10px 10px;
                `}
              />
              <Button
                onClick={() => setEditPhones(true)}
                variant={ButtonVariant.Text}
                data-testid="modify-phones-button"
                css={css`
                  margin: 0 5px 10px 10px;
                  text-align: left;
                  width: 100%;
                `}
              >
                Modify phones
              </Button>
            </>
          }
          className="fs-mask"
          key={phoneOptions.map(option => option.key).join('')}
          formatOptionLabel={({ label, postfix }) => (
            <span className="fs-mask">
              {label}
              <span
                css={css`
                  color: ${colors.grey30};
                  margin-left: 10px;
                `}
              >
                {postfix}
              </span>
            </span>
          )}
        />
        <div>
          <InputField
            label="Date and time"
            required
            id="datetime"
            name="datetime"
            placeholder="mm/dd"
            prefixIcon={<CalendarIcon />}
            disabled={!props.values.phone || isPendingTimezone}
            onFocus={() => {
              setShowPicker(true);
            }}
            onBlur={() => {
              props.setFieldTouched('datetime', false);
            }}
            description={
              isISRRole ? undefined : `Call time can deviate from the scheduled on ${AGENT_SPOT_DURATION} minutes`
            }
          />
        </div>
        <div
          css={css`
            grid-column: 1 / -1;
          `}
        >
          {!isISRRole && (
            <div
              css={css`
                display: grid;
                max-width: calc(2 * 420px + 2 * 24px);
                grid-template-columns: repeat(auto-fit, 420px);
                gap: 24px 24px;
              `}
            >
              {(scheduledCall?.last_lead_id || leadGid) && (
                <SelectField
                  label="Assignee"
                  required
                  id="assignee_id"
                  name="assignee_id"
                  isLoading={isPendingAssignees}
                  onChange={({ target }) => {
                    const chosenAgentEmail =
                      possibleAssignees?.find(assignee => `${assignee.id}` === target.value)?.email || '';

                    props.setValues(values => ({
                      ...values,
                      assignee_id: target.value,
                      assignee_email: chosenAgentEmail,
                      datetime: ''
                    }));
                  }}
                  disabled={
                    isPendingAssignees || (isCurrentAgentLicensed && !authInfo.features.edit_schedule_call_assignee)
                  }
                  options={
                    !isCurrentAgentLicensed || authInfo.features.edit_schedule_call_assignee
                      ? assigneeOptions!
                      : [{ key: authInfo.currentUserId!.toString(), value: authInfo.currentUserName! }]
                  }
                />
              )}
            </div>
          )}
          <TextareaField required id="note" label="Note" name="note" placeholder="Your note here" />
          {isDPLinkInSMSMessageShown && (
            <Container ml={spacings.px48} mb={spacings.px24}>
              <Blockquote text={<Paragraph>{ISR_LINK_MESSAGE}</Paragraph>} />
            </Container>
          )}
        </div>
      </div>

      {callNotes && (
        <FlexBox
          columnDirection
          gap={spacings.px8}
          customCss={css`
            max-width: 864px;
          `}
        >
          {callNotes.map(note => (
            <Note note={note} key={note.created_at} />
          ))}
        </FlexBox>
      )}
    </>
  );
};

const ScheduleCall = ({
  scheduledCall,
  personGid,
  leadGid,
  candidateGid,
  cancelHandler,
  afterSubmitHandler,
  leadId
}: ScheduleProps) => {
  const scheduleCallData = useScheduleCallData({
    personGid,
    leadGid,
    leadId: scheduledCall?.last_lead_id || undefined
  });

  const queryClient = useQueryClient();

  const isISRRole = isISR();
  const { mutateAsync: updateLeadAssignee } = useUpdateLeadAssignee();
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();
  const { data: dispositions } = useLeadDispositions(leadId);

  const onSuccessfulMutate = () => {
    afterSubmitHandler?.();
    if (
      !scheduledCall &&
      leadId &&
      authInfo.currentUserId &&
      isActiveLeadDisposition(dispositions?.current_disposition.disposition_type) &&
      featureFlags.assignLeadAfterCallScheduling
    ) {
      updateLeadAssignee({ leadId, assigneeId: authInfo.currentUserId });
      !isLeadContactMade(dispositions.dispositions) &&
        updateDisposition({
          leadId,
          disposition_type: DispositionType.ContactMade,
          agent_id: authInfo.currentUserId
        });
    }

    queryClient.invalidateQueries({ queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [INFINITE_NOTES_QUERY_KEY] });
  };

  const { mutateAsync: createScheduledInteraction } = useCreateScheduledInteractions({
    onSuccess: () => onSuccessfulMutate()
  });
  const { mutateAsync: updateScheduledInteraction } = useUpdateScheduledInteractions({
    onSuccess: () => onSuccessfulMutate()
  });

  const isCurrentAgentLicensed = !!scheduleCallData.possibleAssignees?.find(
    assignee => assignee.id === authInfo.currentUserId
  );

  const getInitialAssigneeValue = ({
    assigneeId,
    assigneeEmail
  }: {
    assigneeId?: boolean;
    assigneeEmail?: boolean;
  }) => {
    if (assigneeId && scheduledCall?.assignee) {
      return scheduledCall.assignee.id.toString();
    }
    if (assigneeId && (isISRRole || isCurrentAgentLicensed || !leadGid)) {
      return authInfo.currentUserId!.toString();
    }
    if (assigneeEmail && scheduledCall?.assignee) {
      return scheduledCall.assignee.email;
    }
    if (assigneeEmail && (isISRRole || isCurrentAgentLicensed || !leadGid)) {
      return authInfo.currentUserEmail;
    }
    return '';
  };

  const getInitialDatetime = (format?: string) => {
    if (!scheduledCall?.run_at) {
      return '';
    }

    return moment(scheduledCall.run_at).format(format);
  };

  const initialValues = {
    datetime: getInitialDatetime(DISPLAY_DATE_TIME_FORMAT),
    note: '',
    phone: scheduledCall?.phone || '',
    assignee_id: getInitialAssigneeValue({ assigneeId: true }) || '',
    assignee_email: getInitialAssigneeValue({ assigneeEmail: true }) || ''
  };

  const spotTimeRef = React.useRef(getInitialDatetime());

  return (
    <BaseForm
      formTestId="schedule-call-form"
      type="fullScreen"
      cancelWithEscape
      enableReinitialize
      header={
        <FlexBox
          css={css`
            white-space: break-spaces;
          `}
        >
          <Heading type="h3">Schedule a call with </Heading>
          {scheduleCallData.person && (
            <Link
              className="fs-mask"
              target="_blank"
              rel="noopener noreferrer"
              to={`/people/${scheduleCallData.person.gid}`}
            >
              <Heading
                color={colors.azure50}
                type="h3"
                css={css`
                  &:hover {
                    color: ${colors.blueHoverLink};
                  }
                `}
              >
                {scheduleCallData.person.name}
              </Heading>
            </Link>
          )}
        </FlexBox>
      }
      onSubmit={values => {
        if (scheduledCall) {
          return updateScheduledInteraction({
            personGid,
            scheduledCallId: scheduledCall.id,
            params: {
              ...values,
              run_at: spotTimeRef.current,
              lead_gid: leadGid || scheduledCall.engagement_gid,
              candidate_gid: candidateGid || scheduledCall.engagement_gid,
              dialer_action_id: scheduledCall.action_type ? scheduledCall.id : undefined
            }
          });
        }

        return createScheduledInteraction({
          personGid,
          params: {
            ...values,
            run_at: spotTimeRef.current,
            lead_gid: leadGid,
            candidate_gid: candidateGid || ''
          }
        });
      }}
      cancelHandler={cancelHandler}
      validationSchema={yup.object().shape({
        datetime: requiredField,
        phone: requiredField,
        note: requiredField.max(1000, ({ max }) => `Note cannot be more than ${max} characters`)
      })}
      initialValues={initialValues}
      submitText="Schedule"
      renderForm={props => (
        <FormRenderer
          props={props}
          spotTimeRef={spotTimeRef}
          scheduleCallData={scheduleCallData}
          scheduledCall={scheduledCall}
          isCurrentAgentLicensed={isCurrentAgentLicensed}
        />
      )}
    />
  );
};

export default ScheduleCall;
