/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import CollapsingContainer from '../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../components/core/FlexBox';
import { QuoteIcon } from '../../../../components/core/icons';
import Select from '../../../../components/core/inputs/Select';
import Label from '../../../../components/core/Label';
import Paragraph from '../../../../components/core/Paragraph';
import useCarriers from '../../../../queries/carriers/useCarriers';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import usePostSalesSync from '../../../PostSalesExperience/_hooks/usePostSalesSync';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import helpJSON from './help.json';

const Quote = ({ text }: { text: string }) => (
  <Paragraph
    m={spacings.px4}
    css={css`
      position: relative;
      display: flex;
      white-space: break-spaces;
    `}
  >
    <QuoteIcon
      color={colors.grey30}
      css={css`
        margin: 8px 8px 0 0;
        min-width: 20px;
      `}
    />
    {text}
  </Paragraph>
);

/* eslint-disable max-len */
const DEFAULT_HELP_JSON = {
  Application:
    'You will receive your electronic application from the carrier to sign.  Please sign within 7 days of receiving it',
  'Welcome emails': 'You will receive a welcome email from matic with your policy number and copy of your policy',
  'Policy service':
    'We have a customers service team here at Matic to help answer any questions or help get you in touch with your carrier. Their contact information will be in the welcome email'
} as const;
/* eslint-enable max-len */

type HelpKey = keyof typeof helpJSON;

const FinalizeHelpTab = () => {
  const { carrierId, policyType, state } = usePostSalesSync();

  const [selectedValue, setSelectedValue] = React.useState(carrierId);
  React.useEffect(() => {
    setSelectedValue(carrierId);
  }, [carrierId]);

  const { data: carriers, isPending } = useCarriers();
  const carrierOptions = React.useMemo(
    () => (carriers || []).map(({ id, name }) => ({ key: id, value: name })),
    [carriers]
  );

  // JSON key is either carrier + state || carrier + policy_type || carrier in lower case
  const carrierName = (carrierOptions.find(c => c.key === selectedValue)?.value || '').toLowerCase() as HelpKey;
  const carrierEndState = `${carrierName} ${state}`.toLowerCase() as HelpKey;
  const carrierEndPolicyType = `${carrierName} ${policyType}`.toLowerCase() as HelpKey;

  const helpObject =
    helpJSON[carrierEndState] || helpJSON[carrierEndPolicyType] || helpJSON[carrierName] || DEFAULT_HELP_JSON;

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Help</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        <CollapsingContainer containerTitle="Post sales" openedByDefault>
          <FlexBox mt={spacings.px12} mb={spacings.px16} columnDirection>
            <Label htmlFor="help-carrier_id">Carrier</Label>
            <Select
              inputId="help-carrier_id"
              value={selectedValue}
              onChange={carrierId => setSelectedValue(carrierId)}
              placeholder=""
              options={carrierOptions}
              isLoading={isPending}
              ordered
            />
          </FlexBox>
          {selectedValue &&
            (Object.keys(helpObject) as Array<keyof typeof helpObject>).map(key => (
              <React.Fragment key={key}>
                <Paragraph bold>{key}</Paragraph>
                <Quote text={helpObject[key] || '-'} />
              </React.Fragment>
            ))}
        </CollapsingContainer>
        <CollapsingContainer containerTitle="Objections" openedByDefault={false}>
          <Paragraph bold>Now is not a good time</Paragraph>
          <Quote text="I'm seeing some really good quotes for you here, it should only be a few minute" />
          <Quote text="We're almost done and this will only take a few minutes" />
          {/* eslint-disable-next-line max-len */}
          <Quote text="When my customers need to think about it they have questions about price, effective date or coverage. Which can I help you with?" />
          <Quote text="[Customer Name] I'm happy to set a follow up appointment if you're considering purchasing" />
          <Paragraph bold>Not interested</Paragraph>
          <Quote text="On average we save our customers 25% per year on their home insurance" />
          <Quote text="We're partnered with over [40] carriers, I'm sure we can find you a great fit" />
          <Quote text="We're partnered with your mortgage lender" />
          <Quote text="When's the last time you shopped your auto and home insurance?" />
          <Paragraph bold>Price objection</Paragraph>
          <Quote text="Do you have a renewal upcoming soon?" />
          <Quote text="We are offering you better coverages" />
          <Quote text="We offer excellent customer service" />
          <Quote text="We shop your insurance every year" />
        </CollapsingContainer>
      </VerticalTabContent>
    </>
  );
};

export default FinalizeHelpTab;
