import LocalStorageManager from '../../../services/LocalStorageManager';

export const PANEL_CLASS_NAME = 'react-tabs__tab-panel';
export const VERTICAL_TAB_HEADER_Z_INDEX = 6;
export const MIN_TAB_WIDTH = 332;
export const MAX_TAB_WIDTH = 778;
export const LOCAL_STORAGE_KEY = 'react-tabs-width';

export const getCurrentWidth = () => {
  const el = document.getElementsByClassName(PANEL_CLASS_NAME)[0];

  return el ? parseInt(window.getComputedStyle(el).getPropertyValue('width')) : 0;
};

export const setReactTabsWidth = (width: string) =>
  [...document.getElementsByClassName(PANEL_CLASS_NAME)].forEach(el => {
    (el as HTMLElement).style.width = width;
  });

export const getComputedTabWidth = (wide = false) => {
  if (wide) {
    return LocalStorageManager.read(LOCAL_STORAGE_KEY) || MAX_TAB_WIDTH;
  }

  return wide ? MAX_TAB_WIDTH : MIN_TAB_WIDTH;
};
