/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';

import notificationSound from '../../assets/audio/call_notification.wav';
import { TaskChannel } from '../../interfaces/IQuickReply';
import { IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { useActiveConsents } from '../../queries/disclosures/useDisclosure';
import { useSendEmail, useSendSMS } from '../../queries/omni_channel_interactions/useInteractions';
import { QuickRepliesCategory, useCreateQuickReply } from '../../queries/omni_channel_interactions/useQuickReplies';
import usePerson from '../../queries/people/usePerson';
import { useScheduledInteractionsContactFlows } from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { INTERNAL_DATE_FORMAT } from '../../utils/formatter';
import alert from '../core/Alert';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import { HourglassIcon } from '../core/icons';

export const ReminderBody = ({ call }: { call: IScheduledCall }): JSX.Element => {
  const { mutateAsync: createQuickReply, isPending: isPendingQuickReply } = useCreateQuickReply();
  const personGid = call.person_gid;

  const { data: person } = usePerson(personGid);
  const { data: consents = [] } = useActiveConsents(personGid);
  const smsConsentGiven = consents.find(consent => consent.kind === 'sms');
  const { mutate: sendEmail, isPending: isEmailSending } = useSendEmail();
  const { mutate: sendSms, isPending: isSmsSending } = useSendSMS();
  const customerEmail = person?.email;
  const customerPhoneNumber = call.phone;

  const sendReminder = () => {
    createQuickReply({
      personGid,
      categories: [QuickRepliesCategory.PipelineLateNotification],
      personFirstName: person?.first_name
    }).then(data => {
      const replies = data.quick_replies;
      if (smsConsentGiven) {
        const reply = replies.find(reply => reply.task_channel === TaskChannel.Sms);
        sendSms({
          to: [{ address: customerPhoneNumber }],
          smsBody: reply?.prefilled_content || '',
          personGid,
          engagementGid: call.engagement_gid!
        });
      } else {
        const reply = replies.find(reply => reply.task_channel === TaskChannel.Email);
        sendEmail({
          to: [{ address: customerEmail || '' }],
          emailSubject: reply?.subject || '',
          emailBody: reply?.prefilled_content || '',
          personGid,
          engagementGid: call.engagement_gid!
        });
      }
    });
  };

  return (
    <FlexBox
      gap={spacings.px8}
      mt={spacings.px20}
      css={css`
        flex-direction: row-reverse;
      `}
    >
      <Button size={ButtonSize.Small} onClick={() => toast.dismiss(call.id)}>
        Got it
      </Button>
      <Button
        loading={isEmailSending || isSmsSending || isPendingQuickReply}
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        onClick={() => {
          sendReminder();
          return toast.dismiss(call.id);
        }}
      >
        Notify customer I&apos;m late
      </Button>
    </FlexBox>
  );
};

const FollowUpReminders = () => {
  const scheduledReminders = React.useRef<number[]>([]);
  const today = moment().format(INTERNAL_DATE_FORMAT);

  const { data: scheduledInteractions } = useScheduledInteractionsContactFlows({
    assignees_emails: [authInfo.currentUserEmail!],
    page: 1,
    max_run_at: today,
    min_run_at: today,
    flows: [ScheduledInteractionFlow.PipelineHighPriority],
    statuses: ['in_progress']
  });

  React.useEffect(() => {
    scheduledReminders.current.forEach(window.clearTimeout);
    scheduledReminders.current = [];

    if (!scheduledInteractions?.scheduled_interactions) {
      return;
    }
    scheduledInteractions.scheduled_interactions.forEach(followup => {
      const runAt = moment(followup.run_at);
      const delayMs = runAt.subtract(10, 'minutes').diff(moment());

      if (delayMs > 0) {
        const timerId = window.setTimeout(() => {
          const audio = new Audio(notificationSound);
          audio.play();
          alert({
            message: <ReminderBody call={followup} />,
            title: `Reminder: Call with ${followup.person_name} planned in 10 minutes.`,
            options: {
              icon: <HourglassIcon color={colors.azure50} />,
              toastId: followup.id
            }
          }).info();
        }, delayMs);

        scheduledReminders.current.push(timerId);
      }
    });

    return () => {
      scheduledReminders.current.forEach(window.clearTimeout);
      scheduledReminders.current = [];
    };
  }, [scheduledInteractions]);

  return null;
};

export default FollowUpReminders;
