import axios, { AxiosHeaders, isAxiosError } from 'axios';
import { isNetworkError } from 'axios-retry';

import { getIdToken } from '../auth/entraAuthConfig';
import useAppVersion from '../contexts/VersionContext';
import alertFromApiResponse, { genericNetworkError } from '../utils/alertFromApiResponse';
import { rejectEmpty } from '../utils/object';

export interface ErrorResponse {
  status: string;
  message: string;
}

export const apiBaseUrl = () => {
  if (import.meta.env.DEV || import.meta.env.VITEST) {
    // vite config proxy handles APP_BACKEND_URL
    return '';
  }

  if (window.location.host.startsWith('amp')) {
    return `https://api-${window.location.host}`;
  }

  return `https://${window.location.host}`;
};

const isEmpty = (value: any) => value === '' || typeof value === 'undefined';
const emptyToNull = (key: any, value: any) => (isEmpty(value) ? null : value);

const prepareFormData = (params: Record<string, any>) => {
  const data = new FormData();

  if (params) {
    Object.keys(params).forEach(key => data.append(key.toString(), params[key]));
  }

  return data;
};

axios.interceptors.response.use(
  response => response,
  error => {
    if (isNetworkError(error)) {
      genericNetworkError();
    } else if (isAxiosError(error) && error.response?.status === 422) {
      alertFromApiResponse(error.response);
    }

    return Promise.reject(error);
  }
);

const call = async (method: string, url: string, { headers, data, params }: any = {}) => {
  const apiResponse = await axios({
    method,
    url: apiBaseUrl() + url,
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: `Bearer ${getIdToken()}`,
      webCurrentVersion: useAppVersion.getState().current,
      webInitialVersion: useAppVersion.getState().initial
    },
    data,
    params,
    validateStatus: status => status < 400
  });
  const result = apiResponse.data;
  const apiResponseResult = result.data || result;

  if (apiResponseResult && typeof apiResponseResult === 'object') {
    Reflect.set(apiResponseResult, 'httpStatus', apiResponse.status);
  }
  return apiResponseResult;
};

const jsonCall = (
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH',
  url: string,
  {
    headers,
    body,
    params,
    allowEmpty
  }: {
    headers?: AxiosHeaders | Record<string, string | number>;
    body?: any;
    params?: any;
    allowEmpty?: boolean;
  } = {}
) => {
  const options = rejectEmpty({
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body, allowEmpty ? undefined : emptyToNull),
    params
  });

  return call(method, url, options);
};

const formDataCall = (method: string, url: string, { headers, body }: any = {}) =>
  call(method, url, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data'
    },
    data: prepareFormData(body)
  });

type ShiftTuple<T extends any[]> = T extends [T[0], ...infer R] ? R : never;
type ApiArgs = ShiftTuple<Parameters<typeof jsonCall>>;

const api = {
  get: (...args: ApiArgs) => jsonCall('GET', ...args),
  post: (...args: ApiArgs) => jsonCall('POST', ...args),
  put: (...args: ApiArgs) => jsonCall('PUT', ...args),
  delete: (...args: ApiArgs) => jsonCall('DELETE', ...args),
  upload: (...args: ApiArgs) => formDataCall('POST', ...args),
  patch: (...args: ApiArgs) => jsonCall('PATCH', ...args)
};

export default api;
