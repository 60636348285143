/** @jsxImportSource @emotion/react */
import React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import { isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useDNCTip } from '../../../../hooks';
import { useActiveScheduledInteractions } from '../../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../../services/analytics';
import ScheduleCall from '../../../ScheduleCall';
import FollowupPopper from '../../../ScheduleCall/FollowupPopper';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks/useCurrentDispositionSubscription';

const ScheduleCallButton = () => {
  const { personGid, leadGid, candidateGid, callLogId, lead } = useGuidedSellingExperienceContext();

  const { DNCTip, labelProps, doNotContact } = useDNCTip({ personGid });

  const { data: scheduledCalls, isFetching } = useActiveScheduledInteractions(personGid);
  const { leadDispositions } = useCurrentDispositionSubscriptionContext();

  const showCallButton =
    !isEndDisposition(leadDispositions?.current_disposition.disposition_type) &&
    scheduledCalls?.length === 0 &&
    !isFetching;

  const [showScheduledCall, setShowScheduledCall] = React.useState(false);

  if (featureFlags.scheduleFollowUpUI) {
    if (leadGid && personGid) {
      return (
        <div {...labelProps}>
          <FollowupPopper
            isEndDisposition={isEndDisposition(leadDispositions?.current_disposition.disposition_type)}
            key={leadGid}
            disabled={!!doNotContact}
            personGid={personGid}
            leadGid={leadGid}
          />
          <DNCTip />
        </div>
      );
    }

    return null;
  }

  return (
    <>
      {showCallButton && (
        <div {...labelProps}>
          <Button
            disabled={!!doNotContact}
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Small}
            onClick={() => {
              setShowScheduledCall(true);
              analytics.track('Schedule call clicked', {
                call_log_id: callLogId,
                lead_gid: leadGid,
                place: 'scouting_report'
              });
            }}
          >
            Schedule call
          </Button>
          <DNCTip />
        </div>
      )}
      {showScheduledCall && personGid && (
        <ScheduleCall
          personGid={personGid}
          leadGid={leadGid}
          leadId={lead?.id}
          candidateGid={candidateGid}
          cancelHandler={() => setShowScheduledCall(false)}
          afterSubmitHandler={() => setShowScheduledCall(false)}
        />
      )}
    </>
  );
};

export default ScheduleCallButton;
