import React from 'react';

import DropDown from '../../../../components/core/DropDown/DropDown';
import FlexBox from '../../../../components/core/FlexBox';
import { ArrowDownIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import HorizontalTabs from '../../../../components/core/Tabs/HorizontalTabs';
import PriorPolicyEditor from '../../../../components/PriorPolicyEditor';
import PriorPolicyWithHomeForm from '../../../../components/PriorPolicyEditor/PriorPolicyWithHomeForm';
import PriorPolicyWithVehiclesForm from '../../../../components/PriorPolicyEditor/PriorPolicyWithVehiclesForm';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import { usePriorPolicyFormUtils } from '../../_hooks/usePriorPolicyFormUtils';
import AncillaryPolicies from './AncillaryPolicies';
import RealPropertyEssentialPolicies from './RealPropertyEssentialPolicies';
import VehicleEssentialPolicies from './VehicleEssentialPolicies';

const PoliciesTab = () => {
  const { person, personGid, leadGid, candidateGid, isLifeLead } = useGuidedSellingExperienceContext();
  const { data: opportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid
  });

  const {
    selectedPolicyOptions,
    addHomeFlow,
    addVehiclesFlow,
    priorPolicyEditorCommonProps,
    policiesOptions,
    onSelected,
    isDataEditingForbidden
  } = usePriorPolicyFormUtils({ opportunities, uiSource: 'tab' });

  if (!personGid) {
    return null;
  }

  const tabContent = [
    {
      tabName: 'Home',
      content: <RealPropertyEssentialPolicies personGid={personGid} />
    },
    {
      tabName: 'Auto',
      content: <VehicleEssentialPolicies personGid={personGid} />
    },
    {
      tabName: 'Ancillary',
      content: <AncillaryPolicies personGid={personGid} />,
      active: isLifeLead
    }
  ];

  return (
    <>
      <VerticalTabHeader>
        <FlexBox alignItemsBaseline gap={spacings.px8}>
          <Paragraph type="large">Policies</Paragraph>
          <DropDown options={policiesOptions} onSelected={onSelected} iconChildren disabled={isDataEditingForbidden}>
            <Paragraph color={isDataEditingForbidden ? colors.grey60 : colors.azure50} bold>
              Add current policy <ArrowDownIcon />
            </Paragraph>
          </DropDown>
        </FlexBox>
        {personGid && selectedPolicyOptions && (
          <>
            {!addVehiclesFlow && !addHomeFlow && (
              <PriorPolicyEditor {...priorPolicyEditorCommonProps} personGid={personGid} />
            )}
            {addHomeFlow && (
              <PriorPolicyWithHomeForm {...priorPolicyEditorCommonProps} personGid={personGid} leadGid={leadGid} />
            )}
            {addVehiclesFlow && person && (
              <PriorPolicyWithVehiclesForm {...priorPolicyEditorCommonProps} person={person} leadGid={leadGid} />
            )}
          </>
        )}
      </VerticalTabHeader>
      <VerticalTabContent>
        <HorizontalTabs tabs={tabContent} />
      </VerticalTabContent>
    </>
  );
};

export default PoliciesTab;
