import DocsTab from '../../../../components/PersonDocumentsTab';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useVerticalTabsCallbacks } from '../../_hooks';

const Documents = () => {
  const { lead, person } = useGuidedSellingExperienceContext();
  const { onTabClose } = useVerticalTabsCallbacks();

  return person ? <DocsTab lead={lead} person={person} onTabClose={onTabClose} /> : null;
};

export default Documents;
