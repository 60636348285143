/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { ChevronLeft } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import Document, { IExtendedDocument } from '../../../components/Documents/Document';
import { useToggle } from '../../../hooks';
import { IMaticPolicy } from '../../../interfaces';
import { IDocumentDelivery } from '../../../interfaces/IDocumentDelivery';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import usePersonDocuments from '../../../queries/people/person_documents/usePersonDocuments';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

const CollectedDocuments = ({
  deliveries,
  policy,
  personGid
}: {
  deliveries: IDocumentDelivery[] | undefined;
  policy: IMaticPolicy;
  personGid: string;
}) => {
  const [openOtherDocuments, toggleOtherDocuments] = useToggle(false);
  const { data: documents } = usePersonDocuments({ personGid, policyId: policy.id });
  const { data: docTypes } = useDocumentTypes(true);

  const deliveryDocuments = Object.entries(
    (deliveries || [])
      .flatMap(item =>
        item.deliverable_documents.map(doc => ({
          ...doc,
          channel: item.channel,
          recipient_type: item.recipient_type,
          delivery_status: item.status,
          updated_at: item.updated_at,
          last_send_try_at: item.last_send_try_at
        }))
      )
      .reduce(
        (groups, doc) => {
          (groups[doc.document_type] ||= []).push(doc);
          return groups;
        },
        {} as Record<string, IExtendedDocument[]>
      )
  ).map(([document_type, docs]) => ({
    document_type,
    responsible: docs[0]?.responsible,
    document: (docs as IExtendedDocument[]).find(doc => doc.document)?.document || null,
    channels: (docs as IExtendedDocument[]).map(
      ({ channel, status, recipient_type, delivery_status, updated_at, last_send_try_at }) => ({
        channel,
        document_status: status,
        recipient_type,
        delivery_status,
        sent_at: updated_at,
        last_send_try_at
      })
    )
  }));

  if (!(deliveries && documents && docTypes)) {
    return (
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <FlexBox columnDirection gap={spacings.px16} mt={spacings.px12}>
          {[1, 2, 3].map(value => (
            <Container key={value} fitParentWidth>
              <Skeleton height={66} />
            </Container>
          ))}
        </FlexBox>
      </SkeletonTheme>
    );
  }

  const otherDocuments = documents.filter(doc =>
    deliveryDocuments.every(deliveryDoc => deliveryDoc.document?.id !== doc.id)
  );

  if (deliveryDocuments.length === 0 && otherDocuments.length === 0) {
    return null;
  }

  return (
    <FlexBox columnDirection>
      <Text bold>Documents you collected</Text>
      {deliveryDocuments.map(doc => (
        <Document
          key={doc.document_type}
          doc={doc.document}
          documentType={doc.document_type}
          channels={doc.channels}
          policy={policy}
          docTypes={docTypes.document_types}
          isDeliveryDocument
          responsible={doc.responsible}
        />
      ))}
      {otherDocuments.length > 0 && (
        <FlexBox
          customCss={css`
            flex-direction: ${openOtherDocuments ? 'column-reverse' : 'column'};
          `}
        >
          <Button
            variant={ButtonVariant.Text}
            onClick={toggleOtherDocuments}
            customCss={css`
              width: fit-content;
              font-weight: 500;
              font-size: 14px;
            `}
            mt={spacings.px12}
          >
            <ChevronLeft
              css={css`
                transform: rotate(${openOtherDocuments ? '90deg' : '270deg'});
                transition: transform 0.2s;
                margin-bottom: 2px;
              `}
              color={colors.azure50}
            />
            {openOtherDocuments ? 'Hide' : 'See'} other {otherDocuments.length} document
            {otherDocuments.length > 1 ? 's' : ''}
          </Button>
          {openOtherDocuments &&
            otherDocuments.map(doc => (
              <Document
                key={doc.id}
                doc={doc}
                policy={policy}
                documentType={doc.document_type_key}
                docTypes={docTypes.document_types}
              />
            ))}
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default CollectedDocuments;
