import { queryOptions, skipToken, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const AMP_POLICIES_DISCLOSURE = 'amp_policies';
export const AMP_FCRA_DISCLOSURE = 'amp_fcra';

export const CREDIT_PULL_CONSENT = 'credit_pull';
export const CLAIMS_PULL_CONSENT = 'claims_pull';
export const POLICY_CONSENT = 'policy';
export const CALLS_CONSENT = 'calls';
export const SMS_CONSENT = 'sms';

export const NO_TCPA_MESSAGE = 'No TCPA consent. To obtain ask customer to text "Matic" to 380-270-0095';

export interface Disclosure {
  gid: string;
  kind: string;
  prompt?: string;
  content: string;
}

export interface Consent {
  kind:
    | typeof POLICY_CONSENT
    | typeof CALLS_CONSENT
    | typeof SMS_CONSENT
    | typeof CREDIT_PULL_CONSENT
    | typeof CLAIMS_PULL_CONSENT;
  collected_by: string;
  provided_at: string;
  provided_till?: string;
}

interface PersonConsent {
  gid: string;
  person_gid: string;
  kind: string;
  provided_at: string;
  provided_till?: string;
}

export const useFCRADisclosure = () =>
  useQuery({
    queryKey: [AMP_FCRA_DISCLOSURE],
    queryFn: (): Promise<{ disclosures: Disclosure[] }> =>
      api.get(`/api/frontend/disclosures?${toQueryParams({ kinds: [AMP_FCRA_DISCLOSURE] })}`),
    select: data => data.disclosures[0]
  });

export const usePolicyDisclosure = () =>
  useQuery({
    queryKey: [AMP_POLICIES_DISCLOSURE],
    queryFn: (): Promise<{ disclosures: Disclosure[] }> =>
      api.get(`/api/frontend/disclosures?${toQueryParams({ kinds: [AMP_POLICIES_DISCLOSURE] })}`),
    select: data => data.disclosures[0],
    staleTime: Infinity
  });

const acceptDisclosures = ({
  personGid,
  disclosures
}: {
  personGid: string;
  disclosures: Disclosure[];
}): Promise<{ person_consents: PersonConsent[] }> =>
  api.post(`/api/frontend/people/${personGid}/disclosures`, { body: { disclosures } });

const ACTIVE_CONSENTS_QUERY_KEY = 'person_active_consents';
export const useAcceptDisclosures = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: acceptDisclosures,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVE_CONSENTS_QUERY_KEY] });
    }
  });
};

const activeConsentsQueryOptions = (personGid?: string) =>
  queryOptions({
    queryKey: [ACTIVE_CONSENTS_QUERY_KEY, personGid],
    queryFn: personGid
      ? (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`)
      : skipToken,
    staleTime: Infinity,
    select: data => data.consents
  });

export const useActiveConsents = (personGid?: string) => useQuery(activeConsentsQueryOptions(personGid));

export const useActiveFCRAConsent = (personGid?: string) =>
  useQuery({
    ...activeConsentsQueryOptions(personGid),
    select: data =>
      data.consents.some(consent => consent.kind === CREDIT_PULL_CONSENT) &&
      data.consents.some(consent => consent.kind === CLAIMS_PULL_CONSENT)
  });

export const useActiveTCPAConsent = (personGid?: string) =>
  useQuery({
    ...activeConsentsQueryOptions(personGid),
    select: data =>
      data.consents.some(consent => consent.kind === CALLS_CONSENT) ||
      data.consents.some(consent => consent.kind === SMS_CONSENT)
  });

export const useActiveTCPAConsents = ({ peopleGids }: { peopleGids: string[] }) => {
  const queries = useQueries({
    queries: peopleGids.map(personGid => activeConsentsQueryOptions(personGid))
  });

  const data = peopleGids.reduce(
    (acc, personGid, index) => {
      acc[personGid] = queries[index]?.data?.filter(
        consent => consent.kind === CALLS_CONSENT || consent.kind === SMS_CONSENT
      );
      return acc;
    },
    {} as { [personGid: string]: Consent[] | undefined }
  );

  return { data, isPending: queries.some(query => query.isPending) };
};

export const useActivePolicyConsent = (personGid: string) =>
  useQuery({
    ...activeConsentsQueryOptions(personGid),
    select: data => data.consents.find(consent => consent.kind === POLICY_CONSENT)
  });
