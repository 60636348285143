import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Modal, { ModalSize } from '../../../components/core/Modal';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import analytics from '../../../services/analytics';
import { useCustomerEventsState } from '../_hooks';

const PeopleMergedAutomaticallyModal = () => {
  const { leadGid, personGid } = useGuidedSellingExperienceContext();

  const navigate = useNavigate();
  const { personMerged, resetPersonMerged } = useCustomerEventsState();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (personMerged) {
      analytics.track('Person Merged Modal Activated', {
        personGid: personGid || '',
        leadGid: leadGid || '',
        active_lead_gid: personMerged.active_lead_gid || '',
        merged_person_gid: personMerged.merged_person_gid || ''
      });
    }
  }, [personMerged, leadGid, personGid]);

  if (!personMerged) {
    return null;
  }

  const closeModalHandler = () => {
    queryClient.invalidateQueries();

    if (leadGid !== personMerged.active_lead_gid) {
      navigate(
        personMerged.active_lead_gid
          ? `/scouting_report?lead_gid=${personMerged.active_lead_gid}`
          : `/scouting_report?person_gid=${personMerged.merged_person_gid}`
      );
    }
    resetPersonMerged();

    analytics.track('Person Merged Modal Closed', {
      personGid: personGid || '',
      leadGid: leadGid || '',
      active_lead_gid: personMerged.active_lead_gid || '',
      merged_person_gid: personMerged.merged_person_gid || ''
    });
  };

  return (
    <Modal
      containerTitle="Your customer was merged!"
      dangerModal
      size={ModalSize.large}
      confirmHandler={closeModalHandler}
      cancelHandler={closeModalHandler}
      showCancelButton={false}
    >
      AMP has identified this customer already has a lead in our system and will now merge the two leads for you. No
      information previously collected will be lost. Please click OK to continue
    </Modal>
  );
};

export default PeopleMergedAutomaticallyModal;
