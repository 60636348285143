import * as React from 'react';
import { createStore, useStore } from 'zustand';

import createZustandContext from '../../../contexts/ZustandContext';
import { IOpportunity } from '../../../interfaces';
import { DispositionType } from '../../../interfaces/IDisposition';
import { isOpportunityClosed, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { findPolicyTypeLabel, isNonLifeAncillaryPolicyType } from '../../../interfaces/IPolicyType';
import analytics from '../../../services/analytics';

interface IStep {
  active: boolean;
  completed: boolean;
  lost: boolean;
  disabled: boolean;
  label: string;
  key: string | number;
  index: number;
  onClick: () => void;
  opportunity: IOpportunity | undefined;
}

type State = {
  displayedOpportunities: IOpportunity[];
  appendOpportunity: (op: IOpportunity) => void;
  syncDisplayedOpportunities: (ops: IOpportunity[]) => void;
  overrideActiveOpportunity: null | IOpportunity;
  resetOverride: () => void;
};

export const FinalizeStepper = createZustandContext((initialState: State['displayedOpportunities']) =>
  createStore<State>()(set => ({
    displayedOpportunities: initialState,
    syncDisplayedOpportunities: (opportunities: IOpportunity[]) =>
      set(currentState => {
        const freshState = [...currentState.displayedOpportunities];
        for (const opportunity of opportunities) {
          const index = currentState.displayedOpportunities.findIndex(op => op.id === opportunity.id);
          if (index !== -1) {
            freshState[index] = opportunity;
          }
        }

        return { displayedOpportunities: freshState };
      }),
    appendOpportunity: (op: IOpportunity) =>
      set(state => ({
        displayedOpportunities: [...state.displayedOpportunities, op],
        overrideActiveOpportunity: op
      })),
    overrideActiveOpportunity: null,
    resetOverride: () => set({ overrideActiveOpportunity: null })
  }))
);

export const useStepperOpportunities = () => useStore(FinalizeStepper.useContext());

const useFinalizeStepper = ({
  currentDisposition,
  opportunities
}: {
  opportunities: IOpportunity[];
  currentDisposition: DispositionType | undefined;
}) => {
  const { displayedOpportunities, overrideActiveOpportunity, resetOverride, syncDisplayedOpportunities } =
    useStepperOpportunities();

  const qualifiedOpportunities = (displayedOpportunities.length > 0 ? displayedOpportunities : opportunities).filter(
    opp => opp.status !== OpportunityStatus.NotQualified
  );

  const findActiveOpp = (): IOpportunity | undefined =>
    qualifiedOpportunities.find(
      opportunity =>
        !isOpportunityClosed(opportunity) && !isNonLifeAncillaryPolicyType({ policy_type: opportunity.policy_type })
    );

  const [activeOpp, setActiveOpp] = React.useState<IOpportunity | undefined>(findActiveOpp);

  React.useEffect(() => {
    if (overrideActiveOpportunity) {
      setActiveOpp(overrideActiveOpportunity);
      resetOverride();
    }
  }, [overrideActiveOpportunity, resetOverride]);

  React.useEffect(() => {
    syncDisplayedOpportunities(opportunities);
  }, [syncDisplayedOpportunities, opportunities]);

  const steps: IStep[] = qualifiedOpportunities.map((opportunity, index) => ({
    active: opportunity.id === activeOpp?.id,
    completed: opportunity.status === OpportunityStatus.Sold,
    lost: opportunity.status === OpportunityStatus.Lost,
    disabled: opportunity.is_data_collection_enabled === false,
    label: findPolicyTypeLabel(opportunity.policy_type),
    key: opportunity.id,
    index,
    onClick: () => {
      analytics.track('Policy binding, policy type clicked', {
        status: opportunity.status,
        title: opportunity.title,
        policy_type: opportunity.policy_type
      });
      setActiveOpp(opportunity);
    },
    opportunity
  }));

  steps.push({
    active: !activeOpp,
    completed: currentDisposition === DispositionType.PolicySold,
    lost: false,
    disabled: false,
    label: 'Review',
    key: 'final_look',
    index: steps.length,
    onClick: () => {
      analytics.track('Policy binding, final look clicked');
      setActiveOpp(undefined);
    },
    opportunity: undefined
  });

  const stepsLength = steps.length;
  const currentStep = steps.find(s => s.active);
  const isLastStep = currentStep === steps[stepsLength - 1];

  const activeIndex = steps.findIndex(step => step.active);
  const goToNext = () => {
    const nextStep = steps[activeIndex + 1];
    if (nextStep) {
      setActiveOpp(nextStep.opportunity);
    }
  };

  return {
    // Always returns fresh opportunity object
    activeOpportunity: steps[activeIndex]?.opportunity,
    steps,
    isLastStep,
    goToNext
  };
};

export default useFinalizeStepper;
