/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';

import { usePopper } from '../../hooks';
import { IDocument } from '../../interfaces';
import { DeliverableDocumentStatus, DocumentDeliveryStatus } from '../../interfaces/IDocumentDelivery';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { capitalize, dateTimeFormatter } from '../../utils/formatter';
import Loader from '../common/Loader';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import {
  CalendarIcon,
  CheckCircleIcon,
  DesktopIcon,
  ErrorIcon,
  FlagIcon,
  HourglassIcon,
  PartialSuccessIcon,
  RefreshIcon,
  UsersIcon,
  WarningIcon
} from '../core/icons';
import Table from '../core/Table/Table';
import Text from '../core/Text';
import { IChannel } from './Document';

export const ChannelTag = ({ status }: { status: DocumentDeliveryStatus }) => {
  switch (status) {
    case DocumentDeliveryStatus.Pending:
      return (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <Loader width="16px" height="16px" />
          <Text type="small">Sending</Text>
        </FlexBox>
      );
    case DocumentDeliveryStatus.Completed:
      return (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <CheckCircleIcon color={colors.statusGreen} />
          <Text type="small">Sent</Text>
        </FlexBox>
      );
    case DocumentDeliveryStatus.Failed:
      return (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <ErrorIcon color={colors.statusRed} /> <Text type="small">Failed</Text>
        </FlexBox>
      );
    case DocumentDeliveryStatus.NeedRetry:
      return (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <RefreshIcon /> <Text type="small">Retrying</Text>
        </FlexBox>
      );
    default:
      return (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <HourglassIcon /> <Text type="small">Awaits sending</Text>
        </FlexBox>
      );
  }
};

const DocumentStatusTag = ({ channels, document }: { channels?: IChannel[]; document: IDocument | null }) => {
  if (!document || !channels) {
    return (
      <FlexBox gap={spacings.px4} alignItemsCenter>
        <WarningIcon color={colors.statusOrange} /> <Text type="small">Need upload</Text>
      </FlexBox>
    );
  }

  const sent = channels.filter(
    channel =>
      channel.document_status === DeliverableDocumentStatus.Completed &&
      channel.delivery_status === DocumentDeliveryStatus.Completed
  );

  if (sent.length > 0) {
    return (
      <FlexBox gap={spacings.px4} alignItemsCenter>
        {sent.length === channels.length ? (
          <CheckCircleIcon color={colors.statusGreen} />
        ) : (
          <PartialSuccessIcon color={colors.statusOrange} />
        )}
        <Text type="small">Sent</Text>
        <Text type="small" bold>
          ({sent.length} of {channels.length})
        </Text>
      </FlexBox>
    );
  }

  const failed = channels.filter(channel => channel.delivery_status === DocumentDeliveryStatus.Failed);

  if (failed.length > 0) {
    return (
      <FlexBox gap={spacings.px4} alignItemsCenter>
        <ErrorIcon color={colors.statusRed} /> <Text type="small">Failed</Text>
        <Text type="small" bold>
          ({failed.length} of {channels.length})
        </Text>
      </FlexBox>
    );
  }

  return (
    <FlexBox gap={spacings.px4} alignItemsCenter>
      <HourglassIcon /> <Text type="small">Awaits sending</Text>
      <Text type="small" bold>
        (0 of {channels.length})
      </Text>
    </FlexBox>
  );
};
const columnHelper = createColumnHelper<IChannel>();

export const DocumentStatusesTable = ({ channels }: { channels: IChannel[] }) => {
  const tableColumns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'recipient',
        cell: ({ row: { original } }) => <Text mv={spacings.px12}>{capitalize(original.recipient_type)}</Text>,
        header: () => (
          <FlexBox alignItemsCenter gap={spacings.px4} pv={spacings.px16}>
            <UsersIcon /> <Text bold>Recipient</Text>
          </FlexBox>
        ),
        size: 140
      }),
      columnHelper.display({
        id: 'status',
        cell: ({ row: { original } }) => (
          <Container mv={spacings.px12}>
            <ChannelTag status={original.delivery_status} />
          </Container>
        ),
        header: () => (
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <FlagIcon /> <Text bold>Status</Text>
          </FlexBox>
        ),
        size: 160
      }),
      columnHelper.display({
        id: 'sender',
        cell: () => <Text mv={spacings.px12}>AMP</Text>,
        header: () => (
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <UsersIcon /> <Text bold>Sender</Text>
          </FlexBox>
        ),
        size: 140
      }),
      columnHelper.display({
        id: 'channel',
        cell: ({ row: { original } }) => <Text mv={spacings.px12}>{capitalize(original.channel)}</Text>,
        header: () => (
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <DesktopIcon /> <Text bold>Channel</Text>
          </FlexBox>
        ),
        size: 160
      }),
      columnHelper.display({
        id: 'last_send_try_at',
        cell: ({ row: { original } }) => (
          <Text mv={spacings.px12} singleLine>
            {original.last_send_try_at || original.sent_at
              ? dateTimeFormatter(original.last_send_try_at || original.sent_at)
              : 'N/A'}
          </Text>
        ),
        header: () => (
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <CalendarIcon />{' '}
            <Text bold singleLine>
              Sent at/Last try
            </Text>
          </FlexBox>
        ),
        size: 220
      })
    ],
    []
  );

  return (
    <Table
      headerBackgroundColor={colors.grey5}
      withRowBorders
      withBorder
      data={channels}
      columns={tableColumns}
      testId="document-status"
    />
  );
};

const DocumentStatus = ({
  channels,
  document,
  withDetails = true
}: {
  channels?: IChannel[];
  document: IDocument | null;
  withDetails?: boolean;
}) => {
  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { padding: '12px', zIndex: 999 }
  });

  const displayPopper = channels && channels.length > 0 && document && withDetails;

  return (
    <FlexBox>
      <FlexBox
        alignItemsCenter
        gap={spacings.px4}
        onMouseEnter={triggerPopper}
        onMouseLeave={() => setAnchorEl(null)}
        customCss={css`
          white-space: nowrap;

          border-radius: 8px;
          &:hover {
            cursor: ${displayPopper ? 'pointer' : 'default'};
            background-color: ${displayPopper ? colors.grey5 : 'transparent'};
          }
        `}
        backgroundColor={anchorEl ? colors.grey5 : 'transparent'}
        ph={spacings.px6}
        pv={spacings.px6}
      >
        <DocumentStatusTag channels={channels} document={document} />
      </FlexBox>

      {displayPopper && (
        <div ref={elementRef}>
          <PopperComponent {...popperProps}>
            <FlexBox
              gap={spacings.px8}
              roundBorder
              border
              boxShadow
              backgroundColor={colors.white}
              columnDirection
              customCss={css`
                min-width: 300px;
              `}
            >
              <DocumentStatusesTable channels={channels} />
            </FlexBox>
          </PopperComponent>
        </div>
      )}
    </FlexBox>
  );
};

export default DocumentStatus;
