/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'debounce-promise';
import moment from 'moment';
import * as React from 'react';

import alert from '../../../components/core/Alert';
import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import {
  DateInputField,
  InputField,
  labelCss,
  MultiSelectField,
  NumericField,
  RadioGroupField,
  SelectField
} from '../../../components/core/forms/fields';
import { DollarIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { isEndDisposition } from '../../../components/DispositionsModals/dispositionsHelper';
import { collectPolicyAssetsAddresses } from '../../../components/GenerateInvoiceModal/helpers';
import generateDocxReport, { CarrierAddress } from '../../../components/PolicyList/generateDocxReport';
import { DEFAULT_DEDUCTIBLE_TYPE, PERCENTAGE_DEDUCTIBLE_TYPE, Translations } from '../../../constants';
import appTranslations from '../../../constants/appTranslations';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useConstant } from '../../../hooks';
import usePremiumConfirmation from '../../../hooks/usePremiumConfirmation';
import { ISourceDimensions } from '../../../interfaces';
import DatapointKey, { DatapointsUsageKey } from '../../../interfaces/IDatapoint';
import { DocumentOwnerType, FileToUpload, IDocument } from '../../../interfaces/IDocument';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { PolicyPaymentMethod, PolicyStatus, PolicyTransactionType } from '../../../interfaces/IPolicy';
import {
  doesPolicyTypeHavePrimaryCoverage,
  isNonLifeAncillaryPolicyType,
  PolicyType,
  PolicyTypes,
  policyTypesSimilarTo
} from '../../../interfaces/IPolicyType';
import { BusinessTypes } from '../../../interfaces/ISourceDimensions';
import { useAnswers } from '../../../queries/answers/useAnswers';
import useAssignees from '../../../queries/assignees/useAssignees';
import useCarriers from '../../../queries/carriers/useCarriers';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import {
  useLeadOpportunityCoinsureds,
  useUpdateLeadOpportunity
} from '../../../queries/leads/opportunities/useLeadOpportunities';
import { DOCS_QUERY_KEY, useCreateDocument } from '../../../queries/people/person_documents/usePersonDocuments';
import { createMaticPolicy } from '../../../queries/people/person_policies/useCreateMaticPolicy';
import {
  usePolicyCoinsureds,
  useUpdatePolicyCoinsureds
} from '../../../queries/people/person_policies/usePolicyCoinsureds';
import { useCreateOrUpdatePolicyDelivery } from '../../../queries/people/person_policies/usePolicyDeliveries';
import { updatePolicy } from '../../../queries/people/person_policies/useUpdatePolicy';
import usePersonRelatedPeople from '../../../queries/people/usePersonRelatedPeople';
import { validatePolicyNumber } from '../../../queries/policies/useValidatePolicyNumber';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, INTERNAL_DATE_FORMAT } from '../../../utils/formatter';
import { LLC_VALUE, llcTrustPair, TRUST_VALUE } from '../../GuidedSellingExperience/_helpers';
// eslint-disable-next-line max-len
import { useCurrentDispositionSubscriptionContext } from '../../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';
import CarrierInstructions from '../_components/CarrierInstructions';
import { UNIVERSAL_CONSENT_FIELD_KEY } from '../_components/CarrierInstructions/UniversalCarrierConsent';
import Documents from '../_components/Documents';
import PDFFileViewer from '../_components/PDFFileViewer';
import PolicyLegalConsent, { LEGAL_CONSENT_FIELD_KEY } from '../_components/PolicyLegalConsent';
import {
  getOpportunityStatusInitialValue,
  getPeriodOptions,
  getPredefinedPeriod,
  NEW_CLOSING_OPTIONS,
  retrievePolicyFromLocalStorage,
  STATUS_OPTIONS,
  uploadDocuments
} from '../_helpers';
import { usePolicyDisclosureConsent, usePostSalesSync, useSaveDraftPolicy, useSoldPolicyTooltip } from '../_hooks';
import {
  FLOOD_POLICY_ESCROW_WARNING_MESSAGE,
  HomeValues,
  IHomeFormRenderer,
  IHomePolicyForm
} from './HopePolicyForm.utils';
import { validationSchema } from './validationSchema';

const deductibleTypeCss = css`
  color: ${colors.azure50};
  &[aria-selected='true'] {
    color: ${colors.black};
    border-bottom: 2px solid ${colors.black};
  }

  &:hover {
    color: inherit;
  }
`;

const HomeFormRenderer = ({
  setFieldValue,
  values,
  children,
  opportunity,
  policy,
  assignees,
  isPendingAssignees,
  isPolicyRequired,
  showPolicyDisclosure,
  policyNumberWarning
}: IHomeFormRenderer) => {
  const { person, lead } = useGuidedSellingExperienceContext();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: carriers, isPending: isPendingFilteredCarriers } = useCarriers({
    policy_type: values.policy_type
  });
  const { data: allCarriers, isPending: isPendingAllCarriers } = useCarriers();
  const carriersList = carriers && carriers.length > 0 ? carriers : allCarriers;
  const carrierOptions = React.useMemo(() => {
    return (carriersList || []).map(({ id, name }) => ({ key: id, value: name }));
  }, [carriersList]);

  const todayDate = useConstant(() => new Date());
  const { ConfirmationModal, validatePremiumCallback } = usePremiumConfirmation({});

  const { setPostSalesSyncValues } = usePostSalesSync();
  const stateFromFirstAsset = opportunity.assets?.[0]?.address.state;

  React.useEffect(() => {
    setPostSalesSyncValues({
      carrierId: values.carrier_id || null,
      policyType: opportunity.policy_type,
      state: stateFromFirstAsset || null
    });
  }, [setPostSalesSyncValues, opportunity.policy_type, values.carrier_id, stateFromFirstAsset]);

  const syncDocumentRulesCallback = React.useCallback(
    (rules: HomeValues['document_rules_policies_attributes']) => {
      setFieldValue('document_rules_policies_attributes', rules);
    },
    [setFieldValue]
  );

  const { Tip, labelProps } = useSoldPolicyTooltip();

  useSaveDraftPolicy({ opportunityId: opportunity.id, values });

  const { data: answers = [] } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.PropertyOnTheDeed,
    personGid: person!.gid,
    assetGid: opportunity.assets?.[0]?.gid || null,
    relatedPersonGid: null
  });

  const { data: opportunityCoinsureds = [] } = useLeadOpportunityCoinsureds({
    leadId: lead?.id?.toString(),
    opportunityId: opportunity.id.toString()
  });

  const coinsuredsGids = opportunityCoinsureds.map(c => c.gid);

  const entitiesOnTheDeed =
    (answers.find(answer => answer.key === DatapointKey.PropertyOnTheDeed)?.value as string[] | undefined) || [];

  const llcName = entitiesOnTheDeed.includes(LLC_VALUE)
    ? (answers.find(answer => answer.key === DatapointKey.PropertyLLCName)?.value as string | undefined)
    : undefined;
  const trustName = entitiesOnTheDeed.includes(TRUST_VALUE)
    ? (answers.find(answer => answer.key === DatapointKey.PropertyTrustName)?.value as string | undefined)
    : undefined;

  const insuredEntities = [
    {
      key: LLC_VALUE,
      value: policy?.llc_name || llcName,
      description: `${llcTrustPair[LLC_VALUE]} , Coinsured, On the deed`,
      isDisabled: true
    },
    {
      key: TRUST_VALUE,
      value: policy?.trust_name || trustName,
      description: `${llcTrustPair[TRUST_VALUE]} , Coinsured, On the deed`,
      isDisabled: true
    }
  ].filter(({ value }) => value) as { key: string; value: string; description: string; isDisabled: boolean }[];

  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(person?.gid);

  const carrier = (carriersList || []).find(carrier => carrier.id === Number(values.carrier_id));

  const showDwelling = (function () {
    return doesPolicyTypeHavePrimaryCoverage(opportunity.policy_type);
  })();

  const showNewClosing = PolicyTypes.find(type => type.key === opportunity.policy_type)?.possible_origination;

  const showPrimaryFields =
    values.status === 'sold' &&
    (featureFlags.issuePolicyConsent && showPolicyDisclosure ? values[LEGAL_CONSENT_FIELD_KEY] === 'true' : true);

  const showSecondaryFields = carrier && showPrimaryFields;

  return (
    <>
      {children}
      <FlexBox columnDirection gap={spacings.px24} mt={spacings.px32}>
        <Container border p={spacings.px24} roundBorder>
          <FlexBox>
            <FlexBox {...(policy ? labelProps : {})}>
              <RadioGroupField
                required={isPolicyRequired}
                inline
                label={({ required }) => (
                  <Text type="large" bold customCss={labelCss(required)}>
                    Did you sell the policy?
                  </Text>
                )}
                id="status"
                name="status"
                options={
                  policy || isEndDisposition(currentDisposition?.disposition_type)
                    ? STATUS_OPTIONS.map(o => ({ ...o, disabled: true }))
                    : STATUS_OPTIONS
                }
              />
              {policy && <Tip />}
            </FlexBox>
          </FlexBox>
          {values.status === 'lost' && (
            <SelectField
              name="reason"
              id="reason"
              label="Reason"
              required
              options={Translations.opportunityLostReasonOptions}
              inline
            />
          )}

          {values.status === 'sold' && featureFlags.issuePolicyConsent && showPolicyDisclosure && (
            <PolicyLegalConsent disabled={!!policy} />
          )}

          {showPrimaryFields && (
            <>
              <Container>
                <SelectField
                  name="policy_type"
                  id="policy_type"
                  label="Policy type"
                  required
                  options={policyTypesSimilarTo({ policy_type: values.policy_type }).map(({ key, name }) => ({
                    key,
                    value: name
                  }))}
                  inline
                  disabled={!!policy}
                />
                <SelectField
                  name="carrier_id"
                  id="carrier_id"
                  label="Carrier"
                  required
                  options={carrierOptions}
                  inline
                  ordered
                  disabled={!!policy}
                  isLoading={isPendingFilteredCarriers || isPendingAllCarriers}
                />
              </Container>
              {carriersList && <CarrierInstructions carriersList={carriersList} carrierId={values.carrier_id} />}
            </>
          )}
        </Container>
        {showSecondaryFields && (
          <>
            <Container border p={spacings.px24} roundBorder>
              <Documents
                files={[
                  ...values.files.filter((file): file is IDocument => !('deleted' in file)),
                  ...values.filesToUpload
                ]}
                policyType={opportunity.policy_type}
                policyId={policy?.id || null}
                state={stateFromFirstAsset}
                carrier={carrier}
                syncDocumentRules={syncDocumentRulesCallback}
                onAttach={files => {
                  setFieldValue('filesToUpload', [...values.filesToUpload, ...files]);

                  return Promise.resolve();
                }}
                onDelete={fileToDelete => {
                  if ('uid' in fileToDelete) {
                    setFieldValue(
                      'filesToUpload',
                      values.filesToUpload.filter(file => file.uid !== fileToDelete.uid)
                    );
                  } else {
                    setFieldValue(
                      'files',
                      values.files.map(file => (file.id === fileToDelete.id ? { ...file, deleted: true } : file))
                    );
                  }

                  return Promise.resolve();
                }}
                newClosing={values.new_closing}
              />
            </Container>
            <Container border p={spacings.px24} roundBorder>
              <Container id="policy-details">
                <Paragraph type="large" bold>
                  Policy details
                </Paragraph>
                <SelectField
                  name="agent_id"
                  id="agent_id"
                  label="Agent of record"
                  required
                  options={(assignees || []).map(({ id, name }) => ({ key: id, value: name }))}
                  inline
                  ordered
                  isLoading={isPendingAssignees}
                />

                <Paragraph type="large" bold>
                  Details
                </Paragraph>
                <InputField
                  required
                  inline
                  label="Policy number"
                  id="policy_number"
                  name="policy_number"
                  warning={policyNumberWarning}
                  fsMask
                />
                <DateInputField
                  inline
                  required
                  label="Effective date"
                  id="effective_date"
                  name="effective_date"
                  minDate={todayDate}
                />
                <SelectField
                  name="period"
                  id="period"
                  label="Policy period"
                  required
                  options={getPeriodOptions(PolicyType.Home)}
                  inline
                />
                {values.period === 'custom' && (
                  <DateInputField
                    inline
                    required
                    label="Expiration date"
                    id="expiration_date"
                    name="expiration_date"
                    minDate={todayDate}
                  />
                )}
                <SelectField
                  name="payment_method"
                  id="payment_method"
                  label="Billing type"
                  required
                  options={Translations.paymentMethodOptions}
                  inline
                  warning={
                    featureFlags.floodPolicyEscrowWarning &&
                    values.payment_method === PolicyPaymentMethod.Escrow &&
                    values.policy_type === PolicyType.Flood
                      ? FLOOD_POLICY_ESCROW_WARNING_MESSAGE
                      : ''
                  }
                />
                {showNewClosing && (
                  <>
                    <SelectField
                      name="new_closing"
                      id="new_closing"
                      label="New closing"
                      required
                      options={NEW_CLOSING_OPTIONS}
                      inline
                      disabled={!!policy}
                    />
                    {values.new_closing && !policy && (
                      <Container ml={spacings.px12}>
                        <Paragraph type="large" bold>
                          Generate invoice
                        </Paragraph>
                        <SelectField
                          name="mailing_method"
                          id="mailing_method"
                          label="Mailing method"
                          required
                          placeholder="Choose mailing method"
                          options={[
                            carrier?.overnight_address?.full && {
                              key: CarrierAddress.OvernightAddress,
                              value: carrier?.overnight_address?.full,
                              description: 'Overnight address'
                            },
                            carrier?.remittance_address?.full && {
                              key: CarrierAddress.RemittanceAddress,
                              value: carrier?.remittance_address?.full,
                              description: 'Remittance address'
                            }
                          ].filter(Boolean)}
                          inline
                          // eslint-disable-next-line max-len
                          info="If you don`t see an address here, please reach out in the Slack channel to have it added."
                        />
                      </Container>
                    )}
                  </>
                )}

                <MultiSelectField
                  fsMask
                  name="coinsureds"
                  id="coinsureds"
                  label="Insured people"
                  required
                  options={[
                    { key: person!.gid, value: person!.name, description: 'Primary customer', isDisabled: true }
                  ]
                    .concat(
                      (relatedPeople || []).map(({ gid, name, kind }) => ({
                        key: gid,
                        value: name,
                        description: `${appTranslations.relationKind(kind)}${
                          coinsuredsGids.includes(gid) ? ' , Coinsured' : ''
                        }${entitiesOnTheDeed.includes(gid) ? ' , On the deed' : ''}`,
                        isDisabled: entitiesOnTheDeed.includes(gid)
                      }))
                    )
                    .concat(insuredEntities)}
                  inline
                  isLoading={isPendingRelatedPeople}
                />
              </Container>

              <Container id="policy-coverages">
                <Paragraph type="large" bold>
                  Coverages
                </Paragraph>
                {showDwelling && (
                  <NumericField
                    inline
                    required
                    label="Dwelling"
                    id="dwelling"
                    name="dwelling"
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                  />
                )}
                <FlexBox alignItemsBaseline>
                  {values.deductible_type === DEFAULT_DEDUCTIBLE_TYPE ? (
                    <NumericField
                      inline
                      required
                      label="Deductible"
                      id="deductible"
                      name="deductible"
                      valueIsNumericString
                      prefixIcon={<DollarIcon />}
                    />
                  ) : (
                    <NumericField
                      inline
                      required
                      label="Deductible"
                      id="deductible"
                      name="deductible"
                      valueIsNumericString
                    />
                  )}
                  <ButtonWithoutBorder onClick={() => setFieldValue('deductible_type', DEFAULT_DEDUCTIBLE_TYPE)}>
                    <Text
                      bold
                      aria-selected={values.deductible_type === DEFAULT_DEDUCTIBLE_TYPE}
                      customCss={deductibleTypeCss}
                    >
                      in $
                    </Text>
                  </ButtonWithoutBorder>
                  <ButtonWithoutBorder onClick={() => setFieldValue('deductible_type', PERCENTAGE_DEDUCTIBLE_TYPE)}>
                    <Text
                      bold
                      aria-selected={values.deductible_type === PERCENTAGE_DEDUCTIBLE_TYPE}
                      customCss={deductibleTypeCss}
                    >
                      in %
                    </Text>
                  </ButtonWithoutBorder>
                </FlexBox>
                <NumericField
                  inline
                  required
                  label="Premium"
                  id="premium"
                  name="premium"
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  fieldProps={{
                    validate: (value: number) => validatePremiumCallback(value || 0)
                  }}
                />
                <ConfirmationModal />
              </Container>
            </Container>
          </>
        )}
      </FlexBox>
    </>
  );
};

const buildHomeInitialValues = ({
  opportunity,
  policy,
  policyCoinsureds,
  files,
  businessType
}: Pick<IHomePolicyForm, 'opportunity' | 'policy'> & {
  files: IDocument[];
  businessType: ISourceDimensions['business_type'];
  policyCoinsureds: string[];
}) => {
  const {
    status,
    reason,
    policy_type,
    carrier_id,
    policy_number,
    effective_date,
    period,
    expiration_date,
    payment_method,
    dwelling,
    new_closing,
    agent_id,
    deductible,
    deductible_type,
    premium,
    assets_gids,
    coinsureds: coinsuredsFromStorage,
    [LEGAL_CONSENT_FIELD_KEY]: legal_consent
  } = retrievePolicyFromLocalStorage(opportunity.id) || {};

  const cachedAssetsMatchesOpportunityAssets =
    !!assets_gids?.length && assets_gids?.every(gid => opportunity.assets?.some(a => a.gid === gid));

  const assetsGidsValues =
    policy?.assets?.map(a => a.gid) ||
    (cachedAssetsMatchesOpportunityAssets && assets_gids) ||
    opportunity.assets?.map(a => a.gid) ||
    [];

  const consentValue = policy ? ('true' as const) : legal_consent || ('' as const);

  const getNewClosingInitialValue = (): boolean | undefined => {
    if (policy && policy.new_closing !== null) {
      return policy.new_closing;
    } else if (new_closing !== undefined && new_closing !== null) {
      new_closing;
    } else if (
      PolicyTypes.find(type => type.key === opportunity.policy_type)?.possible_origination &&
      opportunity.primary &&
      businessType === BusinessTypes.HomeOrigination
    ) {
      return true;
    }
    return undefined;
  };

  const getCoinsuredsInitialValue = () => {
    if (policy) {
      return policyCoinsureds;
    }

    if (coinsuredsFromStorage && coinsuredsFromStorage.length > 0) {
      return coinsuredsFromStorage;
    }

    return policyCoinsureds;
  };

  return {
    status: getOpportunityStatusInitialValue({ opportunity, policy, cachedStatus: status }),
    reason: reason || opportunity.reason || '',
    policy_type: policy?.policy_type || policy_type || opportunity.policy_type,
    carrier_id: policy?.carrier.id || carrier_id || ('' as unknown as number),
    policy_number: policy?.policy_number || policy_number || '',
    effective_date: policy?.effective_date || effective_date || '',
    period: policy ? getPredefinedPeriod(policy) : period || getPeriodOptions(PolicyType.Home)[0]?.key || '',
    expiration_date: policy?.expiration_date || expiration_date || '',
    payment_method: policy?.payment_method || payment_method || '',
    dwelling: policy?.primary_coverage?.toString() || dwelling || '',
    new_closing: getNewClosingInitialValue(),
    mailing_method: CarrierAddress.RemittanceAddress,
    agent_id: policy?.agent_id || agent_id || authInfo.currentUserId!,
    deductible: policy?.deductible_value || deductible || '',
    deductible_type: policy?.deductible_type || deductible_type || DEFAULT_DEDUCTIBLE_TYPE,
    premium: policy?.premium || premium || '',
    files: (policy ? policy.files || [] : files) as HomeValues['files'],
    filesToUpload: [] as FileToUpload[],
    assets_gids: assetsGidsValues,
    document_rules_policies_attributes: [] as HomeValues['document_rules_policies_attributes'],
    coinsureds: getCoinsuredsInitialValue(),
    [UNIVERSAL_CONSENT_FIELD_KEY]: Boolean(policy),
    [LEGAL_CONSENT_FIELD_KEY]: consentValue
  };
};

const HomePolicyForm = ({
  opportunity,
  policy,
  onSubmit,
  children,
  documents,
  refetchPolicies,
  isDataEditingForbidden,
  showPolicyDisclosure
}: IHomePolicyForm): JSX.Element => {
  const queryClient = useQueryClient();
  const { lead, personGid, sourceDimensions, person } = useGuidedSellingExperienceContext();
  const [policyNumberWarning, setPolicyNumberWarning] = React.useState('');

  const { data: allCarriers } = useCarriers();

  const { mutateAsync: updateOpportunity } = useUpdateLeadOpportunity();
  const { data: assignees, isPending: isPendingAssignees } = useAssignees();
  const { data: docTypes } = useDocumentTypes();
  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: false });

  const { mutateAsync: createDeliveryObject } = useCreateOrUpdatePolicyDelivery();

  const acceptPolicyDisclosure = usePolicyDisclosureConsent();

  const opportunityDocs = documents.filter(
    doc => doc.owner_type === DocumentOwnerType.Opportunity && doc.owner_id === opportunity.id
  );
  const isPolicyRequired = !isNonLifeAncillaryPolicyType({ policy_type: opportunity.policy_type });

  const memoizedPolicyNumberValidationRef = React.useRef<{ policy_number: string } | undefined>(undefined);
  const memoizedValidationArgsRef = React.useRef({
    policy_number: '',
    carrier_id: 0,
    policy_type: ''
  });

  const validatePolicyNumberCallback = debounce(
    ({
      policy_number,
      carrier_id,
      policy_type,
      state
    }: {
      policy_number: string;
      carrier_id: number;
      policy_type: string;
      state: string | null;
    }) => {
      if (carrier_id && policy_number) {
        if (
          memoizedValidationArgsRef.current.carrier_id === carrier_id &&
          memoizedValidationArgsRef.current.policy_number === policy_number &&
          memoizedValidationArgsRef.current.policy_type === policy_type
        ) {
          return memoizedPolicyNumberValidationRef.current;
        }

        memoizedValidationArgsRef.current = { policy_number, carrier_id, policy_type };

        return validatePolicyNumber({ policy_number, carrier_id, policy_type, state }).then(({ policy_number }) => {
          if (policy_number.warning && !policy_number.error) {
            setPolicyNumberWarning(policy_number.warning);
          } else {
            setPolicyNumberWarning('');
          }

          if (policy_number.error) {
            memoizedPolicyNumberValidationRef.current = { policy_number: policy_number.error };
            return { policy_number: policy_number.error };
          } else {
            memoizedPolicyNumberValidationRef.current = undefined;
            return {};
          }
        });
      }
    },
    500
  );

  const { data: propertyOnTheDeedAnswers } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.PropertyOnTheDeed,
    personGid: personGid!,
    assetGid: opportunity.assets?.[0]?.gid || null,
    relatedPersonGid: null
  });

  const { data: policyCoinsureds } = usePolicyCoinsureds({ policyId: policy?.id });
  const { data: opportunityCoinsureds } = useLeadOpportunityCoinsureds({
    leadId: lead?.id?.toString(),
    opportunityId: opportunity.id.toString()
  });
  const { mutateAsync: updatePolicyCoinsureds } = useUpdatePolicyCoinsureds();

  if (!propertyOnTheDeedAnswers || !opportunityCoinsureds || (policy?.id && !policyCoinsureds)) {
    return (
      <Container pt={spacings.px24} pl={spacings.px24}>
        <FormLoader />
      </Container>
    );
  }

  const opportunityCoinsuredsGids = opportunityCoinsureds.map(c => c.gid);

  const onTheDeedAnswer = (propertyOnTheDeedAnswers.find(answer => answer.key === DatapointKey.PropertyOnTheDeed)
    ?.value || []) as string[];

  const initialCoinsureds = (() => {
    let coinsureds;
    if (policy) {
      coinsureds = (policyCoinsureds || [])
        .map(p => p.gid)
        .concat([policy.trust_name && TRUST_VALUE, policy.llc_name && LLC_VALUE].filter(Boolean));
    } else {
      coinsureds = opportunityCoinsuredsGids.concat(
        onTheDeedAnswer.filter(gid => !opportunityCoinsuredsGids.includes(gid))
      );
    }

    return [personGid!, ...coinsureds.filter(c => c !== personGid!)];
  })();

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      disabled={isDataEditingForbidden}
      submitText="Save and proceed"
      initialValues={buildHomeInitialValues({
        opportunity,
        policy,
        policyCoinsureds: initialCoinsureds,
        files: opportunityDocs,
        businessType: sourceDimensions?.business_type
      })}
      // avoid double validation on policy_number field
      validateOnBlur={false}
      validate={values => validatePolicyNumberCallback({ ...values, state: opportunity.state })}
      validationSchema={validationSchema({
        policy,
        opportunity,
        carriers: allCarriers,
        showPolicyDisclosure
      })}
      onSubmit={async values => {
        if (values.status === 'lost' && lead) {
          await updateOpportunity({
            leadId: lead.id,
            opportunityId: opportunity.id,
            params: { status: OpportunityStatus.Lost, reason: values.reason }
          });

          onSubmit(values);
        }

        if (values.status === 'sold' && lead && !policy && personGid) {
          if (showPolicyDisclosure ? !(await acceptPolicyDisclosure({ personGid })) : false) {
            return;
          }

          await updateOpportunity({
            leadId: lead.id,
            opportunityId: opportunity.id,
            params: { status: OpportunityStatus.Sold, policy_type: values.policy_type }
          });

          const { policy: createdPolicy } = await createMaticPolicy({
            person_gid: personGid!,
            lead_id: lead.id,
            transaction_type: PolicyTransactionType.NEW_BUSINESS,
            policy_type: values.policy_type,
            premium: Number(values.premium),
            carrier_id: values.carrier_id,
            status: PolicyStatus.BOUND,
            primary_coverage: values.dwelling ? Number(values.dwelling) : undefined,
            deductible_type: values.deductible_type,
            deductible_value: values.deductible,
            policy_number: values.policy_number,
            sale_date: dateFormatter(new Date(), INTERNAL_DATE_FORMAT),
            effective_date: values.effective_date,
            expiration_date:
              values.period === 'custom'
                ? values.expiration_date
                : moment(values.effective_date).add(values.period, 'months').format(INTERNAL_DATE_FORMAT),
            payment_method: values.payment_method,
            new_closing: values.new_closing,
            state: opportunity.state!,
            agent_id: values.agent_id.toString(),
            asset_gids: values.assets_gids,
            document_rules_policies_attributes: values.document_rules_policies_attributes,
            trust_name: values.coinsureds.includes(TRUST_VALUE)
              ? (propertyOnTheDeedAnswers.find(answer => answer.key === DatapointKey.PropertyTrustName)?.value as
                  | string
                  | undefined)
              : undefined,
            llc_name: values.coinsureds.includes(LLC_VALUE)
              ? (propertyOnTheDeedAnswers.find(answer => answer.key === DatapointKey.PropertyLLCName)?.value as
                  | string
                  | undefined)
              : undefined
          });
          await updatePolicyCoinsureds({
            policyId: createdPolicy.id,
            peopleGids: values.coinsureds.filter(c => c !== personGid).filter(c => c !== LLC_VALUE && c !== TRUST_VALUE)
          });

          await uploadDocuments({
            personGid,
            policyId: createdPolicy.id,
            files: values.files,
            filesToUpload: values.filesToUpload,
            onSuccess: () => onSubmit(values)
          }).then(() => {
            !featureFlags.processDeliveryOnPolicyEvents &&
              createDeliveryObject({ personGid: personGid!, policyId: createdPolicy.id });
          });

          if (createdPolicy.new_closing && values.mailing_method) {
            const policyCarrier = allCarriers?.find(carrier => carrier.id === createdPolicy.carrier.id);

            const reportTitle =
              `${person?.first_name}_${person?.last_name}_` + `${createdPolicy.policy_number}_invoice.docx`;

            generateDocxReport({
              title: reportTitle,
              person: person!,
              policy: createdPolicy,
              carrier: {
                address: policyCarrier?.[values.mailing_method as CarrierAddress],
                name: policyCarrier?.name
              },
              agentName: assignees?.find(({ id }) => id === createdPolicy.agent_id)?.name,
              assetsAddresses: collectPolicyAssetsAddresses(createdPolicy),
              saveFile: false
            }).then(blob => {
              if (blob) {
                const invoiceDocTypeId = docTypes?.document_types?.find(
                  document_type => document_type.key === 'invoice'
                )?.id;

                if (invoiceDocTypeId) {
                  createDocument({
                    personGid,
                    request: {
                      file: new File([blob], reportTitle),
                      uid: crypto.randomUUID(),
                      owner_id: createdPolicy.id,
                      owner_type: DocumentOwnerType.Policy,
                      document_type_id: invoiceDocTypeId
                    }
                  })
                    .then(() => {
                      !featureFlags.processDeliveryOnPolicyEvents &&
                        createDeliveryObject({ personGid: personGid!, policyId: createdPolicy.id });
                    })
                    .then(() => {
                      queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, personGid] });
                      alert({
                        message: 'Invoice was added to your files.',
                        options: {
                          autoClose: 2000
                        }
                      }).info();
                    });
                }
              }
            });
          }

          refetchPolicies();
        }

        if (values.status === 'sold' && lead && policy) {
          await updatePolicy({
            person_gid: personGid!,
            policy_id: policy.id,
            premium: Number(values.premium),
            primary_coverage: values.dwelling ? Number(values.dwelling) : undefined,
            policy_number: values.policy_number,
            deductible_value: values.deductible,
            payment_method: values.payment_method,
            new_closing: values.new_closing,
            deductible_type: values.deductible_type,
            carrier_id: values.carrier_id,
            agent_id: values.agent_id,
            effective_date: values.effective_date,
            expiration_date:
              values.period === 'custom'
                ? values.expiration_date
                : moment(values.effective_date).add(values.period, 'months').format(INTERNAL_DATE_FORMAT),
            transaction_type: policy.transaction_type,
            status: policy.status,
            sale_date: policy.sale_date,
            policy_type: values.policy_type,
            document_rules_policies_attributes: values.document_rules_policies_attributes,
            asset_gids: values.assets_gids,
            trust_name: values.coinsureds.includes(TRUST_VALUE)
              ? (propertyOnTheDeedAnswers.find(answer => answer.key === DatapointKey.PropertyTrustName)?.value as
                  | string
                  | undefined)
              : undefined,
            llc_name: values.coinsureds.includes(LLC_VALUE)
              ? (propertyOnTheDeedAnswers.find(answer => answer.key === DatapointKey.PropertyLLCName)?.value as
                  | string
                  | undefined)
              : undefined
          });

          await updatePolicyCoinsureds({
            policyId: policy.id,
            peopleGids: values.coinsureds.filter(c => c !== personGid).filter(c => c !== LLC_VALUE && c !== TRUST_VALUE)
          });

          await uploadDocuments({
            personGid: personGid!,
            policyId: policy.id,
            files: values.files,
            filesToUpload: values.filesToUpload,
            onSuccess: () => onSubmit(values)
          }).then(() => {
            !featureFlags.processDeliveryOnPolicyEvents &&
              createDeliveryObject({ personGid: personGid!, policyId: policy.id });
          });

          refetchPolicies();
        }
      }}
      renderForm={formikBag => (
        <FlexBox gap={spacings.px48}>
          <FlexBox
            columnDirection
            customCss={css`
              flex: 1;
            `}
          >
            <HomeFormRenderer
              {...formikBag}
              opportunity={opportunity}
              policy={policy}
              isPolicyRequired={isPolicyRequired}
              showPolicyDisclosure={showPolicyDisclosure}
              policyNumberWarning={policyNumberWarning}
              assignees={assignees}
              isPendingAssignees={isPendingAssignees}
            >
              {children}
            </HomeFormRenderer>
          </FlexBox>
          <PDFFileViewer />
        </FlexBox>
      )}
    />
  );
};

export default HomePolicyForm;
