import * as React from 'react';

import cancelIcon from '../../assets/new_icons/close.svg';
import editIcon from '../../assets/new_icons/edit.svg';
import { DropDownMenu } from '../../components/common';
import { IScheduledCall } from '../../interfaces/IScheduledCall';
import CancelCallModal from './CancelCallModal';
import { useScheduledCallActions } from './helpers';
import ScheduleCall from './index';

interface IEditScheduledCall {
  scheduledCall: IScheduledCall;
  leadGid: string | undefined;
  candidateGid: string | undefined;
  withActions?: boolean;
  disabled?: boolean;
}

const EditScheduledCall = ({
  scheduledCall,
  leadGid,
  candidateGid,
  withActions = true,
  disabled = false
}: IEditScheduledCall): JSX.Element => {
  const { editedScheduledCall, setCancelledScheduledCall, cancelledScheduledCall, setEditedScheduledCall } =
    useScheduledCallActions();

  return (
    <>
      {withActions && (
        <DropDownMenu
          wrapperTestId="schedule-call-actions"
          right
          items={[
            {
              icon: editIcon,
              label: 'Edit',
              action: () => setEditedScheduledCall(scheduledCall),
              disabled
            },
            {
              icon: cancelIcon,
              label: 'Cancel',
              action: () => setCancelledScheduledCall(scheduledCall),
              disabled
            }
          ]}
        />
      )}
      {editedScheduledCall?.id === scheduledCall.id && (
        <ScheduleCall
          scheduledCall={editedScheduledCall}
          personGid={scheduledCall.person_gid}
          leadGid={leadGid}
          candidateGid={candidateGid}
          cancelHandler={() => setEditedScheduledCall(null)}
          afterSubmitHandler={() => {
            setEditedScheduledCall(null);
          }}
        />
      )}
      {cancelledScheduledCall?.id === scheduledCall.id && (
        <CancelCallModal
          scheduledCall={cancelledScheduledCall}
          onDiscard={() => setCancelledScheduledCall(null)}
          onConfirm={() => setCancelledScheduledCall(null)}
        />
      )}
    </>
  );
};

export default EditScheduledCall;
