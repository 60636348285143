/** @jsxImportSource @emotion/react */
import React, { useCallback, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import colors from '../../../theme/colors';
import Button, { ButtonSize, ButtonVariant, loaderComponent } from '../buttons/Button';
import { ArrowDownIcon, ArrowUpIcon } from '../icons';
import Text from '../Text';
import { iconCSS, optionCSS, optionsCSS, wrapperCSS } from './DropDown.style';
import { DropDownOption, DropDownPosition, DropDownProps } from './DropDown.utils';

// TODO: refactor to some Combobox component (adobe aria / material)
const DropDown = <TOption extends DropDownOption>({
  children,
  options,
  onSelected,
  size = ButtonSize.Normal,
  optionsSize = ButtonSize.Normal,
  disabled = false,
  loading = false,
  variant = ButtonVariant.Default,
  noArrow = false,
  customCss,
  customOptionsCss,
  iconChildren = false,
  testId,
  staticView = false,
  iconChildrenActiveProperty,
  customButtonCss,
  position = DropDownPosition.Bottom
}: DropDownProps<TOption>): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const clickOutsideHandler = useCallback(() => setShowOptions(false), []);
  useOnClickOutside(dropDownRef, clickOutsideHandler);

  const IconComponent = showOptions ? ArrowUpIcon : ArrowDownIcon;

  const onClickHandler = () => {
    if (options.length === 1) {
      onSelected(options[0]!);
    } else {
      setShowOptions(!showOptions);
    }
  };

  const pickArrowColor = useCallback(() => {
    if (variant === ButtonVariant.Text) {
      return colors.azure50;
    }
    if (variant === ButtonVariant.PlainText) {
      return colors.black;
    }

    return colors.white;
  }, [variant]);

  const renderOption = (option: TOption) => {
    return (
      <li
        key={option.value}
        onClick={() => {
          if (!option.disabled) {
            onSelected(option);
            setShowOptions(false);
          }
        }}
        css={[optionCSS(variant, optionsSize, !!option.disabled), option.customCss]}
      >
        {option.label}
        {option.description && (
          <div>
            <Text color={colors.grey60} type="tiny">
              {option.description}
            </Text>
          </div>
        )}
      </li>
    );
  };

  const iconChildrenActive = iconChildrenActiveProperty ? iconChildrenActiveProperty : { color: colors.azure50 };

  return (
    <div ref={dropDownRef} css={[wrapperCSS(position), customCss]}>
      {iconChildren ? (
        <Button
          variant={ButtonVariant.Text}
          disabled={disabled || loading}
          data-testid={testId}
          onClick={event => {
            event.stopPropagation();
            onClickHandler();
          }}
          customCss={customButtonCss}
        >
          {showOptions ? React.cloneElement(children as JSX.Element, iconChildrenActive) : children}
        </Button>
      ) : (
        <Button
          data-testid={testId}
          size={size}
          disabled={disabled || loading}
          variant={variant}
          onClick={event => {
            event.stopPropagation();
            onClickHandler();
          }}
          customCss={customButtonCss}
        >
          {children}
          {!(disabled || loading || options.length === 1) && !noArrow && (
            <IconComponent data-testid={`${testId}-dropdown-icon`} color={pickArrowColor()} css={iconCSS(size)} />
          )}
          {loaderComponent(loading, size)}
        </Button>
      )}

      {showOptions && (
        <div css={[optionsCSS(staticView, position), customOptionsCss]} key={options[1]?.value}>
          <div>{options.map(option => renderOption(option))}</div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
