/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Translations } from '../../constants';
import { useToggle } from '../../hooks';
import { IMaticPolicy, IPerson } from '../../interfaces';
import { findPolicyTypeLabel } from '../../interfaces/IPolicyType';
import usePersonDeletedPolicies from '../../queries/people/person_policies/usePersonDeletedPolicies';
import usePersonMaticPolicies from '../../queries/people/person_policies/usePersonMaticPolicies';
import usePersonPolicyVersions from '../../queries/people/person_policies/usePersonPolicyVersions';
import useAms360Policy from '../../queries/policies/useAms360Policy';
import usePolicyLinesOfBusiness from '../../queries/policies/usePolicyLinesOfBusiness';
import usePolicyTransactions from '../../queries/policies/usePolicyTransactions';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../utils/formatter';
import { Status } from '../common';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import CollapsingContainer from '../core/CollapsingContainer';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import { ArrowDown2Icon, ArrowRight2Icon, TickIcon } from '../core/icons';
import MultiSelect from '../core/inputs/MultiSelect';
import Modal, { ModalSize } from '../core/Modal';
import PaperTrailItem from '../core/PaperTrailItem';
import Paragraph from '../core/Paragraph';
import Table from '../core/Table/Table';
import Text from '../core/Text';

interface AdminPolicyListProps {
  personGid: IPerson['gid'];
}

const columnHelper = createColumnHelper<IMaticPolicy>();

const RenderSubComponent = ({ row, personGid }: { row: Row<IMaticPolicy>; personGid: string }) => {
  const { data: versions, isSuccess, isPending } = usePersonPolicyVersions({ personGid, policyId: row.original.id });

  return (
    <Container
      customCss={css`
        max-height: 700px;
        overflow: auto;
      `}
    >
      {isPending && (
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <Skeleton height={36} count={4} />
        </SkeletonTheme>
      )}
      {isSuccess && versions.map(version => <PaperTrailItem key={version.id} version={version} />)}
    </Container>
  );
};

const PolicyAMS360Details = ({ policyId }: { policyId: IMaticPolicy['id'] }) => {
  const [isModalOpened, toggleModal] = useToggle(false);
  const { data: ams360Policy } = useAms360Policy(isModalOpened ? policyId : undefined);
  const { data: linesOfBusiness } = usePolicyLinesOfBusiness(isModalOpened ? policyId : undefined);
  const { data: policyTransactions } = usePolicyTransactions(isModalOpened ? policyId : undefined);

  return (
    <>
      <Button size={ButtonSize.Small} variant={ButtonVariant.Text} onClick={toggleModal}>
        Details
      </Button>
      {isModalOpened && (
        <Modal containerTitle="AMS Details" size={ModalSize.extra} cancelHandler={toggleModal} cancelText="Close">
          <Container
            customCss={css`
              max-height: 1000px;
              overflow: auto;
            `}
          >
            {ams360Policy && (
              <>
                <Paragraph bold>AMS 360 policy</Paragraph>
                <pre style={{ fontSize: '14px' }}>
                  <code>{JSON.stringify(ams360Policy, null, 2)}</code>
                </pre>
              </>
            )}
            {linesOfBusiness && (
              <>
                <Paragraph bold>Lines of business</Paragraph>
                <pre style={{ fontSize: '14px' }}>
                  <code>{JSON.stringify(linesOfBusiness, null, 2)}</code>
                </pre>
              </>
            )}
            {policyTransactions && (
              <>
                <Paragraph bold>Policy transactions</Paragraph>
                <pre style={{ fontSize: '14px' }}>
                  <code>{JSON.stringify(policyTransactions, null, 2)}</code>
                </pre>
              </>
            )}
            {!linesOfBusiness && !policyTransactions && (
              <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
                <Skeleton height={36} count={8} />
              </SkeletonTheme>
            )}
          </Container>
        </Modal>
      )}
    </>
  );
};

const tableColumns = [
  columnHelper.display({
    id: 'expander',
    header: () => null,
    cell: ({ row }) => {
      if (!row.getCanExpand()) {
        return null;
      }
      return (
        <Button size={ButtonSize.Small} variant={ButtonVariant.PlainText} onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? <ArrowDown2Icon /> : <ArrowRight2Icon />}
        </Button>
      );
    },
    size: 10
  }),
  columnHelper.display({
    id: 'id',
    cell: ({ row: { original } }) => <Paragraph>{original.id}</Paragraph>,
    header: () => 'ID'
  }),
  columnHelper.display({
    id: 'renewed_id',
    cell: ({ row: { original } }) => <Paragraph>{original.renewed_policy_id}</Paragraph>,
    header: () => 'Renewed policy id'
  }),
  columnHelper.display({
    id: 'policy_number',
    cell: ({ row: { original } }) => <Paragraph>{original.policy_number}</Paragraph>,
    header: () => 'Policy #'
  }),
  columnHelper.display({
    id: 'policy_type',
    cell: ({ row: { original } }) => <Paragraph>{findPolicyTypeLabel(original.policy_type)}</Paragraph>,
    header: () => 'Policy type'
  }),
  columnHelper.display({
    id: 'source',
    cell: ({ row: { original } }) => <Paragraph>{original.source}</Paragraph>,
    header: () => 'Source'
  }),
  columnHelper.display({
    id: 'transaction_type',
    cell: ({ row: { original } }) => <Paragraph>{original.transaction_type}</Paragraph>,
    header: () => 'Transaction type'
  }),
  columnHelper.display({
    id: 'policy_status',
    cell: ({ row: { original } }) => (
      <FlexBox alignItemsCenter>
        <Status
          className={original.status}
          tooltipInfo={{
            reason: Translations.policyCancellationReason(original.cancellation_reason),
            date: dateFormatter(original.cancellation_date)
          }}
        >
          <Text ml={spacings.px4}>{Translations.policyStatus(original.status)}</Text>
        </Status>
      </FlexBox>
    ),
    header: () => 'Status'
  }),
  columnHelper.display({
    id: 'premium',
    cell: ({ row: { original } }) => <Paragraph>{moneyFormatter(original.premium)}</Paragraph>,
    header: () => 'Premium'
  }),
  columnHelper.display({
    id: 'carrier',
    cell: ({ row: { original } }) => <Paragraph>{original.carrier.name}</Paragraph>,
    header: () => 'Carrier'
  }),
  columnHelper.display({
    id: 'sale_date',
    cell: ({ row: { original } }) => <Paragraph>{original.sale_date}</Paragraph>,
    header: () => 'Sale Date'
  }),
  columnHelper.display({
    id: 'effective_date',
    cell: ({ row: { original } }) => <Paragraph>{original.effective_date}</Paragraph>,
    header: () => 'Effective Date'
  }),
  columnHelper.display({
    id: 'expiration_date',
    cell: ({ row: { original } }) => <Paragraph>{original.expiration_date}</Paragraph>,
    header: () => 'Expiration Date'
  }),
  columnHelper.display({
    id: 'created_at',
    cell: ({ row: { original } }) => <Paragraph>{original.created_at}</Paragraph>,
    header: () => 'Created At',
    size: 200
  }),
  columnHelper.display({
    id: 'confirmed_by_ivans',
    cell: ({ row: { original } }) => <Paragraph>{original.last_ams_sync_at ? <TickIcon /> : '-'}</Paragraph>,
    header: () => 'IVANS Sync',
    size: 100
  }),
  columnHelper.display({
    id: 'details',
    cell: ({ row: { original, getCanExpand } }) => {
      if (!getCanExpand()) {
        return null;
      }
      return <PolicyAMS360Details policyId={original.id} />;
    },
    header: () => 'AMS360 Details'
  })
];

const AdminPolicyList = ({ personGid }: AdminPolicyListProps) => {
  const { data: policies } = usePersonMaticPolicies({
    personGid
  });

  const { data: deletedPolicies } = usePersonDeletedPolicies({ personGid });

  const [columnVisibility, setColumnVisibility] = React.useState(() =>
    tableColumns.map(c => c.id!).reduce((acc, id) => ({ ...acc, [id]: true }), {} as Record<string, boolean>)
  );

  const policiesGroups = Object.groupBy(
    policies || [],
    policy => policy.source_policy_id?.toString() || `missing-${policy.policy_type}`
  );

  if (!policies) {
    return null;
  }

  return (
    <FlexBox columnDirection gap={spacings.px16} ph={spacings.px32} pb={spacings.px32}>
      <CollapsingContainer
        preserveChildrenInClosedState
        containerTitle="Columns"
        iconPosition="left"
        customCss={css`
          max-width: 800px;
        `}
      >
        <MultiSelect
          options={tableColumns.map(c => ({ key: c.id, value: c.id }))}
          onChange={(items: string[]) => {
            setColumnVisibility(
              Object.keys(columnVisibility).reduce(
                (acc, key) => {
                  acc[key] = items.includes(key);
                  return acc;
                },
                {} as typeof columnVisibility
              )
            );
          }}
          value={Object.keys(columnVisibility).filter(key => columnVisibility[key])}
        />
      </CollapsingContainer>
      {Object.keys(policiesGroups)
        .toReversed()
        .map(policyId => {
          const policiesGroup = policiesGroups[policyId];

          if (!policiesGroup) {
            return null;
          }

          const lastPolicy = policiesGroup[0];

          return (
            <React.Fragment key={policyId}>
              {lastPolicy && lastPolicy.assets && (
                <Paragraph color={isNaN(Number(policyId)) ? colors.statusRed : undefined}>
                  Source Policy ID - {policyId} {lastPolicy.assets.map(a => a.description).join(', ')}
                </Paragraph>
              )}
              <Table
                data={policiesGroup}
                columns={tableColumns}
                testId="admin-policy-table"
                renderSubComponent={props => <RenderSubComponent {...props} personGid={personGid} />}
                tableOptions={{
                  getRowCanExpand: () => true,
                  state: {
                    columnVisibility
                  }
                }}
              />
            </React.Fragment>
          );
        })}

      {deletedPolicies && deletedPolicies.length > 0 && (
        <>
          <Paragraph bold>Deleted Policies</Paragraph>
          <Table
            data={deletedPolicies}
            columns={tableColumns}
            testId="admin-deleted-policy-table"
            renderSubComponent={props => <RenderSubComponent {...props} personGid={personGid} />}
            tableOptions={{
              getRowCanExpand: () => true,
              state: {
                columnVisibility
              }
            }}
          />
        </>
      )}
    </FlexBox>
  );
};

export default AdminPolicyList;
