/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';

import useToggle from '../../../hooks/useToggle';
import usePersonDNC, { PERSON_DNC_QUERY_KEY, useUpdateDnc } from '../../../queries/people/dncStatus';
import authInfo from '../../../services/authInfo';
import Tooltip from '../../common/Tooltip/NewTooltip';
import FlexBox from '../../core/FlexBox';
import Checkbox from '../../core/inputs/Checkbox';
import Modal from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

const ContactPreferences = ({ personGid }: { personGid: string }) => {
  const { data: doNotContactStatus, isPending: isPendingPersonDNC } = usePersonDNC(personGid);
  const [modalIsShown, toggleModal] = useToggle(false);
  const { mutateAsync, isPending: isUpdatingPersonDNC } = useUpdateDnc();
  const queryClient = useQueryClient();
  const allowedToTurnOff = authInfo.features.do_not_contact_editable;

  const confirmHandler = () => {
    const modalIsShownClosure = modalIsShown;
    return mutateAsync({ personGid, do_not_contact: !doNotContactStatus }).then(() => {
      modalIsShownClosure && toggleModal();
      return queryClient.setQueryData([PERSON_DNC_QUERY_KEY, personGid], { dnc_status: !doNotContactStatus });
    });
  };

  return (
    <FlexBox pl={12} columnDirection>
      <Paragraph bold type="large">
        Contact Preferences
      </Paragraph>
      <div data-for="dnc-disabled" data-tip="Only manager can remove do not contact status">
        <Checkbox
          checked={!!doNotContactStatus}
          id="dnc-status"
          label="Do not contact"
          disabled={isUpdatingPersonDNC || isPendingPersonDNC || (!allowedToTurnOff && !!doNotContactStatus)}
          onChange={allowedToTurnOff ? confirmHandler : toggleModal}
        />
      </div>
      {!allowedToTurnOff && !!doNotContactStatus && <Tooltip id="dnc-disabled" />}
      {modalIsShown && (
        <Modal
          confirmationInProgress={isUpdatingPersonDNC}
          confirmHandler={confirmHandler}
          cancelHandler={toggleModal}
          containerTitle="Confirmation"
          confirmText="Confirm"
        >
          <Text
            customCss={css`
              white-space: break-spaces;
            `}
          >
            {'Are you sure you want to set "do not contact" status?\nOnly manager role can remove this status'}
          </Text>
        </Modal>
      )}
    </FlexBox>
  );
};

export default ContactPreferences;
