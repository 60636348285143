import { useMutation, useQuery } from '@tanstack/react-query';

import { ILoan } from '../../interfaces/ILoan';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const PERSON_LOANS_QUERY_KEY = 'person_loans';
export interface UpdateLoanRequest {
  purpose?: string;
  amount?: number | string;
  est_closing_date?: string;
  loan_number?: string;

  lender_gid?: string;
  servicer_gid?: string;

  officer_gid?: string;
  officer_first_name?: string;
  officer_last_name?: string;
  officer_email?: string;
  officer_phone?: string;

  processor_gid?: string;
  processor_first_name?: string;
  processor_last_name?: string;
  processor_email?: string;
  processor_phone?: string;
}

interface CreateLoanRequest extends UpdateLoanRequest {
  asset_gid: string;
}

export const createLoan = ({
  personGid,
  createLoanRequest
}: {
  personGid: string;
  createLoanRequest: CreateLoanRequest;
}) => api.post(`/api/frontend/people/${personGid}/loans`, { body: createLoanRequest });

export const updateLoan = ({
  personGid,
  loanGid,
  updateLoanRequest
}: {
  personGid: string;
  loanGid: string;
  updateLoanRequest: UpdateLoanRequest;
}) => api.put(`/api/frontend/people/${personGid}/loans/${loanGid}`, { body: updateLoanRequest });

const usePersonLoans = ({ personGid, assetGid }: { personGid?: string; assetGid?: string }) =>
  useQuery({
    queryKey: [PERSON_LOANS_QUERY_KEY, personGid, assetGid],
    queryFn: (): Promise<{ loans: ILoan[] }> =>
      api.get(`/api/frontend/people/${personGid}/loans?${toQueryParams({ asset_gid: assetGid })}`),
    enabled: !!personGid,
    select: data => data.loans
  });

export const useCreateLoan = () => useMutation({ mutationFn: createLoan });

export default usePersonLoans;
