/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../components/core/Container';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useScoutingStrategy } from '../../../queries/scouting_strategies/useScoutingStrategy';
import { isSuperISRFlowEnabled } from '../../../services/authInfo';
import { spacings } from '../../../theme/variables';
import CrossSellWarning from '../GuidedDataCollection/_components/CrossSellWarning';
import ScoutingStrategy from './ScoutingStrategy';
import ScoutingStrategySkeleton from './ScoutingStrategy/ScoutingStrategySkeleton';

const ScoutingReport = () => {
  const { callLogId, personGid, candidateGid, leadGid } = useGuidedSellingExperienceContext();
  const superISRFlow = isSuperISRFlowEnabled();

  const { data: scoutingStrategy, isFetching: isFetchingStrategy } = useScoutingStrategy({
    callLogId,
    personGid,
    leadGid,
    candidateGid
  });

  const isPreparingScoutingStrategy = isFetchingStrategy || !scoutingStrategy;

  return (
    <Container
      ph={superISRFlow ? spacings.px0 : spacings.px32}
      fitParentWidth
      customCss={css`
        max-width: 880px;
      `}
    >
      {isPreparingScoutingStrategy ? (
        <ScoutingStrategySkeleton />
      ) : (
        <>
          <CrossSellWarning />
          <ScoutingStrategy scoutingStrategy={scoutingStrategy} />
        </>
      )}
    </Container>
  );
};

export default ScoutingReport;
