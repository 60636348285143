/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import DropDown from '../../../components/core/DropDown/DropDown';
import { DropDownPosition } from '../../../components/core/DropDown/DropDown.utils';
// eslint-disable-next-line max-len
import NewTrailingOpportunitiesModal from '../../../components/DispositionsModals/TrailingOpportunitiesModal/NewTrailingOpportunitiesModal';
import featureFlags from '../../../constants/featureFlags';
import { DispositionType } from '../../../interfaces/IDisposition';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { UserRoleCategory } from '../../../interfaces/IUser';
import useLeadOpportunities from '../../../queries/leads/opportunities/useLeadOpportunities';
import { useActiveScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import authInfo, { isISR, isSuperISRFlowEnabled } from '../../../services/authInfo';
import { useCurrentDispositionSubscriptionContext } from '../_hooks';
import useCancelActiveCall from '../_hooks/useCancelActiveCall';
import { GuidedSellingPathnames } from '../navigation';

const FinalizeLead = ({ leadId, personGid }: { leadId: number; personGid: string }) => {
  const [trailingOpportunitiesModalOpened, setTrailingOpportunitiesModalOpened] = React.useState(false);
  const { setCancelCallState } = useCancelActiveCall();

  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: opportunities, refetch: refetchOpportunities } = useLeadOpportunities({ leadId });
  const { data: scheduledCalls } = useActiveScheduledInteractions(personGid);
  const uncompletedCall = scheduledCalls?.[0];

  const { currentDisposition, isUpdatingDisposition } = useCurrentDispositionSubscriptionContext();

  const superISRFlow = isSuperISRFlowEnabled();
  const isLoading = !opportunities || isUpdatingDisposition;
  const disableFinalizeDropdown =
    currentDisposition?.assignee?.id !== authInfo.currentUserId && authInfo.roleCategory === UserRoleCategory.Agent;
  const atLeastOneOpportunitySold = !!opportunities?.find(opp => opp.status === OpportunityStatus.Sold);
  const showFinalizeButton =
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified) &&
    (!isISR() || superISRFlow);

  return (
    <>
      {showFinalizeButton && (
        <>
          {atLeastOneOpportunitySold && !superISRFlow ? (
            <Button
              data-testid="finalize-lead-button"
              customCss={css`
                min-width: 150px;
              `}
              onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}
            >
              Finalize
            </Button>
          ) : (
            <div data-tip="Lead should be assigned to you" data-for="assign-to-self-tip">
              {superISRFlow ? (
                <Button
                  disabled={disableFinalizeDropdown}
                  variant={ButtonVariant.SecondaryDanger}
                  onClick={() => setTrailingOpportunitiesModalOpened(true)}
                  loading={isLoading}
                  customCss={css`
                    white-space: nowrap;
                  `}
                >
                  All lost
                </Button>
              ) : (
                <DropDown
                  testId="finalize-lead-dropdown"
                  options={[
                    { label: 'At least one sold', value: DispositionType.PolicySold },
                    !featureFlags.cstGuidedSellingExperience && { label: 'All lost', value: DispositionType.Lost }
                  ].filter(Boolean)}
                  disabled={disableFinalizeDropdown}
                  loading={isLoading}
                  onSelected={selected => {
                    if (selected.value === DispositionType.PolicySold) {
                      return navigate(`${GuidedSellingPathnames.Finalize}${search}`);
                    } else {
                      setTrailingOpportunitiesModalOpened(true);
                    }
                  }}
                  customButtonCss={css`
                    min-width: 150px;
                  `}
                  position={DropDownPosition.Top}
                >
                  Finalize
                </DropDown>
              )}
              {disableFinalizeDropdown && <Tooltip id="assign-to-self-tip" />}
            </div>
          )}
        </>
      )}
      {trailingOpportunitiesModalOpened && opportunities && (
        <NewTrailingOpportunitiesModal
          leadId={leadId}
          opportunities={opportunities}
          dispositionType={DispositionType.Lost}
          closeModal={() => {
            setTrailingOpportunitiesModalOpened(false);
          }}
          onDispositionUpdated={() => {
            refetchOpportunities().then(() => {
              uncompletedCall && setCancelCallState(uncompletedCall, personGid, currentDisposition?.assignee || null);

              if (featureFlags.displayDisqualifyButton) {
                const path = isSuperISRFlowEnabled()
                  ? GuidedSellingPathnames.ISRFinish
                  : GuidedSellingPathnames.AgentFinish;
                navigate(`${path}${search}`);
              } else {
                navigate(`${GuidedSellingPathnames.Finalize}${search}`);
              }
            });
          }}
        />
      )}
    </>
  );
};

export default FinalizeLead;
