import { useQueryClient } from '@tanstack/react-query';

import Modal from '../../components/core/Modal';
import { IScheduledCall } from '../../interfaces';
import useCancelScheduledInteraction from '../../queries/scheduled_calls/useCancelScheduledInteraction';
import {
  PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY,
  SCHEDULED_INTERACTIONS_QUERY_KEY
} from '../../queries/scheduled_calls/useScheduledInteractions';

const CancelCallModal = ({
  scheduledCall,
  onConfirm,
  onDiscard
}: {
  scheduledCall: IScheduledCall;
  onConfirm: () => void;
  onDiscard: () => void;
}) => {
  const { mutateAsync: cancelCall, isPending: cancellingCall } = useCancelScheduledInteraction(() => {});
  const queryClient = useQueryClient();

  return (
    <Modal
      containerTitle={
        (
          <>
            Cancel call with <span className="fs-mask">{scheduledCall.person_name}</span>?
          </>
        ) as any
      }
      confirmText="Cancel call"
      cancelText="Discard"
      cancelHandler={() => onDiscard()}
      confirmationInProgress={cancellingCall}
      confirmHandler={() => {
        cancelCall({
          personGid: scheduledCall.person_gid,
          contactFlowId: scheduledCall.id,
          dialerActionId: scheduledCall.action_type ? scheduledCall.id : undefined
        }).then(() => {
          onConfirm();
          queryClient.invalidateQueries({ queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY] });
          queryClient.invalidateQueries({ queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY] });
        });
      }}
    >
      You are going to cancel the call. It means that the customer will be excluded from the call cadence.
    </Modal>
  );
};

export default CancelCallModal;
