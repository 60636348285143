import * as React from 'react';

import { usePlannedCallState } from '../../contexts/PlannedCallContext';

const useCallCountdown = ({ personGid }: { personGid: string }) => {
  const { currentPlannedCall } = usePlannedCallState();

  const targetTimestamp = React.useMemo(() => {
    return currentPlannedCall ? new Date(currentPlannedCall.preview_timestamp) : new Date();
  }, [currentPlannedCall]);

  const calculateTimeLeft = React.useCallback(() => {
    const now = new Date().getTime();
    const difference = targetTimestamp.getTime() - now;
    return difference > 0 ? difference : 0;
  }, [targetTimestamp]);

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const displayTimer = timeLeft > 0 && personGid === currentPlannedCall?.person_gid;
  const formattedTime = formatTime(timeLeft);

  return {
    displayTimer,
    formattedTime
  };
};

export default useCallCountdown;
