/** @jsxImportSource @emotion/react */

import { createColumnHelper } from '@tanstack/react-table';

import { InvoiceIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import { IDashboardLead, IPartner } from '../../interfaces';
import colors from '../../theme/colors';
import { minutesAndHoursToNow } from '../../utils/formatter';
import getStateFullName from '../../utils/stateFullName';
import getCustomCellContent, { LeadsDashboardColumnKeys } from './DashboardTable.util';

const columnHelper = createColumnHelper<IDashboardLead>();

export const columns = (partners?: IPartner[]) => [
  columnHelper.display({
    id: LeadsDashboardColumnKeys.LeadCadence,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.LeadCadence, original),
    enableSorting: true,
    size: 40
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.LeadID,
    size: 100,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.LeadID, original),
    header: () => 'Lead ID'
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.CustomerName,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.CustomerName, original),
    header: () => 'Customer Name',
    size: 320
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.Partner,
    size: 246,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.Partner, original, partners),
    header: () => 'Partner',
    enableSorting: true
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.LastInteraction,
    cell: ({
      row: {
        original: { last_interaction_attempt }
      }
    }) => (
      <Paragraph color={last_interaction_attempt ? undefined : colors.grey30}>
        {last_interaction_attempt ? `${minutesAndHoursToNow(last_interaction_attempt)} ago` : 'Not interacted yet'}
      </Paragraph>
    ),
    header: () => 'Last interacted',
    size: 170,
    enableSorting: true
  }),

  columnHelper.display({
    size: 140,
    id: LeadsDashboardColumnKeys.CreatedAt,
    cell: ({
      row: {
        original: { created_at }
      }
    }) => <Paragraph>{`${minutesAndHoursToNow(created_at)}`}</Paragraph>,
    header: () => 'Age',
    enableSorting: true
  }),
  columnHelper.display({
    size: 120,
    id: LeadsDashboardColumnKeys.ResidentialState,
    cell: ({ row: { original } }) => <Paragraph>{getStateFullName(original.residential_state)}</Paragraph>,
    header: () => 'State',
    enableSorting: true
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.PhoneNumber,
    size: 200,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.PhoneNumber, original),
    header: () => 'Phone number'
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.DispositionType,
    size: 160,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.DispositionType, original),
    header: () => 'Disposition',
    enableSorting: true
  }),
  columnHelper.display({
    id: LeadsDashboardColumnKeys.LeadProposal,
    size: 66,
    cell: ({ row: { original } }) => getCustomCellContent(LeadsDashboardColumnKeys.LeadProposal, original),
    header: () => <InvoiceIcon />,
    enableSorting: true
  })
];
