/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { toast } from 'react-toastify';

import notificationSound from '../../assets/audio/call_notification.wav';
import IPlannedCallLog from '../../interfaces/IPlannedCallLog';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { Link } from '../common';
import alert from '../core/Alert';
import Button, { ButtonSize } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import { HourglassIcon } from '../core/icons';
import Text from '../core/Text';

export const onPlannedCallLog = ({ plannedCall }: { plannedCall: IPlannedCallLog }) => {
  const audio = new Audio(notificationSound);
  audio.play();

  alert({
    title: 'Call with the customer starts in 1 minute.',
    message: (
      <FlexBox columnDirection>
        <Text color={colors.grey60} type="small">
          Review proposal & last call notes before calling.
        </Text>
        <FlexBox
          gap={spacings.px12}
          mt={spacings.px16}
          css={css`
            flex-direction: row-reverse;
          `}
        >
          <Button size={ButtonSize.Small}>
            <Link
              to={`/scouting_report?lead_gid=${plannedCall.lead_gid}`}
              css={css`
                color: ${colors.white};
                &:hover {
                  color: ${colors.white};
                }
              `}
              onClick={() => toast.dismiss(plannedCall.external_id)}
            >
              Get prepared
            </Link>
          </Button>
        </FlexBox>
      </FlexBox>
    ),
    options: { icon: <HourglassIcon color={colors.azure50} />, toastId: plannedCall.external_id, autoClose: 60000 }
  }).info();
};
