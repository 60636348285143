import CancelTrailingScheduledCallModal from '../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import useCancelActiveCall from '../_hooks/useCancelActiveCall';

const CancelActiveCallModal = () => {
  const { activeCall, personGid, assignee, reset } = useCancelActiveCall();

  if (activeCall && personGid) {
    return (
      <CancelTrailingScheduledCallModal
        personGid={personGid}
        scheduledCall={activeCall}
        assignee={assignee}
        onCloseModal={() => reset()}
      />
    );
  }

  return null;
};

export default CancelActiveCallModal;
