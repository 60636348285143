import { IUserWithRole } from './IUser';

export enum CallStatus {
  Ready = 'ready',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
  InDialer = 'in_dialer',
  CallStarted = 'call_started',
  ContactMade = 'contact_made',
  ContactFailed = 'contact_failed',
  InProgress = 'in_progress'
}

export enum ScheduledInteractionFlow {
  Calendly = 'calendly',
  ScheduledCall = 'scheduled_call', // Legacy ISR scheduled call
  ScheduledCallIsrs = 'scheduled_call_isrs', // New ISR scheduled call
  ScheduledCallV2 = 'scheduled_call_v2', // Agent scheduled call
  ScheduledCallEmail = 'scheduled_call_email', // Call scheduled by customer via email
  ScheduledByRecapture = 'abandoned_qualified_lead_recapture', // Scheduled by recapture method
  CalendlyFollowUp = 'calendly_follow_up',
  PipelineLowPriority = 'pipeline_low_priority',
  PipelineHighPriority = 'pipeline_high_priority',
  PipelineUnderwriting = 'pipeline_underwriting'
}

export enum ActionType {
  Call = 'call',
  SMS = 'sms'
}

export interface IScheduledCall {
  id: number;
  created_at: string;
  run_at: string;
  phone: string;
  action_type: ActionType;
  flow: ScheduledInteractionFlow;
  person_gid: string;
  engagement_gid: string | undefined;
  person_name: string;
  last_lead_id: number | null;
  status: CallStatus;
  assignee: IUserWithRole | null;
  assignor: IUserWithRole | null;
  proposal_builder_url: string | null;
}
