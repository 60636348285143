/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import { InfoIcon } from '../../../../../components/core/icons';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import { isPersonMarried } from '../../../../../interfaces/IPerson';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import { IQuotingConfigurationFormValues } from '../_interfaces/IQuotingConfigurationOpportunity';
import { PAF_TIP } from '../_utils';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import OpportunityCustomerChoice from '../OpportunityCustomerChoice';
import PAFCoverage from './PAFCoverage';

export const pafOpportunityInitialCoverages = (
  opportunity: ConfigurationComponentProps['opportunity']
): ConfigurationComponentProps['opportunity']['agent_selected_coverages'] => {
  return (
    opportunity.agent_selected_coverages || [
      {
        asset_gid: opportunity.assets?.[0]?.gid || null,
        coverages_tier: OpportunityCoveragesTier.Quoted,
        coverages: null
      }
    ]
  );
};

const PAFOpportunity = ({ index, person, opportunity, refetchCurrentPolicies }: ConfigurationComponentProps) => {
  const { values } = useFormikContext<IQuotingConfigurationFormValues>();
  const opportunityValues = values.opportunities[opportunity.id.toString()]!;

  const isOpportunityEnabled = opportunityValues.enabled;

  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px12}
      justifySpaceBetween
      data-testid={`quoting-configuration-opportunity-${index}`}
    >
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <CheckboxField
          inline
          key={`opportunities.${opportunity.id}.enabled`}
          id={`opportunities.${opportunity.id}.enabled`}
          name={`opportunities.${opportunity.id}.enabled`}
          disabled={!!opportunity.disqualification_reason}
          label={
            <FlexBox gap={spacings.px8} alignItemsCenter>
              <Text bold type="large">
                {opportunity.title}
              </Text>
              {opportunity.primary && (
                <Tag
                  label="Primary opportunity"
                  transparent
                  backgroundColor={colors.violet}
                  textColor={colors.violet}
                  mt={spacings.px4}
                />
              )}
              {isPersonMarried(person.marital_status) && !noAppointedCarriers && (
                <>
                  <Tag
                    data-for="paf-recommendation"
                    data-tip={PAF_TIP}
                    label={
                      <FlexBox gap={spacings.px8} alignItemsCenter>
                        Recommended
                        <InfoIcon height={spacings.px16} width={spacings.px16} />
                      </FlexBox>
                    }
                    transparent
                    backgroundColor={colors.statusGreen}
                    textColor={colors.statusGreen}
                    mt={spacings.px4}
                  />
                  <Tooltip id="paf-recommendation" />
                </>
              )}
            </FlexBox>
          }
        />
        {isOpportunityEnabled && <PAFCoverage opportunity={opportunity} person={person} />}
        {noAppointedCarriers && (
          <FlexBox>
            <SystemMessage
              type="warning"
              // eslint-disable-next-line max-len
              heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
            />
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox gap={spacings.px12} columnDirection>
        <OpportunityCustomerChoice opportunity={opportunity} />
        <OpportunityCurrentInsurance
          person={person}
          opportunity={opportunity}
          refetchCurrentPolicies={refetchCurrentPolicies}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default PAFOpportunity;
