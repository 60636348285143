import React from 'react';

import Copy from '../../../../components/core/Copy';
import DescriptionList from '../../../../components/core/DescriptionList';
import Text from '../../../../components/core/Text';
import { ILoan } from '../../../../interfaces';
import { spacings } from '../../../../theme/variables';
import { phoneFormatter } from '../../../../utils/formatter';

const LoanPersonInfo = ({ title, person }: { title: string; person: ILoan['officer'] | ILoan['processor'] }) => {
  return (
    <>
      <Text bold ml={spacings.px4}>
        {title}
      </Text>
      {person?.first_name && (
        <DescriptionList
          term={<Text>First name</Text>}
          details={
            <Copy className="fs-mask" value={person.first_name}>
              <Text>{person.first_name}</Text>
            </Copy>
          }
        />
      )}
      {person?.last_name && (
        <DescriptionList
          term={<Text>Last name</Text>}
          details={
            <Copy className="fs-mask" value={person.last_name}>
              <Text>{person.last_name}</Text>
            </Copy>
          }
        />
      )}
      {person?.phone && (
        <DescriptionList
          term={<Text>Phone</Text>}
          details={
            <Copy className="fs-mask" value={phoneFormatter(person.phone)}>
              <Text>{phoneFormatter(person.phone)}</Text>
            </Copy>
          }
        />
      )}
      {person?.email && (
        <DescriptionList
          term={<Text>Email</Text>}
          details={
            <Copy className="fs-mask" value={person.email}>
              <Text>{person.email}</Text>
            </Copy>
          }
        />
      )}
    </>
  );
};

export default LoanPersonInfo;
