import { keepPreviousData, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

import { IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import api from '../../services/api';
import { isEmpty } from '../../utils/object';
import { toQueryParams } from '../../utils/queryParams';

export const PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY = 'person_scheduled_interactions';
export const SCHEDULED_INTERACTIONS_QUERY_KEY = 'scheduled_interactions';
export const SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY = 'scheduled_interactions_contact_flows';

interface IScheduledInteractionsParams {
  phone: string;
  run_at: string;
  assignee_id: number | string;
  note: string;
  lead_gid?: string;
  candidate_gid?: string;
  dialer_action_id?: string | number;
  flow_key?: ScheduledInteractionFlow;
  proposal_url?: string;
}

interface IMutateScheduledInteractionsArgs {
  personGid: string;
  params: IScheduledInteractionsParams;
  scheduledCallId?: number;
}

//post

const createScheduledInteractions = async ({
  personGid,
  params
}: IMutateScheduledInteractionsArgs): Promise<unknown> => {
  return await api.post(`/api/frontend/people/${personGid}/scheduled_interactions`, { body: params });
};

export const useCreateScheduledInteractions = (
  options?: Omit<UseMutationOptions<unknown, unknown, IMutateScheduledInteractionsArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: createScheduledInteractions, ...options });

//put

const updateScheduledInteractions = async ({
  personGid,
  scheduledCallId,
  params
}: IMutateScheduledInteractionsArgs): Promise<unknown> => {
  return await api.put(`/api/frontend/people/${personGid}/scheduled_interactions/${scheduledCallId}`, { body: params });
};

export const useUpdateScheduledInteractions = (
  options?: Omit<UseMutationOptions<unknown, unknown, IMutateScheduledInteractionsArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: updateScheduledInteractions, ...options });

//get

export const usePersonScheduledInteractions = (gid?: string, statuses?: Array<string>) =>
  useQuery({
    queryKey: [PERSON_SCHEDULED_INTERACTIONS_QUERY_KEY, gid, statuses],
    queryFn: (): Promise<{ scheduled_interactions: IScheduledCall[] }> =>
      api.get(
        '/api/frontend/scheduled_interactions/contact_flows?' + `${toQueryParams({ person_gid: gid, statuses })}`
      ),
    enabled: !!gid,
    select: data => data.scheduled_interactions
  });

export const useActiveScheduledInteractions = (personGid?: string) =>
  usePersonScheduledInteractions(personGid, ['in_progress']);

export const useCompletedScheduledInteractions = (personGid?: string) =>
  usePersonScheduledInteractions(personGid, ['finished', 'cancelled', 'not_started']);

interface TimeSportsForAgentParams {
  date: string;
  person_gid: string;
  engagement_gid: string;
  spot_duration?: number;
}

export type TimeSpot =
  | {
      open: false;
      reason: 'past_time' | 'customer_timezone' | 'off_time' | 'lunch' | 'meeting' | 'another_call_scheduled';
    }
  | { open: true };

type TimeSpots = Record<string, TimeSpot>;

export const usePersonTimeSpots = ({ date, person_gid, engagement_gid, spot_duration }: TimeSportsForAgentParams) =>
  useQuery({
    queryKey: ['time-spots-for-agent', date, person_gid, engagement_gid, spot_duration],
    enabled: Boolean(date && person_gid && engagement_gid),
    queryFn: (): Promise<{ time_spots: TimeSpots; engagement_timezone: string }> =>
      api.get(
        `/api/frontend/people/${person_gid}/scheduled_interactions/time_spots?` +
          `${toQueryParams({ date, engagement_gid, spot_duration })}`
      )
  });

export interface Filter {
  page: number;
  assignees_emails?: string[];
  min_run_at?: Date | string;
  max_run_at?: Date | string;
  statuses?: string[];
  flows?: ScheduledInteractionFlow[];
}

export interface DashboardFilter extends Filter {
  flows: undefined;
}

interface PaginatedCalls {
  page: number | null;
  scheduled_interactions: IScheduledCall[];
  total_pages: number;
}

export const scheduledInteractionsQueryFn = (filter: Filter) => (): Promise<PaginatedCalls> =>
  api.get(`/api/frontend/scheduled_interactions?${toQueryParams(filter)}`);

// be aware that queryKey change impacts <ScheduledCalls />
const useScheduledInteractions = (filter: Filter) =>
  useQuery({
    queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY, filter],
    queryFn: scheduledInteractionsQueryFn(filter),
    enabled: !isEmpty(filter),
    placeholderData: keepPreviousData
  });

export const scheduledInteractionsContactFlowsQueryFn = (filter: Filter) => (): Promise<PaginatedCalls> =>
  api.get(`/api/frontend/scheduled_interactions/contact_flows?${toQueryParams(filter)}`);

export const useScheduledInteractionsContactFlows = (filter: Filter) =>
  useQuery({
    queryKey: [SCHEDULED_INTERACTIONS_CONTACT_FLOWS_QUERY_KEY, filter],
    queryFn: scheduledInteractionsContactFlowsQueryFn(filter)
  });

export default useScheduledInteractions;
