/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import debounce from 'debounce-promise';
import { getIn, setIn, useFormikContext } from 'formik';
import * as React from 'react';

import { CITY, SMARTY_LINE1, SMARTY_ZIP, STATE } from '../../../../../constants/addressForm';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { IAddressSuggestion, ISuggestionResponse } from '../../../../../interfaces';
import { QuestionVerificationStatus } from '../../../../../interfaces/IWorkflow';
import streetsService from '../../../../../services/streets';
import { InputSize, LabelSize } from '../../../../../theme/variables';
import Input from '../../../inputs/Base';
import FormError from '../FormError';
import LabeledInput from '../LabeledInput';
import { address as addressCSS, addressMenu, loading, menuList } from './Address.style';
import AddressOption, { ADDRESS_OPTION_TESTID } from './AddressOption';
import { azureColor } from './AddressOption.style';
import FallbackAddress from './FallbackAddress';

export interface AddressProps {
  defaultValue?: IAddressSuggestion;
  placeholder?: string;
  defaultOptions?: IAddressSuggestion[];
  fallbackNames: Record<string, `${AddressProps['id']}.${string}`>;
  label: string;
  id: string;
  required?: boolean;
  testId?: string;
  inline?: boolean;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  inputSize?: InputSize;
  labelSize?: LabelSize;
  disabled?: boolean;
  manualEntryAllowed?: boolean;
  verificationStatus?: QuestionVerificationStatus;
}

interface Options {
  data: IAddressSuggestion[];
}

const AddressField = ({
  label,
  placeholder,
  defaultValue,
  fallbackNames,
  defaultOptions = [],
  id,
  required = false,
  testId,
  inline = false,
  secondary = false,
  customLabelCss,
  inputSize = InputSize.Medium,
  labelSize,
  manualEntryAllowed = true,
  disabled,
  verificationStatus
}: AddressProps): JSX.Element => {
  const { setFieldTouched, errors, touched, setTouched, setValues, submitCount } = useFormikContext();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const addressRef = React.useRef<HTMLDivElement>(null);
  const showDevError = React.useRef<boolean>(true);

  const [isFocused, setFocused] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [showManually, setShowManually] = React.useState(false);
  const [options, setOptions] = React.useState<Options>({ data: defaultOptions });

  const clickOutsideHandler = React.useCallback(() => isFocused && setFocused(false), [isFocused]);
  useOnClickOutside(addressRef, clickOutsideHandler);

  React.useEffect(() => {
    if (showDevError.current && import.meta.env.DEV && !Object.values(fallbackNames)[0]!.startsWith(`${id}.`)) {
      /* eslint-disable no-console */
      console.error(
        'Incorrect id and fallbackNames props, look at AddressField implementation',
        '\n',
        Object.values(fallbackNames)[0],
        `should start with ${id}.`
      );
      /* eslint-enable no-console */

      showDevError.current = false;
    }
  }, [id, fallbackNames]);

  const submitCountRef = React.useRef<number>(submitCount);

  React.useEffect(() => {
    if (submitCountRef.current !== submitCount) {
      submitCountRef.current = submitCount;
      Object.keys(fallbackNames).forEach(name => setFieldTouched(fallbackNames[name]!));
    }
  }, [fallbackNames, setFieldTouched, submitCount]);

  const optionCallback = (option: IAddressSuggestion) => {
    const newValues = {} as any;
    Object.keys(fallbackNames).forEach(name => {
      newValues[fallbackNames[name]!.replace(`${id}.`, '')] = option[name as keyof IAddressSuggestion] || '';
    });

    setValues((values: any) => setIn(values, id, newValues));
  };

  const addressError = () => {
    return (
      [SMARTY_LINE1, CITY, STATE, SMARTY_ZIP].map(name => getIn(errors, fallbackNames[name]!)).find(Boolean) ||
      getIn(errors, id)
    );
  };

  const getDefaultValueString = () => {
    if (!defaultValue) {
      return '';
    }

    return [
      defaultValue.street_line,
      defaultValue.secondary,
      defaultValue.city,
      defaultValue.state,
      defaultValue.zipcode
    ]
      .filter(Boolean)
      .join(' ')
      .trim();
  };

  const defaultValueRef = React.useRef<string>(getDefaultValueString());

  const onInput = debounce((inputValue: string) => {
    if (inputValue) {
      setLoading(true);

      streetsService
        .suggest(inputValue)
        .then((response: ISuggestionResponse) => {
          setOptions({ data: response.data.suggestions || defaultOptions });
        })
        .catch(() => {
          setOptions({ data: defaultOptions });
        })
        .finally(() => setLoading(false));
    } else {
      optionCallback({ street_line: '', secondary: '', city: '', zipcode: '', state: '', entries: 0 });
      setOptions({ data: defaultOptions });
    }
  }, 500);

  const onSelect = (option: IAddressSuggestion, optionString: string) => {
    if (+option.entries > 1) {
      setLoading(true);
      streetsService
        .suggestSecondary(inputRef.current?.value as string, optionString)
        .then((response: ISuggestionResponse) => setOptions({ data: response.data.suggestions || defaultOptions }))
        .catch(() => setOptions({ data: defaultOptions }))
        .finally(() => setLoading(false));

      if (inputRef.current) {
        inputRef.current.value = option.street_line;
        inputRef.current.focus();
      }
    } else {
      if (inputRef.current) {
        inputRef.current.value = optionString;
      }

      optionCallback(option);
      setFocused(false);
    }
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValueRef.current;
    }
  }, []);

  const onManuallySelected = () => {
    setShowManually(true);
    setFocused(false);
    setOptions({ data: defaultOptions });
  };

  const showError = !!addressError();
  const someFieldTouched = [SMARTY_LINE1, CITY, STATE, SMARTY_ZIP]
    .map(name => getIn(touched, fallbackNames[name]!))
    .find(Boolean);
  const when = showError && someFieldTouched && !showManually;

  return (
    <LabeledInput
      id={id}
      label={label}
      required={required}
      inline={inline}
      secondary={secondary}
      disabled={disabled}
      customCss={customLabelCss}
      inputSize={inputSize}
      labelSize={labelSize}
      verificationStatus={verificationStatus}
    >
      <div css={addressCSS} ref={addressRef} data-testid={testId || 'address'}>
        <Input
          id={id}
          autoComplete="off"
          onInput={e => {
            onInput((e.target as HTMLInputElement).value);
          }}
          onFocus={() => setFocused(true)}
          onBlur={event => {
            if (
              event.relatedTarget &&
              event.relatedTarget instanceof HTMLButtonElement &&
              event.relatedTarget.dataset.testid === ADDRESS_OPTION_TESTID
            ) {
              return;
            }

            const newTouchedValues = {} as any;
            Object.keys(fallbackNames).forEach(name => {
              newTouchedValues[fallbackNames[name]!.replace(`${id}.`, '')] = true;
            });

            setTouched({ ...touched, [id]: { ...newTouchedValues } });
          }}
          placeholder={placeholder || (inline ? '—' : '')}
          inputRef={inputRef}
          data-testid="address-input"
          disabled={disabled || showManually}
          hasError={when}
          fsMask
          inline={inline}
          inputSize={inputSize}
        />
        {inline ? (
          when && <FormError id={id} hasError={when} error={addressError()} />
        ) : (
          <FormError id={id} hasError={when} error={addressError()} />
        )}
        {(!!options.data.length || manualEntryAllowed) && (
          <div css={addressMenu(isFocused, inputSize)} data-testid="address-menu">
            <div css={menuList} role="listbox" aria-label="Address menu list">
              {isLoading && <div css={loading}>Loading...</div>}
              {options.data.map(option => (
                <AddressOption
                  key={JSON.stringify(option)}
                  inputValue={inputRef.current?.value}
                  option={option}
                  onSelect={onSelect}
                />
              ))}

              {!isLoading && manualEntryAllowed && (
                <AddressOption
                  key="Manual"
                  className="fs-mask"
                  customCss={azureColor}
                  onSelect={onManuallySelected}
                  option={{
                    street_line: 'Enter manually',
                    secondary: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    entries: ''
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {showManually && (
        <FallbackAddress
          label={label}
          names={fallbackNames}
          hideFallback={() => {
            setShowManually(false);
            optionCallback({ street_line: '', secondary: '', city: '', zipcode: '', state: '', entries: 0 });
            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }}
        />
      )}
    </LabeledInput>
  );
};

export default AddressField;
