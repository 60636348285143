/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import CollapsingContainer from '../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { CheckboxField } from '../../../../components/core/forms/fields';
import FormError from '../../../../components/core/forms/fields/FormError';
import { CarIcon, FloodIcon, HomeIcon, JewelryIcon, ShieldIcon } from '../../../../components/core/icons';
import Checkbox from '../../../../components/core/inputs/Checkbox';
import { ModalSize } from '../../../../components/core/Modal';
import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import { opportunityDescription, OpportunityStatus } from '../../../../interfaces/IOpportunity';
import { findPolicyTypeLabel, PolicyType } from '../../../../interfaces/IPolicyType';
import useCrossSellLead from '../../../../queries/leads/cross_sell/useCrossSellLead';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import analytics from '../../../../services/analytics';
import { spacings } from '../../../../theme/variables';
import guidedSellingExperienceNavigation from '../../navigation';
import { OpportunityStatus as OpportunityStatusTag, OpportunityTitle } from '../../Summary/Context/Opportunities';

const initialPolicyTypes: PolicyType[] = [];
const REAL_PROPERTY_POLICY_TYPES = [
  PolicyType.Home,
  PolicyType.Condo,
  PolicyType.Renters,
  PolicyType.ManufacturedOrMobileHome,
  PolicyType.Fire
];
const VEHICLE_POLICY_TYPES = [PolicyType.Auto, PolicyType.Moto, PolicyType.RV, PolicyType.Boat];
const HOME_ENDORSEMENTS_POLICY_TYPES = [PolicyType.Earthquake, PolicyType.Flood];
const SPECIALTY_LINES_POLICY_TYPES = [PolicyType.Umbrella, PolicyType.PAF, PolicyType.Pet];

const CrossSellButton = () => {
  const navigate = useNavigate();
  const { lead, personGid } = useGuidedSellingExperienceContext();
  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);
  const { mutateAsync: crossSellLead, isPending: isLeadCrossSelling } = useCrossSellLead();

  const { data: opportunities = [] } = usePersonOpportunities({
    personGid,
    leadGid: lead?.gid
  });

  const initialValues = {
    // fake value for typings
    atLeastOneSelectedError: '',
    policyTypes: initialPolicyTypes,
    opportunities:
      opportunities.reduce(
        (acc, opportunity) => {
          if (opportunity.status !== OpportunityStatus.Sold) {
            acc[opportunity.id] = false;
          }
          return acc;
        },
        {} as Record<number, boolean>
      ) || {}
  };

  const confirmHandler = (values: typeof initialValues) => {
    // Order is necessary for the backend to derive the primary opportunity
    const orderedPolicyTypes = [
      ...REAL_PROPERTY_POLICY_TYPES,
      ...VEHICLE_POLICY_TYPES,
      ...HOME_ENDORSEMENTS_POLICY_TYPES,
      ...SPECIALTY_LINES_POLICY_TYPES
    ];

    return crossSellLead({
      leadGid: lead!.gid,
      policyTypes: values.policyTypes.sort((a, b) => {
        return orderedPolicyTypes.indexOf(a) - orderedPolicyTypes.indexOf(b);
      }),
      opportunitiesIds: Object.keys(values.opportunities).filter(
        key => !!values.opportunities[key as unknown as number]
      )
    }).then(data => {
      const {
        lead: { gid: lead_gid }
      } = data;

      navigate(guidedSellingExperienceNavigation.forLead({ lead_gid }));
      analytics.track('New Lead was created', { from: 'scouting_report_cross_sell', lead_gid });
      toggleShowConfirmationModal();
    });
  };

  return (
    <>
      <Button onClick={toggleShowConfirmationModal} size={ButtonSize.Small}>
        {featureFlags.cstGuidedSellingExperience ? 'Service' : 'Cross-sell'}
      </Button>
      {showConfirmationModal && (
        <FormModal
          size={ModalSize.large}
          confirmHandler={values => confirmHandler(values)}
          cancelHandler={toggleShowConfirmationModal}
          confirmationInProgress={isLeadCrossSelling}
          // eslint-disable-next-line max-len
          containerTitle={`Select opportunities to ${featureFlags.cstGuidedSellingExperience ? 'service' : 'cross-sell'}`}
          confirmText="Create opportunities"
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            opportunities: yup.object(),
            policyTypes: yup.array(),
            atLeastOneSelectedError: yup
              .mixed()
              .test('at-least-one-selected', 'At least one opportunity or policy type must be selected', function () {
                const { opportunities, policyTypes } = this.parent;
                const hasOpportunities = opportunities && Object.values(opportunities).some(v => !!v);
                const hasPolicyTypes = policyTypes && policyTypes.length > 0;
                return hasOpportunities || hasPolicyTypes;
              })
          })}
          renderForm={({ values, errors }) => {
            return (
              <>
                <FlexBox alignItemsCenter gap={spacings.px8} mb={spacings.px20}>
                  <ShieldIcon />
                  <Paragraph bold>Previous lead opportunities</Paragraph>
                </FlexBox>

                <FlexBox gap={spacings.px12} columnDirection>
                  {opportunities.map(opportunity => (
                    <FlexBox alignItemsCenter gap={spacings.px20} key={opportunity.id}>
                      <CheckboxField
                        name={`opportunities.${opportunity.id}`}
                        id={`opportunities.${opportunity.id}`}
                        label={<OpportunityTitle personGid={personGid!} opportunity={opportunity} />}
                        inline
                        description={
                          opportunityDescription({
                            policy_type: opportunity.policy_type,
                            assets: opportunity.assets
                          }) as string
                        }
                      />
                      <OpportunityStatusTag opportunity={opportunity} isOpportunityQuoted={false} />
                    </FlexBox>
                  ))}
                  {featureFlags.crossSellOtherOpportunities && (
                    <CollapsingContainer
                      containerTitle="Other opportunities"
                      titleSize={ParagraphType.Default}
                      iconPosition="left"
                      openedByDefault={false}
                      gap={spacings.px20}
                    >
                      <FieldArray name="policyTypes">
                        {({ push, remove }) => (
                          <FlexBox mb={spacings.px12} mt={spacings.px24} gap={spacings.px32} columnDirection>
                            <FlexBox>
                              <FlexBox
                                columnDirection
                                gap={spacings.px12}
                                css={css`
                                  width: 300px;
                                `}
                              >
                                <FlexBox alignItemsCenter gap={spacings.px8}>
                                  <HomeIcon width={20} height={20} />
                                  <Paragraph bold>Property</Paragraph>
                                </FlexBox>
                                <FlexBox gap={spacings.px20} columnDirection>
                                  {REAL_PROPERTY_POLICY_TYPES.map(policyType => (
                                    <Checkbox
                                      key={policyType}
                                      label={findPolicyTypeLabel(policyType)}
                                      id={policyType}
                                      onChange={e => {
                                        e.target.checked
                                          ? push(policyType)
                                          : remove(values.policyTypes.findIndex(v => v === policyType));
                                      }}
                                    />
                                  ))}
                                </FlexBox>
                              </FlexBox>
                              <FlexBox columnDirection gap={spacings.px12}>
                                <FlexBox alignItemsCenter gap={spacings.px8}>
                                  <FloodIcon width={20} height={20} />
                                  <Paragraph bold>Home endorsements</Paragraph>
                                </FlexBox>
                                <FlexBox gap={spacings.px20} columnDirection>
                                  {HOME_ENDORSEMENTS_POLICY_TYPES.map(policyType => (
                                    <Checkbox
                                      key={policyType}
                                      label={findPolicyTypeLabel(policyType)}
                                      id={policyType}
                                      onChange={e => {
                                        e.target.checked
                                          ? push(policyType)
                                          : remove(values.policyTypes.findIndex(v => v === policyType));
                                      }}
                                    />
                                  ))}
                                </FlexBox>
                              </FlexBox>
                            </FlexBox>
                            <FlexBox>
                              <FlexBox
                                columnDirection
                                gap={spacings.px12}
                                css={css`
                                  width: 300px;
                                `}
                              >
                                <FlexBox alignItemsCenter gap={spacings.px8}>
                                  <CarIcon width={20} height={20} />
                                  <Paragraph bold>Vehicles</Paragraph>
                                </FlexBox>
                                <FlexBox gap={spacings.px20} columnDirection>
                                  {VEHICLE_POLICY_TYPES.map(policyType => (
                                    <Checkbox
                                      key={policyType}
                                      label={findPolicyTypeLabel(policyType)}
                                      id={policyType}
                                      onChange={e => {
                                        e.target.checked
                                          ? push(policyType)
                                          : remove(values.policyTypes.findIndex(v => v === policyType));
                                      }}
                                    />
                                  ))}
                                </FlexBox>
                              </FlexBox>
                              <FlexBox columnDirection gap={spacings.px12}>
                                <FlexBox alignItemsCenter gap={spacings.px8}>
                                  <JewelryIcon width={20} height={20} />
                                  <Paragraph bold>Specialty lines</Paragraph>
                                </FlexBox>
                                <FlexBox gap={spacings.px20} columnDirection>
                                  {SPECIALTY_LINES_POLICY_TYPES.map(policyType => (
                                    <Checkbox
                                      key={policyType}
                                      label={findPolicyTypeLabel(policyType)}
                                      id={policyType}
                                      onChange={e => {
                                        e.target.checked
                                          ? push(policyType)
                                          : remove(values.policyTypes.findIndex(v => v === policyType));
                                      }}
                                    />
                                  ))}
                                </FlexBox>
                              </FlexBox>
                            </FlexBox>
                          </FlexBox>
                        )}
                      </FieldArray>
                    </CollapsingContainer>
                  )}
                  {!!errors.atLeastOneSelectedError && (
                    <FormError id="atLeastOneSelectedError" hasError error={errors.atLeastOneSelectedError} />
                  )}
                </FlexBox>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default CrossSellButton;
