/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import guidedSellingExperienceNavigation from '../../pages/GuidedSellingExperience/navigation';
import useCallLog from '../../queries/call_logs/useCallLog';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { Link } from '../common';
import Button, { ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import Text from '../core/Text';

const ScheduleCallRequiredBanner = ({ currentCallId }: { currentCallId: number }) => {
  const { data: currentCallLog } = useCallLog(currentCallId);
  const leadData = useGuidedSellingExperienceContext();

  const currentLeadGid = leadData.leadGid;
  const location = useLocation();

  const isSearchPage = location.pathname.includes('people_search');
  const isOutsideOfCurrentCall = currentLeadGid !== currentCallLog?.lead_gid;
  const show = currentCallLog?.is_scheduled_call_required && (isOutsideOfCurrentCall || isSearchPage);
  // Additional check for search page because it has current call lead context

  React.useEffect(() => {
    if (currentCallLog?.is_scheduled_call_required) {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [currentCallLog]);

  if (!show) {
    return null;
  }

  return (
    <FlexBox
      data-test-id="required-scheduled-call-message-box"
      backgroundColor={colors.violet}
      ph={spacings.px16}
      pv={spacings.px12}
      justifySpaceBetween
      alignItemsCenter
      gap={spacings.px8}
    >
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <Text type="large" color={colors.white}>
          <b>🚨 Important Alert:</b> You must schedule a follow-up with your last call lead to unlock Twilio. No new
          calls can be taken until this is completed.
        </Text>
      </FlexBox>
      <Link
        to={guidedSellingExperienceNavigation.forCall({
          call_log_id: currentCallId
        })}
        css={css`
          color: ${colors.azure50};
          &:hover {
            color: ${colors.azure50};
            background-color: transparent;
          }
        `}
      >
        <Button
          variant={ButtonVariant.Secondary}
          customCss={css`
            &:hover {
              color: ${colors.azure50};
              background-color: ${colors.white};
            }
            width: 170px;
          `}
        >
          Schedule Follow-up
        </Button>
      </Link>
    </FlexBox>
  );
};

export default ScheduleCallRequiredBanner;
