/** @jsxImportSource @emotion/react */

import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import * as yup from 'yup';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import { DateInputField, InputField, MultiSelectField, SelectField } from '../../../../../components/core/forms/fields';
import { answerForDatapoint } from '../../../../../components/core/forms/workflowHelpers';
import { HomeIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import { QUESTION_VERIFICATION_STATUS_TOOLTIP } from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import { formInputLabelStyle } from '../../../../../components/UIFlow/input.styles';
import { Translations } from '../../../../../constants';
import { IOpportunity, IPerson, IRelatedPerson, IUIFlow, IUIFlowTextBox } from '../../../../../interfaces';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { IHome, isExtendedHomeInfoAvailable } from '../../../../../interfaces/IHome';
import { Requirement } from '../../../../../interfaces/IPolicyType';
import { IQuestion, QuestionVerificationStatus } from '../../../../../interfaces/IWorkflow';
import { useReconcileOpportunities } from '../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import {
  useLeadOpportunityCoinsureds,
  useUpdateLeadOpportunityCoinsureds
} from '../../../../../queries/leads/opportunities/useLeadOpportunities';
// eslint-disable-next-line max-len
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import { PERSON_QUERY_KEY } from '../../../../../queries/people/usePerson';
import {
  SIDEBAR_HOME_BASIC_INFO_V2,
  SIDEBAR_HOME_EXTENDED_INFO_V2,
  useSaveAnswers
} from '../../../../../queries/workflows/useUiFlow';
import { InputSize, LabelSize, spacings } from '../../../../../theme/variables';
import { filterLLCTrustFromAnswer } from '../../../_helpers';
import HomeFormTitle from './HomeFormTitle';
import HomeSectionIcon from './HomeSectionIcon';

interface HomeFormProps {
  personGid: string;
  engagementGid: string | undefined;
  leadId: number | undefined;
  homeOpportunity: IOpportunity;
  homeProfileUIFlow: IUIFlow;
  homeDetailsUIFlow: IUIFlow;
  homeEligibilityUIFlow: IUIFlow;
  isCustomerDataCompletenessHigh: boolean;
  isExtraSmall: boolean;
  relatedPeople: IRelatedPerson[] | undefined;

  onClose: () => void;
  onSubmit: () => void;
}

const HomeForm = ({
  personGid,
  engagementGid,
  leadId,
  homeOpportunity,
  homeProfileUIFlow,
  homeDetailsUIFlow,
  isCustomerDataCompletenessHigh,
  onClose,
  onSubmit,
  isExtraSmall,
  relatedPeople
}: HomeFormProps) => {
  const home = homeOpportunity.assets![0] as IHome;

  const { mutateAsync: saveAnswers } = useSaveAnswers();
  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  const queryClient = useQueryClient();

  const propertyUsageTypeQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyUsageType)?.content;
  const propertyOwnershipTypeQuestion = homeProfileUIFlow.questionForDatapoint(
    DatapointKey.PropertyOwnershipType
  )?.content;
  const propertyOnTheDeedQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyOnTheDeed)?.content;
  const propertyTypeQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyType)?.content;
  const propertySquareFootageQuestion = homeProfileUIFlow.questionForDatapoint(
    DatapointKey.PropertySquareFootage
  )?.content;
  const propertyYearBuiltQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyYearBuilt)?.content;
  const propertyPurchaseDateQuestion = homeProfileUIFlow.questionForDatapoint(
    DatapointKey.PropertyPurchaseDate
  )?.content;

  const propertyTrustNameQuestion = homeProfileUIFlow?.questionForDatapoint(DatapointKey.PropertyTrustName)?.content;
  const propertyLLCNameQuestion = homeProfileUIFlow?.questionForDatapoint(DatapointKey.PropertyLLCName)?.content;

  const person = queryClient.getQueryData<IPerson>([PERSON_QUERY_KEY, personGid]);

  const { data: currentCoinsureds = [] } = useLeadOpportunityCoinsureds({
    leadId: leadId?.toString(),
    opportunityId: homeOpportunity.id.toString()
  });
  const { mutateAsync: updateOpportunityCoinsureds } = useUpdateLeadOpportunityCoinsureds();

  const buildDeedList = () => {
    const relatedPeopleList = (relatedPeople || [])
      .map(person => ({
        value: person.gid,
        label: person.name,
        description: Translations.relationKind(person.kind) || '',
        isDisabled: false
      }))
      .concat([
        { value: 'llc', label: 'LLC', description: '', isDisabled: false },
        { value: 'trust', label: 'Trust', description: '', isDisabled: false }
      ]);

    return [{ value: personGid, label: person?.name, description: 'Primary contact', isDisabled: true }].concat(
      relatedPeopleList
    );
  };

  const propertyOwnershipVerificationStatus =
    answerForDatapoint(homeProfileUIFlow?.answers, DatapointKey.PropertyOwnershipType) && isCustomerDataCompletenessHigh
      ? QuestionVerificationStatus.Verified
      : propertyOwnershipTypeQuestion?.verification_status;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <BaseForm
      pt={spacings.px0}
      pr={spacings.px0}
      pb={spacings.px0}
      pl={spacings.px0}
      customControls
      validationSchema={yup.object().shape({
        [home.gid]: yup
          .object()
          .shape(homeProfileUIFlow.validations())
          .concat(yup.object().shape(homeDetailsUIFlow.validations()))
      })}
      initialValues={{
        [home.gid]: {
          ...homeProfileUIFlow.initialValues(),
          ...homeDetailsUIFlow.initialValues()
        }
      }}
      onSubmit={async values => {
        const homeValues = values[home.gid]!;

        const basicInfoAnswers = homeProfileUIFlow.answersForFormValues({
          formValues: homeValues,
          personGid,
          engagementGid,
          assetGid: home.gid
        });
        await saveAnswers({
          uiFlowKey: SIDEBAR_HOME_BASIC_INFO_V2,
          answers: basicInfoAnswers
        });

        if (leadId) {
          const currentCoinsuredsGids = currentCoinsureds.map(c => c.gid);
          const deedAnswerValue = basicInfoAnswers.find(a => a.question_key === DatapointKey.PropertyOnTheDeed)
            ?.value as string[] | undefined;
          const personGidsOnTheDeed = filterLLCTrustFromAnswer(deedAnswerValue || []);

          if (personGidsOnTheDeed.length) {
            updateOpportunityCoinsureds({
              leadId,
              opportunityId: homeOpportunity.id,
              peopleGids: [
                ...new Set(currentCoinsuredsGids.concat(personGidsOnTheDeed.filter(gid => gid !== personGid)))
              ]
            });
          }
        }

        await saveAnswers({
          uiFlowKey: SIDEBAR_HOME_EXTENDED_INFO_V2,
          answers: homeDetailsUIFlow.answersForFormValues({
            formValues: homeValues,
            personGid,
            engagementGid,
            assetGid: home.gid
          })
        });

        leadId &&
          (await reconcileOpportunities({ leadId, requirement: Requirement.Essential, assetsGids: [home.gid] }));

        queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });

        onSubmit();
      }}
      renderForm={({ values, submitForm, isSubmitting }) => {
        const extendedHomeInfoAvailable = isExtendedHomeInfoAvailable({
          ownership: values[home.gid]?.[DatapointKey.PropertyOwnershipType]
        });

        const isLLCNameShown = values[home.gid]?.[DatapointKey.PropertyOnTheDeed].includes('llc');
        const isTrustNameShown = values[home.gid]?.[DatapointKey.PropertyOnTheDeed].includes('trust');

        return (
          <FlexBox gap={spacings.px12} columnDirection>
            <HomeFormTitle
              personGid={personGid}
              homeOpportunity={homeOpportunity}
              home={home}
              isSubmitting={isSubmitting}
              onClose={onClose}
              submitForm={submitForm}
            />

            <FlexBox gap={spacings.px4} alignItemsCenter>
              <HomeIcon />
              <Text type="small" bold>
                Basic info
              </Text>
            </FlexBox>

            <Grid container rowSpacing={2}>
              <Grid item xs={isExtraSmall ? 12 : 6}>
                <SelectField
                  inline
                  label="Residence type"
                  labelSize={LabelSize.Small}
                  inputSize={InputSize.Small}
                  id={`${home.gid}.${DatapointKey.PropertyUsageType}`}
                  name={`${home.gid}.${DatapointKey.PropertyUsageType}`}
                  testId={`${home.gid}-${DatapointKey.PropertyUsageType}`}
                  options={Translations.homeUsageOptions}
                  customLabelCss={formInputLabelStyle}
                  verificationStatus={propertyUsageTypeQuestion?.verification_status}
                />
                <SelectField
                  inline
                  label="Owned or rented?"
                  labelSize={LabelSize.Small}
                  inputSize={InputSize.Small}
                  key={`${home.gid}.${DatapointKey.PropertyOwnershipType}`}
                  id={`${home.gid}.${DatapointKey.PropertyOwnershipType}`}
                  name={`${home.gid}.${DatapointKey.PropertyOwnershipType}`}
                  testId={`${home.gid}-${DatapointKey.PropertyOwnershipType}`}
                  options={Translations.homeOwnershipOptions}
                  customLabelCss={formInputLabelStyle}
                  verificationStatus={propertyOwnershipVerificationStatus}
                />
                {extendedHomeInfoAvailable && (
                  <>
                    <MultiSelectField
                      inline
                      fsMask
                      key={`${home.gid}.${DatapointKey.PropertyOnTheDeed}`}
                      label="On the deed"
                      labelSize={LabelSize.Small}
                      inputSize={InputSize.Small}
                      name={`${home.gid}.${DatapointKey.PropertyOnTheDeed}`}
                      id={`${home.gid}.${DatapointKey.PropertyOnTheDeed}`}
                      options={buildDeedList()}
                      testId={`${home.gid}-${DatapointKey.PropertyOnTheDeed}`}
                      labelName="label"
                      valueName="value"
                      customLabelCss={formInputLabelStyle}
                      verificationStatus={propertyOnTheDeedQuestion?.verification_status}
                    />
                    {isLLCNameShown && (
                      <InputField
                        verificationStatus={propertyLLCNameQuestion?.verification_status}
                        fsMask
                        label="LLC name"
                        id={`${home.gid}.${DatapointKey.PropertyLLCName}`}
                        name={`${home.gid}.${DatapointKey.PropertyLLCName}`}
                        labelSize={LabelSize.Small}
                        inputSize={InputSize.Small}
                        inline
                        testId={`${home.gid}-${DatapointKey.PropertyLLCName}`}
                        customLabelCss={formInputLabelStyle}
                      />
                    )}
                    {isTrustNameShown && (
                      <InputField
                        fsMask
                        verificationStatus={propertyTrustNameQuestion?.verification_status}
                        label="Trust name"
                        id={`${home.gid}.${DatapointKey.PropertyTrustName}`}
                        name={`${home.gid}.${DatapointKey.PropertyTrustName}`}
                        labelSize={LabelSize.Small}
                        inputSize={InputSize.Small}
                        inline
                        testId={`${home.gid}-${DatapointKey.PropertyTrustName}`}
                        customLabelCss={formInputLabelStyle}
                      />
                    )}
                    <SelectField
                      inline
                      label="Type"
                      labelSize={LabelSize.Small}
                      inputSize={InputSize.Small}
                      key={`${home.gid}.${DatapointKey.PropertyType}`}
                      id={`${home.gid}.${DatapointKey.PropertyType}`}
                      name={`${home.gid}.${DatapointKey.PropertyType}`}
                      testId={`${home.gid}-${DatapointKey.PropertyType}`}
                      options={Translations.propertyTypeOptions}
                      verificationStatus={propertyTypeQuestion?.verification_status}
                      customLabelCss={formInputLabelStyle}
                    />
                  </>
                )}
              </Grid>
              {extendedHomeInfoAvailable && (
                <Grid item xs={isExtraSmall ? 12 : 6}>
                  <InputField
                    inline
                    label="Sqft"
                    labelSize={LabelSize.Small}
                    inputSize={InputSize.Small}
                    key={`${home.gid}.${DatapointKey.PropertySquareFootage}`}
                    id={`${home.gid}.${DatapointKey.PropertySquareFootage}`}
                    name={`${home.gid}.${DatapointKey.PropertySquareFootage}`}
                    testId={`${home.gid}-${DatapointKey.PropertySquareFootage}`}
                    type="number"
                    customLabelCss={formInputLabelStyle}
                    verificationStatus={propertySquareFootageQuestion?.verification_status}
                  />
                  <InputField
                    inline
                    label="Built"
                    labelSize={LabelSize.Small}
                    inputSize={InputSize.Small}
                    key={`${home.gid}.${DatapointKey.PropertyYearBuilt}`}
                    id={`${home.gid}.${DatapointKey.PropertyYearBuilt}`}
                    name={`${home.gid}.${DatapointKey.PropertyYearBuilt}`}
                    testId={`${home.gid}-${DatapointKey.PropertyYearBuilt}`}
                    type="number"
                    customLabelCss={formInputLabelStyle}
                    verificationStatus={propertyYearBuiltQuestion?.verification_status}
                  />
                  <DateInputField
                    inline
                    label="Purchase date"
                    labelSize={LabelSize.Small}
                    inputSize={InputSize.Small}
                    key={`${home.gid}.${DatapointKey.PropertyPurchaseDate}`}
                    id={`${home.gid}.${DatapointKey.PropertyPurchaseDate}`}
                    name={`${home.gid}.${DatapointKey.PropertyPurchaseDate}`}
                    testId={`${home.gid}-${DatapointKey.PropertyPurchaseDate}`}
                    customLabelCss={formInputLabelStyle}
                    verificationStatus={propertyPurchaseDateQuestion?.verification_status}
                  />
                </Grid>
              )}
              {extendedHomeInfoAvailable &&
                homeDetailsUIFlow.containers.map(container => {
                  return (
                    <Grid key={container.key} item xs={isExtraSmall ? 12 : 6}>
                      {container.elements.map(element => {
                        if (element.kind === 'text_box') {
                          const textBox = element.content as IUIFlowTextBox;

                          return (
                            <FlexBox key={element.key} gap={spacings.px4} alignItemsCenter>
                              <HomeSectionIcon sectionKey={element.key} />
                              <Paragraph type="small" bold mb={spacings.px0}>
                                {textBox.heading}
                              </Paragraph>
                            </FlexBox>
                          );
                        }

                        const question = element.content as IQuestion;

                        const input = homeDetailsUIFlow.inputForQuestion({
                          element,
                          formValues: values[home.gid]!,
                          propsProvider: () => ({
                            inline: true,
                            key: `${home.gid}.${question.key}`,
                            id: `${home.gid}.${question.key}`,
                            label: question.alt_label || question.label,
                            labelSize: LabelSize.Small,
                            inputSize: InputSize.Small,
                            description: undefined,
                            testId: `${home.gid}-${question.key}`,
                            customLabelCss: formInputLabelStyle,
                            verificationStatus: question.verification_status
                          })
                        });

                        return input || <React.Fragment key={element.key} />;
                      })}
                    </Grid>
                  );
                })}
            </Grid>
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </FlexBox>
        );
      }}
    />
  );
};

export default HomeForm;
