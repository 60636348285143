import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { useCallDispatch, useCallState } from '../../contexts/CallContext';
import { usePlannedCallDispatch } from '../../contexts/PlannedCallContext';
import { ICallLog } from '../../interfaces';
import IPlannedCallLog, { CallLogStatus } from '../../interfaces/IPlannedCallLog';
import { CALL_LOG_QUERY_KEY, SCOUTING_CALL_LOG_QUERY } from '../../queries/call_logs/useCallLog';
import { CALL_PARTICIPANTS_QUERY } from '../../queries/call_logs/useCallParticipants';
import { getOngoingCallLog } from '../../queries/call_logs/useOngoingCallLog';
import { getPlannedCallLog } from '../../queries/call_logs/usePlannedCallLog';
import { Subscription } from '../../services/ActionCable';
import authInfo from '../../services/authInfo';
import { ActionCableContext } from '../../utils/actioncable';
import { onPlannedCallLog } from './helpers';
import TwilioCallIndicator from './TwilioCallIndicator';
import useTwilioRedirectEvent from './useTwilioRedirectEvent';

const LINKED_TO_CALL = 'linked_to_call';
const LEAD_CREATED_OR_UPDATED = 'lead_created_or_updated';

const TwilioIntegration = () => {
  const queryClient = useQueryClient();
  const dispatchCall = useCallDispatch();
  const dispatchPlannedCall = usePlannedCallDispatch();

  const { currentCallId } = useCallState();

  useTwilioRedirectEvent();

  // Explanation: this API call should happen only once, at the application start.
  React.useEffect(() => {
    getOngoingCallLog().then(({ call_log }) => {
      if (call_log) {
        queryClient.setQueryData([CALL_LOG_QUERY_KEY, call_log.id], { call_log }, { updatedAt: Date.now() });
        dispatchCall({ type: 'setCurrentCallId', payload: call_log.id });
      } else {
        dispatchCall({ type: 'callEnded' });
      }
    });
  }, [queryClient, dispatchCall]);

  React.useEffect(() => {
    getPlannedCallLog().then(({ planned_call_log }) => {
      if (planned_call_log) {
        dispatchPlannedCall({ type: 'setCurrentPlannedCall', payload: planned_call_log });
      }
    });
  }, [dispatchPlannedCall]);

  const cable = React.useContext(ActionCableContext);

  React.useEffect(() => {
    let twilioEventsChannel: Subscription;

    if (cable) {
      twilioEventsChannel = cable.subscriptions.create(
        { channel: 'TwilioEventsChannel', user_id: authInfo.currentUserId },
        {
          received: ({
            call_log,
            planned_call_log,
            message
          }: {
            call_log?: ICallLog;
            planned_call_log?: IPlannedCallLog;
            message: string;
          }) => {
            if (call_log) {
              queryClient.setQueryData([CALL_LOG_QUERY_KEY, call_log.id], { call_log }, { updatedAt: Date.now() });
              if (call_log.is_finished) {
                dispatchCall({ type: 'callEnded' });
              } else {
                dispatchCall({ type: 'setCurrentCallId', payload: call_log.id });
              }

              queryClient.refetchQueries({ queryKey: [CALL_PARTICIPANTS_QUERY, call_log.id] });

              if ([LINKED_TO_CALL, LEAD_CREATED_OR_UPDATED].includes(message)) {
                queryClient.refetchQueries({ queryKey: [SCOUTING_CALL_LOG_QUERY, call_log.id] });
              }
            }
            if (planned_call_log) {
              const isPending = planned_call_log.status === CallLogStatus.Pending;
              if (isPending) {
                dispatchPlannedCall({ type: 'setCurrentPlannedCall', payload: planned_call_log });
                onPlannedCallLog({ plannedCall: planned_call_log });
              } else {
                dispatchPlannedCall({ type: 'setCurrentPlannedCall', payload: null });
              }
            }
          }
        }
      );
    }

    return () => {
      if (cable) {
        twilioEventsChannel.unsubscribe();
      }
    };
  }, [cable, queryClient, dispatchCall, dispatchPlannedCall]);

  return currentCallId ? <TwilioCallIndicator currentCallId={currentCallId} /> : null;
};

export default TwilioIntegration;
