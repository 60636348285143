import { css, SerializedStyles } from '@emotion/react';

import colors from '../../../theme/colors';

export const mainDivCSS = css`
  width: 100%;
  font-weight: normal;
  border-spacing: 0 4px;
`;

export const tableCSS = css`
  width: 100%;
  font-weight: normal;
  border-spacing: 0 4px;
`;

export const columnCSS = (isSortingEnabled: boolean, width: number): SerializedStyles => css`
  font-weight: normal;
  padding: 14px 0;
  position: relative;
  min-width: ${width}px;
  width: -webkit-fill-available;
  display: flex;

  ${isSortingEnabled &&
  css`
    cursor: pointer;
  `}
`;

export const columnTitleCSS = css`
  margin-right: 8px;
`;

export const columnContentCSS = (isSortingEnabled: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${isSortingEnabled ? colors.black : colors.grey32};
  font-weight: 600;

  span {
    margin-right: 8px;
  }
`;

export const rowCSS = (withBorder: boolean, transform?: number): SerializedStyles => css`
  height: 48px;
  ${withBorder && `border-bottom: 1px solid ${colors.grey10};`}
  display: flex;
  position: absolute;
  width: 100%;
  transform: translateY(${transform}px);

  > td:first-of-type {
    max-width: 40px;
  }

  &:hover {
    background-color: ${colors.grey5};
  }
`;

export const rowCellCSS = (withBorder: boolean, width: number): SerializedStyles => css`
  height: 48px;
  padding: 4px 8px;
  width: ${width}px;
  ${withBorder ? `border-right: 1px solid ${colors.grey10}` : ''};
`;

export const headCSS = (headerBackgroundColor: string): SerializedStyles => css`
  background-color: ${headerBackgroundColor};
  display: grid;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const fetchingCSS = css`
  padding-top: 44px;
  color: ${colors.azure50};
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  height: 80px;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    fill: ${colors.azure50};
    animation: rotation 3s linear infinite;
  }

  span {
    margin-left: 8px;
  }
`;
