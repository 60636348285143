/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { useToggle } from '../../hooks';
import { IMaticPolicy } from '../../interfaces';
import LoanAssetPicker from '../../pages/Customer/CustomerLoans/LoanAssetPicker';
import LoanEditor from '../../pages/Customer/CustomerLoans/LoanEditor';
import TabSkeleton from '../../pages/CustomerView/_components/VerticalTabs/TabSkeleton';
import usePersonAssets from '../../queries/people/person_assets/usePersonAssets';
import usePersonMaticPolicies from '../../queries/people/person_policies/usePersonMaticPolicies';
import usePersonLoans from '../../queries/people/usePersonLoans';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Button, { ButtonSize, ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import { Add2Icon } from '../core/icons';
import Paragraph from '../core/Paragraph';
import Text from '../core/Text';
import VerticalTabContent from '../core/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../core/VerticalTabs/VerticalTabHeader';
import AssetLoans from './AssetLoans';

const getPolicyByAssetGid = (assetGid: string, policies?: IMaticPolicy[]) => {
  return policies?.find(policy => policy.assets?.[0]?.gid === assetGid);
};

const PersonLoansTab = ({ personGid, onTabClose }: { personGid: string; onTabClose: () => void }) => {
  const [pickedAssetGid, setPickedAssetGid] = React.useState<string>();
  const [assetPickerOpened, toggleAssetPickerOpened] = useToggle(false);

  const [editorOpened, toggleEditorOpened] = useToggle(false);

  const { data: loans, isFetchedAfterMount: isFetchedLoans } = usePersonLoans({ personGid });
  const { data: personAssets, isFetched: isFetchedAssets } = usePersonAssets({ personGid });
  const { data: policies, isFetched: isFetchedPolicies } = usePersonMaticPolicies({ personGid });
  const hideAssetPicker = () => {
    setPickedAssetGid(undefined);
    toggleAssetPickerOpened();
  };

  return (
    <>
      <VerticalTabHeader onTabClose={onTabClose}>
        <FlexBox fitParentWidth justifySpaceBetween>
          <FlexBox gap={spacings.px8} alignItemsCenter>
            <Paragraph type="large" bold>
              Loans
            </Paragraph>
          </FlexBox>
          {toggleAssetPickerOpened && (
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.Simple}
              data-testid="add-loan-button"
              onClick={() => {
                toggleAssetPickerOpened();
              }}
            >
              <FlexBox gap={spacings.px4} alignItemsCenter>
                <Add2Icon width={16} height={16} />
                <Text type="small" bold>
                  Add loan
                </Text>
              </FlexBox>
            </Button>
          )}
        </FlexBox>
      </VerticalTabHeader>

      <VerticalTabContent>
        {!isFetchedLoans || !isFetchedAssets || !isFetchedPolicies ? (
          <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
        ) : (
          <FlexBox columnDirection gap={spacings.px40}>
            {personAssets &&
              personAssets.map(asset => (
                <>
                  {loans?.find(loan => loan.home?.gid === asset.gid || loan.vehicle?.gid === asset.gid) && (
                    <AssetLoans
                      key={asset.gid}
                      personGid={personGid}
                      asset={asset}
                      policy={getPolicyByAssetGid(asset.gid, policies)}
                    />
                  )}
                </>
              ))}
          </FlexBox>
        )}

        {!loans?.length && (
          <Paragraph
            mt={spacings.px24}
            color={colors.grey60}
            customCss={css`
              text-align: center;
            `}
          >
            No loan information found
          </Paragraph>
        )}
        {assetPickerOpened && (
          <LoanAssetPicker
            assets={personAssets!}
            cancelHandler={hideAssetPicker}
            guidedSellingContext
            confirmHandler={assetGid => {
              hideAssetPicker();
              setPickedAssetGid(assetGid);
              toggleEditorOpened();
            }}
          />
        )}
        {editorOpened && pickedAssetGid && (
          <LoanEditor
            personGid={personGid!}
            assetGid={pickedAssetGid}
            cancelHandler={toggleEditorOpened}
            confirmHandler={() => toggleEditorOpened()}
          />
        )}
      </VerticalTabContent>
    </>
  );
};

export default PersonLoansTab;
