/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import alert from '../../../components/core/Alert';
import Button, { ButtonSize, ButtonVariant } from '../../../components/core/buttons/Button';
import { ChatIcon, EmailIcon, PhoneIcon } from '../../../components/core/icons';
import featureFlags from '../../../constants/featureFlags';
import { useDNCTip } from '../../../hooks';
import { IPerson } from '../../../interfaces';
import useCreateContactAttempt, { MediaType } from '../../../queries/contact_attempts/useCreateContactAttempt';
import { getLastLeadId } from '../../../queries/people/useLastLead';
import { useActiveScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../../services/authInfo';
import Indicator from '../../ScheduleCall/Indicator';

const ActionButtons = ({ person }: { person: IPerson }) => {
  const { doNotContact, DNCTip, labelProps } = useDNCTip({ personGid: person.gid });
  const { mutate: createContactAttempt, isPending: isContactAttemptCreating } = useCreateContactAttempt({
    onSuccess: () => alert({ message: 'Request successfully passed to Twilio' }).success()
  });
  const allowContactActions = doNotContact ? authInfo.features.do_not_contact_call_available : true;

  const handleCreateContactAttempt = (mediaType: MediaType) => {
    getLastLeadId(person.gid).then(({ lead }) => {
      if (!lead) {
        return alert({ message: 'No lead associated with this customer' }).error();
      }
      const phone = person.phone || person.secondary_phone || person.business_phone;

      if (!phone) {
        return alert({ message: 'No phone number associated with this customer' }).error();
      }

      return createContactAttempt({
        phone,
        email: person.email || undefined,
        personGid: person.gid,
        leadGid: lead.gid,
        mediaType
      });
    });
  };

  const { data: activeCalls, isFetching } = useActiveScheduledInteractions(person.gid);

  const showCallButton = !isFetching && activeCalls?.length === 0;
  const navigate = useNavigate();

  return (
    <>
      <div {...labelProps}>
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Simple}
          disabled={!allowContactActions}
          loading={isContactAttemptCreating}
          onClick={() => handleCreateContactAttempt(MediaType.Call)}
        >
          <PhoneIcon /> Call
        </Button>
      </div>
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.Simple}
        disabled={!allowContactActions}
        loading={isContactAttemptCreating}
        onClick={() => handleCreateContactAttempt(MediaType.SMS)}
      >
        <ChatIcon /> Text
      </Button>
      {featureFlags.clickToTwilioEmail && (
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Simple}
          disabled={!allowContactActions}
          loading={isContactAttemptCreating}
          onClick={() => handleCreateContactAttempt(MediaType.Email)}
        >
          <EmailIcon /> Email
        </Button>
      )}
      <Button size={ButtonSize.Small}>Service</Button>
      {showCallButton && (
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Simple}
          disabled={!allowContactActions}
          loading={isContactAttemptCreating}
          onClick={() => navigate('schedule_call')}
        >
          Schedule call
        </Button>
      )}
      <Indicator
        personGid={person.gid}
        leadGid={undefined}
        candidateGid={undefined}
        containerCss={css`
          margin-left: auto;
        `}
      />
      <DNCTip />
    </>
  );
};

export default ActionButtons;
