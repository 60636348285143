import { create } from 'zustand';

import { IScheduledCall, IUser } from '../../../interfaces';

const useCancelActiveCall = create<{
  activeCall: IScheduledCall | null;
  setCancelCallState: (activeCall: IScheduledCall, personGid: string, assignee: IUser | null) => void;
  personGid: string | null;
  assignee: IUser | null;
  reset: () => void;
}>()(set => ({
  activeCall: null,
  personGid: null,
  assignee: null,
  setCancelCallState: (activeCall: IScheduledCall, personGid: string, assignee: IUser | null) =>
    set({ activeCall, personGid, assignee }),
  reset: () => set({ activeCall: null, personGid: null, assignee: null })
}));

export default useCancelActiveCall;
