/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  TableOptions,
  useReactTable
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import emptyImage from '../../../assets/img/empty.svg';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from '../../../constants';
import useWindowHeight from '../../../hooks/useWindowHeight';
import colors from '../../../theme/colors';
import ScrollToTopButton from '../../ScrollToTopButton';
import { SortingArrowsIcon, SpinnerIcon } from '../icons';
import {
  columnContentCSS,
  columnCSS,
  fetchingCSS,
  headCSS,
  mainDivCSS,
  rowCSS,
  tableCSS
} from './VirtualizedTable.style';
import VirtualizedTablePlaceholder from './VirtualizedTablePlaceholder';

interface VirtualizedTableProps<T> {
  columns: any[];
  data: T[];
  testId: string;
  sorting: { sort_column: string; sort_order: string };
  withBorder?: boolean;
  withRowBorders?: boolean;
  headerBackgroundColor?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  isManualSortingDisabled?: boolean;
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  tableOptions?: Omit<TableOptions<T>, 'columns' | 'data' | 'getCoreRowModel' | 'getExpandedRowModel'>;
  onSortingChange: ({ sort_column, sort_order }: { sort_column: string; sort_order: string }) => void;
  fetchMoreOnBottomReached: (current: any) => void;
}

const VirtualizedTable = <T extends Record<string, any>>({
  columns,
  data,
  testId,
  sorting,
  fetchMoreOnBottomReached,
  withBorder = true,
  headerBackgroundColor = colors.white,
  tableOptions,
  isLoading,
  isFetching,
  isManualSortingDisabled = false,
  onSortingChange
}: VirtualizedTableProps<T>) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...tableOptions
  });

  const { rows } = table.getRowModel();
  const height = useWindowHeight();

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const element = tableRef.current;
      if (element && element.scrollTop !== element.scrollHeight - element.clientHeight) {
        fetchMoreOnBottomReached(event.currentTarget);
      }
    };

    const element = tableRef.current;
    element?.addEventListener('scroll', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, [fetchMoreOnBottomReached, tableRef]);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 15,
    getScrollElement: () => tableRef?.current,
    overscan: 5
  });

  const onSortingChangeClicked = (columnName: any) => {
    onSortingChange({
      sort_column: columnName,
      sort_order: sorting.sort_order === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC
    });

    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  table.setOptions(prev => ({
    ...prev,
    onSortingChange: onSortingChangeClicked
  }));

  const getTableContent = () => {
    if (!data?.length) {
      return (
        <div
          css={css`
            margin: 5% auto auto;
            color: ${colors.grey60};
            text-align: center;
            font-weight: bold;
          `}
        >
          <img src={emptyImage} alt="Empty" width={225} />
          <div>No leads assigned to you</div>
        </div>
      );
    }

    return rowVirtualizer.getVirtualItems().map(virtualRow => {
      const row = rows[virtualRow.index] as any;
      return (
        <tr
          data-index={virtualRow.index}
          data-testid={((testId && `${testId}-`) || '') + row.id}
          ref={node => rowVirtualizer.measureElement(node)}
          key={row.id}
          css={[row.original.rowCustomCSS, rowCSS(withBorder, virtualRow.start)]}
        >
          {row.getVisibleCells().map((cell: any) => {
            return (
              <td
                key={cell.id}
                style={{
                  display: 'flex',
                  minWidth: cell.column.getSize(),
                  alignItems: 'center',
                  width: '-webkit-fill-available'
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div>
      <div
        ref={tableRef}
        css={mainDivCSS}
        style={{
          overflow: 'auto',
          position: 'relative',
          height: height - 140
        }}
      >
        <table data-testid={testId} css={tableCSS}>
          <thead css={headCSS(headerBackgroundColor)}>
            {table.getHeaderGroups().map(headerGroup => {
              return (
                <tr
                  key={headerGroup.id}
                  data-testid={((testId && `${testId}-`) || '') + headerGroup.id}
                  css={css`
                    &:hover {
                      background-color: ${colors.grey5};
                    }
                    display: flex;

                    > th:first-of-type {
                      max-width: 40px;
                    }

                    ${withBorder && `border-bottom: 1px solid ${colors.grey10};`}
                  `}
                >
                  {headerGroup.headers.map(header => {
                    const {
                      columnDef: { enableSorting, id }
                    } = header.column;

                    const isSortingEnabled = !isManualSortingDisabled && !!enableSorting;

                    return (
                      <th
                        key={header.id}
                        data-testid={((testId && `${testId}-`) || '') + header.id}
                        css={columnCSS(isSortingEnabled, header.column.getSize())}
                        onClick={() => {
                          isSortingEnabled ? onSortingChangeClicked(id!) : null;
                        }}
                      >
                        <div css={columnContentCSS(isSortingEnabled)}>
                          <span>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}{' '}
                          </span>
                          {isSortingEnabled && <SortingArrowsIcon width={16} />}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative'
            }}
          >
            {isLoading ? <VirtualizedTablePlaceholder /> : getTableContent()}
          </tbody>
        </table>

        {isFetching && !isLoading && (
          <div css={fetchingCSS}>
            <SpinnerIcon />
            <span>Loading more</span>
          </div>
        )}
      </div>
      <ScrollToTopButton elementRef={tableRef} scrollOffset={100} />
    </div>
  );
};

export default VirtualizedTable;
