import { anyPass, isEmpty, isNil, reject } from 'ramda';

export const compact = reject(anyPass([isEmpty, isNil]));

export const shallowEqual = (arrA: undefined | any[], arrB: undefined | any[]): boolean => {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (arrA: any[], arrB: any[]): boolean => {
  if (arrA.length !== arrB.length) {
    return false;
  }

  const areObjectsEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2) {
      return true;
    }

    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };
  for (let i = 0; i < arrA.length; i++) {
    if (!areObjectsEqual(arrA[i], arrB[i])) {
      return false;
    }
  }

  return true;
};
