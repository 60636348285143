/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import {
  Modal as ReactStrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps as ReactStrapModalProps
} from 'reactstrap';

import colors from '../../theme/colors';
import Button, { ButtonVariant } from './buttons/Button';
import { CloseIcon, WarningIcon } from './icons';
import Text from './Text';

export enum ModalSize {
  small = 'sm',
  medium = 'md',
  large = 'lg',
  extra = 'xl'
}

export interface ModalProps extends ReactStrapModalProps {
  size?: ModalSize;
  containerTitle?: string;
  header?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmHandler?: () => void;
  cancelHandler?: () => void;
  showCancelButton?: boolean;
  confirmationInProgress?: boolean;
  children?: React.ReactNode | undefined;
  dangerModal?: boolean;
  headerCss?: SerializedStyles;
  bodyCss?: SerializedStyles;
  footerCss?: SerializedStyles;
  warningIcon?: boolean | false;
}

const CustomClose = ({ onClick }: { onClick?: () => void }) => (
  <Button
    onClick={onClick}
    aria-label="Close"
    variant={ButtonVariant.Text}
    customCss={css`
      color: ${colors.black};
    `}
  >
    <CloseIcon />
  </Button>
);

const Modal = ({
  size = ModalSize.medium,
  containerTitle: title,
  header,
  confirmText = 'OK',
  cancelText = 'Cancel',
  confirmHandler,
  cancelHandler,
  showCancelButton = true,
  confirmationInProgress = false,
  children,
  dangerModal,
  headerCss,
  bodyCss,
  footerCss,
  warningIcon,
  ...props
}: ModalProps): JSX.Element => {
  const cancelClick = confirmationInProgress ? () => {} : cancelHandler;
  const confirmClick = confirmationInProgress ? () => {} : confirmHandler;
  const showFooter = showCancelButton || confirmHandler;

  const [isConfirming, setIsConfirming] = React.useState(false);

  return (
    <ReactStrapModal {...props} isOpen toggle={cancelClick} size={size} backdrop="static">
      <ModalHeader close={<CustomClose onClick={cancelClick} />} css={headerCss}>
        {title && (
          <Text bold type="large">
            {warningIcon && (
              <WarningIcon
                css={css`
                  position: relative;
                  margin-right: 4px;
                `}
                color={colors.statusOrange}
              />
            )}
            {title}
          </Text>
        )}
        {header}
      </ModalHeader>
      <ModalBody css={bodyCss}>{children}</ModalBody>
      {showFooter && (
        <ModalFooter css={footerCss}>
          {showCancelButton && cancelHandler && (
            <Button variant={ButtonVariant.Secondary} onClick={cancelClick}>
              {cancelText}
            </Button>
          )}
          {confirmHandler && (
            <Button
              onClick={async () => {
                setIsConfirming(true);
                await confirmClick?.();
                setIsConfirming(false);
              }}
              variant={dangerModal ? ButtonVariant.Danger : ButtonVariant.Default}
              loading={isConfirming || confirmationInProgress}
            >
              {confirmText}
            </Button>
          )}
        </ModalFooter>
      )}
    </ReactStrapModal>
  );
};

export default Modal;
