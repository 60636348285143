/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import featureFlags from '../../../constants/featureFlags';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import FinalizeLead from '../_components/FinalizeLead';
import { GuidedSellingPathnames } from '../navigation';
import Quotes from './Quotes';

const GuidedQuotingForm = ({
  onConfigureQuoting,
  personGid,
  leadGid,
  leadId
}: {
  onConfigureQuoting: () => void;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <Container
      customCss={css`
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      <FlexBox
        p={spacings.px24}
        customCss={css`
          overflow: auto;
        `}
      >
        <Quotes leadId={leadId} leadGid={leadGid} personGid={personGid} onGetQuotes={() => onConfigureQuoting()} />
      </FlexBox>
      <FlexBox
        justifyRight
        p={spacings.px24}
        gap={spacings.px12}
        customCss={css`
          border-top: 1px solid ${colors.grey10};
        `}
      >
        {leadId && personGid && featureFlags.newQuotesUI && <FinalizeLead leadId={leadId} personGid={personGid} />}
        {featureFlags.proposalBuilderPage && (
          <Button onClick={() => navigate(`${GuidedSellingPathnames.Proposal}${search}`)}>Build proposal</Button>
        )}
      </FlexBox>
    </Container>
  );
};

export default GuidedQuotingForm;
