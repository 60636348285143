import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { DropDownProps } from '../../../components/core/DropDown/DropDown.utils';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { PriorPolicyHelperSubmitHandler } from '../../../components/PriorPolicyEditor/helpers';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity } from '../../../interfaces';
import { NewPolicyOptions } from '../../../interfaces/INewPolicyOptions';
import {
  areManyAssetsSupported,
  doesPolicyTypeRequireAsset,
  findPolicyType,
  isOnlyOneAssetSupported,
  LIFE_DEFAULT_OPTIONS,
  PL_DEFAULT_OPTIONS
} from '../../../interfaces/IPolicyType';
import { useReconcileOpportunities } from '../../../queries/leads/data_collection/useDataCollectionOpportunities';
// eslint-disable-next-line max-len
import { OPPORTUNITIES_WITH_POLICIES_QUERY_KEY } from '../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
// eslint-disable-next-line max-len
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../queries/people/person_opportunities/usePersonOpportunities';
import { GROUPED_POLICY_QUERY_KEY } from '../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import analytics from '../../../services/analytics';
import useDataEditingForbidden from './useDataEditingForbidden';

type DropDownOption = { value: number | string; label: React.ReactNode };

type UISource = 'summary' | 'tab';

const usePriorPolicyFormUtils = ({
  opportunities,
  uiSource
}: {
  opportunities: IOpportunity[] | undefined;
  uiSource: UISource;
}) => {
  const { lead, isLifeLead } = useGuidedSellingExperienceContext();
  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();
  const [selectedPolicyOptions, setSelectedPolicyOptions] = React.useState<NewPolicyOptions>();
  const queryClient = useQueryClient();

  const policyType = selectedPolicyOptions?.policyType;
  const assets = selectedPolicyOptions?.assets;
  const areManyAssetsRequired = policyType && areManyAssetsSupported(policyType);
  const isOnlyOneAssetRequired = policyType && isOnlyOneAssetSupported(policyType);
  const requireAsset = policyType && doesPolicyTypeRequireAsset(policyType);
  const addHomeFlow = requireAsset && isOnlyOneAssetRequired && !assets?.length;
  const addVehiclesFlow = requireAsset && areManyAssetsRequired && !assets?.length;

  const isDataEditingForbidden = useDataEditingForbidden();

  const opportunitiesWithAssets = (opportunities || []).filter(
    (opp): opp is IOpportunity & { assets: NonNullable<IOpportunity['assets']> } =>
      !!(opp.assets && opp.assets.length > 0)
  );

  const policiesOptions: DropDownProps<DropDownOption>['options'] = opportunitiesWithAssets.map(
    ({ id, title, assets }) => {
      return {
        value: id,
        label: (
          <FlexBox columnDirection>
            <Paragraph color="inherit">{title}</Paragraph>
            <Text color="inherit" type="tiny" className="fs-mask">
              {assets?.[0]?.address.full || '-'}
            </Text>
          </FlexBox>
        )
      };
    }
  );

  const onSelected: DropDownProps<DropDownOption>['onSelected'] = ({ value }) => {
    const opportunity = opportunitiesWithAssets.find(({ id }) => id === value);

    if (opportunity) {
      setSelectedPolicyOptions({
        policyType: opportunity.policy_type,
        assets: opportunity.assets
      });
    } else if (typeof value === 'string') {
      const policyType = findPolicyType(value);
      policyType && setSelectedPolicyOptions({ policyType: policyType.key, assets: [] });
    }
  };

  const priorPolicyEditorCommonProps = {
    uiSource,
    policyType,
    assets,
    cancelHandler: () => setSelectedPolicyOptions(undefined),

    confirmHandler: async (args: Parameters<PriorPolicyHelperSubmitHandler>[0]) => {
      setSelectedPolicyOptions(undefined);
      if (args) {
        args.gid && analytics.track('Prior policy added', { policy_type: args.policy_type, place: uiSource });
        const policyType = findPolicyType(args.policy_type);
        if (lead) {
          await reconcileOpportunities({
            leadId: lead.id,
            insurableInterest: policyType?.insurable_interest || undefined
          });
        }
      }

      queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_WITH_POLICIES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [GROUPED_POLICY_QUERY_KEY] });
    }
  };

  return {
    priorPolicyEditorCommonProps,
    addHomeFlow,
    addVehiclesFlow,
    selectedPolicyOptions,
    policiesOptions: isLifeLead
      ? policiesOptions.concat(LIFE_DEFAULT_OPTIONS)
      : policiesOptions.concat(PL_DEFAULT_OPTIONS),
    onSelected,
    isDataEditingForbidden
  };
};

export { usePriorPolicyFormUtils };
