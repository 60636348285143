/** @jsxImportSource @emotion/react */
import { ClassNames, css, Global } from '@emotion/react';
import { Field, FieldArray, getIn } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { IOpportunity, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { findPolicyTypeLabel, isNonLifeAncillaryPolicyType } from '../../../interfaces/IPolicyType';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { updateOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';
import { Copy, ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import Header from '../../common/typography/Header';
import alert from '../../core/Alert';
import FlexBox from '../../core/FlexBox';
import FormModal from '../../core/FormModal';
import { WarningIcon } from '../../core/icons';
import { ModalSize } from '../../core/Modal';
import PolicyTypeIcon from '../../core/PolicyTypeIcon';
import Tag from '../../core/Tag';
import Text from '../../core/Text';

interface TrailingOpportunitiesModalProps {
  leadId: string | number;
  dispositionType: string;
  opportunities: IOpportunity[];
  closeModal: () => void;
  onDispositionUpdated?: () => void;
}

const SOLD = 'sold';
const LOST = 'lost';

const statusMapping = {
  policy_sold: SOLD,
  lost: LOST
};

const TrailingOpportunitiesModal = ({
  leadId,
  dispositionType,
  opportunities,
  closeModal,
  onDispositionUpdated
}: TrailingOpportunitiesModalProps): JSX.Element => {
  const leadStatus = statusMapping[dispositionType as keyof typeof statusMapping];
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  const opportunitiesToClose = opportunities.filter(
    opportunity => !(leadStatus === SOLD && opportunity.status === OpportunityStatus.Sold)
  );
  const opportunitiesToConfirm = opportunitiesToClose.filter(
    opportunity => !isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened
  );
  const opportunitiesToAutoclose = opportunitiesToClose
    .filter(opportunity => isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened)
    .map(opportunity => ({ ...opportunity, reason: opportunity.reason || 'not_interested' }));

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.large}
      initialValues={{
        opportunities: opportunitiesToConfirm
      }}
      validationSchema={yup.object().shape({
        opportunities: yup.array().of(
          yup.object().shape({
            reason: yup.string().nullable().required('Please, fill in the field')
          })
        )
      })}
      confirmHandler={async values => {
        async function makeRequests(opportunities: IOpportunity[]) {
          for (const opportunity of opportunities) {
            await updateOpportunity({
              leadId,
              opportunityId: opportunity.id,
              params: { status: OpportunityStatus.Lost, reason: opportunity.reason }
            });
          }
        }

        return await makeRequests([...values.opportunities, ...opportunitiesToAutoclose])
          .then(() => updateDisposition({ leadId, disposition_type: dispositionType }))
          .then(() => {
            closeModal();
            onDispositionUpdated?.();
          });
      }}
      cancelHandler={() => {
        alert({
          message: 'You cannot close a lead until all opportunities are closed',
          options: {
            autoClose: 3000
          }
        }).error();
        closeModal();
      }}
      confirmText={`Update to ${leadStatus}`}
      containerTitle={`Update the lead status as ${leadStatus}`}
      renderForm={({ values, errors, touched }) => (
        <FieldArray
          name="opportunities"
          render={() => (
            <>
              <Global
                styles={css`
                  .react-select {
                    margin-bottom: 0 !important;
                  }
                `}
              />
              <ClassNames>
                {({ css }) => (
                  <Container>
                    {values.opportunities.map((opportunity: IOpportunity, index: number) => {
                      const reasonFieldName = `opportunities.${index}.reason`;
                      const error = getIn(errors, reasonFieldName);
                      const touch = getIn(touched, reasonFieldName);

                      return (
                        <Row
                          key={opportunity.id}
                          className={css`
                            &:nth-of-type(n) {
                              margin-bottom: 40px;
                            }
                            &:last-of-type {
                              margin-bottom: 0px;
                            }
                          `}
                        >
                          <Col sm={6}>
                            <Header size={4} className="mt-1 mb-2">
                              <PolicyTypeIcon
                                policyType={opportunity.policy_type}
                                css={css`
                                  margin-right: 8px;
                                `}
                              />
                              <Text bold mr={spacings.px8}>
                                {findPolicyTypeLabel(opportunity.policy_type)}
                              </Text>
                              {opportunity.primary && <Tag label="Primary opportunity" />}
                            </Header>
                            {opportunity.assets?.map(({ gid, description_without_icon }) => (
                              <FlexBox key={gid} pr={16} pt={4}>
                                <Copy
                                  className="fs-mask"
                                  value={description_without_icon}
                                  displayText={description_without_icon}
                                />
                              </FlexBox>
                            ))}
                            {!opportunity.assets?.length && <Text>–</Text>}
                          </Col>
                          <Col sm={5}>
                            <div
                              className={css`
                                margin-top: 4px;
                                margin-left: 12px;
                              `}
                            >
                              Lost reason
                            </div>
                            <ErrorCol
                              name={reasonFieldName}
                              error={error}
                              when={error && touch}
                              messageClassName={css`
                                margin-top: 0;
                                min-height: 0;
                              `}
                            >
                              <Field
                                name={reasonFieldName}
                                component={Select}
                                options={Translations.opportunityLostReasonOptions}
                                data-testid={`${opportunity.policy_type}-select-opportunity-lost-reason`}
                              />
                            </ErrorCol>
                            {leadStatus === LOST && opportunity.status === OpportunityStatus.Sold && (
                              <div
                                className={css`
                                  padding-left: inherit;
                                  padding-right: inherit;
                                  margin-top: 4px;
                                `}
                              >
                                <div
                                  className={css`
                                    border-radius: ${borderRadius}px;
                                    background-color: ${colors.warningBackground};
                                    padding: 8px;
                                    display: flex;
                                  `}
                                >
                                  <WarningIcon
                                    color={colors.statusOrange}
                                    className={css`
                                      margin-right: 6px;
                                    `}
                                  />
                                  This asset has a policy attached
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </Container>
                )}
              </ClassNames>
            </>
          )}
        />
      )}
    />
  );
};

export default TrailingOpportunitiesModal;
