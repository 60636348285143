/** @jsxImportSource @emotion/react */
import { useMsal } from '@azure/msal-react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { Configuration } from '../../constants';
import useUserAvatarUrl from '../../queries/users/useUserAvatarUrl';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import DropDown from '../core/DropDown/DropDown';
import FlexBox from '../core/FlexBox';
import { UserIcon } from '../core/icons';

const AVATAR_IMAGE_PADDING = 2;
const AVATAR_IMAGE_SIZE = 40;
const BUTTON_SIZE = AVATAR_IMAGE_SIZE + AVATAR_IMAGE_PADDING * 2;
const OPTION_HEIGHT = 44;

const EntraUserAvatar = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { data: avatarUrl } = useUserAvatarUrl(authInfo.currentUserId!);

  const options = [
    {
      value: 'launch_twilio_flex',
      label: <span data-testid="launch-twilio-flex-option">Launch Twilio Flex</span>,
      action: () => {
        const newWindow = window.open(Configuration.twilioFlexSignInUrl, '_blank', 'scrollbars=yes,status=yes');
        if (newWindow) {
          newWindow.resizeTo(newWindow.screen.availWidth / 3.8, newWindow.screen.availHeight);
        }
      }
    },
    {
      value: 'logout',
      label: <span data-testid="logout-option">Logout</span>,
      action: () => {
        instance.logoutPopup();
        navigate('/login');
      }
    }
  ];

  return (
    <DropDown
      testId="user-avatar-dropdown"
      options={options}
      onSelected={option => option.action()}
      iconChildren
      customCss={css`
        width: ${BUTTON_SIZE}px;
        height: ${BUTTON_SIZE}px;
        border-radius: ${AVATAR_IMAGE_SIZE + AVATAR_IMAGE_PADDING}px;
        padding: ${AVATAR_IMAGE_PADDING}px;

        &:hover {
          outline: 1px solid ${colors.white};
          cursor: pointer;
        }
        &:active,
        &:focus {
          outline: 2px solid ${colors.white};
          cursor: pointer;
        }
      `}
      customOptionsCss={css`
        margin-top: -${spacings.px8 + BUTTON_SIZE + OPTION_HEIGHT * options.length}px;
      `}
    >
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt="avatar"
          css={css`
            border-radius: ${spacings.px24}px;
            width: ${AVATAR_IMAGE_SIZE}px;
            height: ${AVATAR_IMAGE_SIZE}px;
          `}
        />
      ) : (
        <FlexBox
          justifyCenter
          alignItemsCenter
          customCss={css`
            width: ${AVATAR_IMAGE_SIZE}px;
            height: ${AVATAR_IMAGE_SIZE}px;
          `}
        >
          <UserIcon width={spacings.px24} height={spacings.px24} color={colors.white} />
        </FlexBox>
      )}
    </DropDown>
  );
};

export default EntraUserAvatar;
