import * as yup from 'yup';

import { PERCENTAGE_DEDUCTIBLE_TYPE } from '../../../constants';
import featureFlags from '../../../constants/featureFlags';
import { ICarrier, IMaticPolicy } from '../../../interfaces';
import { CarrierKey } from '../../../interfaces/ICarrier';
import { RealPropertyOpportunity } from '../../../interfaces/IOpportunity';
import {
  doesPolicyTypeHavePrimaryCoverage,
  doesPolicyTypeRequireAsset,
  PolicyTypes
} from '../../../interfaces/IPolicyType';
import {
  existingPolicyEffectiveDateValidation,
  expirationDateValidation,
  newPolicyEffectiveDateValidation,
  REQUIRED_MESSAGE,
  requiredField
} from '../../../utils/yupRules';
import { UNIVERSAL_CONSENT_FIELD_KEY } from '../_components/CarrierInstructions/UniversalCarrierConsent';
import { LEGAL_CONSENT_FIELD_KEY } from '../_components/PolicyLegalConsent';

export const validationSchema = ({
  opportunity,
  policy,
  carriers,
  showPolicyDisclosure
}: {
  opportunity: RealPropertyOpportunity;
  policy: IMaticPolicy | undefined;
  carriers: ICarrier[] | undefined;
  showPolicyDisclosure: boolean;
}) => {
  let isPolicyRequired;
  const showDwelling = (function () {
    return doesPolicyTypeHavePrimaryCoverage(opportunity.policy_type);
  })();

  const showNewClosing =
    !policy && PolicyTypes.find(type => type.key === opportunity.policy_type)?.possible_origination;

  const universalCarrier = (carriers || []).find(c => c.key === CarrierKey.Universal);

  return yup.object().shape({
    assets_gids: yup
      .array()
      .ensure()
      .when(['status'], ([status], schema) => {
        if (status === 'sold' && doesPolicyTypeRequireAsset(opportunity.policy_type)) {
          return schema.min(1, 'Select at least one asset');
        }

        return schema;
      }),
    status: isPolicyRequired ? requiredField : yup.string(),
    reason: yup.string().when(['status'], ([status], schema) => {
      if (status === 'lost') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    policy_type: yup.string().when(['assets_gids', 'status'], ([assets_gids, status], schema) => {
      if (status === 'sold' && assets_gids.length === 0) {
        return schema.matches(/(never-matching-regex)/, 'Please create an asset for this policy type');
      } else if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    carrier_id: yup.number().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    policy_number: yup.string().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    effective_date: yup.date().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return policy ? existingPolicyEffectiveDateValidation(schema) : newPolicyEffectiveDateValidation(schema);
      }

      return schema;
    }),
    period: yup.string().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    payment_method: yup.string().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    new_closing: yup.boolean().when(['status'], ([status], schema) => {
      if (status === 'sold' && showNewClosing) {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    mailing_method: yup.string().when(['status', 'new_closing'], ([status, new_closing], schema) => {
      if (status === 'sold' && new_closing && !policy) {
        return schema.required(
          'If you don`t see an address here, please reach out in the Slack channel to have it added.'
        );
      }

      return schema;
    }),
    agent_id: yup.string().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    expiration_date: yup.date().when(['period', 'effective_date'], ([period, effective_date], schema) => {
      if (period === 'custom') {
        return expirationDateValidation(schema, effective_date);
      }

      return schema;
    }),
    dwelling: yup.number().when(['status'], ([status], schema) => {
      if (status === 'sold' && showDwelling) {
        return schema.required(REQUIRED_MESSAGE).max(2147483647, 'Dwelling is too large');
      }

      return schema;
    }),
    deductible: yup.number().when(['status', 'deductible_type'], ([status, deductible_type], schema) => {
      if (status === 'sold') {
        if (deductible_type === PERCENTAGE_DEDUCTIBLE_TYPE) {
          return schema.required(REQUIRED_MESSAGE).max(100);
        } else {
          return schema.required(REQUIRED_MESSAGE);
        }
      }

      return schema;
    }),
    premium: yup.string().when(['status'], ([status], schema) => {
      if (status === 'sold') {
        return schema.required(REQUIRED_MESSAGE);
      }

      return schema;
    }),
    coinsureds: yup
      .array()
      .ensure()
      .when(['status'], ([status], schema) => {
        if (status === 'sold') {
          return schema.min(1, REQUIRED_MESSAGE);
        }

        return schema;
      }),
    [UNIVERSAL_CONSENT_FIELD_KEY]: yup.boolean().when(['status', 'carrier_id'], ([status, carrier_id], schema) => {
      if (status === 'sold' && carrier_id === universalCarrier?.id) {
        return schema.equals([true], 'You must agree to the terms');
      }

      return schema;
    }),
    [LEGAL_CONSENT_FIELD_KEY]: yup.string().when(['status'], ([status], schema) => {
      if (featureFlags.issuePolicyConsent && status === 'sold' && !policy && showPolicyDisclosure) {
        return schema.equals(['true'], 'You must confirm verbal consent').required(REQUIRED_MESSAGE);
      }

      return schema;
    })
  });
};
