import * as React from 'react';

import IPlannedCallLog from '../interfaces/IPlannedCallLog';

export type PlannedCallAction = { type: 'setCurrentPlannedCall'; payload: IPlannedCallLog | null };

interface State {
  currentPlannedCall: IPlannedCallLog | null;
}

const initialState: State = {
  currentPlannedCall: null
};

const reducer = (state: State, action: PlannedCallAction) => {
  switch (action.type) {
    case 'setCurrentPlannedCall':
      return { ...state, currentPlannedCall: action.payload };
    default:
      return state;
  }
};

const retrieveState = () => {
  const storedState = localStorage.getItem('plannedCallContextState') || '';

  return storedState ? (JSON.parse(storedState) as State) : initialState;
};

const PlannedCallStateContext = React.createContext(initialState);
const PlannedCallDispatchContext = React.createContext<React.Dispatch<PlannedCallAction>>(() => null);

const PlannedCallContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, {}, retrieveState);

  React.useEffect(() => {
    localStorage.setItem('plannedCallContextState', JSON.stringify(state));
  }, [state]);

  return (
    <PlannedCallStateContext.Provider value={state}>
      <PlannedCallDispatchContext.Provider value={dispatch}>{children}</PlannedCallDispatchContext.Provider>
    </PlannedCallStateContext.Provider>
  );
};

const usePlannedCallState = () => React.useContext(PlannedCallStateContext);
const usePlannedCallDispatch = () => React.useContext(PlannedCallDispatchContext);

export { PlannedCallContextProvider, usePlannedCallDispatch, usePlannedCallState };
