// TODO: upgrade Formik and use nested fields and subform
import { Field, Form, Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { CustomerForm as CustomerFormConstants, LICENSE_NUMBER_MAX_LENGTH, Translations } from '../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../constants/addressForm';
import featureFlags from '../../constants/featureFlags';
import { PromptComponent } from '../../hooks/usePromptBlocker';
import authInfo from '../../services/authInfo';
import { phoneFormatter } from '../../utils/formatter';
import { createSmartyStreetsMapping, hasDifferentValues, renameKeys, verifyPromise } from '../../utils/object';
import {
  deprecatedPhoneField,
  emailField,
  getTodayDate,
  minDate,
  minDOBField,
  requiredField,
  ssnField,
  zipField
} from '../../utils/yupRules';
import { CopyField, ErrorCol } from '../common';
import SmartyStreetsAddress from '../common/Address/Address';
import {
  Checkbox,
  Cleave,
  DatePicker,
  Input,
  NumberInput,
  PhoneInput,
  Select
} from '../common/FormikComponents/FormikComponents';
import Incidents, {
  generateInitialValues as generateIncidentsInitialValues,
  INCIDENTS_NESTED_FORM_NAME,
  normalizeValuesBeforeSend as normalizeIncidentsValuesBeforeSend,
  validationSchema as incidentsValidationSchema
} from '../common/nestedForms/Incidents';
import Modal from '../core/Modal';
import Paragraph from '../core/Paragraph';
import SensitiveInfoWrapper from '../core/SensitiveInfoWrapper';
import ContactPreferences from './ContactPreferences';

const {
  GENDER,
  LIVING_ADDRESS_LINE1,
  LIVING_ADDRESS_LINE2,
  LIVING_ADDRESS_STATE,
  LIVING_ADDRESS_ZIP,
  LIVING_ADDRESS_CITY,
  MAILING_ADDRESS_LINE1,
  MAILING_ADDRESS_LINE2,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  MAILING_ADDRESS_CITY
} = CustomerFormConstants;

const BLANK = '';
const LIVING_ADDRESS_PREFIX = 'living_address_';
const MAILING_ADDRESS_PREFIX = 'mailing_address_';

const CURRENT_ADDRESS_AS_MAILING_ADDRESS = 'current_address_as_mailing_address';
const PHONE_EMAIL_ERROR_MESSAGE = 'Please, fill phone or email field';

const COMPARISON_SCHEMA = {
  [LIVING_ADDRESS_LINE1]: MAILING_ADDRESS_LINE1,
  [LIVING_ADDRESS_LINE2]: MAILING_ADDRESS_LINE2,
  [LIVING_ADDRESS_STATE]: MAILING_ADDRESS_STATE,
  [LIVING_ADDRESS_ZIP]: MAILING_ADDRESS_ZIP,
  [LIVING_ADDRESS_CITY]: MAILING_ADDRESS_CITY
};

class CustomerEditor extends React.Component<any, any> {
  state = { hasErrors: false, showUnsavedModal: false };

  componentDidMount() {
    // @ts-expect-error old code
    this.formik && this.formik.setTouched(setNestedObjectValues(this.getFormValues()));
  }

  // @ts-expect-error old code
  getFormValues = () => this.formik.values;

  getData = () => this.formatValues(this.getFormValues());

  mailingAddressAsCurrentAddress = () => {
    const customer = this.props.customer || {};

    const currentAddress = this.generateCurrentAddress(customer);
    const mailingAddress = this.generateMailingAddress(customer);

    return Object.keys(COMPARISON_SCHEMA).every(currentAddressKey => {
      const mailingAddressKey = COMPARISON_SCHEMA[currentAddressKey]!;

      return currentAddress[currentAddressKey] === mailingAddress[mailingAddressKey];
    });
  };

  generateCurrentAddress = (customer: { living_address: Record<string, string> }) => {
    const livingAddress = customer.living_address || {};

    return {
      [LIVING_ADDRESS_LINE1]: livingAddress.line1 || BLANK,
      [LIVING_ADDRESS_LINE2]: livingAddress.line2 || BLANK,
      [LIVING_ADDRESS_CITY]: livingAddress.city || BLANK,
      [LIVING_ADDRESS_STATE]: livingAddress.state || BLANK,
      [LIVING_ADDRESS_ZIP]: livingAddress.zip || BLANK
    };
  };

  generateMailingAddress = (customer: { mailing_address: Record<string, string> }) => {
    const mailingAddress = customer.mailing_address || {};

    return {
      [MAILING_ADDRESS_LINE1]: mailingAddress.line1 || BLANK,
      [MAILING_ADDRESS_LINE2]: mailingAddress.line2 || BLANK,
      [MAILING_ADDRESS_CITY]: mailingAddress.city || BLANK,
      [MAILING_ADDRESS_STATE]: mailingAddress.state || BLANK,
      [MAILING_ADDRESS_ZIP]: mailingAddress.zip || BLANK
    };
  };

  formatValues = ({
    [MAILING_ADDRESS_LINE1]: line1,
    [MAILING_ADDRESS_LINE2]: line2,
    [MAILING_ADDRESS_STATE]: state,
    [MAILING_ADDRESS_ZIP]: zip,
    [MAILING_ADDRESS_CITY]: city,
    ...values
  }) => {
    const livingAddressAttributes = {
      line1: values[LIVING_ADDRESS_LINE1],
      line2: values[LIVING_ADDRESS_LINE2],
      state: values[LIVING_ADDRESS_STATE],
      city: values[LIVING_ADDRESS_CITY],
      zip: values[LIVING_ADDRESS_ZIP]
    };
    // @ts-expect-error old code
    const mailingAddressAttributes = !this.formik.values[CURRENT_ADDRESS_AS_MAILING_ADDRESS]
      ? { line1, line2, state, zip, city }
      : {
          line1: values[LIVING_ADDRESS_LINE1],
          line2: values[LIVING_ADDRESS_LINE2],
          state: values[LIVING_ADDRESS_STATE],
          city: values[LIVING_ADDRESS_CITY],
          zip: values[LIVING_ADDRESS_ZIP]
        };

    // @ts-expect-error old code
    const incidentsAttributes = normalizeIncidentsValuesBeforeSend(values);

    return {
      ...values,
      living_address: livingAddressAttributes,
      mailing_address: mailingAddressAttributes,
      ...incidentsAttributes
    };
  };

  // @ts-expect-error old code
  getErrorsCount = () => Object.keys(this.formik.errors).length;

  validate = () => {
    const hasErrors = !!this.getErrorsCount();
    this.setState({ hasErrors });

    return !hasErrors;
  };

  customMailingAddressOnChangeHandler =
    (setFieldValue: {
      (field: string, value: any, shouldValidate?: boolean | undefined): void;
      (arg0: string, arg1: string): void;
    }) =>
    /* eslint-disable indent */
    () => {
      setFieldValue(MAILING_ADDRESS_LINE1, BLANK);
      setFieldValue(MAILING_ADDRESS_LINE2, BLANK);
      setFieldValue(MAILING_ADDRESS_STATE, BLANK);
      setFieldValue(MAILING_ADDRESS_ZIP, BLANK);
      setFieldValue(MAILING_ADDRESS_CITY, BLANK);
    };
  /* eslint-enable indent */

  onChangeFieldCreator = (name: any) => () => this.props.onChangeField(name);

  generateInitialValues = () => {
    const customer = this.props.customer || {};
    const drivingRecord = this.props.drivingRecord || {};
    const currentAddress = this.generateCurrentAddress(customer);
    const mailingAddress = this.generateMailingAddress(customer);

    return {
      id: customer.id || BLANK,
      gid: customer.gid || BLANK,
      first_name: customer.first_name || BLANK,
      middle_name: customer.middle_name || BLANK,
      last_name: customer.last_name || BLANK,
      marital_status: customer.marital_status || BLANK,
      date_of_birth: customer.date_of_birth || BLANK,
      ssn: customer.ssn || BLANK,
      fico_score: customer.fico_score || BLANK,
      email: customer.email || BLANK,
      phone: customer.phone || BLANK,
      business_phone: customer.business_phone || BLANK,
      secondary_phone: customer.secondary_phone || BLANK,
      [GENDER]: customer[GENDER] || BLANK,
      [CURRENT_ADDRESS_AS_MAILING_ADDRESS]: this.mailingAddressAsCurrentAddress(),
      ...currentAddress,
      ...mailingAddress,
      education: customer.education || BLANK,
      occupation_type: customer.occupation_type || BLANK,
      occupation_since_date: customer.occupation_since_date || BLANK,
      license_status: customer.license_status || BLANK,
      license_number: customer.license_number || BLANK,
      license_state: customer.license_state || BLANK,
      age_first_licensed: customer.age_first_licensed || BLANK,
      [INCIDENTS_NESTED_FORM_NAME]: generateIncidentsInitialValues(drivingRecord)
    };
  };

  createFormikRef = (ref: any) => {
    // @ts-expect-error old code
    this.formik = ref;
  };

  lastRouteTransition = null;
  handleRouteTransition = (routeTransition: null) => {
    this.lastRouteTransition = routeTransition;
    this.setState({ showUnsavedModal: true });
  };

  render() {
    const ModifiedField = ({ name, ...props }: any) => (
      <Field name={name} onChange={this.onChangeFieldCreator(name)} {...props} />
    );

    return (
      <Formik
        initialValues={this.generateInitialValues()}
        validationSchema={yup.object().shape({
          first_name: requiredField,
          last_name: requiredField,
          ssn: ssnField,
          email: emailField,
          phone: deprecatedPhoneField({ loose: authInfo.features.loose_phone_validation }),
          business_phone: deprecatedPhoneField({ loose: authInfo.features.loose_phone_validation }),
          secondary_phone: deprecatedPhoneField({ loose: authInfo.features.loose_phone_validation }),
          date_of_birth: minDOBField(),
          [LIVING_ADDRESS_ZIP]: zipField,
          [INCIDENTS_NESTED_FORM_NAME]: incidentsValidationSchema,
          occupation_since_date: yup
            .date()
            .min(minDate, "Date can't be earlier than 1799 year")
            .max(getTodayDate(), "Date can't be later than today")
        })}
        enableReinitialize
        validate={({ email, phone }) => {
          if (!email && !phone) {
            return {
              email: PHONE_EMAIL_ERROR_MESSAGE,
              phone: PHONE_EMAIL_ERROR_MESSAGE
            };
          }

          return {};
        }}
        innerRef={this.createFormikRef}
        onSubmit={this.getData}
      >
        {({ errors, values, setFieldValue, initialValues }) => (
          <Form className={this.props.className} autoComplete="off">
            <Row className="mt-5 mb-5">
              <Col sm={2} className="section-title">
                Contact Info
              </Col>
              <Col sm={9}>
                <Row>
                  <ErrorCol sm={8} id="email" error={errors.email as any} when={this.state.hasErrors}>
                    <Label htmlFor="email-input">Email</Label>
                    <CopyField value={values.email}>
                      <ModifiedField
                        component={Input}
                        id="email-input"
                        name="email"
                        className="fs-mask"
                        onChangeInputModifier={(value: string) => value.trim()}
                      />
                    </CopyField>
                  </ErrorCol>
                </Row>
                <Row>
                  <ErrorCol sm={4} id="phone" error={errors.phone as any} when={this.state.hasErrors}>
                    <Label htmlFor="phone-input">Phone</Label>
                    <CopyField value={phoneFormatter(values.phone)}>
                      <ModifiedField id="phone-input" component={PhoneInput} name="phone" className="fs-mask" />
                    </CopyField>
                  </ErrorCol>
                  <ErrorCol sm={4} id="business_phone" error={errors.business_phone as any} when={this.state.hasErrors}>
                    <Label htmlFor="business-phone-input">Business Phone</Label>
                    <CopyField value={phoneFormatter(values.business_phone)}>
                      <ModifiedField
                        id="business-phone-input"
                        component={PhoneInput}
                        name="business_phone"
                        className="fs-mask"
                      />
                    </CopyField>
                  </ErrorCol>
                  <ErrorCol
                    sm={4}
                    id="secondary_phone"
                    error={errors.secondary_phone as any}
                    when={this.state.hasErrors}
                  >
                    <Label htmlFor="secondary-phone-input">Secondary Phone</Label>
                    <CopyField value={phoneFormatter(values.secondary_phone)}>
                      <ModifiedField
                        id="secondary-phone-input"
                        component={PhoneInput}
                        name="secondary_phone"
                        className="fs-mask"
                      />
                    </CopyField>
                  </ErrorCol>
                </Row>
                <Row>
                  <ContactPreferences personGid={this.props.customer.gid} />
                </Row>
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col sm={2} className="section-title">
                Personal Info
              </Col>
              <Col sm={9}>
                <Row>
                  <ErrorCol sm={4} id="first_name" error={errors.first_name as any} when={this.state.hasErrors}>
                    <Label htmlFor="first-name-input">First Name *</Label>
                    <ModifiedField id="first-name-input" name="first_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                  <ErrorCol sm={4}>
                    <Label htmlFor="middle-name-input">Middle Name</Label>
                    <ModifiedField id="middle-name-input" name="middle_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                  <ErrorCol sm={4} id="last_name" error={errors.last_name as any} when={this.state.hasErrors}>
                    <Label htmlFor="last-name-input">Last Name *</Label>
                    <ModifiedField id="last-name-input" name="last_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Row>
                      <ErrorCol>
                        <Label htmlFor="marital-status-input">Marital Status</Label>
                        <ModifiedField
                          inputId="marital-status-input"
                          component={Select}
                          name="marital_status"
                          options={Translations.maritalStatusOptions}
                          placeholder="Select"
                        />
                      </ErrorCol>
                      <ErrorCol sm={4} error={errors.date_of_birth as any} when>
                        <Label htmlFor="date_of_birth-input">DOB</Label>
                        <ModifiedField
                          id="date_of_birth-input"
                          component={DatePicker}
                          name="date_of_birth"
                          className="fs-mask"
                          maxDate={getTodayDate()}
                          minDate={minDate}
                        />
                      </ErrorCol>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <Row>
                      <ErrorCol sm={4}>
                        <Label htmlFor="gender-input">Gender</Label>
                        <ModifiedField
                          inputId="gender-input"
                          component={Select}
                          name={GENDER}
                          options={Translations.genderOptions}
                          placeholder="Select"
                        />
                      </ErrorCol>
                      <SensitiveInfoWrapper>
                        <ErrorCol error={errors.ssn as any} when={this.state.hasErrors}>
                          <Label htmlFor="ssn-input">SSN</Label>
                          <ModifiedField
                            id="ssn-input"
                            component={Cleave}
                            className="form-control fs-mask"
                            name="ssn"
                            options={{
                              delimiter: '-',
                              blocks: [3, 2, 4]
                            }}
                          />
                        </ErrorCol>
                      </SensitiveInfoWrapper>
                      <ErrorCol sm={3}>
                        <Label htmlFor="fico-score-input">Fico Score</Label>
                        <ModifiedField
                          id="fico-score-input"
                          component={Input}
                          name="fico_score"
                          className="fs-mask"
                          disabled={!authInfo.features.edit_fico_score}
                        />
                      </ErrorCol>
                    </Row>
                  </Col>
                </Row>
                {!this.props.shortForm && (
                  <Row>
                    <Col sm={4}>
                      <Label htmlFor="education-input">Education</Label>
                      <Field
                        inputId="education-input"
                        component={Select}
                        name="education"
                        options={Translations.educationOptions}
                        placeholder="Select"
                      />
                    </Col>
                    <Col sm={4}>
                      <Label htmlFor="occupation-input">Occupation</Label>
                      <Field
                        inputId="occupation-input"
                        component={Select}
                        name="occupation_type"
                        options={Translations.occupationTypeOptions}
                        placeholder="Select"
                      />
                    </Col>
                    <ErrorCol sm={4} when error={errors.occupation_since_date as any}>
                      <Label htmlFor="current-job-start-date-input">Current job start date</Label>
                      <ModifiedField
                        id="current-job-start-date-input"
                        component={DatePicker}
                        name="occupation_since_date"
                        minDate={minDate}
                        maxDate={getTodayDate()}
                      />
                    </ErrorCol>
                  </Row>
                )}
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col sm={2} className="section-title">
                Current address
              </Col>
              <Col sm={9}>
                {/* @ts-expect-error old code */}
                <SmartyStreetsAddress
                  label="Customer address"
                  placeholder="Search for an address"
                  defaultValue={
                    renameKeys(API_TO_SMARTY_STREETS_MAPPING, this.props.customer?.living_address || {}) as any
                  }
                  fallbackNames={createSmartyStreetsMapping(LIVING_ADDRESS_PREFIX)}
                />
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col sm={2} className="section-title">
                Mailing address
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm="auto">
                    <ModifiedField
                      label="Mailing address is the same as current"
                      name={CURRENT_ADDRESS_AS_MAILING_ADDRESS}
                      component={Checkbox}
                      onChange={this.customMailingAddressOnChangeHandler(setFieldValue)}
                    />
                  </Col>
                </Row>
                {!values[CURRENT_ADDRESS_AS_MAILING_ADDRESS] && (
                  <div className="mt-4">
                    {/* @ts-expect-error old code */}
                    <SmartyStreetsAddress
                      label="Mailing address"
                      placeholder="Search for an address"
                      defaultValue={
                        renameKeys(API_TO_SMARTY_STREETS_MAPPING, this.props.customer?.mailing_address || {}) as any
                      }
                      fallbackNames={createSmartyStreetsMapping(MAILING_ADDRESS_PREFIX)}
                    />
                  </div>
                )}
              </Col>
            </Row>
            {!this.props.shortForm && (
              <SensitiveInfoWrapper>
                <Row className="mt-5 mb-5">
                  <Col sm={2} className="section-title">
                    Driver Info
                  </Col>
                  <Col sm={9}>
                    <Row>
                      <Col sm={4}>
                        <Label htmlFor="license-status-select">License status</Label>
                        <Field
                          inputId="license-status-select"
                          component={Select}
                          name="license_status"
                          options={Translations.licenseStatusOptions}
                          placeholder="Select"
                        />
                      </Col>
                      <Col sm={4}>
                        <Label htmlFor="license-number-input">License number</Label>
                        <Field
                          id="license-number-input"
                          component={Input}
                          maxLength={LICENSE_NUMBER_MAX_LENGTH}
                          name="license_number"
                          className="fs-mask"
                        />
                      </Col>
                      <Col sm={4}>
                        <Label htmlFor="license-state-select">License state</Label>
                        <Field
                          className="fs-mask"
                          inputId="license-state-select"
                          component={Select}
                          name="license_state"
                          options={Translations.usaStates}
                          placeholder="Select"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Label htmlFor="age-first-licensed-input">Age first licensed</Label>
                        <Field
                          id="age-first-licensed-input"
                          component={NumberInput}
                          name="age_first_licensed"
                          className="fs-mask"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2} className="section-title">
                    Incidents
                  </Col>
                  <Col sm={6}>
                    {/* @ts-expect-error old code */}
                    <Incidents formName={INCIDENTS_NESTED_FORM_NAME} residentialState={values[LIVING_ADDRESS_STATE]} />
                  </Col>
                </Row>
              </SensitiveInfoWrapper>
            )}
            {/* @ts-expect-error old code */}
            <PromptComponent message={this.handleRouteTransition} when={hasDifferentValues(initialValues, values)} />
            {this.state.showUnsavedModal && !featureFlags.hideSensitiveSataForPnc && (
              <Modal
                containerTitle="Please save changes before leaving."
                confirmText="Save"
                confirmationInProgress={this.props.isUpdatingCustomer}
                confirmHandler={() => {
                  try {
                    return verifyPromise(this.props.updateCustomer()).then(() => {
                      // @ts-expect-error old code
                      this.lastRouteTransition.retry();
                    });
                  } catch (_) {
                    this.setState({ showUnsavedModal: false });
                  }
                }}
                cancelHandler={() => this.setState({ showUnsavedModal: false })}
              >
                <Paragraph type="large">{"Would you like to save the changes you've made to the page?"}</Paragraph>
              </Modal>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

/* @ts-expect-error old code */
CustomerEditor.propTypes = {
  customer: PropTypes.object,
  drivingRecord: PropTypes.object,
  className: PropTypes.string,
  onChangeField: PropTypes.func,
  shortForm: PropTypes.bool,
  updateCustomer: PropTypes.func,
  isUpdatingCustomer: PropTypes.bool
};

/* @ts-expect-error old code */
CustomerEditor.defaultProps = {
  customer: null,
  drivingRecord: null,
  className: null,
  onChangeField: () => {},
  shortForm: false,
  updateCustomer: () => {},
  isUpdatingCustomer: false
};

export default CustomerEditor;
