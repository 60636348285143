import { useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import { Link } from '../../../components/common';
import Container from '../../../components/core/Container';
import BaseForm from '../../../components/core/forms/BaseForm';
import { MultiSelectField, SelectField } from '../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Text from '../../../components/core/Text';
import { NewPolicyOptions } from '../../../interfaces/INewPolicyOptions';
import {
  areManyAssetsSupported,
  isOnlyOneAssetSupported,
  PolicyType,
  PolicyTypes
} from '../../../interfaces/IPolicyType';
import usePersonAssets from '../../../queries/people/person_assets/usePersonAssets';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';

interface PriorPolicyAssetsPickerProps {
  leadId: number | undefined;
  personGid: string;
  cancelHandler: () => void;
  confirmHandler: ({ policyType, assets }: NewPolicyOptions) => void;
}

const NotifyPolicyTypeUpdated = ({
  onPolicyTypeUpdated
}: {
  onPolicyTypeUpdated: (policyType: PolicyType) => void;
}) => {
  const {
    values: { policy_type }
  } = useFormikContext<{ policy_type: PolicyType }>();

  React.useEffect(() => {
    onPolicyTypeUpdated(policy_type);
  }, [onPolicyTypeUpdated, policy_type]);

  return null;
};

const PriorPolicyAssetsPicker = ({
  leadId,
  personGid,
  cancelHandler,
  confirmHandler
}: PriorPolicyAssetsPickerProps): JSX.Element => {
  const [selectedPolicyType, setSelectedPolicyType] = React.useState<PolicyType>();

  const { data: assetsForNewPolicy, isFetching } = usePersonAssets({
    personGid,
    policyType: selectedPolicyType,
    enabled: !!selectedPolicyType
  });

  const areManyAssetsRequired = !!selectedPolicyType && areManyAssetsSupported(selectedPolicyType);
  const isOnlyOneAssetRequired = !!selectedPolicyType && isOnlyOneAssetSupported(selectedPolicyType);

  return (
    <Modal
      containerTitle="Create prior policy"
      size={ModalSize.medium}
      showCancelButton={false}
      cancelHandler={cancelHandler}
    >
      <BaseForm
        enableReinitialize
        initialValues={{
          policy_type: '' as PolicyType,
          asset_gids: [] as string[],
          asset_gid: ''
        }}
        validationSchema={yup.object().shape({
          policy_type: requiredField,
          ...(areManyAssetsRequired ? { asset_gids: yup.array().min(1, REQUIRED_MESSAGE) } : {}),
          ...(isOnlyOneAssetRequired ? { asset_gid: requiredField } : {})
        })}
        onSubmit={({ policy_type, asset_gids, asset_gid }) => {
          let assets: NewPolicyOptions['assets'] = [];

          if (areManyAssetsRequired) {
            assets = assetsForNewPolicy?.filter(a => asset_gids.includes(a.gid)) || [];
          } else if (isOnlyOneAssetRequired) {
            assets = assetsForNewPolicy?.find(a => a.gid === asset_gid)
              ? [assetsForNewPolicy.find(a => a.gid === asset_gid)!]
              : [];
          } else {
            assets = [];
          }

          confirmHandler({ policyType: policy_type, assets });
        }}
        cancelHandler={cancelHandler}
        pt={16}
        pb={16}
        pl={16}
        pr={16}
        disabled={isFetching}
        renderForm={() => {
          return (
            <>
              <NotifyPolicyTypeUpdated onPolicyTypeUpdated={setSelectedPolicyType} />
              <SelectField
                required
                label="Policy type"
                id="policy_type"
                name="policy_type"
                testId="policy-type-select"
                options={PolicyTypes.map(({ key, name }) => ({ key, value: name }))}
              />
              {areManyAssetsRequired && (
                <MultiSelectField
                  fsMask
                  required
                  placeholder=""
                  label="Assets"
                  id="asset_gids"
                  name="asset_gids"
                  testId="assets-select"
                  options={
                    assetsForNewPolicy?.map(({ gid, description }) => ({
                      key: gid,
                      value: description
                    })) || []
                  }
                />
              )}
              {isOnlyOneAssetRequired && (
                <SelectField
                  fsMask
                  required
                  placeholder=""
                  label="Asset"
                  id="asset_gid"
                  name="asset_gid"
                  testId="assets-select"
                  options={
                    assetsForNewPolicy?.map(({ gid, description }) => ({
                      key: gid,
                      value: description
                    })) || []
                  }
                />
              )}
              {(areManyAssetsRequired || isOnlyOneAssetRequired) && (
                <Container mt={spacings.px8}>
                  {leadId && (
                    <Link to={`/leads/${leadId}/assets`} data-testid="create-new-asset">
                      <Text color={colors.azure50} bold>
                        Or create new asset
                      </Text>
                    </Link>
                  )}
                </Container>
              )}
            </>
          );
        }}
      />
    </Modal>
  );
};

export default PriorPolicyAssetsPicker;
