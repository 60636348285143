import { IPerson, IRelatedPerson } from '../../../../../interfaces';
import { phoneFormatter } from '../../../../../utils/formatter';
import { useAnswersWithVerificationStatus } from '../../../_hooks/useAnswersWithVerificationStatus';

export const useCustomerProfileInfoTabInitialValues = ({
  person,
  relatedPeople
}: {
  person: IPerson;
  relatedPeople: IRelatedPerson[];
}) => {
  const personGid = person.gid;
  const answersWithVerificationStatus = useAnswersWithVerificationStatus({
    personGid,
    relatedPeopleGids: relatedPeople.map(({ gid }) => gid)
  });

  const personInitialValues = {
    person: {
      gid: person.gid,
      first_name: person.first_name,
      last_name: person.last_name,
      middle_name: person.middle_name || '',
      date_of_birth: person.date_of_birth || '',
      email: person.email || '',
      phone: phoneFormatter(person.phone) || '',
      phone_secondary: phoneFormatter(person.secondary_phone) || '',
      phone_business: phoneFormatter(person.business_phone) || '',
      gender: person.gender || '',
      marital_status: person.marital_status || ('' as IRelatedPerson['marital_status']),
      license_number: person.license_number || '',
      license_state: person.license_state || '',
      mailing_address: person.mailing_address || ({} as IPerson['mailing_address'])
    }
  };

  const relatedPeopleInitialValues = relatedPeople.map(relatedPerson => ({
    gid: relatedPerson.gid,
    first_name: relatedPerson.first_name,
    last_name: relatedPerson.last_name,
    middle_name: relatedPerson.middle_name || '',
    date_of_birth: relatedPerson.date_of_birth || '',
    email: relatedPerson.email || '',
    phone: phoneFormatter(relatedPerson.phone) || '',
    gender: relatedPerson.gender || '',
    marital_status: relatedPerson.marital_status || ('' as IRelatedPerson['marital_status']),
    license_number: relatedPerson.license_number || '',
    license_state: relatedPerson.license_state || '',
    relation_kind: relatedPerson.kind || ''
  }));

  return {
    answersWithVerificationStatus,
    relatedPeopleInitialValues,
    personInitialValues
  };
};
