import { css } from '@emotion/react';
import * as React from 'react';

import Button from '../../components/core/buttons/Button';
import Modal, { ModalSize } from '../../components/core/Modal';
import Paragraph from '../../components/core/Paragraph';

export const helpText = `Description can contain dynamic values. Example - Hello, {{first_name}}.

Available variables:
first_name
full_name
user_name
savings
brand_name
primary_opportunity
marketing_opportunity
lead_capture_method
attributed_marketing_medium
lead_create_date
prior_policy_expiration_date
prior_policy_cost
prior_policy_carrier
loan_officer_name
loan_closing_date
engagement_experience_type
property_address_state
property_address
garaging_address_state
garaging_address

Supported markdown:

<a> - Links. Example of usage:
some text with <a href="https://google.com">Google home page</a>

<b> - Bold text. Example of usage:
some text with <b>Bold</b> words.

<p> - Paragraph. Example of usage:
First line. <p>Second paragraph. Second sentence of second paragraph</p>

<li> - List item. Example of usage:
<li>First item</li>
<li>Second item</li>

<highlighted> - Highlighted piece of text. Example of usage:
Some sentence with <highlighted>Important</highlighted> word`;

const HelpModal = () => {
  const [showHelp, setShowHelp] = React.useState(false);
  return (
    <>
      <Button onClick={() => setShowHelp(true)}>Show help</Button>
      {showHelp && (
        <Modal containerTitle="Help" size={ModalSize.large} cancelHandler={() => setShowHelp(false)}>
          <Paragraph
            customCss={css`
              white-space: break-spaces;
            `}
          >
            {helpText}
          </Paragraph>
        </Modal>
      )}
    </>
  );
};

export default HelpModal;
