/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import { ChevronLeft, ShieldIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { useToggle } from '../../../hooks';
import { IPerson } from '../../../interfaces';
import { servicerOrLender } from '../../../interfaces/ILender';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import useVerticalTabsCallback from '../_hooks/useVerticalTabsCallback';

const LoansSummary = ({ person }: { person: IPerson }) => {
  const { data: loans } = usePersonLoans({ personGid: person.gid });
  const primaryLoan = loans?.[0];
  const company = servicerOrLender({ servicer: primaryLoan?.servicer, lender: primaryLoan?.lender });
  const mortgageeClause = company?.business_name;
  const loanAsset = primaryLoan?.home || primaryLoan?.vehicle;
  const [isOpen, toggleOpen] = useToggle(false);
  const { onTabClose, setSelectedIndex, selectedIndex } = useVerticalTabsCallback();

  React.useEffect(() => {
    if (selectedIndex !== 2 && isOpen) {
      toggleOpen();
    }
  }, [selectedIndex, toggleOpen, isOpen]);

  return (
    <FlexBox
      border
      backgroundColor={colors.white}
      roundBorder
      columnDirection
      p={spacings.px16}
      css={css`
        width: 440px;
      `}
      onClick={() => {
        toggleOpen();
        if (isOpen) {
          onTabClose();
        } else {
          setSelectedIndex(2);
        }
      }}
    >
      <FlexBox justifySpaceBetween>
        <FlexBox gap={spacings.px8} alignItemsCenter mb={spacings.px12}>
          <Paragraph type="large" bold>
            Loans
          </Paragraph>
          {primaryLoan && (
            <Text
              type="tiny"
              ph={spacings.px4}
              color={colors.grey30}
              customCss={css`
                border-radius: 4px;
                border: 1px solid ${colors.grey10};
                background-color: ${colors.grey5};
                line-height: 1.5;
              `}
            >
              {loans?.length}
            </Text>
          )}
        </FlexBox>
        <ChevronLeft
          css={css`
            transform: rotate(${isOpen || selectedIndex === 2 ? '0deg' : '180deg'});
            transition: transform 0.2s;
          `}
          color={colors.black}
        />
      </FlexBox>
      {!loans?.length && (
        <Paragraph
          color={colors.grey60}
          customCss={css`
            text-align: center;
          `}
        >
          No loan information found
        </Paragraph>
      )}
      {primaryLoan && (
        <>
          <FlexBox gap={spacings.px8} alignItemsCenter>
            <ShieldIcon />
            {mortgageeClause && <Paragraph>{mortgageeClause}</Paragraph>}
          </FlexBox>
          {loanAsset?.description_without_icon && (
            <FlexBox pl={spacings.px28}>
              <Text color={colors.grey80}>For</Text>
              <Copy value={loanAsset.description_without_icon}>{loanAsset.description_without_icon}</Copy>
            </FlexBox>
          )}
          {primaryLoan.loan_number && (
            <FlexBox gap={spacings.px4} pl={spacings.px28}>
              <Text color={colors.grey60}>Loan number:</Text>{' '}
              <Copy value={primaryLoan.loan_number}>{primaryLoan.loan_number}</Copy>
            </FlexBox>
          )}
        </>
      )}
    </FlexBox>
  );
};

export default LoansSummary;
