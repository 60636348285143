/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';

import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Container from '../../components/core/Container';
import VirtualizedTable from '../../components/core/VirtualizedTable/VirtualizedTable';
import { SORT_ORDER_DESC, Translations } from '../../constants';
import { useDocumentTitle } from '../../hooks';
import { IDashboardLead, ILeadMode } from '../../interfaces/IDashboardLead';
import { DispositionType } from '../../interfaces/IDisposition';
import { PrimaryOpportunities } from '../../interfaces/ISourceDimensions';
import { LicensingTypes, UserRoleCategory } from '../../interfaces/IUser';
import {
  LeadsDashboardFilters as ILeadsDashboardFilters,
  LeadsDashboardSorting,
  useLeadsVirtualized
} from '../../queries/leads/useLeads';
import usePartners from '../../queries/partners/usePartners';
import authInfo from '../../services/authInfo';
import { queryClient } from '../../test-utils';
import colors from '../../theme/colors';
import { defaultFontValues } from '../../theme/typography';
import { spacings } from '../../theme/variables';
import { deepEqual } from '../../utils/array';
import LeadsDashboardFilters from '../LeadsDashboard/LeadsDashboardFilters/index';
import { LeadsDashboardColumnKeys } from './DashboardTable.util';
import { columns } from './DashboardTableColumns.util';

const tabCSS = (isActive: boolean) => css`
  font-family: ${defaultFontValues.fontFamily};
  text-transform: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
  padding: 0;
  width: unset;
  min-height: 20px;
  max-width: auto;

  max-width: fit-content;
  width: unset;
  min-width: inherit;

  ${isActive &&
  css`
    font-weight: 700;
    font-color: ${colors.black};
  `}
`;
const tabsWrapperCSS = css`
  border-bottom: 1px solid ${colors.grey10};
  margin: 12px 0 20px;
  height: 24px;
  min-height: 24px;

  > span {
    height: 3px;
    background: ${colors.azure50};
  }
`;

const LEADS_FILTERS_STORAGE_KEY = 'leadsFilters';
const DEFAULT_SORTING = { sort_column: LeadsDashboardColumnKeys.LastInteraction, sort_order: SORT_ORDER_DESC };

const dispositionTabs = [
  { key: DispositionType.Pipeline, label: 'Pipeline' },
  { key: DispositionType.Lost, label: 'Lost/Archived' },
  { key: DispositionType.PolicySold, label: 'Sold' },
  { key: 'all_leads', label: 'All leads' }
];

const defaultFilters = () => ({
  lead_mode: [ILeadMode.RegularLead],
  dispositions: [DispositionType.Pipeline],
  agent_ids: [authInfo.currentUserId!]
});

const getPrimaryOpportunityFilterKeysWithoutLife = () =>
  Translations.primaryOpportunityTypeOptions
    .filter(option => option.key !== PrimaryOpportunities.Life)
    .map(option => option.key);

const getPrimaryTabBasedOnFilters = (filters: ILeadsDashboardFilters): string => {
  if (!filters['dispositions']) {
    return DispositionType.Pipeline;
  }

  if (filters['dispositions'].length === 1) {
    const dispositionTab = dispositionTabs.find(tab => tab.key === filters['dispositions']?.[0]);
    return dispositionTab?.key || '';
  }

  return '';
};

const DashboardTable = () => {
  useDocumentTitle('Sales dashboard');

  const [page, setPage] = useState(1);
  const [leadsData, setLeadsData] = useState<IDashboardLead[]>([]);
  const [isTableReloading, setIsTableReloading] = useState(true);
  const [sorting, setSorting] = useState<LeadsDashboardSorting>(DEFAULT_SORTING);
  const [filters, setFilters] = useState<ILeadsDashboardFilters>(() => defaultFilters());
  const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
  const { data: partners } = usePartners();
  const [tabValue, setTabValue] = useState(getPrimaryTabBasedOnFilters(filters));

  const lifeOpportunityFilterIsAvailable = Boolean(
    authInfo.roleCategory === UserRoleCategory.Admin || authInfo.licensingTypes?.includes(LicensingTypes.Life)
  );

  const adjustedFilters = (() => {
    if (lifeOpportunityFilterIsAvailable) {
      return filters;
    }

    if (filters['primary_opportunities'] && filters['primary_opportunities'].length > 0) {
      const filterWithoutLife = filters['primary_opportunities'].filter(option => option !== PrimaryOpportunities.Life);

      const nonEmptyFilter =
        filterWithoutLife.length > 0 ? filterWithoutLife : getPrimaryOpportunityFilterKeysWithoutLife();

      return {
        ...filters,
        primary_opportunities: nonEmptyFilter
      };
    } else {
      return {
        ...filters,
        primary_opportunities: getPrimaryOpportunityFilterKeysWithoutLife()
      };
    }
  })();

  const { data, isFetching } = useLeadsVirtualized({
    page,
    ...sorting,
    ...adjustedFilters
  });

  useEffect(() => {
    const flatData = data?.pages?.length ? data?.pages?.flatMap(page => page.leads) : [];

    setLeadsData(prevDataArray => {
      if (!deepEqual(prevDataArray, flatData) && !hasFiltersChanged) {
        return [...prevDataArray, ...flatData];
      }

      if (hasFiltersChanged) {
        setHasFiltersChanged(false);
        return [...flatData];
      }

      return prevDataArray;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!isFetching) {
      setIsTableReloading(false);
    }
  }, [isFetching]);

  const totalDBRowCount = data?.pages?.[0]?.count ? data?.pages?.[0]?.count : 0;
  const totalFetched = leadsData.length;

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && totalFetched < totalDBRowCount) {
          setPage(page + 1);
        }
      }
    },
    [isFetching, totalFetched, totalDBRowCount, page]
  );

  const onSortingChanged = (newSorting: LeadsDashboardSorting) => {
    setPage(1);
    setLeadsData([]);
    setSorting(newSorting);
    setIsTableReloading(true);
  };

  const onFiltersSubmit = (filters: Record<string, any>) => {
    setHasFiltersChanged(true);
    setPage(1);
    queryClient.invalidateQueries({ queryKey: ['leads'] });
    setFilters(filters);

    localStorage.setItem(LEADS_FILTERS_STORAGE_KEY, JSON.stringify(filters));

    if (filters['dispositions'] && filters['dispositions'].length === 1) {
      const dispositionTab = dispositionTabs.find(tab => tab.key === filters['dispositions'][0]);
      setTabValue(dispositionTab?.key || '');
    } else {
      setTabValue('');
    }

    setIsTableReloading(true);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== tabValue) {
      setTabValue(dispositionTabs.find(tab => tab?.key === newValue) ? (dispositionTabs[0]?.key as string) : '');
      onFiltersSubmit({ ...filters, dispositions: [dispositionTabs.find(tab => tab?.key === newValue)?.key] });

      if (newValue === 'all_leads') {
        onSortingChanged({
          sort_column: LeadsDashboardColumnKeys.CreatedAt,
          sort_order: SORT_ORDER_DESC
        });
      } else if (sorting !== DEFAULT_SORTING) {
        onSortingChanged(DEFAULT_SORTING);
      }
    }
  };

  return (
    <Container fitParentWidth>
      <FiltersSubHeader headerText="Leads dashboard" salesDashboardHeader salesPipelineDashboard>
        <LeadsDashboardFilters
          filters={filters}
          onFilterReset={() => {
            setFilters(defaultFilters);
            setTabValue(DispositionType.Pipeline);
            localStorage.setItem(LEADS_FILTERS_STORAGE_KEY, JSON.stringify(defaultFilters));
          }}
          onFilterSubmit={onFiltersSubmit}
          shouldDisableDisposition={[
            DispositionType.Pipeline,
            DispositionType.PolicySold,
            DispositionType.Lost
          ].includes(tabValue)}
          salesPipelineDashboard
          message=""
        />
      </FiltersSubHeader>

      <Container fitParentWidth mt={spacings.px8} ph={spacings.px24}>
        <Tabs css={tabsWrapperCSS} value={tabValue} onChange={(event, tabValue) => handleTabChange(event, tabValue)}>
          {dispositionTabs.map(tab => (
            <Tab value={tab.key} label={tab.label} key={tab.key} css={tabCSS(tab.key === tabValue)} />
          ))}
        </Tabs>
      </Container>

      <Container fitParentWidth mt={spacings.px8} ph={spacings.px24}>
        <VirtualizedTable
          data={leadsData || []}
          columns={columns(partners)}
          testId="leads"
          isLoading={isTableReloading}
          isFetching={isFetching}
          sorting={sorting}
          onSortingChange={onSortingChanged}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        />
      </Container>
    </Container>
  );
};

export default DashboardTable;
