/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';

import { Link } from '../../components/common';
import NewTooltip from '../../components/common/Tooltip/NewTooltip';
import Copy from '../../components/core/Copy';
import FlexBox from '../../components/core/FlexBox';
import { HotIcon, LinkSharpIcon, ShieldWarningIcon, SnowIcon, WarningIcon } from '../../components/core/icons';
import Tag from '../../components/core/Tag';
import TelephonyTooltip from '../../components/core/TelephonyTooltip';
import Text from '../../components/core/Text';
import { Translations } from '../../constants';
import featureFlags from '../../constants/featureFlags';
import { IDashboardLead, IPartner } from '../../interfaces';
import { ILeadMode } from '../../interfaces/IDashboardLead';
import { DispositionType } from '../../interfaces/IDisposition';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { findBrandName } from '../../queries/partners/useBrand';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { borderRadius, spacings } from '../../theme/variables';
import { daysToNow, ellipsis, phoneFormatter } from '../../utils/formatter';

export enum LeadsDashboardColumnKeys {
  CustomerName = 'customer_name',
  PhoneNumber = 'phone_number',
  LeadID = 'lead_id',
  Partner = 'partner',
  ResidentialState = 'residential_state',
  DispositionType = 'disposition_type',
  CreatedAt = 'created_at',
  LenderName = 'lender_name',
  LeadCadence = 'lead_cadence_flow',
  LeadProposal = 'lead_proposal_created',
  LastInteraction = 'last_interaction_attempt'
}

const renderCustomerNameCell = ({ customer_id, customer_name, lead_mode }: IDashboardLead) => {
  const isFakeLead = lead_mode === ILeadMode.FakeLead;
  const customerName = isFakeLead ? 'Unknown Unknown' : customer_name;

  return (
    <FlexBox alignItemsCenter gap={spacings.px8}>
      {customer_id && (
        <Link
          css={css`
            font-weight: 600;
            color: ${colors.black};
            cursor: pointer;
          `}
          className="fs-mask"
          to={{ pathname: `/customers/${customer_id}` }}
        >
          {customerName.length > 40 ? (
            <span data-for={`customer-name-tooltip-${customer_id}`} data-tip={customerName}>
              <span>{ellipsis(customerName, 40)}</span>
              <NewTooltip id={`customer-name-tooltip-${customer_id}`} />
            </span>
          ) : (
            customerName
          )}
        </Link>
      )}
      {isFakeLead && (
        <>
          <Tag
            backgroundColor={colors.statusRed}
            label="John Doe lead"
            borderRadius={borderRadius}
            textType="tiny"
            data-for={`fake-lead-tooltip-${customer_id}`}
            data-tip="Doesn’t take part in search, invisible for agents"
          />
          <NewTooltip id={`fake-lead-tooltip-${customer_id}`} />
        </>
      )}
    </FlexBox>
  );
};

const renderPhoneCell = ({ phone, customer_gid, do_not_contact }: IDashboardLead) => {
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  const number = (!do_not_contact || canCallWithDNC) && (
    <FlexBox>
      <TelephonyTooltip phone={phone} personGid={customer_gid} shouldRenderSimplified>
        <ClassNames>
          {({ css }) => (
            <Copy
              className={
                do_not_contact && authInfo.features.do_not_contact_call_available
                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                  : 'fs-mask'
              }
              value={phoneFormatter(phone)}
            >
              {phoneFormatter(phone)}
            </Copy>
          )}
        </ClassNames>
      </TelephonyTooltip>
    </FlexBox>
  );

  const dnc = do_not_contact && (
    <Text color={colors.statusRed} data-testid="do-not-contact">
      Do not contact
    </Text>
  );

  return number || dnc;
};

const renderLeadCell = ({ lead_id, lead_gid }: IDashboardLead) => (
  <Link
    to={featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${lead_gid}` : `/leads/${lead_id}`}
    css={css`
      font-weight: 600;
      color: ${colors.black};
    `}
  >
    {lead_id}
  </Link>
);

const renderLeadDispositionCell = ({ disposition_type }: IDashboardLead) => (
  <Text color={disposition_type === DispositionType.NewRequest ? colors.statusRed : ''}>
    {Translations.dispositionType(disposition_type as DispositionType)}
  </Text>
);

const renderLeadCadenceCell = ({ lead_cadence_flow, id }: IDashboardLead) => {
  const getCadenceTooltipLabel = () => {
    switch (lead_cadence_flow) {
      case ScheduledInteractionFlow.PipelineHighPriority:
        return 'High priority cadence (with calls)';
      case ScheduledInteractionFlow.PipelineLowPriority:
        return 'Low priority cadence (no calls)';
      default:
        return 'No follow-up cadence assigned';
    }
  };

  const getCadenceIcon = () => {
    switch (lead_cadence_flow) {
      case ScheduledInteractionFlow.PipelineHighPriority:
        return <HotIcon />;
      case ScheduledInteractionFlow.PipelineLowPriority:
        return <SnowIcon />;
      default:
        return <WarningIcon color="#EA580C" />;
    }
  };

  return (
    <span data-for={`cadence-icon-tooltip-${id}`} data-tip={getCadenceTooltipLabel()}>
      {getCadenceIcon()}
      <NewTooltip id={`cadence-icon-tooltip-${id}`} />
    </span>
  );
};

const renderLeadProposalCell = ({ lead_proposal_created, lead_gid, id }: IDashboardLead) => {
  return (
    <div>
      <span
        data-for={`proposal-icon-tooltip-${id}`}
        data-tip={lead_proposal_created ? 'Proposal created' : 'Proposal’s not created'}
      >
        {lead_proposal_created ? (
          <Link to={`/scouting_report/proposal?lead_gid=${lead_gid}`}>
            <LinkSharpIcon
              color={colors.black}
              css={css`
                transform: rotate(135deg);
              `}
            />
          </Link>
        ) : (
          <ShieldWarningIcon />
        )}
        <NewTooltip id={`proposal-icon-tooltip-${id}`} place="left" />
      </span>
    </div>
  );
};

const renderPartnerCell = (lead: IDashboardLead, partners: any[] | undefined) => {
  const brandName = findBrandName(partners, lead.partner);
  return <Text>{brandName}</Text>;
};

const renderAgeCell = ({ created_at }: IDashboardLead) => `${daysToNow(created_at)} day(s)`;

const getCustomCellContent = (
  key: LeadsDashboardColumnKeys,
  lead: IDashboardLead,
  partners?: IPartner[]
): React.ReactNode => {
  switch (key) {
    case LeadsDashboardColumnKeys.CustomerName:
      return renderCustomerNameCell(lead);
    case LeadsDashboardColumnKeys.LeadID:
      return renderLeadCell(lead);
    case LeadsDashboardColumnKeys.PhoneNumber:
      return renderPhoneCell(lead);
    case LeadsDashboardColumnKeys.CreatedAt:
      return renderAgeCell(lead);
    case LeadsDashboardColumnKeys.DispositionType:
      return renderLeadDispositionCell(lead);
    case LeadsDashboardColumnKeys.LeadCadence:
      return renderLeadCadenceCell(lead);
    case LeadsDashboardColumnKeys.LeadProposal:
      return renderLeadProposalCell(lead);
    case LeadsDashboardColumnKeys.Partner:
      return renderPartnerCell(lead, partners);
  }
};

export default getCustomCellContent;
