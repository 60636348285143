/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import debounce from 'debounce-promise';
import { useEffect, useState } from 'react';

import Button, { ButtonSize } from '../core/buttons/Button';
import { ArrowUp2Icon } from '../core/icons';

const ScrollToTopButton = ({
  elementRef,
  scrollOffset = 100
}: {
  elementRef?: React.RefObject<HTMLElement>;
  scrollOffset?: number;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const element = elementRef?.current;

    if (element) {
      const scrollPosition = element.scrollTop;
      setIsVisible(scrollPosition > scrollOffset);
    } else if (window.scrollY > scrollOffset) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 100);

  useEffect(() => {
    const element = elementRef?.current;
    const currentElement = element;

    const scrollTarget = currentElement || window;
    scrollTarget.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      scrollTarget.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll, elementRef]);

  const scrollToTop = () => {
    if (elementRef?.current) {
      elementRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Button
      size={ButtonSize.Small}
      onClick={scrollToTop}
      css={css`
        right: 24px;
        bottom: 42px;
        position: absolute;
        margin-top: 12px;
        font-weight: 600;
        transition: opacity 0.3s ease;
        opacity: ${isVisible ? 1 : 0};

        svg {
          margin-right: 5px;
        }
      `}
    >
      <ArrowUp2Icon />
      Go up
    </Button>
  );
};

export default ScrollToTopButton;
