import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces';
import { IDocumentDelivery } from '../../../interfaces/IDocumentDelivery';
import api from '../../../services/api';

export const POLICY_DELIVERIES_QUERY_KEY = 'policyDeliveries';

const createOrUpdatePolicyDelivery = ({
  personGid,
  policyId
}: {
  personGid: string;
  policyId: number;
}): Promise<{ deliveries: IDocumentDelivery[] }> =>
  api.post(`/api/frontend/people/${personGid}/policies/${policyId}/deliveries`);

const getPolicyDeliveries = ({
  personGid,
  policyId
}: {
  personGid: string;
  policyId: number;
}): Promise<{ deliveries: IDocumentDelivery[] }> =>
  api.get(`/api/frontend/people/${personGid}/policies/${policyId}/deliveries`);

export const useCreateOrUpdatePolicyDelivery = () => useMutation({ mutationFn: createOrUpdatePolicyDelivery });

export const usePolicyDeliveries = ({ personGid, policyId }: { personGid: string; policyId: number }) =>
  useQuery({
    queryKey: [POLICY_DELIVERIES_QUERY_KEY, personGid, policyId],
    queryFn: () => getPolicyDeliveries({ personGid, policyId }),
    select: data => data.deliveries
  });

export const usePoliciesDeliveries = ({ personGid, policies }: { personGid: string; policies: IMaticPolicy[] }) => {
  const queries = useQueries({
    queries: policies.map(policy => ({
      queryKey: [POLICY_DELIVERIES_QUERY_KEY, personGid, policy.id],
      queryFn: () => getPolicyDeliveries({ personGid, policyId: policy.id })
    }))
  });

  const data: Record<IMaticPolicy['id'], IDocumentDelivery[]> = {};
  policies.forEach((policy, index) => {
    const deliveries = queries[index]?.data?.deliveries;
    if (!deliveries) {
      return;
    }

    data[policy.id] = deliveries;
  });

  return data;
};
