/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import { InfoIcon } from '../../../../../components/core/icons';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import OpportunityCustomerChoice from '../OpportunityCustomerChoice';

export const umbrellaOpportunityInitialCoverages = (
  opportunity: ConfigurationComponentProps['opportunity']
): ConfigurationComponentProps['opportunity']['agent_selected_coverages'] => {
  return (
    opportunity.agent_selected_coverages || [
      {
        asset_gid: opportunity.assets?.[0]?.gid || null,
        coverages_tier: OpportunityCoveragesTier.Quoted,
        coverages: null
      }
    ]
  );
};

const UmbrellaOpportunity = ({ index, person, opportunity, refetchCurrentPolicies }: ConfigurationComponentProps) => {
  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px12}
      data-testid={`quoting-configuration-opportunity-${index}`}
    >
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox alignItemsCenter>
          <CheckboxField
            inline
            key={`opportunities.${opportunity.id}.enabled`}
            id={`opportunities.${opportunity.id}.enabled`}
            name={`opportunities.${opportunity.id}.enabled`}
            disabled={!!opportunity.disqualification_reason}
            label={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Text bold type="large">
                  {opportunity.title}
                </Text>
                {opportunity.primary && (
                  <Tag
                    label="Primary opportunity"
                    transparent
                    backgroundColor={colors.violet}
                    textColor={colors.violet}
                    mt={spacings.px4}
                  />
                )}
                {!noAppointedCarriers && (
                  <>
                    <Tag
                      data-for="umbrella-recommendation"
                      data-tip="TBD"
                      label={
                        <FlexBox gap={spacings.px8} alignItemsCenter>
                          Recommended
                          <InfoIcon height="16" width="16" />
                        </FlexBox>
                      }
                      transparent
                      backgroundColor={colors.statusGreen}
                      textColor={colors.statusGreen}
                      mt={spacings.px4}
                    />
                    {/* <Tooltip id="umbrella-recommendation" /> TBD: READ REASON FROM BACKEND */}
                  </>
                )}
              </FlexBox>
            }
          />
        </FlexBox>
        {noAppointedCarriers ? (
          <FlexBox>
            <SystemMessage
              type="warning"
              // eslint-disable-next-line max-len
              heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
            />
          </FlexBox>
        ) : (
          <Text color={colors.grey60}>
            No integration available yet. We will display carriers, which can be quoted manually.
          </Text>
        )}
      </FlexBox>
      <FlexBox gap={spacings.px12} columnDirection>
        <OpportunityCustomerChoice opportunity={opportunity} />
        <OpportunityCurrentInsurance
          person={person}
          opportunity={opportunity}
          refetchCurrentPolicies={refetchCurrentPolicies}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default UmbrellaOpportunity;
