/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import colors from '../../../theme/colors';
import IconButton, { ButtonIcons } from './IconButton';

export const ButtonGroup = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
  return (
    <div
      css={css`
        position: absolute;
        right: 0;
        top: -30px;
        background-color: ${colors.white};
      `}
    >
      <IconButton icon={ButtonIcons.ChevronLeft} color={colors.black} onClick={() => previous && previous()} />
      <IconButton icon={ButtonIcons.ChevronRight} color={colors.black} onClick={() => next && next()} />
    </div>
  );
};
