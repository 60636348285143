/* eslint-disable max-len */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Blockquote from '../../../../../components/core/Blockquote';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Paragraph from '../../../../../components/core/Paragraph';
import { spacings } from '../../../../../theme/variables';

const HowToQuoteUmbrellaModal = ({ cancelHandler }: { cancelHandler: () => void }) => {
  return (
    <Modal
      containerTitle="Recommendations how to quote"
      cancelHandler={cancelHandler}
      showCancelButton={false}
      size={ModalSize.extra}
    >
      <FlexBox
        ph={spacings.px24}
        pb={spacings.px12}
        columnDirection
        gap={spacings.px12}
        customCss={css`
          white-space: pre-line;
        `}
      >
        <Container>
          <Paragraph bold>Pitch coverages</Paragraph>
          <Blockquote
            text={`The umbrella policy usually provides a good discount. It can cost you less than a dollar per day. Umbrella insurance extends your homeowners and auto insurance protection by providing additional liability coverage.
            Umbrella covers for dog bites, provide social media liability and legal fee coverage in case of a lawsuit, protect inexperienced drivers beyond auto policy limits, insure farmers against catastrophic losses.
            If a neighbour trips on your sidewalk or a visitor falls in your home, you’re protected from suits that go above your home insurance policy’s liability coverage, as well as injuries caused by things like pools and trampolines are covered.`}
          />
        </Container>
      </FlexBox>
    </Modal>
  );
};
export default HowToQuoteUmbrellaModal;
