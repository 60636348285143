/** @jsxImportSource @emotion/react */

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import React, { useCallback, useRef, useState } from 'react';

import colors from '../../../theme/colors';
import Button, { ButtonSize, ButtonVariant, loaderComponent } from '../buttons/Button';
import { ArrowDownIcon, ArrowUpIcon } from '../icons';
import Text from '../Text';
import { iconCSS, optionCSS, optionsCSS, wrapperCSS } from './DropDown.style';
import { DropDownOption, DropDownPosition, DropDownProps } from './DropDown.utils';

// TODO: refactor to some Combobox component (adobe aria / material)
const DropDownWithPopper = <TOption extends DropDownOption>({
  children,
  options,
  onSelected,
  size = ButtonSize.Normal,
  optionsSize = ButtonSize.Normal,
  disabled = false,
  loading = false,
  variant = ButtonVariant.Default,
  noArrow = false,
  customCss,
  customOptionsCss,
  iconChildren = false,
  testId,
  staticView = false,
  iconChildrenActiveProperty,
  customButtonCss,
  position = DropDownPosition.Bottom,
  personGid
}: DropDownProps<TOption>): JSX.Element => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? `simple-popper-${personGid}` : undefined;
  const IconComponent = open ? ArrowUpIcon : ArrowDownIcon;

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);

    if (options.length === 1) {
      onSelected(options[0]!);
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const pickArrowColor = useCallback(() => {
    if (variant === ButtonVariant.Text) {
      return colors.azure50;
    }
    if (variant === ButtonVariant.PlainText) {
      return colors.black;
    }

    return colors.white;
  }, [variant]);

  const renderOption = (option: TOption) => {
    return (
      <li
        key={option.value}
        onClick={event => {
          event.stopPropagation();

          if (!option.disabled) {
            onSelected(option);
            setAnchorEl(null);
          }
        }}
        css={[optionCSS(variant, optionsSize, !!option.disabled), option.customCss]}
      >
        {option.label}
        {option.description && (
          <div>
            <Text color={colors.grey60} type="tiny">
              {option.description}
            </Text>
          </div>
        )}
      </li>
    );
  };

  const iconChildrenActive = iconChildrenActiveProperty ? iconChildrenActiveProperty : { color: colors.azure50 };

  return (
    <div ref={dropDownRef} css={[wrapperCSS(position), customCss]}>
      {iconChildren ? (
        <Button
          variant={ButtonVariant.Text}
          disabled={disabled || loading}
          data-testid={testId}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();
            onClickHandler(event);
          }}
          customCss={customButtonCss}
        >
          {open ? React.cloneElement(children as JSX.Element, iconChildrenActive) : children}
        </Button>
      ) : (
        <Button
          data-testid={testId}
          size={size}
          disabled={disabled || loading}
          variant={variant}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();
            onClickHandler(event);
          }}
          customCss={customButtonCss}
        >
          {children}
          {!(disabled || loading || options.length === 1) && !noArrow && (
            <IconComponent data-testid={`${testId}-dropdown-icon`} color={pickArrowColor()} css={iconCSS(size)} />
          )}
          {loaderComponent(loading, size)}
        </Button>
      )}

      {open && (
        <ClickAwayListener
          key={id}
          onClickAway={() => {
            setAnchorEl(null);
          }}
        >
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <div css={[optionsCSS(staticView, position), customOptionsCss]} key={options[1]?.value}>
              <div>{options.map(option => renderOption(option))}</div>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DropDownWithPopper;
