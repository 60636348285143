/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import emptyImage from '../../assets/img/empty.svg';
import Tooltip from '../../components/common/Tooltip/NewTooltip';
import Button, { ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Select from '../../components/core/inputs/Select';
import Paragraph from '../../components/core/Paragraph';
import { Configuration } from '../../constants';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../hooks';
import { DispositionType } from '../../interfaces/IDisposition';
import { IProposal } from '../../interfaces/IProposal';
import { isMoreThanOneWeekOld } from '../../interfaces/IQuote';
import { usePersonProposals } from '../../queries/people/proposals/usePersonProposal';
import colors from '../../theme/colors';
import { InputSize, spacings } from '../../theme/variables';
import { isImageCached } from '../../utils/object';
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';
import NewMessage from '../NewMessage';
import { createProposalOptions, ProposalCustomOption } from './_components';
import { PROPOSAL_IFRAME_ID } from './_helpers';
import { ProposalContext, useProposalContext } from './_hooks/useProposalContext';
import ProposalSummary from './ProposalSummary';

const getTooltipText = (currentProposal: IProposal | undefined | null) => {
  if (!currentProposal) {
    return 'Please select or create a proposal to review with customer';
  }

  if (currentProposal?.canceled_at) {
    return 'Proposal has been canceled';
  }

  if (isMoreThanOneWeekOld(currentProposal.created_at)) {
    return 'Proposal is more than one week old';
  }

  return '';
};

const ProposalBuilder = ({ proposals }: { proposals: IProposal[] }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { leadGid, person } = useGuidedSellingExperienceContext();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const [isSendMessageModalOpened, toggleSendMessageModalOpened] = useToggle(false);
  const [imageLoaded, setImageLoaded] = React.useState(() => isImageCached(emptyImage));

  const { setCurrentProposalGid, currentProposalGid, resetQuotesForOpportunities } = useProposalContext();
  const currentProposal = proposals.find(proposal => proposal.gid === currentProposalGid);

  const showFinalizeButton =
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified);
  const proposalUrl = currentProposal ? `${Configuration.customerPortalPublicUrl}/proposal/${currentProposal.gid}` : '';

  const proposalsOptions = createProposalOptions(proposals);

  return (
    <Container
      customCss={css`
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      <FlexBox
        fitParentHeight
        customCss={css`
          overflow: auto;
        `}
      >
        <FlexBox
          columnDirection
          customCss={css`
            border-right: 1px solid ${colors.grey10};
            min-width: 485px;
            height: 100%;
            overflow: auto;
          `}
        >
          <FlexBox
            p={spacings.px12}
            customCss={css`
              border-bottom: 1px solid ${colors.grey10};
            `}
            justifySpaceBetween
            pr={spacings.px24}
            alignItemsCenter
          >
            <Paragraph type="large">Build proposal</Paragraph>

            <Select
              options={proposalsOptions}
              onChange={value => {
                setCurrentProposalGid(value);
                resetQuotesForOpportunities();
              }}
              inputSize={InputSize.Large}
              value={currentProposal?.gid}
              customStyles={{
                control: () => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '310px',
                  border: `1px solid ${colors.grey10}`,
                  borderRadius: '8px'
                })
              }}
              customComponents={{ Option: ProposalCustomOption as unknown as React.ReactNode }}
              menuOptions={
                <>
                  <hr
                    css={css`
                      margin: 0 10px 10px 10px;
                    `}
                  />
                  <Button
                    onClick={e => {
                      setCurrentProposalGid(undefined);
                      resetQuotesForOpportunities();
                      e.stopPropagation();
                    }}
                    variant={ButtonVariant.PlainText}
                    data-testid="new-proposal-button"
                    customCss={css`
                      padding: 4px 12px 16px 12px;
                      text-align: left;
                      width: 100%;
                      color: ${colors.azure50};
                    `}
                  >
                    + New proposal
                  </Button>
                </>
              }
            />
          </FlexBox>
          <ProposalSummary currentProposal={currentProposal} key={currentProposal?.gid} />
        </FlexBox>

        {!currentProposal && (
          <FlexBox columnDirection alignItemsCenter gap={spacings.px40} p={spacings.px40}>
            <img src={emptyImage} alt="Empty" width={344} onLoad={() => setImageLoaded(true)} />
            {imageLoaded && (
              <Paragraph
                type="large"
                customCss={css`
                  text-align: center;
                `}
              >
                Your proposal will be displayed here after creation. To start building a proposal, please select the
                insurance products you want to include.
              </Paragraph>
            )}
          </FlexBox>
        )}
        {currentProposal && (
          <FlexBox
            alignItemsCenter
            css={css`
              overflow-y: auto;
              margin: 0 auto;
            `}
          >
            <iframe
              css={css`
                border: none;
              `}
              id={PROPOSAL_IFRAME_ID}
              height="100%"
              width="900px"
              key="Proposal"
              src={`${proposalUrl}?referrer=amp`}
              title="Customer proposal"
              loading="lazy"
            ></iframe>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        justifyRight
        p={spacings.px24}
        gap={spacings.px12}
        customCss={css`
          border-top: 1px solid ${colors.grey10};
        `}
      >
        {showFinalizeButton && (
          <Button onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}>Finalize</Button>
        )}
        <Button
          onClick={toggleSendMessageModalOpened}
          disabled={
            !person ||
            !currentProposal ||
            !!currentProposal.canceled_at ||
            isMoreThanOneWeekOld(currentProposal.created_at)
          }
          data-tip={getTooltipText(currentProposal)}
          data-for="review-with-customer-tooltip"
        >
          Review with customer
        </Button>
        <Tooltip id="review-with-customer-tooltip" />
      </FlexBox>

      {isSendMessageModalOpened && person && currentProposal && leadGid && (
        <NewMessage
          cancelHandler={() => {
            toggleSendMessageModalOpened();
          }}
          person={person}
          proposalGid={currentProposal.gid}
          proposalUrl={proposalUrl}
          leadGid={leadGid}
          trackProposalSending={!currentProposal.sent_at}
        />
      )}
    </Container>
  );
};

const ProposalBuilderV2 = () => {
  const { personGid, leadGid } = useGuidedSellingExperienceContext();
  const { data: proposals } = usePersonProposals({ personGid, engagementGid: leadGid });

  // TODO: add loader
  if (!proposals) {
    return null;
  }

  return (
    <ProposalContext.Provider initialValue={proposals[0]?.gid}>
      <ProposalBuilder proposals={proposals} />
    </ProposalContext.Provider>
  );
};

export default ProposalBuilderV2;
