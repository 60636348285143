/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../components/core/FlexBox';
import { useActiveScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import CallPopper from './CallPopper';
import EditScheduledCall from './EditScheduledCall';

const Indicator = ({
  personGid,
  containerCss,
  leadGid,
  candidateGid,
  disabled = false
}: {
  personGid: string;
  containerCss?: SerializedStyles;
  leadGid: string | undefined;
  candidateGid: string | undefined;
  disabled?: boolean;
}): JSX.Element => {
  const { data: scheduledCalls } = useActiveScheduledInteractions(personGid);

  const scheduledCall = scheduledCalls?.[0];

  return (
    <>
      {scheduledCall && (
        <FlexBox justifyCenter customCss={containerCss}>
          <FlexBox
            data-testid="schedule-call-indicator"
            roundBorder
            border={false}
            backgroundColor={colors.grey5}
            customCss={css`
              padding: 2px ${spacings.px12}px;
            `}
          >
            <FlexBox gap={spacings.px12} alignItemsCenter>
              <CallPopper
                scheduledCall={scheduledCall}
                personGid={personGid}
                engagementGid={leadGid || candidateGid}
                disabled={disabled}
              >
                <EditScheduledCall
                  scheduledCall={scheduledCall}
                  leadGid={leadGid}
                  candidateGid={candidateGid}
                  withActions={false}
                  disabled={disabled}
                />
              </CallPopper>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default Indicator;
