/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { getSortedRowModel } from '@tanstack/react-table';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';

import emptyImage from '../../assets/img/empty.svg';
import Tooltip from '../../components/common/Tooltip/NewTooltip';
import { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import CollapsingContainer from '../../components/core/CollapsingContainer';
import Container from '../../components/core/Container';
import DropDown from '../../components/core/DropDown/DropDown';
import FlexBox from '../../components/core/FlexBox';
import { Filter2Icon, FilterIcon, GroupIcon } from '../../components/core/icons';
import Table from '../../components/core/Table/Table';
import Text from '../../components/core/Text';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import useDocumentTypes from '../../queries/document_types/useDocumentTypes';
import useGroupedTasks, { TasksGroupBy } from '../../queries/tasks/useGroupedTasks';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import {
  DATE_GROUP_ORDER,
  DateFilter,
  FILTER_OPTIONS,
  formatDates,
  formatGroupBy,
  GROUP_OPTIONS,
  Sorting,
  SORTING_OPTIONS,
  SortKey,
  tableColumns
} from './helpers';

const TasksDashboard = () => {
  const [sorting, setSorting] = React.useState<Sorting[]>([{ id: SortKey.DueDate, desc: false }]);
  const [groupBy, setGroupBy] = React.useState<TasksGroupBy>(TasksGroupBy.DueDate);
  const [dateFilter, setDateFilter] = React.useState<DateFilter>(DateFilter.Month);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { data: tasks, isLoading } = useGroupedTasks({
    ...formatDates(dateFilter),
    group_by: groupBy,
    flows: [
      ScheduledInteractionFlow.PipelineHighPriority,
      ScheduledInteractionFlow.ScheduledCallV2,
      ScheduledInteractionFlow.ScheduledCall
    ]
  });

  const sortedTasks = tasks?.sort((a, b) => {
    if (groupBy === TasksGroupBy.DueDate) {
      return DATE_GROUP_ORDER.indexOf(a.group_name) - DATE_GROUP_ORDER.indexOf(b.group_name);
    }
    return a.group_name.localeCompare(b.group_name);
  });

  const { data: docTypesData } = useDocumentTypes();

  return (
    <FlexBox columnDirection gap={spacings.px24} p={spacings.px24}>
      <FlexBox columnDirection gap={spacings.px24}>
        <FlexBox gap={spacings.px16} pb={spacings.px16}>
          <Text bold type="large">
            Tasks
          </Text>
          <FlexBox border roundBorder borderColor={colors.violet} alignItemsCenter p={spacings.px4}>
            <DropDown
              options={SORTING_OPTIONS}
              onSelected={e => {
                setSorting([{ id: e.value, desc: false }]);
              }}
              size={ButtonSize.Small}
              variant={ButtonVariant.PlainText}
            >
              <FilterIcon
                css={css`
                  position: relative;
                  bottom: 2px;
                `}
              />
              <Text bold mh={spacings.px4}>
                Sort
              </Text>
              <Text>{SORTING_OPTIONS.find(option => option.value === sorting[0]?.id)?.label}</Text>
            </DropDown>
          </FlexBox>

          <FlexBox border roundBorder borderColor={colors.violet} alignItemsCenter p={spacings.px4}>
            <DropDown
              options={GROUP_OPTIONS}
              onSelected={e => {
                setGroupBy(e.value);
              }}
              size={ButtonSize.Small}
              variant={ButtonVariant.PlainText}
            >
              <GroupIcon
                css={css`
                  position: relative;
                  bottom: 2px;
                `}
              />
              <Text bold mh={spacings.px4}>
                Group
              </Text>
              <Text>{GROUP_OPTIONS.find(o => o.value === groupBy)?.label || groupBy}</Text>
            </DropDown>
          </FlexBox>

          <FlexBox border roundBorder borderColor={colors.violet} alignItemsCenter p={spacings.px4}>
            <DropDown
              options={FILTER_OPTIONS}
              onSelected={e => {
                setDateFilter(e.value);
              }}
              size={ButtonSize.Small}
              variant={ButtonVariant.PlainText}
            >
              <Filter2Icon
                css={css`
                  position: relative;
                  bottom: 2px;
                `}
              />
              <Text bold mh={spacings.px4}>
                Filter
              </Text>
              <Text>{FILTER_OPTIONS.find(o => o.value === dateFilter)?.label || dateFilter}</Text>
            </DropDown>
          </FlexBox>
        </FlexBox>

        {isLoading ? (
          <Container pv={spacings.px12}>
            <Skeleton count={20} height={40} />
          </Container>
        ) : (
          <>
            {sortedTasks?.length === 0 && (
              <FlexBox columnDirection alignItemsCenter gap={spacings.px8}>
                <img src={emptyImage} alt="Empty" width={225} />
                <Text>No tasks are assigned to you</Text>
              </FlexBox>
            )}
            {sortedTasks?.map(({ group_name, items }) => (
              <CollapsingContainer
                key={group_name}
                containerTitle={
                  <FlexBox alignItemsCenter gap={spacings.px8}>
                    <Text bold>
                      {groupBy === TasksGroupBy.Customer ? items[0]?.customer_name : formatGroupBy(group_name, groupBy)}
                    </Text>
                    <Text
                      type="tiny"
                      ph={spacings.px4}
                      color={colors.grey60}
                      customCss={css`
                        border-radius: 4px;
                        border: 1px solid ${colors.grey60};
                        background-color: ${colors.grey5};
                        line-height: 1.5;
                      `}
                    >
                      {items.length}
                    </Text>
                  </FlexBox>
                }
                iconPosition="left"
                openedByDefault={items.length < 20}
              >
                <Table
                  data={items}
                  testId="tasks-dashboard-table"
                  columns={tableColumns({ docTypes: docTypesData?.document_types, groupName: group_name })}
                  headerBottomBorder
                  tableOptions={{ state: { sorting }, getSortedRowModel: getSortedRowModel() }}
                />
                <Tooltip id={`created-by-system-${group_name}`} />
              </CollapsingContainer>
            ))}
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default TasksDashboard;
