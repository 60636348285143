/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import featureFlags from '../../../constants/featureFlags';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import { LicensingTypes } from '../../../interfaces/IUser';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { ButtonSize } from '../../core/buttons/Button';
import Container from '../../core/Container';
import DropDown from '../../core/DropDown/DropDown';
import FlexBox from '../../core/FlexBox';
import Heading from '../../core/Heading';
import { Filter2Icon, FilterIcon } from '../../core/icons/index';

interface Props {
  headerText: string;
  isOpen?: boolean;
  salesDashboardHeader?: boolean;
  salesPipelineDashboard?: boolean;
  children: React.ReactNode;
}

const FiltersSubHeader = ({
  headerText,
  isOpen = false,
  salesDashboardHeader = false,
  salesPipelineDashboard = false,
  children
}: Props): JSX.Element => {
  const [show, setShow] = React.useState(isOpen);
  const navigate = useNavigate();
  const isUserLifeLicensed = authInfo.licensingTypes?.includes(LicensingTypes.Life);

  const getFilterIcon = () => (
    <div
      onClick={() => setShow(current => !current)}
      id="filtering_button"
      data-testid="filtering_button"
      css={css`
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        ${salesPipelineDashboard &&
        css`
          font-size: 14px;
          padding-left: 16px;
          margin-left: 8px;
          border-left: 1px solid ${colors.grey10};
          svg {
            margin-right: 4px;
          }
        `}
      `}
    >
      {salesPipelineDashboard ? (
        <Filter2Icon color={show ? colors.azure50 : undefined} />
      ) : (
        <FilterIcon color={show ? colors.azure50 : undefined} />
      )}
      {salesPipelineDashboard && 'Filters'}
    </div>
  );
  return (
    <Container
      borderBottom
      borderColor={salesPipelineDashboard ? 'transparent' : colors.grey10}
      customCss={css`
        background-color: ${colors.white};
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 5rem;
        padding: 24px;

        ${salesPipelineDashboard &&
        css`
          padding: 16px 24px 0;
          min-height: 2rem;
        `}
      `}
    >
      <FlexBox alignItemsBaseline justifySpaceBetween fitParentWidth>
        <Heading
          css={css`
            font-size: 32px;
            ${salesPipelineDashboard &&
            css`
              font-size: 20px;
              display: flex;
            `}
          `}
        >
          {headerText}
          {children && salesPipelineDashboard && getFilterIcon()}
        </Heading>

        <FlexBox alignItemsCenter gap={spacings.px24}>
          {children && !salesPipelineDashboard && getFilterIcon()}

          {salesDashboardHeader && !featureFlags.hideDashboardCreateButton && (
            <DropDown
              options={[
                {
                  label: isUserLifeLicensed ? 'Lead(Personal lines)' : 'Lead',
                  value: 'pl_lead',
                  entity: 'lead',
                  insuranceCategory: InsuranceCategory.PersonalLines
                },
                isUserLifeLicensed && {
                  label: 'Lead(Life)',
                  value: 'life_lead',
                  entity: 'lead',
                  insuranceCategory: InsuranceCategory.Life
                },
                authInfo.features.create_customer && {
                  label: 'Customer',
                  entity: 'customer',
                  value: 'customer',
                  insuranceCategory: undefined
                }
              ].filter(Boolean)}
              onSelected={selected => {
                navigate(`/new_${selected.entity}`, {
                  state: { from: 'sales_dashboard', insuranceCategory: selected.insuranceCategory }
                });
              }}
              size={ButtonSize.Small}
              customOptionsCss={css`
                min-width: 100px;
                right: 0;
              `}
            >
              + Create
            </DropDown>
          )}
        </FlexBox>
      </FlexBox>
      <Collapse isOpen={show}>{children}</Collapse>
    </Container>
  );
};

export default FiltersSubHeader;
