/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import * as yup from 'yup';

import Tooltip from '../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import {
  CheckboxField,
  InputField,
  MultiSelectField,
  NumericField,
  SelectField
} from '../../components/core/forms/fields';
import { DollarIcon, LinkSharpIcon } from '../../components/core/icons';
import Select from '../../components/core/inputs/Select';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useDeepEqualMemo } from '../../hooks';
import { IQuote, IVehicle } from '../../interfaces';
import { NO_VALUE, YES_VALUE } from '../../interfaces/IDatapoint';
import { isDriverInfoCollected, isPersonAgedToDrive, personAge } from '../../interfaces/IPerson';
import { coverageByKey, LimitType, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteSource } from '../../interfaces/IQuote';
import { buildVehicleTitle } from '../../interfaces/IVehicle';
import useCarriers from '../../queries/carriers/useCarriers';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../queries/people/person_quotes/useAggregatedQuotes';
import { useCreateQuote } from '../../queries/people/person_quotes/useCreateQuote';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import { QUOTES_REQUESTS_QUERY_KEY } from '../../queries/quotes_requests/useQuotesRequests';
import colors from '../../theme/colors';
import { InputSize, spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';
import { SmallDropdownIndicator } from './_components';

const selectedTextCss = css`
  color: ${colors.azure50};
  &[aria-selected='true'] {
    color: ${colors.black};
    border-bottom: 2px solid ${colors.black};
  }

  &:hover {
    color: inherit;
  }
`;

const POSSIBLE_INCLUDED_COVERAGES_OPTIONS = [
  {
    key: PolicyCoverageKey.Towing,
    value: 'Roadside Assistance Coverage'
  },
  {
    key: PolicyCoverageKey.FullGlass,
    value: 'Full Glass'
  },
  {
    key: PolicyCoverageKey.RentalMaxAmount,
    value: 'Rental Car Reimbursement'
  }
];

type ValuesPerVehicle = { [key: string]: { [key: string]: string | number | string[] } };
const vehicleCoveragesValues = (quote: IQuote | undefined, opportunityAssets: IVehicle[]) => ({
  ...opportunityAssets.reduce((acc, { gid: vehicleGid }) => {
    acc[vehicleGid] = {};

    acc[vehicleGid][PolicyCoverageKey.Collision] =
      quote?.attributes.coverages?.find(
        coverage => PolicyCoverageKey.Collision === coverage.key && coverage.asset_gid === vehicleGid
      )?.deductible_value || '';

    acc[vehicleGid][PolicyCoverageKey.Comprehensive] =
      quote?.attributes.coverages?.find(
        coverage => PolicyCoverageKey.Comprehensive === coverage.key && coverage.asset_gid === vehicleGid
      )?.deductible_value || '';

    acc[vehicleGid]['included_coverages'] =
      quote?.attributes.coverages
        ?.filter(
          coverage =>
            POSSIBLE_INCLUDED_COVERAGES_OPTIONS.map(({ key }) => key).includes(coverage.key) &&
            coverage.asset_gid === vehicleGid &&
            (coverage.limit_value || coverage.limit_description === 'Included')
        )
        .map(({ key }) => key) || [];

    return acc;
  }, {} as ValuesPerVehicle)
});

const compareVehicleCoverages = (vehicleCoverages: ReturnType<typeof vehicleCoveragesValues>) => {
  const arrayOfItems = Object.values(vehicleCoverages);

  return arrayOfItems.every(item => R.equals(item, arrayOfItems[0]));
};

const buildInitialValues = (quote: IQuote | undefined, opportunityAssets: IVehicle[], personGid: string) => {
  const vehicleCoverages = vehicleCoveragesValues(quote, opportunityAssets);

  const medPay = coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_value;
  const pip = coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalInjuryProtection)?.limit_value;

  return {
    carrier_key: quote?.attributes.carrier.key || '',
    premium: quote?.attributes.premium || '',
    downpayment: quote?.attributes.payment_options?.[0]?.downpayment || '',
    med_pay_or_pip: medPay || pip || '',
    subsequent_payment: quote?.attributes.payment_options?.[0]?.subsequent_payment || '',
    [PolicyCoverageKey.MedicalPayments]: !!medPay || (!pip && true) || false,
    [PolicyCoverageKey.PersonalInjuryProtection]: !!pip || false,
    [PolicyCoverageKey.PropertyDamage]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PropertyDamage)?.limit_value || '',
    [PolicyCoverageKey.BiPerPerson]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.BiPerPerson)?.limit_value || '',
    [PolicyCoverageKey.BiPerAccident]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.BiPerAccident)?.limit_value || '',
    [PolicyCoverageKey.UninsuredMbiPerPerson]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.UninsuredMbiPerPerson)?.limit_value || '',
    [PolicyCoverageKey.UninsuredMbiPerAccident]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.UninsuredMbiPerAccident)?.limit_value || '',
    [PolicyCoverageKey.UninsuredMpd]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.UninsuredMpd)?.limit_value || '',
    insured_gids: quote?.attributes.coinsureds_gids?.concat(personGid) || [personGid],
    agent_bridge_url: quote?.attributes.binding_url || '',
    paid_in_full: quote?.attributes.payment_options?.[0]?.downpayment ? NO_VALUE : YES_VALUE,
    premium_period: quote?.attributes.payment_options?.[0]?.term_months || 6,
    apply_same_coverages_to_all_vehicles: compareVehicleCoverages(vehicleCoverages),
    ...vehicleCoverages
  };
};

const buildCoverages = ({
  property_damage,
  med_pay_or_pip,
  medical_payments,
  personal_injury_protection,
  bodily_injury_per_person,
  bodily_injury_per_accident,
  uninsured_motorist_bodily_injury_per_person,
  uninsured_motorist_bodily_injury_per_accident,
  uninsured_motorist_property_damage
}: ReturnType<typeof buildInitialValues> & ValuesPerVehicle) => {
  const propertyDamageNode = property_damage
    ? {
        key: PolicyCoverageKey.PropertyDamage,
        limit_value: property_damage,
        deductible_value: 0,
        limit_description: moneyFormatter(property_damage, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const biPerPersonNode = bodily_injury_per_person
    ? {
        key: PolicyCoverageKey.BiPerPerson,
        limit_value: bodily_injury_per_person,
        deductible_value: 0,
        limit_description: moneyFormatter(bodily_injury_per_person, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const biPerAccidentNode = bodily_injury_per_accident
    ? {
        key: PolicyCoverageKey.BiPerAccident,
        limit_value: bodily_injury_per_accident,
        deductible_value: 0,
        limit_description: moneyFormatter(bodily_injury_per_accident, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  const umbiPerPersonNode = uninsured_motorist_bodily_injury_per_person
    ? {
        key: PolicyCoverageKey.UninsuredMbiPerPerson,
        limit_value: uninsured_motorist_bodily_injury_per_person,
        deductible_value: 0,
        limit_description: moneyFormatter(uninsured_motorist_bodily_injury_per_person, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const umbiPerAccidentNode = uninsured_motorist_bodily_injury_per_accident
    ? {
        key: PolicyCoverageKey.UninsuredMbiPerAccident,
        limit_value: uninsured_motorist_bodily_injury_per_accident,
        deductible_value: 0,
        limit_description: moneyFormatter(uninsured_motorist_bodily_injury_per_accident, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  const underinsuredMbiPerPersonNode = umbiPerPersonNode
    ? { ...umbiPerPersonNode, key: PolicyCoverageKey.UnderinsuredMbiPerPerson }
    : undefined;

  const underinsuredMbiPerAccidentNode = umbiPerAccidentNode
    ? { ...umbiPerAccidentNode, key: PolicyCoverageKey.UnderinsuredMbiPerAccident }
    : undefined;

  const uninsuredMotoristPropertyDamageNode = uninsured_motorist_property_damage
    ? {
        key: PolicyCoverageKey.UninsuredMpd,
        limit_value: uninsured_motorist_property_damage,
        deductible_value: 0,
        limit_description: moneyFormatter(uninsured_motorist_property_damage, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  const medPayOrPipNode =
    (medical_payments || personal_injury_protection) && med_pay_or_pip
      ? {
          key: medical_payments ? PolicyCoverageKey.MedicalPayments : PolicyCoverageKey.PersonalInjuryProtection,
          limit_value: med_pay_or_pip,
          deductible_value: 0,
          limit_description: moneyFormatter(med_pay_or_pip, true),
          limit_type: LimitType.MoneyLimit,
          deductible_description: undefined,
          deductible_type: undefined
        }
      : undefined;

  return [
    propertyDamageNode,
    biPerPersonNode,
    biPerAccidentNode,
    umbiPerAccidentNode,
    umbiPerPersonNode,
    underinsuredMbiPerAccidentNode,
    underinsuredMbiPerPersonNode,
    uninsuredMotoristPropertyDamageNode,
    medPayOrPipNode
  ].filter(Boolean);
};

const generateAssetsWithCoverages = (
  values: ReturnType<typeof buildInitialValues> & ValuesPerVehicle,
  opportunityAssets: IVehicle[]
) => {
  return opportunityAssets.map(vehicle => {
    const includedCoverages = values[`${vehicle.gid}`]?.included_coverages as PolicyCoverageKey[];
    const collisionValue = values[`${vehicle.gid}`]?.[PolicyCoverageKey.Collision] as string | number;
    const comprehensiveValue = values[`${vehicle.gid}`]?.[PolicyCoverageKey.Comprehensive] as string | number;

    return {
      gid: vehicle.gid,
      coverages: [
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Collision,
          limit_value: 0,
          deductible_value: collisionValue || 0,
          limit_description: '',
          limit_type: undefined,
          deductible_description: moneyFormatter(collisionValue, true) || '',
          deductible_type: undefined
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Comprehensive,
          limit_value: 0,
          deductible_value: comprehensiveValue || 0,
          limit_description: '',
          limit_type: undefined,
          deductible_description: moneyFormatter(comprehensiveValue, true) || ''
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Towing,
          limit_value: 0,
          deductible_value: 0,
          limit_description: includedCoverages.includes(PolicyCoverageKey.Towing) ? 'Included' : 'Not included',
          limit_type: undefined,
          deductible_description: '',
          deductible_type: undefined
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.FullGlass,
          limit_value: 0,
          deductible_value: 0,
          limit_description: includedCoverages.includes(PolicyCoverageKey.FullGlass) ? 'Included' : 'Not included',
          limit_type: undefined,
          deductible_description: '',
          deductible_type: undefined
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.RentalMaxAmount,
          limit_value: 0,
          deductible_value: 0,
          limit_description: includedCoverages.includes(PolicyCoverageKey.RentalMaxAmount)
            ? 'Included'
            : 'Not included',
          limit_type: undefined,
          deductible_description: '',
          deductible_type: undefined
        },
        ...buildCoverages(values)
      ]
    };
  });
};

const VehiclesCoverages = ({
  opportunityAssets,
  values,
  setValues
}: {
  opportunityAssets: IVehicle[];
  values: ReturnType<typeof buildInitialValues> & ValuesPerVehicle;
  setValues: (values: ReturnType<typeof buildInitialValues> & ValuesPerVehicle) => void;
}) => {
  const currentValues = useDeepEqualMemo(values);
  const prevValues = React.useRef(currentValues);

  React.useEffect(() => {
    if (currentValues.apply_same_coverages_to_all_vehicles) {
      const firstVehicleValues = currentValues[opportunityAssets[0]!.gid];
      const prevFirstVehicleValues = prevValues.current[opportunityAssets[0]!.gid];

      // check if changes are made to first vehicle, if so, copy them
      if (firstVehicleValues && !R.equals(firstVehicleValues, prevFirstVehicleValues)) {
        const newValues = {
          ...currentValues,
          ...opportunityAssets.reduce((acc, { gid }) => ({ ...acc, [gid]: firstVehicleValues }), {} as ValuesPerVehicle)
        };

        prevValues.current = newValues;

        setValues(newValues);
      } else {
        // check if other vehicles have different values, if so, reset checkbox, otherwise store current values
        const isDifferent = opportunityAssets.some(vehicle => {
          const vehicleValues = currentValues[vehicle.gid];
          const prevVehiclesValues = prevValues.current[vehicle.gid];

          return !R.equals(vehicleValues, prevVehiclesValues);
        });

        if (isDifferent) {
          const newValues = {
            ...currentValues,
            apply_same_coverages_to_all_vehicles: false
          } as ReturnType<typeof buildInitialValues> & ValuesPerVehicle;

          prevValues.current = newValues;

          setValues(newValues);
        } else {
          prevValues.current = currentValues;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  // remove blink caused by setValues renders
  const areValuesDifferent = React.useDeferredValue(
    !compareVehicleCoverages(
      opportunityAssets.reduce((acc, currentValue) => {
        const values = currentValues[currentValue.gid];
        if (!values) {
          return acc;
        }

        acc[currentValue.gid] = values;
        return acc;
      }, {} as ValuesPerVehicle)
    )
  );

  return (
    <React.Fragment>
      <Container mh={spacings.px4} mv={spacings.px12}>
        <CheckboxField
          name="apply_same_coverages_to_all_vehicles"
          id="apply_same_coverages_to_all_vehicles"
          label="Apply the same coverage for each vehicle"
          inline
          disabled={areValuesDifferent}
        />
      </Container>
      {opportunityAssets.map(vehicle => {
        const vehicleGid = vehicle.gid;

        return (
          <React.Fragment key={vehicle.gid}>
            <Text bold m={spacings.px4}>
              {buildVehicleTitle(vehicle)}
            </Text>
            <NumericField
              name={`${vehicleGid}.${PolicyCoverageKey.Collision}`}
              id={`${vehicleGid}.${PolicyCoverageKey.Collision}`}
              label="Collision deductible"
              inline
              valueIsNumber
              prefixIcon={<DollarIcon />}
              decimalScale={2}
            />
            <NumericField
              name={`${vehicleGid}.${PolicyCoverageKey.Comprehensive}`}
              id={`${vehicleGid}.${PolicyCoverageKey.Comprehensive}`}
              label="Comprehensive deductible"
              inline
              valueIsNumber
              prefixIcon={<DollarIcon />}
              decimalScale={2}
            />
            <MultiSelectField
              fsMask
              inline
              label="Included coverages"
              key={`${vehicleGid}.included_coverages`}
              name={`${vehicleGid}.included_coverages`}
              id={`${vehicleGid}.included_coverages`}
              options={POSSIBLE_INCLUDED_COVERAGES_OPTIONS}
              css={css`
                background-color: ${colors.white};
                border-radius: 4px;
              `}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const AutoQuoteForm = ({
  policyType,
  setOpenQuoteEditor,
  quote,
  opportunityAssets,
  setSelectedQuoteKey
}: {
  policyType: PolicyType;
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote?: IQuote;
  opportunityAssets: IVehicle[];
  setSelectedQuoteKey: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const queryClient = useQueryClient();
  const { personGid, person, leadGid } = useGuidedSellingExperienceContext();
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({
    policy_type: policyType,
    appointed: true,
    state: opportunityAssets[0]?.address.state
  });
  const { mutateAsync: createQuote } = useCreateQuote();

  const carrierOptions = React.useMemo(() => {
    return (carriers || []).map(({ key, name }) => ({ key, value: name }));
  }, [carriers]);
  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(personGid);

  const primaryInsuredOption = { key: person?.gid, value: person?.name, isDisabled: true, isSelected: true };
  const coinsuredPeopleOptions =
    relatedPeople?.map(person => {
      const { name, gid } = person;
      let optionConfig;

      if (!isDriverInfoCollected(person)) {
        optionConfig = { value: `${name} (driver info is not collected)`, isDisabled: true };
      } else if (!isPersonAgedToDrive(person)) {
        optionConfig = { value: `${name} (${personAge(person)} years old)`, isDisabled: true };
      } else {
        optionConfig = { value: name, isDisabled: false };
      }

      return { key: gid, ...optionConfig };
    }) || [];

  const insuredDriversOptions = [
    primaryInsuredOption,
    ...coinsuredPeopleOptions.sort((option1, option2) => (option1.isDisabled ? 1 : 0) - (option2.isDisabled ? 1 : 0))
  ];

  return (
    <Container
      border
      borderColor={colors.azure50}
      boxShadow
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 410px;
      `}
      backgroundColor={colors.ghostWhite}
      p={spacings.px12}
    >
      <BaseForm
        pt={spacings.px0}
        pr={spacings.px0}
        pb={spacings.px0}
        pl={spacings.px0}
        initialValues={buildInitialValues(quote, opportunityAssets, personGid!)}
        onSubmit={values => {
          createQuote({
            personGid: personGid!,
            data: {
              premium: values.premium,
              source: QuoteSource.AU,
              policy_type: policyType,
              carrier_key: values.carrier_key,
              engagement_gid: leadGid!,
              coverages: buildCoverages(values as ReturnType<typeof buildInitialValues> & ValuesPerVehicle),
              assets: generateAssetsWithCoverages(
                values as ReturnType<typeof buildInitialValues> & ValuesPerVehicle,
                opportunityAssets
              ),
              coinsureds: values.insured_gids.filter(gid => gid !== personGid).map(gid => ({ gid })),
              coverages_tier: 'mixed',
              payment_options: [
                {
                  downpayment: Number(values.downpayment),
                  subsequent_payment: Number(values.subsequent_payment),
                  term_months: values.premium_period,
                  full_term_premium: Number(values.premium)
                }
              ],
              agent_bridge_url: values.agent_bridge_url
            }
          }).then(data => {
            queryClient.invalidateQueries({ queryKey: [QUOTES_REQUESTS_QUERY_KEY, personGid] });
            return queryClient.invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY, personGid] }).then(() => {
              setSelectedQuoteKey(data.quote.gid);
            });
          });

          setOpenQuoteEditor(false);
        }}
        validationSchema={yup.object().shape({
          carrier_key: requiredField,
          premium: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          agent_bridge_url: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .test('is-url', 'must be a valid URL', value => {
              try {
                new URL(value);
                return true;
              } catch (error) {
                return false;
              }
            })
        })}
        customControls
        renderForm={({ values, setFieldValue, setValues }) => {
          return (
            <FlexBox columnDirection gap={spacings.px12}>
              <FlexBox justifySpaceBetween>
                <Text bold ml={spacings.px4}>
                  Add insurance details
                </Text>
                <IconButton
                  icon={ButtonIcons.Close}
                  color={colors.black}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} columnDirection>
                <SelectField
                  name="carrier_key"
                  id="carrier_key"
                  label="Carrier"
                  options={carrierOptions}
                  inline
                  ordered
                  isLoading={isPendingCarriers}
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                  required
                  menuMinWidth="180px"
                />
                <InputField
                  prefixIcon={<LinkSharpIcon />}
                  name="agent_bridge_url"
                  id="agent_bridge_url"
                  label="Carrier portal URL"
                  required
                  inline
                />
                <NumericField
                  name="premium"
                  id="premium"
                  label="Premium(full period)"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  required
                  decimalScale={2}
                  customPrefix={
                    <Container
                      customCss={css`
                        width: 82px;
                      `}
                    >
                      <Select
                        onChange={e => {
                          setFieldValue('premium_period', e);
                        }}
                        value={values.premium_period}
                        isPrefix
                        options={[
                          { key: 6, value: '6 mo' },
                          { key: 12, value: '1 yr' }
                        ]}
                        menuMinWidth="80px"
                        customComponents={{ DropdownIndicator: SmallDropdownIndicator as unknown as React.ReactNode }}
                        customStyles={{ valueContainer: (base: any) => ({ ...base, padding: '2px' }) }}
                      />
                    </Container>
                  }
                />
                <SelectField
                  name="paid_in_full"
                  id="paid_in_full"
                  key="paid_in_full"
                  label="Paid in full"
                  inline
                  options={[
                    { key: YES_VALUE, value: 'Yes' },
                    { key: NO_VALUE, value: 'No' }
                  ]}
                  menuMinWidth="180px"
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
                {values.paid_in_full === NO_VALUE && (
                  <>
                    <NumericField
                      name="downpayment"
                      id="downpayment"
                      label="First month (Downpayment)"
                      inline
                      valueIsNumber
                      prefixIcon={<DollarIcon />}
                      decimalScale={2}
                    />
                    <NumericField
                      name="subsequent_payment"
                      id="subsequent_payment"
                      label="Subsequent monthly payments"
                      inline
                      valueIsNumber
                      prefixIcon={<DollarIcon />}
                      decimalScale={2}
                    />
                  </>
                )}
                <Text bold m={spacings.px4}>
                  General automobile coverages
                </Text>

                <NumericField
                  name={PolicyCoverageKey.BiPerPerson}
                  id={PolicyCoverageKey.BiPerPerson}
                  label="Bodily injury liability"
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each person
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />
                <NumericField
                  name={PolicyCoverageKey.BiPerAccident}
                  id={PolicyCoverageKey.BiPerAccident}
                  label=""
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each accident
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />
                <NumericField
                  name={PolicyCoverageKey.PropertyDamage}
                  id={PolicyCoverageKey.PropertyDamage}
                  label="Property damage"
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each accident
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />
                <NumericField
                  name={PolicyCoverageKey.UninsuredMbiPerPerson}
                  id={PolicyCoverageKey.UninsuredMbiPerPerson}
                  label={
                    <Text>
                      <Text
                        dotted
                        underline
                        data-for="umbi-tip"
                        data-tip="Uninsured/Underinsured motorist bodily injury"
                      >
                        UM/UIM
                      </Text>{' '}
                      <Tooltip id="umbi-tip" />
                      injury liability
                    </Text>
                  }
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each person
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />
                <NumericField
                  name={PolicyCoverageKey.UninsuredMbiPerAccident}
                  id={PolicyCoverageKey.UninsuredMbiPerAccident}
                  label=""
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each accident
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />
                <NumericField
                  name={PolicyCoverageKey.UninsuredMpd}
                  id={PolicyCoverageKey.UninsuredMpd}
                  label={
                    <Text>
                      <Text dotted underline data-for="mpd-tip" data-tip="Uninsured motorist property damage">
                        UM/UIM
                      </Text>{' '}
                      <Tooltip id="mpd-tip" />
                      property damage
                    </Text>
                  }
                  customCss={css`
                    padding-right: 4px;
                    padding-left: 24px;
                  `}
                  customPrefix={
                    <Container
                      pv={spacings.px8}
                      ph={spacings.px16}
                      backgroundColor={colors.grey5}
                      borderColor={colors.grey30}
                      customCss={css`
                        min-width: 100px;
                        border-radius: 4px;
                      `}
                    >
                      <Text
                        color={colors.grey60}
                        type="tiny"
                        bold
                        customCss={css`
                          white-space: nowrap;
                        `}
                      >
                        Each accident
                      </Text>
                    </Container>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  inputSize={InputSize.Small}
                />

                <NumericField
                  name="med_pay_or_pip"
                  id="med_pay_or_pip"
                  label={
                    <>
                      <ButtonWithoutBorder
                        p={spacings.px0}
                        onClick={() => {
                          setFieldValue(PolicyCoverageKey.MedicalPayments, true);
                          setFieldValue(PolicyCoverageKey.PersonalInjuryProtection, false);
                        }}
                      >
                        <Text
                          bold
                          aria-selected={values[PolicyCoverageKey.MedicalPayments]}
                          customCss={selectedTextCss}
                        >
                          Med Pay
                        </Text>
                      </ButtonWithoutBorder>
                      <Text bold>{'  /  '}</Text>
                      <ButtonWithoutBorder
                        p={spacings.px0}
                        onClick={() => {
                          setFieldValue(PolicyCoverageKey.PersonalInjuryProtection, true);
                          setFieldValue(PolicyCoverageKey.MedicalPayments, false);
                        }}
                      >
                        <Text
                          bold
                          aria-selected={values[PolicyCoverageKey.PersonalInjuryProtection]}
                          customCss={selectedTextCss}
                        >
                          PIP
                        </Text>
                      </ButtonWithoutBorder>
                    </>
                  }
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                />

                <MultiSelectField
                  fsMask
                  inline
                  label="Insured drivers"
                  key="insured_gids"
                  id="insured_gids"
                  name="insured_gids"
                  options={insuredDriversOptions}
                  disabled={isPendingRelatedPeople}
                  isLoading={isPendingRelatedPeople}
                  css={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
                <Text bold m={spacings.px4}>
                  Coverages per vehicle
                </Text>
                <VehiclesCoverages
                  opportunityAssets={opportunityAssets}
                  values={values as ReturnType<typeof buildInitialValues> & ValuesPerVehicle}
                  setValues={setValues}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} justifyRight>
                <Button
                  type="button"
                  size={ButtonSize.Small}
                  variant={ButtonVariant.SecondaryDanger}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" size={ButtonSize.Small}>
                  Save
                </Button>
              </FlexBox>
            </FlexBox>
          );
        }}
      />
    </Container>
  );
};

export default AutoQuoteForm;
