/** @jsxImportSource @emotion/react */
import FlexBox from '../../../../../components/core/FlexBox';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Text from '../../../../../components/core/Text';
import { IDispositionVersion } from '../../../../../interfaces';
import { Change } from '../../../../../interfaces/IPaperTrailVersion';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import {
  capitalize,
  dateTimeFormatter,
  humanize,
  SCOUTING_REPORT_DATE_TIME_FORMAT
} from '../../../../../utils/formatter';
import UserRoleTag from './UserRoleTag';
import Version from './Version';

const getSourceFromChanges = (changes: Change[]) => {
  const content = changes.find(c => c.content.includes('source'))?.content;
  const source = content?.split(' ')[1];
  return capitalize(humanize(source));
};

const getDispositionVerb = (version: IDispositionVersion) => {
  if (version.deleted_by) {
    return 'reverted';
  } else if (version.event === 'create' && !version.current_assignee) {
    return 'created';
  } else if (version.event === 'create') {
    return 'assigned';
  } else if (version.event === 'update') {
    return 'reassigned';
  }
  return '';
};

const DispositionsVersionModal = ({
  cancelHandler,
  dispositionsVersions
}: {
  cancelHandler: () => void;
  dispositionsVersions: IDispositionVersion[];
}) => {
  return (
    <Modal
      containerTitle="Log of changes made with the dispositions"
      showCancelButton={false}
      cancelHandler={cancelHandler}
      size={ModalSize.large}
    >
      <FlexBox gap={spacings.px8} columnDirection p={spacings.px12}>
        {dispositionsVersions?.map(version => {
          return (
            <Version created={version.event === 'create'} columnsTemplate="22% 27% 28% 19%" key={version.id}>
              <FlexBox gap={spacings.px4}>
                <Text
                  color={colors.grey60}
                  type="small"
                  singleLine
                  title={`${capitalize(humanize(version.disposition_type))} ${getDispositionVerb(version)}`}
                >
                  <Text color={colors.grey60} type="small" bold>
                    {capitalize(humanize(version.disposition_type))}
                  </Text>{' '}
                  {getDispositionVerb(version)}
                </Text>
              </FlexBox>
              <FlexBox
                gap={spacings.px8}
                alignItemsCenter
                title={!version.deleted_by && version.current_assignee ? `on ${version.current_assignee.name}` : ''}
              >
                <Text color={colors.grey60} type="small" singleLine>
                  {!version.deleted_by && version.current_assignee && `on ${version.current_assignee.name}`}
                </Text>
                <UserRoleTag processedWithRole={version.current_assignee_role} />
              </FlexBox>
              <FlexBox
                gap={spacings.px8}
                alignItemsCenter
                title={`by ${version.author_name || getSourceFromChanges(version.changes)}`}
              >
                <Text color={colors.grey60} type="small" singleLine>
                  by {version.author_name || getSourceFromChanges(version.changes)}
                </Text>
                <UserRoleTag processedBy={version.processed_by} processedWithRole={version.processed_with_role} />
              </FlexBox>

              <Text
                color={colors.grey60}
                type="small"
                singleLine
                title={dateTimeFormatter(version.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
              >
                {dateTimeFormatter(version.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
              </Text>
            </Version>
          );
        })}
      </FlexBox>
    </Modal>
  );
};

export default DispositionsVersionModal;
