import { LicensingTypes, UserRole, UserRoleCategory } from '../interfaces/IUser';

export enum Permissions {
  // This list of permissions is not exhaustive. It only defines those that are used by front-end application.
  EditLeadSourceDimensions = 'edit_lead_source_dimensions',
  DoNotContactCallAvailable = 'do_not_contact_call_available',
  DoNotContactEditable = 'do_not_contact_editable',
  EditClosedLeadData = 'edit_closed_lead_data',
  SetPolicyDocumentsCouldNotCollect = 'set_policy_documents_could_not_collect',
  RewritePolicies = 'rewrite_policies',
  EditPolicyStatus = 'edit_policy_status',
  PersonSensitiveDataAvailable = 'person_sensitive_data_available',
  SendPolicyDocForEsign = 'send_policy_doc_for_esign',
  DispositionHistoryEditable = 'dispositions_history_editable',
  AmpUiProductTab = 'amp_ui_product_tabs',
  EditDispositionReason = 'edit_disposition_reason',
  EditScheduleCallAssignee = 'edit_schedule_call_assignee',
  EditLeadAssignee = 'edit_lead_assignee',
  AssetDelete = 'asset_delete',
  PersonExternalLinksAvailable = 'person_external_links_available',
  AllowPostSaleCarriers = 'allow_post_sale_carriers',
  CreatePolicyFromCustomer = 'create_policy_from_customer_level',
  CustomerProfileQuoting = 'customer_profile_quoting',
  CreateCustomer = 'create_customer',
  EditFicoScore = 'edit_fico_score',
  FullstorySessionRecording = 'fullstory_session_recording',
  GeneratePolicyInvoice = 'generate_policy_invoice',
  InsightsTab = 'insights_tab',
  LoosePhoneValidation = 'loose_phone_validation',
  ReviewQuotesOutsidePipeline = 'review_quotes_outside_pipeline',
  UpdateBusinessType = 'update_business_type',
  UpdateAssignee = 'update_assignee',
  UpdatePartner = 'update_partner',
  UpdateWhoQualified = 'update_who_qualified',
  LeadTestMode = 'lead_test_mode',
  RollbackDispositions = 'rollback_dispositions',
  DownloadCallRecordings = 'download_call_recordings',
  LoosePolicyValidation = 'loose_policy_validation',
  ExtendedPolicyListView = 'extended_policy_list_view',
  CustomerCandidatesView = 'customer_candidates_view',
  SuperIsr = 'super_isr'
}

export interface IAuthInfo {
  currentUserId: number | null;
  currentUserName: string | null;
  currentUserFirstName: string | null;
  currentUserLastName: string | null;
  currentUserEmail: string | null;

  features: { [key in Permissions]: boolean };
  role: UserRole | null;
  roleCategory: UserRoleCategory | null;
  roleLabel: string | null;
  dateOfBirth: string | null;
  licensingTypes: LicensingTypes[] | null;
}

const authInfoImpl = {
  currentUserId: null,
  currentUserName: null,
  currentUserFirstName: null,
  currentUserLastName: null,
  currentUserEmail: null,

  features: {},
  role: null,
  roleCategory: null,
  roleLabel: null,
  dateOfBirth: null,
  licensingTypes: null
};

const authInfo = authInfoImpl as IAuthInfo;

export const isSuperISRFlowEnabled = () => authInfo.features.super_isr;
export const isISR = () => authInfo.roleCategory === UserRoleCategory.ISR;
export const isAgent = () => authInfo.roleCategory === UserRoleCategory.Agent;

export const isLeadOperationsAllowed = () => {
  return (
    authInfo.features.lead_test_mode ||
    authInfo.features.update_assignee ||
    authInfo.features.update_business_type ||
    authInfo.features.update_partner ||
    authInfo.features.update_who_qualified ||
    authInfo.features.rollback_dispositions
  );
};

export default authInfo;
