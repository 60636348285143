/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { components } from 'react-select';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { NumericField } from '../../../components/core/forms/fields';
import { Add2Icon, ArrowUpIcon, CarIcon, DollarIcon, HomeIcon, PercentageIcon } from '../../../components/core/icons';
import Select from '../../../components/core/inputs/Select';
import Text from '../../../components/core/Text';
import { LimitType, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { InsurableInterest, PolicyType } from '../../../interfaces/IPolicyType';
import { IProposal } from '../../../interfaces/IProposal';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { buildInitialValues, COVERAGE_LABELS, generateProposalTitle } from '../_helpers';

export const ProposalCustomOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];
  const isSelected = rest.isSelected;

  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      <FlexBox columnDirection fitParentWidth m={spacings.px4} gap={spacings.px4}>
        <FlexBox justifySpaceBetween fitParentWidth alignItemsFlexEnd>
          {option.carriers({ isSelected })}
          <Text bold color={isSelected ? colors.white : colors.black}></Text>
        </FlexBox>
      </FlexBox>
    </components.Option>
  );
};

export const createProposalOptions = (proposals: IProposal[]) =>
  proposals.map(proposal => ({
    key: proposal.gid,
    value: generateProposalTitle(proposal),
    created_at: proposal.created_at,
    sent_at: proposal.sent_at,
    carriers: ({ isSelected }: { isSelected: boolean }) => {
      return (
        <FlexBox columnDirection gap={spacings.px8}>
          <Text type="tiny" color={isSelected ? colors.white : colors.grey60}>
            {generateProposalTitle(proposal)}
          </Text>
          <FlexBox
            customCss={css`
              flex-wrap: wrap;
            `}
            fitParentWidth
          >
            {proposal.proposal_options?.[0]?.quotes.map((quote, index) => (
              <FlexBox key={quote.gid} alignItemsCenter gap={spacings.px4}>
                {quote.attributes.insurable_interest === InsurableInterest.RealProperty ? <HomeIcon /> : <CarIcon />}
                <div
                  css={css`
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 30px;
                    width: 80px;
                    filter: ${isSelected ? 'invert(100%)' : ''};
                    ${quote.attributes.carrier.logo ? `background-image: url(${quote.attributes.carrier.logo})` : ''};
                  `}
                  title={quote.attributes.carrier.name}
                />
                {proposal.proposal_options[0] && index < proposal.proposal_options[0].quotes.length - 1 && (
                  <Container m={spacings.px4}>
                    <Add2Icon width={12} />
                  </Container>
                )}
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>
      );
    }
  }));

export const SmallDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowUpIcon
      className="select-dropdown-indicator"
      color={colors.black}
      css={css`
        width: 12px;
        height: 12px;
        transform: rotate(${props.selectProps.menuIsOpen ? 0 : 180}deg);
        transition:
          transform 0.3s ease,
          visibility 0.2s ease,
          opacity 0.2s ease;
      `}
    />
  </components.DropdownIndicator>
);

const DeductibleInput = ({ values }: { values: { deductible: string | number } }) => (
  <NumericField
    name="deductible"
    id="deductible"
    label="Deductible"
    inline
    valueIsNumber
    prefixIcon={values.deductible && Number(values.deductible) < 100 ? <PercentageIcon /> : <DollarIcon />}
    decimalScale={2}
    required
  />
);

const DwellingInput = () => (
  <NumericField
    name={PolicyCoverageKey.Dwelling}
    id={PolicyCoverageKey.Dwelling}
    label={COVERAGE_LABELS[PolicyCoverageKey.Dwelling]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const OtherStructuresInput = () => (
  <NumericField
    name={PolicyCoverageKey.OtherStructures}
    id={PolicyCoverageKey.OtherStructures}
    label={COVERAGE_LABELS[PolicyCoverageKey.OtherStructures]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const PersonalPropertyInput = () => (
  <NumericField
    name={PolicyCoverageKey.PersonalProperty}
    id={PolicyCoverageKey.PersonalProperty}
    label={COVERAGE_LABELS[PolicyCoverageKey.PersonalProperty]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const LossOfUseInput = ({
  values,
  setFieldValue
}: {
  values: { lossOfUseLimitType: string };
  setFieldValue: (key: string, value: string) => void;
}) => (
  <NumericField
    name={PolicyCoverageKey.LossOfUse}
    id={PolicyCoverageKey.LossOfUse}
    label={COVERAGE_LABELS[PolicyCoverageKey.LossOfUse]}
    inline
    valueIsNumber
    decimalScale={2}
    required
    customPrefix={
      <Container
        customCss={css`
          width: 62px;
        `}
      >
        <Select
          onChange={e => {
            setFieldValue('lossOfUseLimitType', e);
          }}
          value={values.lossOfUseLimitType}
          isPrefix
          options={[
            { key: LimitType.MoneyLimit, value: '$' },
            { key: LimitType.MonthlyLimit, value: 'mo' }
          ]}
          menuMinWidth="60px"
          customComponents={{
            DropdownIndicator: SmallDropdownIndicator as unknown as React.ReactNode
          }}
          customStyles={{ valueContainer: (base: any) => ({ ...base, padding: '2px' }) }}
        />
      </Container>
    }
  />
);

const PersonalLiabilityInput = ({ policyType }: { policyType: PolicyType }) => (
  <NumericField
    name={PolicyCoverageKey.PersonalLiability}
    id={PolicyCoverageKey.PersonalLiability}
    label={policyType === PolicyType.Fire ? 'Premises liability' : COVERAGE_LABELS[PolicyCoverageKey.PersonalLiability]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const MedicalPaymentsInput = () => (
  <NumericField
    name={PolicyCoverageKey.MedicalPayments}
    id={PolicyCoverageKey.MedicalPayments}
    label={COVERAGE_LABELS[PolicyCoverageKey.MedicalPayments]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const HouseholdFurnishingsInput = () => (
  <NumericField
    name={PolicyCoverageKey.HouseholdFurnishings}
    id={PolicyCoverageKey.HouseholdFurnishings}
    label={COVERAGE_LABELS[PolicyCoverageKey.HouseholdFurnishings]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const FairRentalValueInput = () => (
  <NumericField
    name={PolicyCoverageKey.FairRentalValue}
    id={PolicyCoverageKey.FairRentalValue}
    label={COVERAGE_LABELS[PolicyCoverageKey.FairRentalValue]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

interface IHomeCoverages {
  values: ReturnType<typeof buildInitialValues>;
  setFieldValue: (key: string, value: string) => void;
}

const DefaultHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <OtherStructuresInput />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Home} />
    <MedicalPaymentsInput />
  </>
);

const CondoHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Condo} />
    <MedicalPaymentsInput />
  </>
);

const InvestmentHomeCoverages = ({ values }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <OtherStructuresInput />
    <HouseholdFurnishingsInput />
    <FairRentalValueInput />
    <PersonalLiabilityInput policyType={PolicyType.Fire} />
    <MedicalPaymentsInput />
  </>
);

const RentersHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Renters} />
    <MedicalPaymentsInput />
  </>
);

export const HomeCoveragesComponent = ({ policyType, ...props }: IHomeCoverages & { policyType: PolicyType }) => {
  switch (policyType) {
    case PolicyType.Condo:
      return <CondoHomeCoverages {...props} />;
    case PolicyType.Fire:
      return <InvestmentHomeCoverages {...props} />;
    case PolicyType.Renters:
      return <RentersHomeCoverages {...props} />;
    case PolicyType.Home:
    default:
      return <DefaultHomeCoverages {...props} />;
  }
};
