/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { IPerson } from '../../interfaces';
import { NewPolicyOptions } from '../../interfaces/INewPolicyOptions';
import { opportunityDescription } from '../../interfaces/IOpportunity';
import { buildVehicleTitle, VehicleKind } from '../../interfaces/IVehicle';
import usePersonVehicles from '../../queries/people/personVehicles';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import {
  initialValues as vehiclesInitialValues,
  useVehiclesForm,
  validationSchema as vehiclesValidationSchema
} from '../AddVehiclesForm/utils';
import VehiclesFormRenderer from '../AddVehiclesForm/VehiclesFormRenderer';
import { ButtonVariant } from '../core/buttons/Button';
import CollapsingContainer from '../core/CollapsingContainer';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import BaseForm from '../core/forms/BaseForm';
import Heading from '../core/Heading';
import { CarIcon, MotorbikeIcon } from '../core/icons';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph, { ParagraphType } from '../core/Paragraph';
import Text from '../core/Text';
import { buildInitialValues, usePriorPolicyEditorSubmit, validationSchema as mainSchema } from './helpers';
import MainForm from './MainForm';

export interface PriorPolicyWithVehiclesFormProps {
  person: IPerson;
  leadGid: string | undefined;
  policyType?: NewPolicyOptions['policyType'];
  assets?: NewPolicyOptions['assets'] | null;
  cancelHandler: () => void;
  confirmHandler: Parameters<typeof usePriorPolicyEditorSubmit>[0]['confirmHandler'];
  uiSource?: string;
}

const PriorPolicyWithVehiclesForm = ({
  person,
  leadGid,
  policyType,
  assets,
  cancelHandler,
  confirmHandler,
  uiSource
}: PriorPolicyWithVehiclesFormProps) => {
  const personGid = person.gid;
  const assetsGids = assets?.map(({ gid }) => gid);
  const submitHandler = usePriorPolicyEditorSubmit({
    personGid,
    confirmHandler,
    policyType,
    assetsGids,
    priorPolicy: undefined,
    leadGid
  });

  const { data: personVehicles, isSuccess } = usePersonVehicles(person.gid);
  const vehiclesFormData = useVehiclesForm({ personGid, leadGid, withFenrisCollapse: false, uiSource });

  const firstPersonAddress = vehiclesFormData.personAddresses?.[0];

  const initialValues = {
    ...buildInitialValues({ priorPolicy: undefined, policyType }),
    ...vehiclesInitialValues({
      dateOfBirth: person.date_of_birth,
      prefilledAddress: firstPersonAddress
    })
  };

  const personAddresses = vehiclesFormData.personAddresses;
  const isPersonVehiclesAdded = personVehicles && personVehicles.length > 0;

  return (
    <Modal
      headerCss={css`
        align-items: center;
      `}
      header={
        <FlexBox columnDirection>
          <Paragraph type="large" bold>
            {`Add prior policy details for ${policyType || ''}`}
          </Paragraph>
          {assets && policyType && (
            /* @ts-expect-error: TODO consider using description_without_icon method and allow IPersonAsset */
            <Paragraph className="fs-mask">{opportunityDescription({ policy_type: policyType, assets })}</Paragraph>
          )}
        </FlexBox>
      }
      size={ModalSize.large}
      showCancelButton={false}
      cancelHandler={cancelHandler}
    >
      {!personAddresses || !isSuccess ? (
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <FlexBox columnDirection gap={spacings.px24}>
            {[1, 2, 3, 4, 5].map(value => (
              <FlexBox key={value} gap={spacings.px24}>
                <Heading
                  customCss={css`
                    width: 50%;
                  `}
                >
                  <Skeleton />
                </Heading>
                <Heading
                  customCss={css`
                    width: 50%;
                  `}
                >
                  <Skeleton />
                </Heading>
              </FlexBox>
            ))}
          </FlexBox>
        </SkeletonTheme>
      ) : (
        <BaseForm
          initialValues={initialValues}
          validationSchema={mainSchema.concat(
            vehiclesValidationSchema({
              withPrefillValidation: vehiclesFormData.showPrefill
            })
          )}
          onSubmit={values => submitHandler(values)}
          cancelHandler={cancelHandler}
          cancelVariant={ButtonVariant.Secondary}
          submitText="Add"
          loading={
            !vehiclesFormData.personAddresses ||
            vehiclesFormData.isPrefillingVehiclesAndDrivers ||
            vehiclesFormData.isUpdatingPerson
          }
          pt={16}
          pb={16}
          pl={16}
          pr={16}
          renderForm={formikProps => {
            const {
              vehicle_presence: vehiclePresenceError,
              vehicles: vehiclesError,
              date_of_birth: dobError
            } = formikProps.errors;

            const shouldForceExpandCollapsingContainer =
              formikProps.submitCount > 0 && (!!vehiclePresenceError || !!vehiclesError || !!dobError);

            return (
              <>
                <MainForm selectedPolicyType={policyType} formikProps={formikProps} />
                {isPersonVehiclesAdded && (
                  <>
                    <Container borderBottom borderColor={colors.grey80} />
                    <Paragraph bold pt={spacings.px20}>
                      These vehicles are already added to the profile
                    </Paragraph>
                    <FlexBox mt={spacings.px8} mb={spacings.px16} gap={spacings.px16}>
                      {personVehicles?.map(vehicle => (
                        <FlexBox gap={spacings.px8} alignItemsCenter key={vehicle.gid}>
                          {vehicle.kind === VehicleKind.MOTORCYCLE ? <MotorbikeIcon /> : <CarIcon />}
                          <Text>{buildVehicleTitle(vehicle)}</Text>
                        </FlexBox>
                      ))}
                    </FlexBox>
                  </>
                )}
                <Container borderBottom borderColor={colors.grey80} />
                <CollapsingContainer
                  pt={spacings.px12}
                  openedByDefault={!isPersonVehiclesAdded || shouldForceExpandCollapsingContainer}
                  containerTitle="Add auto asset"
                  iconPosition="left"
                  titleSize={ParagraphType.Default}
                  key={shouldForceExpandCollapsingContainer ? formikProps.submitCount : 0}
                >
                  <VehiclesFormRenderer
                    {...formikProps}
                    {...vehiclesFormData}
                    personAddresses={personAddresses}
                    personGid={personGid}
                    person={person}
                  />
                </CollapsingContainer>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default PriorPolicyWithVehiclesForm;
