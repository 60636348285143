/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

import { Link } from '../../components/common';
import Container from '../../components/core/Container';
import DottedTag from '../../components/core/DottedTag';
import FlexBox from '../../components/core/FlexBox';
import { ClockIcon, RobotIcon } from '../../components/core/icons';
import Tag from '../../components/core/Tag';
import Text from '../../components/core/Text';
import UploadPolicyDocument from '../../components/Documents/UploadPolicyDocument';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { TaskKind } from '../../interfaces/ITask';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import {
  DateGroupName,
  ITaskDashboardContactFlow,
  ITaskDashboardTask,
  ItemStatus,
  TaskDashboardItems,
  TasksGroupBy
} from '../../queries/tasks/useGroupedTasks';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { capitalize, dateFormatter, humanize } from '../../utils/formatter';

export enum DateFilter {
  PreviousMonth = 'previous_month',
  Month = 'month',
  Week = 'week'
}
export enum SortKey {
  DueDate = 'due_time',
  CustomerName = 'customer_name',
  Kind = 'kind'
}

export type Sorting = {
  id: SortKey;
  desc: boolean;
};

export const SORTING_OPTIONS = [
  { label: 'Due date', value: SortKey.DueDate },
  { label: 'Customer', value: SortKey.CustomerName },
  { label: 'Task type', value: SortKey.Kind }
] as const;

export const FILTER_OPTIONS = [
  { label: 'Previous month', value: DateFilter.PreviousMonth },
  { label: 'Current month', value: DateFilter.Month },
  { label: 'Current week', value: DateFilter.Week }
] as const;

export const GROUP_OPTIONS = [
  { label: 'Due date', value: TasksGroupBy.DueDate },
  { label: 'Customer', value: TasksGroupBy.Customer },
  { label: 'Task type', value: TasksGroupBy.TaskType }
] as const;

export const formatDates = (filter: DateFilter) => {
  switch (filter) {
    case DateFilter.PreviousMonth:
      return {
        date_from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        date_to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      };
    case DateFilter.Month:
      return {
        date_from: moment().startOf('month').format('YYYY-MM-DD'),
        date_to: moment().endOf('month').format('YYYY-MM-DD')
      };
    case DateFilter.Week:
      return {
        date_from: moment().startOf('week').format('YYYY-MM-DD'),
        date_to: moment().endOf('week').format('YYYY-MM-DD')
      };
  }
};

export const DATE_GROUP_ORDER = [
  DateGroupName.Overdue,
  DateGroupName.Today,
  DateGroupName.Future,
  DateGroupName.Resolved
] as const;

export const formatGroupBy = (
  groupName: TaskDashboardItems['group_name'],
  groupBy: TasksGroupBy.DueDate | TasksGroupBy.TaskType
) => {
  if (groupBy === TasksGroupBy.DueDate) {
    switch (groupName as DateGroupName) {
      case DateGroupName.Overdue:
        return 'Overdue';
      case DateGroupName.Today:
        return 'Today';
      case DateGroupName.Future:
        return 'Future';
      case DateGroupName.Resolved:
        return 'Resolved';
      default:
        return groupName;
    }
  }

  return capitalize(humanize(groupName));
};

const TASK_KIND_ORDER = [
  ScheduledInteractionFlow.PipelineHighPriority,
  TaskKind.FollowUp,
  ScheduledInteractionFlow.ScheduledCallV2,
  TaskKind.Call,
  TaskKind.TrailingDoc,
  TaskKind.Fax,
  TaskKind.Email,
  TaskKind.PreBindApproval,
  TaskKind.AdvisorError,
  TaskKind.Escalation,
  TaskKind.Service,
  TaskKind.Underwriting
];

export const getTagColor = (status: ItemStatus) => {
  switch (status) {
    case ItemStatus.ToDo:
    case ItemStatus.Attempt1:
      return colors.grey60;
    case ItemStatus.InProgress:
      return colors.azure50;
    case ItemStatus.Completed:
      return colors.statusGreen;
    case ItemStatus.Rescheduled:
    case ItemStatus.LastAttempt:
      return colors.statusOrange;
    case ItemStatus.Cancelled:
      return colors.statusRed;
    case ItemStatus.Overdue:
      return colors.statusRed;
    default:
      return colors.azure50;
  }
};

export const isTaskType = (item: ITaskDashboardTask | ITaskDashboardContactFlow): item is ITaskDashboardTask => {
  return 'policy_id' in item;
};

export const TaskType = ({ item }: { item: ITaskDashboardTask | ITaskDashboardContactFlow }) => {
  if (!isTaskType(item)) {
    if (item.kind === ScheduledInteractionFlow.PipelineHighPriority) {
      return <Tag label="Follow-up" backgroundColor={colors.violet} textColor={colors.violet} transparent border />;
    }
    if (item.kind === ScheduledInteractionFlow.ScheduledCallV2) {
      return <Tag label="Call" backgroundColor={colors.violet} textColor={colors.violet} transparent border />;
    }
  }
  if (item.kind === TaskKind.Fax || item.kind === TaskKind.TrailingDoc) {
    return (
      <Tag
        label={capitalize(item.kind)}
        backgroundColor={colors.yellow200}
        textColor={colors.yellow800}
        transparent
        border
      />
    );
  }

  return (
    <Tag label={capitalize(item.kind)} backgroundColor={colors.violet} textColor={colors.violet} transparent border />
  );
};

export const isTimeLaterThanNow = ({ dueTime, dueDate }: { dueTime: string | null; dueDate: string }) => {
  const now = moment();

  if (dueTime) {
    return moment(dueTime).isAfter(now);
  }
  return moment(dueDate).endOf('day').isAfter(now);
};

const columnHelper = createColumnHelper<ITaskDashboardTask | ITaskDashboardContactFlow>();

const extractDocumentTypeFromTaskDescription = (text: string | null) => {
  // hack that needs to be fixed because we can't live with this
  const match = text?.match(/Upload (.*?) for policy #(\w+)/);
  return match ? match[1] : null;
};

const deformatString = (str: string | null | undefined) =>
  str ? str.replace(/\s+/g, '_').replace(/^./, c => c.toLowerCase()) : ''; // hack that needs to be fixed

export const tableColumns = ({ docTypes, groupName }: { docTypes?: DocumentType[]; groupName: string }) => [
  columnHelper.accessor('kind', {
    cell: ({ row: { original } }) => (
      <Container
        customCss={css`
          width: 130px;
        `}
        mv={spacings.px4}
      >
        <TaskType item={original} />
      </Container>
    ),
    header: () => (
      <Text bold color={colors.grey60}>
        Type
      </Text>
    ),
    size: 130,
    sortingFn: (rowA, rowB) => {
      const kindA = rowA.original.kind;
      const kindB = rowB.original.kind;

      const indexA = TASK_KIND_ORDER.indexOf(kindA);
      const indexB = TASK_KIND_ORDER.indexOf(kindB);

      if (indexA === indexB) {
        return rowA.index - rowB.index;
      }

      return indexA - indexB;
    }
  }),
  columnHelper.display({
    id: 'description',
    cell: ({ row: { original } }) => (
      <FlexBox
        customCss={css`
          width: 500px;
        `}
        gap={spacings.px4}
      >
        <Text singleLine title={original.description} className="fs-mask">
          {original.description}
        </Text>
        {isTaskType(original) && !original.author_id && (
          <RobotIcon
            data-for={`created-by-system-${groupName}`}
            data-tip="Created by system"
            color={colors.grey30}
            css={css`
              flex-shrink: 0;
            `}
          />
        )}
      </FlexBox>
    ),
    header: () => (
      <Text bold color={colors.grey60}>
        Description
      </Text>
    ),
    size: 500
  }),
  columnHelper.display({
    id: 'status',
    cell: ({ row: { original } }) => (
      <Container
        customCss={css`
          width: 120px;
        `}
      >
        <DottedTag text={humanize(capitalize(original.status))} color={getTagColor(original.status)} />
      </Container>
    ),
    header: () => (
      <Text bold color={colors.grey60}>
        Status
      </Text>
    ),
    size: 120
  }),
  columnHelper.accessor('due_time', {
    cell: ({ row: { original } }) => (
      <FlexBox
        alignItemsCenter
        gap={spacings.px4}
        customCss={css`
          width: 180px;
        `}
      >
        <ClockIcon />
        <Text
          color={
            isTimeLaterThanNow({ dueTime: original.due_time, dueDate: original.due_date })
              ? colors.black
              : colors.statusRed
          }
          singleLine
          type="small"
          title={
            original.due_time
              ? moment(original.due_time).format('MM/DD/YYYY | h:mma')
              : dateFormatter(original.due_date)
          }
        >
          due{' '}
          {original.due_time
            ? moment(original.due_time).format('MM/DD/YYYY | h:mma')
            : dateFormatter(original.due_date)}
        </Text>
      </FlexBox>
    ),
    header: () => (
      <Text bold color={colors.grey60}>
        Due date
      </Text>
    ),
    size: 180,
    sortingFn: (rowA, rowB) => {
      if (!rowA.original.due_time || !rowB.original.due_time) {
        return 0;
      }

      const dueTimeA = new Date(rowA.original.due_time).valueOf();
      const dueTimeB = new Date(rowB.original.due_time).valueOf();

      return dueTimeA > dueTimeB ? 1 : -1;
    }
  }),
  columnHelper.accessor('customer_name', {
    cell: ({ row: { original } }) => (
      <Link
        css={css`
          color: ${colors.black};
          cursor: pointer;
          width: 220px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 700;
          font-size: 14px;
        `}
        className="fs-mask"
        to={{ pathname: `/people/${original.person_gid}` }}
      >
        {original.customer_name}
      </Link>
    ),
    header: () => (
      <Text bold color={colors.grey60} singleLine>
        Customer name
      </Text>
    ),
    size: 220
  }),
  columnHelper.display({
    id: 'action',
    cell: ({ row: { original } }) => {
      const documentTypeKey = deformatString(extractDocumentTypeFromTaskDescription(original.description));
      const displayUpload =
        isTaskType(original) &&
        original.policy_id &&
        original.kind === TaskKind.TrailingDoc &&
        documentTypeKey &&
        original.description &&
        !original.completed &&
        docTypes;

      return (
        <Container
          customCss={css`
            width: 140px;
          `}
        >
          {displayUpload && (
            <UploadPolicyDocument
              documentTypeKey={documentTypeKey}
              policyId={original.policy_id!}
              personGid={original.person_gid}
              docTypes={docTypes}
            />
          )}
        </Container>
      );
    },
    size: 140
  })
];
