import guidedSellingExperienceNavigation from '../../pages/GuidedSellingExperience/navigation';
import peopleSearchNavigation from '../../pages/PeopleSearch/navigation';

export interface InputParams {
  currentCallId?: number;
  leadGid?: string;
  candidateGid?: string;
  personGid?: string;
  criteria?: string;
}

const buildUrl = ({ currentCallId, leadGid, candidateGid, personGid, criteria }: InputParams): string => {
  if (currentCallId && leadGid) {
    return guidedSellingExperienceNavigation.forLeadAndCall({ call_log_id: currentCallId, lead_gid: leadGid });
  } else if (currentCallId) {
    return guidedSellingExperienceNavigation.forCall({ call_log_id: currentCallId });
  } else if (leadGid) {
    return guidedSellingExperienceNavigation.forLead({ lead_gid: leadGid });
  } else if (candidateGid) {
    return guidedSellingExperienceNavigation.forCandidate({ candidate_gid: candidateGid });
  } else if (personGid) {
    return guidedSellingExperienceNavigation.forPerson({ person_gid: personGid });
  } else if (criteria) {
    return peopleSearchNavigation.fromExternalSource({ term: criteria });
  }

  return '/';
};

export default buildUrl;
