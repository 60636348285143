import { FormikProps } from 'formik';

import { CarrierAddress } from '../../../components/PolicyList/generateDocxReport';
import { IDocument, IMaticPolicy, IUser } from '../../../interfaces';
import { FileToUpload, PolicyFileToProcess } from '../../../interfaces/IDocument';
import { RealPropertyOpportunity } from '../../../interfaces/IOpportunity';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { CreatePolicyRequest } from '../../../queries/people/person_policies/useCreateMaticPolicy';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import { UNIVERSAL_CONSENT_FIELD_KEY } from '../_components/CarrierInstructions/UniversalCarrierConsent';
import { LEGAL_CONSENT_FIELD_KEY } from '../_components/PolicyLegalConsent';
import { getPeriodOptions, NEW_CLOSING_OPTIONS, STATUS_OPTIONS } from '../_helpers';

export interface HomeValues {
  status: (typeof STATUS_OPTIONS)[number]['value'];
  reason: string;
  policy_type: PolicyType;
  carrier_id: number;
  policy_number: string;
  effective_date: string;
  period: ReturnType<typeof getPeriodOptions>[number]['key'];
  payment_method: string;
  expiration_date: string;
  dwelling: string;
  deductible: string;
  deductible_type: string;
  premium: string;
  new_closing: (typeof NEW_CLOSING_OPTIONS)[number]['key'] | undefined;
  mailing_method: CarrierAddress;
  agent_id: number;
  files: (IDocument | PolicyFileToProcess)[];
  filesToUpload: FileToUpload[];
  assets_gids: string[];
  document_rules_policies_attributes: CreatePolicyRequest['document_rules_policies_attributes'];
  coinsureds: string[];
  [UNIVERSAL_CONSENT_FIELD_KEY]: boolean;
  [LEGAL_CONSENT_FIELD_KEY]: 'true' | '';
}

export interface IHomePolicyForm {
  opportunity: RealPropertyOpportunity;
  documents: IDocument[];
  policy?: IMaticPolicy;
  onSubmit: (values: HomeValues) => void;
  children?: React.ReactNode;
  refetchPolicies: ReturnType<typeof usePersonMaticPolicies>['refetch'];
  isDataEditingForbidden: boolean;
  showPolicyDisclosure: boolean;
}

export type IHomeFormRenderer = FormikProps<HomeValues> & {
  opportunity: RealPropertyOpportunity;
  policy?: IMaticPolicy;
  assignees?: IUser[];
  isPendingAssignees: boolean;
  children?: React.ReactNode;
  isPolicyRequired: boolean;
  showPolicyDisclosure: boolean;
  policyNumberWarning: string;
};

export const FLOOD_POLICY_ESCROW_WARNING_MESSAGE =
  'You must read the following statement verbatim to the customer: ' +
  '"To confirm, your mortgage lender requires you to carry flood insurance, ' +
  'and the flood policy has been approved to be escrowed, correct?"';
