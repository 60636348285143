/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import * as React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import {
  AttachmentIcon,
  CatchUpIcon,
  ClockIcon,
  DollarIcon,
  HelpIcon,
  LightbulbIcon,
  NoteIcon,
  OperationsIcon,
  ShieldCheckIcon,
  TaskIcon,
  UserIcon
} from '../../components/core/icons';
import Modal from '../../components/core/Modal';
import {
  isEndDisposition,
  isLeadTransferred,
  isSidebarDataEditingEnabled
} from '../../components/DispositionsModals/dispositionsHelper';
import PersonLoansTab from '../../components/PersonLoansTab';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useDocumentTitle, useTrackFullStorySession, useTrackOpeningLead } from '../../hooks';
import { IDataCollectionPageType } from '../../interfaces/IDataCollection';
import { DispositionType } from '../../interfaces/IDisposition';
import { LeadTypes } from '../../interfaces/ISourceDimensions';
import { UserRoleCategory } from '../../interfaces/IUser';
import useDataCollection from '../../queries/leads/data_collection/useDataCollection';
import authInfo, { isISR, isLeadOperationsAllowed, isSuperISRFlowEnabled } from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import PostSalesExperience from '../PostSalesExperience';
import useDisplayFinalizedSalesPage from '../PostSalesExperience/_contexts/useFinalizedPage';
import CSTPostSalesExperience from '../PostSalesExperience/CSTPostSalesExperience';
import FinalizedSalesPage from '../PostSalesExperience/ThankYouPage/FinalizedSalesPage';
import NewFinalizedSalesPage from '../PostSalesExperience/ThankYouPage/NewFinalizedSalesPage';
import ProposalBuilder from '../ProposalBuilder';
import CancelActiveCallModal from './_components/CancelActiveCallModal';
import EngagementNavigation from './_components/EngagementNavigation';
import PeopleMergedAutomaticallyModal from './_components/PeopleMergedAutomaticallyModal';
import VerticalTabs from './_components/VerticalTabs';
import { isCustomerDataCompletenessHigh } from './_helpers';
import {
  useCleanupLocalStorage,
  useCurrentDispositionSubscriptionContext,
  useCustomerEventsChannel,
  useCustomerEventsState,
  useDataEditingForbidden,
  useVerticalTabsCallbacks
} from './_hooks';
import GuidedDataCollection from './GuidedDataCollection';
import useDataCollectionStepper from './GuidedDataCollection/_hooks/useDataCollectionStepper';
import HandOffPage from './GuidedDataCollection/HandOffPage';
import ISRFinishPage from './GuidedDataCollection/ISRFinishPage';
import SuperISRFinishPage from './GuidedDataCollection/SuperISRFinishPage';
import GuidedQuoting from './GuidedQuoting';
import GuidedQuotingForm from './GuidedQuoting/GuidedQuotingForm';
import PersonHeader from './header/PersonHeader';
import Layout from './Layout';
import { GuidedSellingPathnames, GuidedSellingRoutes } from './navigation';
import Summary from './Summary';
import CatchUpTab from './tabs/CatchupTab';
import CollectedInfoTab from './tabs/CollectedInfoTab';
import CollectedBy from './tabs/CollectedInfoTab/_components/CollectedBy';
import CommunicationTab from './tabs/CommunicationTab';
import Documents from './tabs/FilesTab';
import FinalizeHelpTab from './tabs/FinalizeHelpTab';
import InsightsTab from './tabs/InsightsTab';
import ManagerTab from './tabs/ManagerTab';
import NotesTab from './tabs/NotesTab';
import ObjectionsGuideTab from './tabs/ObjectionsGuideTab';
import DeprecatedLoansTab from './tabs/PersonLoansTab';
import PoliciesTab from './tabs/PoliciesTab';
import TasksTab from './tabs/TasksTab';

const DATA_COLLECTION_CONFIRMED_KEY = 'data-collection-confirmed';
const Tabs = ({ isEditModeAvailable }: { isEditModeAvailable: boolean }) => {
  const { person, lead, customerDataCompleteness } = useGuidedSellingExperienceContext();
  const { pathname } = useLocation();
  const { displayFinalizedSalesPage } = useDisplayFinalizedSalesPage();
  const { createdNote } = useCustomerEventsState();

  const showCollectedByBlock =
    isCustomerDataCompletenessHigh(customerDataCompleteness) &&
    !localStorage.getItem(`${DATA_COLLECTION_CONFIRMED_KEY}-${lead?.id}`);
  // eslint-disable-next-line max-len
  const collectedByCustomerMessage = `Hey ${authInfo.currentUserFirstName}! This is what ${person?.first_name} collected about themselves already online.`;
  const displayLeadOperationsTab = lead && isLeadOperationsAllowed();

  useCleanupLocalStorage(DATA_COLLECTION_CONFIRMED_KEY);
  const { onTabClose } = useVerticalTabsCallbacks();

  const derivedHelpTab = () => {
    if (pathname === GuidedSellingPathnames.Finalize) {
      return {
        tabIcon: <HelpIcon key="help" />,
        tabName: 'Help',
        content: <FinalizeHelpTab />,
        openedByDefault: displayFinalizedSalesPage === false
      };
    }

    if (pathname === GuidedSellingPathnames.Quotes) {
      return {
        tabIcon: <HelpIcon key="help" />,
        tabName: 'Help',
        content: <ObjectionsGuideTab panel="quotes" />
      };
    }

    return {
      tabIcon: <HelpIcon key="help" />,
      tabName: 'Help',
      content: <ObjectionsGuideTab panel="rapport" />
    };
  };

  const tabContent = [
    isISR() && {
      tabIcon: <CatchUpIcon key="catch-up" />,
      tabName: 'Catchup',
      content: <CatchUpTab />,
      openedByDefault: true
    },
    {
      tabIcon: (
        <FlexBox key="info">
          {showCollectedByBlock && (
            <CollectedBy
              message={collectedByCustomerMessage}
              onConfirm={() =>
                localStorage.setItem(`${DATA_COLLECTION_CONFIRMED_KEY}-${lead?.id}`, Date.now().toString())
              }
            />
          )}
          <UserIcon key="info" />
        </FlexBox>
      ),
      tabName: 'Info',
      content: <CollectedInfoTab isEditModeAvailable={isEditModeAvailable} />
    },
    {
      tabIcon: (
        <React.Fragment key="note">
          {createdNote && (
            <Container
              customCss={css`
                position: relative;
                top: 0px;
                left: 18px;
                width: 8px;
                height: 8px;
                border-radius: 4px;

                background-color: ${colors.statusRed};
              `}
            />
          )}
          <NoteIcon />
        </React.Fragment>
      ),
      tabName: 'Notes',
      content: <NotesTab />
    },
    {
      tabIcon: <TaskIcon key="tasks" />,
      tabName: 'Tasks',
      content: <TasksTab />,
      openedByDefault: pathname === GuidedSellingPathnames.Finalize && displayFinalizedSalesPage
    },
    {
      tabIcon: <ShieldCheckIcon key="policies" />,
      tabName: 'Policies',
      content: <PoliciesTab />
    },
    {
      tabIcon: <ClockIcon key="feed" />,
      tabName: 'Feed',
      content: <CommunicationTab personGid={person?.gid} />
    },
    {
      tabIcon: <AttachmentIcon key="files" />,
      tabName: 'Docs',
      content: <Documents />
    },
    {
      tabIcon: <DollarIcon key="loans" />,
      tabName: 'Loans',
      content:
        featureFlags.newGuidedSellingLoansUI && person?.gid ? (
          <PersonLoansTab personGid={person.gid} onTabClose={onTabClose} />
        ) : (
          <DeprecatedLoansTab />
        )
    },
    derivedHelpTab(),
    authInfo.features.insights_tab && {
      tabIcon: <LightbulbIcon key="insights" />,
      tabName: 'Insights',
      content: <InsightsTab />
    },
    displayLeadOperationsTab && {
      tabIcon: <OperationsIcon key="operations" />,
      tabName: 'Manager',
      content: <ManagerTab />
    }
  ].filter(Boolean);

  return <VerticalTabs tabs={tabContent} collapsedByDefault={!isISR()} wide />;
};

const KnownPersonPage = () => {
  const navigate = useNavigate();
  const { search, state } = useLocation();

  const configureQuoting = !!(state as any)?.configureQuoting;

  const { leadGid, candidateGid, lead, person, sourceDimensions } = useGuidedSellingExperienceContext();

  useTrackOpeningLead({ leadGid, leadId: lead?.id });
  useTrackFullStorySession({ leadGid, candidateGid });
  useDocumentTitle(person!.name);
  useCustomerEventsChannel(person!.gid);

  const dataCollectionQuery = useDataCollection(lead?.id);
  const { leadDispositions, updateDisposition, currentDisposition } = useCurrentDispositionSubscriptionContext();

  const dataCollectionStepper = useDataCollectionStepper({
    dataCollection: dataCollectionQuery.data,
    leadId: lead?.id,
    configureQuoting
  });

  React.useEffect(() => {
    if (configureQuoting) {
      dataCollectionStepper.moveToPage(IDataCollectionPageType.QuotingConfiguration);
    }
  }, [configureQuoting, dataCollectionStepper]);

  const isDataEditingForbidden = useDataEditingForbidden();

  const isDataCollectionFinishedLoading = !!leadDispositions && dataCollectionQuery.isFetchedAfterMount;
  const isEditModeAvailable =
    isSidebarDataEditingEnabled(currentDisposition?.disposition_type) && !isDataEditingForbidden;

  const onDataCollectionCompleted = () => {
    if (isISR()) {
      if (isSuperISRFlowEnabled()) {
        return navigate(`${GuidedSellingRoutes.HandOff}${search}`);
      }
      return navigate(`${GuidedSellingRoutes.ISRFinish}${search}`);
    }

    if (isLeadTransferred(leadDispositions?.dispositions || [])) {
      return navigate(`${GuidedSellingRoutes.Quotes}${search}`);
    }

    return updateDisposition({
      leadId: lead!.id,
      agent_id: authInfo.currentUserId!,
      disposition_type: DispositionType.Pipeline
    })
      .then(() => navigate(`${GuidedSellingRoutes.Quotes}${search}`))
      .catch(() => null);
  };

  if (authInfo.roleCategory !== UserRoleCategory.Admin && sourceDimensions?.lead_type === LeadTypes.UnknownEngagement) {
    return <Navigate to="/leads" replace />;
  }

  const getThankYouContent = () => {
    if (featureFlags.cstGuidedSellingExperience && dataCollectionQuery.data) {
      return <FinalizedSalesPage dataCollection={dataCollectionQuery.data} showOpportunities={false} />;
    }
    if (!isISR() && isEndDisposition(currentDisposition?.disposition_type) && dataCollectionQuery.data) {
      return <NewFinalizedSalesPage />;
    } else {
      return <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />;
    }
  };

  return (
    <>
      <Layout
        header={<PersonHeader />}
        localNavigation={
          <EngagementNavigation
            dataCollectionQuery={dataCollectionQuery}
            dataCollectionStepper={dataCollectionStepper}
          />
        }
        content={
          <>
            <Routes>
              <Route
                path={GuidedSellingRoutes.CollectedInfo}
                element={
                  person &&
                  lead && (
                    <ClassNames>
                      {({ css: className }) => (
                        <Modal
                          className={className`
                        height: 100%;
                        max-width: 100%;
                        background-color: ${colors.white};
                        margin: 0;
                      `}
                          contentClassName={className`
                        border: none;
                      `}
                          modalClassName={className`
                        height: 100%;
                        width: 100%;
                        background-color: ${colors.white};
                        margin: 0;
                        z-index: 99999999;
                      `}
                          headerCss={css`
                            display: none;
                          `}
                          footerCss={css`
                            display: none;
                          `}
                        >
                          <CollectedInfoTab isEditModeAvailable={false} fullScreen />
                        </Modal>
                      )}
                    </ClassNames>
                  )
                }
              />
              <Route
                path="*"
                element={
                  <Summary dataCollection={dataCollectionQuery.data} dataCollectionStepper={dataCollectionStepper} />
                }
              />
              <Route
                path={GuidedSellingRoutes.DataCollection}
                element={
                  <>
                    {isDataCollectionFinishedLoading && dataCollectionQuery.data && (
                      <GuidedDataCollection
                        onDataCollectionCompleted={onDataCollectionCompleted}
                        dataCollection={dataCollectionQuery.data}
                        dataCollectionStepper={dataCollectionStepper}
                      />
                    )}
                    {isDataCollectionFinishedLoading && !dataCollectionQuery.data && (
                      <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                    )}
                  </>
                }
              />
              <Route
                path={GuidedSellingRoutes.ISRFinish}
                element={
                  isISR() ? (
                    <>
                      {isSuperISRFlowEnabled() ? (
                        dataCollectionQuery.data && <SuperISRFinishPage dataCollection={dataCollectionQuery.data} />
                      ) : (
                        <ISRFinishPage dataCollection={dataCollectionQuery.data} />
                      )}
                    </>
                  ) : (
                    <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                  )
                }
              />
              <Route
                path={GuidedSellingRoutes.HandOff}
                element={
                  isSuperISRFlowEnabled() ? (
                    dataCollectionQuery.data && <HandOffPage dataCollection={dataCollectionQuery.data} />
                  ) : (
                    <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                  )
                }
              />
              <Route
                path={GuidedSellingRoutes.AgentFinish}
                element={
                  currentDisposition ? (
                    <>
                      {!isISR() && isEndDisposition(currentDisposition.disposition_type) && dataCollectionQuery.data ? (
                        <NewFinalizedSalesPage />
                      ) : (
                        <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                      )}
                    </>
                  ) : null
                }
              />
              <Route path={GuidedSellingRoutes.ThankYou} element={currentDisposition ? getThankYouContent() : null} />
              <Route
                path={GuidedSellingRoutes.Quotes}
                element={
                  person && (
                    <>
                      {!isISR() ? (
                        <>
                          {featureFlags.newQuotesUI ? (
                            <GuidedQuotingForm
                              leadGid={lead?.gid}
                              leadId={lead?.id}
                              personGid={person.gid}
                              onConfigureQuoting={() => {
                                navigate(`${GuidedSellingRoutes.DataCollection}${search}`, {
                                  state: { configureQuoting: true }
                                });
                              }}
                            />
                          ) : (
                            <Container pv={spacings.px12} ph={spacings.px24}>
                              <GuidedQuoting
                                leadGid={lead?.gid}
                                leadId={lead?.id}
                                personGid={person.gid}
                                onConfigureQuoting={() => {
                                  navigate(`${GuidedSellingRoutes.DataCollection}${search}`, {
                                    state: { configureQuoting: true }
                                  });
                                }}
                              />
                            </Container>
                          )}
                        </>
                      ) : (
                        <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                      )}
                    </>
                  )
                }
              />
              <Route
                path={`${GuidedSellingRoutes.Finalize}/*`}
                element={
                  <>
                    {isDataCollectionFinishedLoading && !featureFlags.cstGuidedSellingExperience && (
                      <PostSalesExperience dataCollectionStepper={dataCollectionStepper} />
                    )}
                    {isDataCollectionFinishedLoading && featureFlags.cstGuidedSellingExperience && (
                      <CSTPostSalesExperience />
                    )}
                    {isISR() ||
                      (isDataCollectionFinishedLoading && !dataCollectionQuery.data && (
                        <Navigate to={`${GuidedSellingPathnames.ScoutingReport}${search}`} replace />
                      ))}
                  </>
                }
              />
              {featureFlags.proposalBuilderPage && (
                <Route path={GuidedSellingRoutes.Proposal} element={<ProposalBuilder />} />
              )}
            </Routes>
          </>
        }
        tabs={<Tabs isEditModeAvailable={isEditModeAvailable} />}
      />
      <PeopleMergedAutomaticallyModal />
      <CancelActiveCallModal />
    </>
  );
};

export default KnownPersonPage;
