import { useMutation } from '@tanstack/react-query';

import { IQuickReply } from '../../interfaces/IQuickReply';
import api from '../../services/api';

interface QuickReplyPayload {
  categories: QuickRepliesCategory[];
  personGid: string;
  proposalUrl?: string;
  personFirstName?: string;
}

export enum QuickRepliesCategory {
  ProposalBuilder = 'proposal_builder',
  PipelineLateNotification = 'pipeline_late_notification'
}

const createQuickReply = ({
  personGid,
  proposalUrl,
  personFirstName,
  categories
}: QuickReplyPayload): Promise<{ quick_replies: IQuickReply[] }> =>
  api.post(`/api/frontend/people/${personGid}/omni_channel_interactions/quick_replies`, {
    body: { context: { proposal_url: proposalUrl, person: { first_name: personFirstName } }, categories }
  });

export const useCreateQuickReply = () => useMutation({ mutationFn: createQuickReply });
