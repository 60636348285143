import { FormikConfig, FormikProps, FormikValues } from 'formik';
import * as React from 'react';

import useEventListener from '../../../hooks/useEventListener';
import { ButtonProps, ButtonVariant } from '../buttons/Button';
import DefaultForm from './DefaultForm';
import FullPageForm from './FullPageForm';
import FullScreenForm from './FullScreenForm';

export interface BaseFormProps<T> extends FormikConfig<T> {
  renderForm: (props: FormikProps<T>) => JSX.Element;
  cancelHandler?: () => void;
  closeHandler?: () => void;
  type?: 'default' | 'fullScreen' | 'fullPage';
  header?: JSX.Element | null | ((props: FormikProps<T>) => JSX.Element | null);
  footer?: JSX.Element | null | ((props: FormikProps<T>) => JSX.Element | null);
  submitText?: string | ((props: FormikProps<T>) => string) | JSX.Element;
  cancelText?: string;
  cancelWithEscape?: boolean;
  disabled?: boolean | ((props: FormikProps<T>) => boolean);
  loading?: boolean;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  controlsAlignment?: 'left' | 'right';
  controlsWidth?: number;
  submitVariant?: ButtonProps['variant'];
  cancelVariant?: ButtonProps['variant'];
  customControls?: boolean;
  buttonsSize?: ButtonProps['size'];
  cancelTestId?: string;
  submitTestId?: string;
  revertButtonsPosition?: boolean;
  formTestId?: string;
}

const BaseForm = <T extends FormikValues>({
  type = 'default',
  cancelWithEscape = false,
  disabled = false,
  loading = false,
  pt = 40,
  pr = 40,
  pb = 60,
  pl = 40,
  controlsAlignment = 'right',
  controlsWidth = 120,
  cancelVariant = ButtonVariant.Text,
  revertButtonsPosition = false,
  ...rest
}: BaseFormProps<T>): JSX.Element => {
  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      cancelWithEscape && (rest.closeHandler || rest.cancelHandler)?.();
    }
  });

  const fullScreen = type === 'fullScreen';
  const fullPage = type === 'fullPage';

  const propsWithDefaults = {
    disabled,
    loading,
    pt,
    pr,
    pb,
    pl,
    controlsAlignment,
    controlsWidth,
    cancelVariant,
    revertButtonsPosition,
    cancelWithEscape
  };

  const props = { ...propsWithDefaults, ...rest };

  if (fullScreen) {
    return <FullScreenForm {...props} />;
  }

  if (fullPage) {
    return <FullPageForm {...props} />;
  }

  return <DefaultForm {...props} />;
};

export default BaseForm;
