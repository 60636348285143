/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';

import { API_TO_SMARTY_STREETS_MAPPING, CITY, LINE1, STATE, ZIP } from '../../constants/addressForm';
import { useConstant } from '../../hooks';
import { IAddressSuggestion, IPerson } from '../../interfaces';
import { VIN_REGEXP } from '../../interfaces/IVehicle';
import autoSearchEntities from '../../services/autoSearchEntities';
import colors from '../../theme/colors';
import { InputSize, spacings } from '../../theme/variables';
import { createSmartyStreetsMapping, renameKeys, slice } from '../../utils/object';
import AddButton from '../core/buttons/AddButton';
import Button, { ButtonSize } from '../core/buttons/Button';
import ButtonWithoutBorder from '../core/buttons/ButtonWithoutBorder';
import CollapsingContainer from '../core/CollapsingContainer';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import { AddressField, CheckboxField, DateInputField, SelectField } from '../core/forms/fields';
import AsyncSelectField from '../core/forms/fields/AsyncSelectField';
import FormError from '../core/forms/fields/FormError';
import { DeleteIcon } from '../core/icons';
import Paragraph from '../core/Paragraph';
import Text from '../core/Text';
import VehicleKind from '../core/VehicleKind';
import PrefilledDriver from './PrefilledDriver';
import { AutoSearchProvider, initialValues, useVehiclesForm } from './utils';

type RequiredValues = ReturnType<typeof initialValues>;
type OtherProps = ReturnType<typeof useVehiclesForm>;
type NonNullableOtherPropsValues = {
  [K in keyof OtherProps]: NonNullable<OtherProps[K]>;
};

type RendererProps = {
  personGid: string;
  person: IPerson;
  touched: FormikTouched<RequiredValues>;
  values: RequiredValues;
  errors: FormikErrors<RequiredValues>;
  setFieldValue: (key: keyof RequiredValues | (string & Record<never, never>), value: any) => void;
  // all props are must be non nullable except for the ones that are explicitly marked as optional
} & Omit<NonNullableOtherPropsValues, 'prefilledVehiclesAndDrivers'> & {
    prefilledVehiclesAndDrivers: OtherProps['prefilledVehiclesAndDrivers'];
  };

const VehiclesFormRenderer = ({
  values,
  touched,
  errors,
  setFieldValue,
  toggleShowPrefill,
  personAddresses,
  updatePerson,
  isUpdatingPerson,
  isPrefillingVehiclesAndDrivers,
  prefilledVehiclesAndDrivers,
  showPrefill,
  personGid,
  person,
  withFenrisCollapse
}: RendererProps) => {
  const todayDate = useConstant(() => new Date());
  const showDobField = useConstant(() => !person.date_of_birth);
  const [manualIndexes, setManualIndexes] = React.useState<Set<number>>(() => new Set());
  const fallbackToManualCreation = (index: number) => {
    setManualIndexes(currentSet => new Set(currentSet.add(index)));
  };
  const fallbackToSearch = (index: number) => {
    setManualIndexes(currentSet => {
      currentSet.delete(index);

      return new Set(currentSet);
    });
  };

  const everyRequiredAddressValueIsPresent = Object.values(
    slice(values.prefilled_vehicles_garage_address, LINE1, CITY, STATE, ZIP)
  ).every(Boolean);

  const errorInAnyRequiredAddressFieldPresent = Object.values(
    slice(errors.prefilled_vehicles_garage_address || {}, LINE1, CITY, STATE, ZIP)
  ).some(Boolean);

  const firstPersonAddress = personAddresses[0] || {};

  const noVehiclesFound =
    prefilledVehiclesAndDrivers &&
    !prefilledVehiclesAndDrivers.vehicles.length &&
    !prefilledVehiclesAndDrivers.drivers.length;

  const renderFenris = React.useCallback(
    (children: React.ReactNode) => {
      if (withFenrisCollapse) {
        return (
          <CollapsingContainer
            containerTitle="Data prefill check"
            iconPosition="left"
            onClick={() => toggleShowPrefill()}
            openedByDefault
          >
            {children}
          </CollapsingContainer>
        );
      }

      return children;
    },
    [withFenrisCollapse, toggleShowPrefill]
  );

  React.useEffect(() => {
    if (prefilledVehiclesAndDrivers) {
      setFieldValue(
        'prefilled_vehicles',
        prefilledVehiclesAndDrivers.vehicles.map(v => ({ vehicle: v, confirmed: false }))
      );
      setFieldValue(
        'prefilled_drivers',
        prefilledVehiclesAndDrivers.drivers.map(d => ({ driver: d, confirmed: false }))
      );
    }
  }, [prefilledVehiclesAndDrivers, setFieldValue]);

  return (
    <>
      {renderFenris(
        <div>
          <FlexBox gap={spacings.px8}>
            {showDobField && (
              <FlexBox
                customCss={css`
                  flex-basis: 20%;
                  flex-shrink: 0;
                `}
              >
                <Container fitParentWidth>
                  <DateInputField
                    fsMask
                    label="DOB"
                    id="date_of_birth"
                    name="date_of_birth"
                    testId="date_of_birth"
                    maxDate={todayDate}
                    required
                  />
                </Container>
              </FlexBox>
            )}

            <FlexBox
              customCss={css`
                flex-basis: 55%;
                flex-grow: 1;
              `}
            >
              <Container fitParentWidth>
                <AddressField
                  required
                  label="Living address"
                  id="prefilled_vehicles_garage_address"
                  key="prefilled_vehicles_garage_address"
                  placeholder=""
                  defaultValue={renameKeys(API_TO_SMARTY_STREETS_MAPPING, firstPersonAddress) as IAddressSuggestion}
                  defaultOptions={
                    personAddresses.map(address =>
                      renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                    ) as IAddressSuggestion[]
                  }
                  fallbackNames={createSmartyStreetsMapping('prefilled_vehicles_garage_address.')}
                  inputSize={InputSize.Large}
                />
              </Container>
            </FlexBox>

            <FlexBox
              columnDirection
              mt={spacings.px24}
              customCss={css`
                flex-basis: max-content;
              `}
            >
              <Button
                loading={isUpdatingPerson || isPrefillingVehiclesAndDrivers}
                size={ButtonSize.Small}
                disabled={
                  !everyRequiredAddressValueIsPresent ||
                  errorInAnyRequiredAddressFieldPresent ||
                  !!errors.date_of_birth ||
                  !values.date_of_birth
                }
                onClick={() =>
                  updatePerson({
                    gid: personGid,
                    living_address: values.prefilled_vehicles_garage_address,
                    date_of_birth: values.date_of_birth
                  })
                }
              >
                Run data-prefill
              </Button>
            </FlexBox>
          </FlexBox>

          {noVehiclesFound && (
            <FlexBox p={spacings.px4} mb={spacings.px24} justifyCenter roundBorder backgroundColor={colors.grey5}>
              <Text>No vehicle or driver found</Text>
            </FlexBox>
          )}
          {(!!values.prefilled_vehicles.length || !!values.prefilled_drivers.length) && (
            <FlexBox
              p={spacings.px12}
              gap={spacings.px24}
              mb={spacings.px24}
              roundBorder
              backgroundColor={colors.grey5}
            >
              <FieldArray
                name="prefilled_vehicles"
                render={() => (
                  <div
                    css={css`
                      flex: auto;
                    `}
                  >
                    <Paragraph bold>Vehicles we found</Paragraph>
                    {values.prefilled_vehicles.map(({ vehicle }, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Container mt={spacings.px8} key={index}>
                        <CheckboxField
                          descriptionFsMask
                          id={`prefilled_vehicles.${index}.confirmed`}
                          name={`prefilled_vehicles.${index}.confirmed`}
                          label={
                            <FlexBox alignItemsCenter gap={spacings.px8}>
                              <Text className={vehicle.make && vehicle.model && vehicle.year ? '' : 'fs-mask'}>
                                {vehicle.description_without_icon}
                              </Text>
                              <VehicleKind kind={vehicle.kind} />
                            </FlexBox>
                          }
                          description={(vehicle.model && vehicle.vin) || undefined}
                          preserveErrorSpace={false}
                        />
                      </Container>
                    ))}
                  </div>
                )}
              />
              <FieldArray
                name="prefilled_drivers"
                render={() => (
                  <div
                    css={css`
                      flex: auto;
                    `}
                  >
                    <Paragraph bold>Drivers we found</Paragraph>
                    {values.prefilled_drivers.map(({ driver }, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Container mt={spacings.px8} key={index}>
                        <PrefilledDriver driver={driver} person={person} index={index} />
                      </Container>
                    ))}
                  </div>
                )}
              />
            </FlexBox>
          )}
        </div>
      )}
      {!showPrefill && (
        <Container
          mv={spacings.px24}
          css={css`
            width: 100%;
            border-bottom: solid 2px ${colors.grey10};
          `}
        />
      )}
      <Container>
        <FieldArray
          name="vehicles"
          render={vehiclesArrayHelpers => (
            <Grid container justifyContent="space-between">
              <FlexBox gap={spacings.px12} alignItemsCenter>
                <Paragraph bold type="large">
                  Enter manually
                </Paragraph>
                <AddButton
                  testId="add-vehicles-modal-add-vehicle-item"
                  onClick={() =>
                    vehiclesArrayHelpers.push({
                      address: {},
                      auto: {
                        year: '',
                        make: '',
                        model: '',
                        submodel: '',
                        vin: ''
                      },
                      gid: crypto.randomUUID()
                    })
                  }
                  content="Add vehicle"
                />
              </FlexBox>
              {values.vehicles.map((v, index: number) => (
                <Grid
                  item
                  container
                  xs={12}
                  columnSpacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  key={v.gid}
                >
                  <Grid item xs={5}>
                    <AddressField
                      required
                      label="Garage address"
                      id={`vehicles.${index}.address`}
                      placeholder=""
                      defaultOptions={
                        personAddresses.map(address =>
                          renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                        ) as IAddressSuggestion[]
                      }
                      fallbackNames={createSmartyStreetsMapping(`vehicles.${index}.address.`)}
                      inputSize={InputSize.Large}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <AsyncSelectField
                      required
                      disabled={manualIndexes.has(index)}
                      label="Make, year, model, or 17-char VIN"
                      id={`vehicles.${index}.auto`}
                      name={`vehicles.${index}.auto`}
                      asyncAction={(term: string) => {
                        if (term.match(VIN_REGEXP)) {
                          return Promise.resolve({ auto_search_entities: [{ vin: term }] });
                        }
                        return autoSearchEntities.get(term);
                      }}
                      optionsNormalizer={({
                        auto_search_entities
                      }:
                        | Awaited<ReturnType<typeof autoSearchEntities.get>>
                        | { auto_search_entities: { vin: string }[] }) => {
                        return auto_search_entities.map(vehicle => {
                          if ('vin' in vehicle) {
                            return {
                              label: vehicle.vin,
                              value: { ...vehicle, year: '', make: '', model: '', submodel: '' }
                            };
                          }

                          return {
                            label: [vehicle.year, vehicle.make, vehicle.model, vehicle.submodel].join(' '),
                            value: vehicle
                          };
                        });
                      }}
                      errorNormalizer={(error: { year: string; make: string; model: string } | null) => {
                        return manualIndexes.has(index)
                          ? 'Fill in all required fields below'
                          : error?.model || error?.make || error?.year || '';
                      }}
                      placeholder="Start typing"
                      // @ts-expect-error - wrong type in react-select
                      noOptionsMessage={() => (
                        <span
                          css={css`
                            display: block;
                            width: 100%;
                            color: ${colors.azure50};
                            &:hover {
                              opacity: 0.8;
                            }
                          `}
                          onClick={() => fallbackToManualCreation(index)}
                        >
                          Create manually
                        </span>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonWithoutBorder
                      disabled={manualIndexes.has(index)}
                      p={spacings.px8}
                      customCss={css`
                        position: relative;
                        bottom: ${spacings.px8}px;
                        &:hover {
                          opacity: 0.8;
                        }
                      `}
                      onClick={() => {
                        vehiclesArrayHelpers.remove(index);
                      }}
                    >
                      <DeleteIcon color={colors.grey60} />
                    </ButtonWithoutBorder>
                  </Grid>
                  {manualIndexes.has(index) && (
                    <AutoSearchProvider>
                      {({
                        years,
                        makes,
                        models,
                        submodels,
                        setMake,
                        setModel,
                        isPendingMakes,
                        isPendingModels,
                        isPendingYears,
                        isPendingSubmodels
                      }) => (
                        <>
                          <Grid item xs={2}>
                            <SelectField
                              required
                              id={`vehicles.${index}.auto.year`}
                              name={`vehicles.${index}.auto.year`}
                              label="Year"
                              isLoading={isPendingYears}
                              placeholder="Select"
                              options={years}
                              createOptionFromSearch
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <SelectField
                              required
                              id={`vehicles.${index}.auto.make`}
                              name={`vehicles.${index}.auto.make`}
                              label="Make"
                              isLoading={isPendingMakes}
                              placeholder="Ford"
                              options={makes}
                              onInputChange={(value, meta) => {
                                if (meta.action === 'input-change') {
                                  setMake(value);
                                  setFieldValue(`vehicles.${index}.auto.make`, value);
                                }
                              }}
                              onChange={({ target: { value } }) => {
                                setMake(value);
                                setFieldValue(`vehicles.${index}.auto.make`, value);
                              }}
                              createOptionFromSearch
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <SelectField
                              required
                              id={`vehicles.${index}.auto.model`}
                              name={`vehicles.${index}.auto.model`}
                              label="Model"
                              isLoading={isPendingModels}
                              placeholder="Mustang"
                              options={models}
                              onInputChange={(value, meta) => {
                                if (meta.action === 'input-change') {
                                  setModel(value);
                                  setFieldValue(`vehicles.${index}.auto.model`, value);
                                }
                              }}
                              onChange={({ target: { value } }) => {
                                setModel(value);
                                setFieldValue(`vehicles.${index}.auto.model`, value);
                              }}
                              createOptionFromSearch
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <SelectField
                              id={`vehicles.${index}.auto.submodel`}
                              name={`vehicles.${index}.auto.submodel`}
                              label="Submodel"
                              isLoading={isPendingSubmodels}
                              placeholder="Coupe"
                              options={submodels}
                              createOptionFromSearch
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <ButtonWithoutBorder
                              color={colors.black}
                              p={spacings.px8}
                              customCss={css`
                                position: relative;
                                bottom: ${spacings.px8}px;
                                &:hover {
                                  opacity: 0.8;
                                }
                              `}
                              onClick={() => {
                                fallbackToSearch(index);
                                setFieldValue(`vehicles.${index}.auto.year`, '');
                                setFieldValue(`vehicles.${index}.auto.make`, '');
                                setFieldValue(`vehicles.${index}.auto.model`, '');
                                setFieldValue(`vehicles.${index}.auto.submodel`, '');
                                setFieldValue(`vehicles.${index}.auto.vin`, '');
                              }}
                            >
                              <DeleteIcon color={colors.grey60} />
                            </ButtonWithoutBorder>
                          </Grid>
                        </>
                      )}
                    </AutoSearchProvider>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        />
        {!!errors.vehicle_presence && touched.vehicle_presence && (
          <FormError id="vehicle_presence" hasError error={errors.vehicle_presence} />
        )}
      </Container>
    </>
  );
};

export default VehiclesFormRenderer;
