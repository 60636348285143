import { ICurrentDisposition, IOpportunity } from '../../../interfaces';
import ReviewForm from './ReviewForm';

const FinalLook = ({
  opportunities,
  currentDisposition,
  isDataEditingForbidden
}: {
  isDataEditingForbidden: boolean;
  opportunities: IOpportunity[];
  currentDisposition: ICurrentDisposition | undefined;
}) => {
  return (
    <ReviewForm
      opportunities={opportunities}
      currentDisposition={currentDisposition}
      isDataEditingForbidden={isDataEditingForbidden}
    />
  );
};

export default FinalLook;
