import './PolicyList.scss';

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';

import Sortable, { ORDER_TYPES } from '../../hocs/Sortable';
import { IMaticPolicy, IPerson } from '../../interfaces';
import { EmptyResults } from '../common';
import LeadPolicyRow from './LeadPolicyRow';

interface PolicyListProps {
  person: IPerson;
  policies: IMaticPolicy[];
  quidedSellingContext?: boolean;
}

const PolicyList = ({ person, policies, quidedSellingContext = false }: PolicyListProps) => {
  if (!policies.length) {
    return <EmptyResults message="No policies found" />;
  }

  return (
    <Sortable
      sortableColumns={{
        status: ORDER_TYPES.STRING,
        lead_id: ORDER_TYPES.NUMBER,
        id: ORDER_TYPES.NUMBER,
        policy_type: ORDER_TYPES.STRING,
        'carrier.name': ORDER_TYPES.STRING,
        premium: ORDER_TYPES.NUMBER,
        primary_coverage: ORDER_TYPES.NUMBER,
        deductible_value: ORDER_TYPES.STRING,
        sale_date: ORDER_TYPES.DATE,
        effective_date: ORDER_TYPES.DATE,
        expiration_date: ORDER_TYPES.DATE,
        policy_number: ORDER_TYPES.STRING
      }}
      list={policies}
      defaultColumn="expiration_date"
      render={({
        list,
        sortBy,
        getSortableClasses
      }: {
        list: IMaticPolicy[];
        sortBy: (column: string) => () => void;
        getSortableClasses: (column: string) => string;
      }) => {
        return (
          <div className="policy-list-container table">
            <Row className="table-headers" noGutters>
              <Col sm={1} className={classNames('table-cell', getSortableClasses('status'))} onClick={sortBy('status')}>
                Status
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('lead_id'))}
                onClick={sortBy('lead_id')}
              >
                Lead ID
              </Col>
              <Col sm={1} className={classNames('table-cell', getSortableClasses('id'))} onClick={sortBy('id')}>
                Policy ID
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('policy_type'))}
                onClick={sortBy('policy_type')}
              >
                Type
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('carrier.name'))}
                onClick={sortBy('carrier.name')}
              >
                Carrier
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('premium'))}
                onClick={sortBy('premium')}
              >
                Premium
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('primary_coverage'))}
                onClick={sortBy('primary_coverage')}
              >
                Coverage
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('deductible_value'))}
                onClick={sortBy('deductible_value')}
              >
                Deductible
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('sale_date'))}
                onClick={sortBy('sale_date')}
              >
                Sale date
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('effective_date'))}
                onClick={sortBy('effective_date')}
              >
                Eff. date
              </Col>
              <Col
                sm={1}
                className={classNames('table-cell', getSortableClasses('policy_number'))}
                onClick={sortBy('policy_number')}
              >
                Policy number
              </Col>
            </Row>
            {list.map(policy => (
              <LeadPolicyRow
                key={policy.id}
                person={person}
                policy={policy}
                quidedSellingContext={quidedSellingContext}
              />
            ))}
          </div>
        );
      }}
    />
  );
};

export default React.memo(PolicyList);
