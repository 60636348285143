/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import * as React from 'react';

import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import Table from '../../../../../components/core/Table/Table';
import PriorPolicyEditor from '../../../../../components/PriorPolicyEditor';
import featureFlags from '../../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { opportunityDescription } from '../../../../../interfaces/IOpportunity';
import { AgencyName, CANCELLED_STATUSES, IPolicy } from '../../../../../interfaces/IPolicy';
import { findPolicyTypeLabel, isInsurableInterestVehicle } from '../../../../../interfaces/IPolicyType';
// eslint-disable-next-line max-len
import { OPPORTUNITIES_WITH_POLICIES_QUERY_KEY } from '../../../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
import {
  GROUPED_POLICY_QUERY_KEY,
  PersonPoliciesGroup
} from '../../../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import colors from '../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../theme/variables';
import { getCurrentWidth } from '../../../_components/VerticalTabs/helpers';
import useDataEditingForbidden from '../../../_hooks/useDataEditingForbidden';
import CarouselColumns from './CarouselColumns';
import { tableColumns, TableDataModel } from './tableColumns';

const currentYearRowCSS = css`
  border-radius: ${borderRadius}px;
  outline: 1px solid ${colors.azure50};
  background: ${colors.ghostWhite};
`;

const predictedYearRowCSS = css`
  border-radius: ${borderRadius}px;
  outline: 1px dashed ${colors.grey30};
  background: ${colors.grey5};
`;

const rowCSSForPolicy = ({ isCurrent, isPredicted }: { isCurrent: boolean; isPredicted: boolean }) => {
  switch (true) {
    case isCurrent:
      return currentYearRowCSS;
    case isPredicted:
      return predictedYearRowCSS;
    default:
      return undefined;
  }
};

const calculatePremiumChange = (currentPolicy: IPolicy, previousPolicy: IPolicy | undefined) => {
  if (!previousPolicy) {
    return '-';
  }
  const previousPremium = Number(previousPolicy.premium);
  const currentPremium = Number(currentPolicy.premium);
  const premiumChange = currentPremium - previousPremium;

  if ([previousPremium, currentPremium, premiumChange].includes(0)) {
    return '-';
  }

  return premiumChange;
};

const COLLAPSE_WIDTH = 625;

export interface HeadingProps {
  policy: IPolicy;
  activePolicy: IPolicy | undefined;
  policyNumber: string;
}

const RenderHeading = ({ policy, activePolicy, policyNumber }: HeadingProps) => {
  return (
    <FlexBox alignItemsCenter justifySpaceBetween>
      <FlexBox
        alignItemsCenter
        gap={spacings.px8}
        customCss={css`
          flex-wrap: wrap;
        `}
      >
        <CircledPolicyTypeIcon policyType={policy.policy_type} />
        <Paragraph bold>{findPolicyTypeLabel(policy.policy_type) || '-'}</Paragraph>
        {policy.assets && <Paragraph className="fs-mask">{opportunityDescription(policy)}</Paragraph>}
      </FlexBox>
      <Paragraph type="tiny" color={colors.grey80} className={activePolicy?.policy_number ? 'fs-mask' : undefined}>
        {policyNumber}
      </Paragraph>
    </FlexBox>
  );
};

const PolicyGroupTable = ({
  policyGroup,
  collapseWidth = COLLAPSE_WIDTH,
  renderHeading = RenderHeading
}: {
  policyGroup: PersonPoliciesGroup;
  collapseWidth?: number;
  renderHeading?: (props: HeadingProps) => React.ReactNode;
}) => {
  const { personGid } = useGuidedSellingExperienceContext();
  const isDataEditingForbidden = useDataEditingForbidden();
  const isVehicle = policyGroup.group[0] && isInsurableInterestVehicle(policyGroup.group[0].policy_type);
  const [isPremiumAnnual, setIsPremiumAnnual] = React.useState(!isVehicle);
  const [policyToEdit, setPolicyToEdit] = React.useState<IPolicy>();
  const filteredPolicies = policyGroup.group.filter(p => !(!p.premium && p.agency_name === AgencyName.Prediction));
  const activePolicy = (() => {
    return filteredPolicies
      .filter(p => p.agency_name !== AgencyName.Prediction)
      .filter(p => !CANCELLED_STATUSES.includes(p.status))
      .find(p => moment(p.expiration_date).isAfter(moment()));
  })();

  const policy = filteredPolicies[0]!;

  const tableData = React.useMemo(() => {
    return filteredPolicies.map((policy, index) => {
      const isCurrent = policy.gid === activePolicy?.gid;
      const isPredicted = policy.agency_name === AgencyName.Prediction;

      return {
        ...policy,
        isPredicted,
        isPremiumAnnual,
        setIsPremiumAnnual,
        isCurrent,
        isUnknown: !policy.expiration_date,
        rateChange: calculatePremiumChange(policy, filteredPolicies[index + 1]),
        rowCustomCSS: rowCSSForPolicy({ isCurrent, isPredicted }),
        disabled: isDataEditingForbidden,
        onEdit: () => {
          setPolicyToEdit(policy);
        }
      };
    });
  }, [activePolicy, filteredPolicies, isPremiumAnnual, isDataEditingForbidden]) satisfies TableDataModel[];

  const queryClient = useQueryClient();

  const policyNumber = (() => {
    if (!activePolicy) {
      return 'no active policy';
    }

    if (!activePolicy.policy_number) {
      return 'no policy number';
    }

    return `active policy # ${activePolicy.policy_number}`;
  })();

  const [isExtraSmall, setIsExtraSmall] = React.useState(() => getCurrentWidth() < collapseWidth);
  const elRef = React.useRef<HTMLDivElement | null>(null);
  useResizeObserver(elRef, entry => setIsExtraSmall(entry.contentRect.width < collapseWidth));

  const isCstExperienceEnabled = featureFlags.cstGuidedSellingExperience;

  const stableTableColumns = React.useMemo(() => {
    return tableColumns(isCstExperienceEnabled);
  }, [isCstExperienceEnabled]);

  return (
    <FlexBox columnDirection gap={spacings.px8} ref={elRef}>
      {renderHeading({ policy, activePolicy, policyNumber })}
      {isExtraSmall ? (
        <CarouselColumns data={tableData} />
      ) : (
        <Table data={tableData} columns={stableTableColumns} testId="policy-history-table" withBorder={false} />
      )}
      {policyToEdit && personGid && (
        <PriorPolicyEditor
          personGid={personGid}
          priorPolicy={policyToEdit}
          cancelHandler={() => setPolicyToEdit(undefined)}
          confirmHandler={() => {
            setPolicyToEdit(undefined);
            queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_WITH_POLICIES_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [GROUPED_POLICY_QUERY_KEY] });
          }}
        />
      )}
    </FlexBox>
  );
};

export default PolicyGroupTable;
