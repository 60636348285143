import moment from 'moment';
import React from 'react';
import { create } from 'zustand';

import { useDebouncedValue } from '../../hooks';
import { ICallLog, Pretty } from '../../interfaces';
import { CallStatus, IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { ITimezone } from '../../interfaces/ITimezone';
import useLeadAssignees from '../../queries/leads/assignees/useLeadAssignees';
import useLead from '../../queries/leads/useLead';
import usePerson from '../../queries/people/usePerson';
import { ICalendarData } from '../../queries/scheduled_calls/useCalendar';
import useCallNotes from '../../queries/scheduled_calls/useCallNotes';
import { fixTZName, userTimezone } from '../../utils/formatter';
import { FormValues } from './FollowupForm';

export const AGENT_SPOT_DURATION = 15;

export const ISR_LINK_MESSAGE =
  "To make our upcoming call as smooth and efficient as possible, we're going to send you a quick link via SMS. " +
  'This link leads to a form where you can fill in some key details about your property at your convenience. ' +
  'Completing this before our call helps us tailor the conversation to your exact needs, ensuring we can provide ' +
  'the best possible advice and options for you. Look out for a text from us shortly!';

export const isCallCompleted = (scheduledCall: IScheduledCall): boolean =>
  scheduledCall.status === CallStatus.ContactMade;

export const isCallFailed = (scheduledCall: IScheduledCall): boolean =>
  [CallStatus.ContactFailed, CallStatus.Cancelled].includes(scheduledCall.status);

export const getCallSource = (scheduledCall: IScheduledCall) => {
  switch (scheduledCall.flow) {
    case ScheduledInteractionFlow.Calendly:
    case ScheduledInteractionFlow.CalendlyFollowUp:
      return 'by Calendly';
    case ScheduledInteractionFlow.ScheduledByRecapture:
      return 'by AMP';
    default:
      return `by ${scheduledCall.assignor?.name || scheduledCall.assignee?.name || 'Unknown user'}`;
  }
};

export const buildTimezonesOptions = (
  calendarData: ICalendarData,
  personTimezone: ITimezone | null | undefined
): ITimezone[] => {
  const currentTimezone =
    calendarData.available_timezones.find(timezone => timezone.utc_offset_hours === userTimezone.utc_offset_hours) ||
    userTimezone;

  const timezonesExceptCurrent = calendarData.available_timezones.filter(
    timezone =>
      timezone.utc_offset_hours !== currentTimezone.utc_offset_hours &&
      timezone.utc_offset_hours !== personTimezone?.utc_offset_hours
  );

  const personTimezoneOption = personTimezone ? { ...personTimezone, postfix: 'Customer Local Time' } : null;

  return [
    personTimezoneOption,
    {
      ...currentTimezone,
      name: currentTimezone.name,
      display_name: fixTZName(currentTimezone.name),
      postfix: 'Your Local Time'
    },
    ...timezonesExceptCurrent
  ].filter(Boolean) as ITimezone[];
};

export const findTimezoneOption = (calendarData: ICalendarData, timezonesOptions: ITimezone[]) =>
  timezonesOptions.find(timezone => timezone.utc_offset_hours === calendarData.timezone.utc_offset_hours) ||
  timezonesOptions[0];

export const isFollowUpCall = (flow: ScheduledInteractionFlow) =>
  [ScheduledInteractionFlow.PipelineHighPriority, ScheduledInteractionFlow.PipelineLowPriority].includes(flow);
interface IScheduledCallActions {
  editedScheduledCall: null | IScheduledCall;
  setEditedScheduledCall: (call: IScheduledCallActions['editedScheduledCall']) => void;
  cancelledScheduledCall: null | IScheduledCall;
  setCancelledScheduledCall: (call: IScheduledCallActions['cancelledScheduledCall']) => void;
}
export const useScheduledCallActions = create<IScheduledCallActions>()(set => ({
  editedScheduledCall: null,
  setEditedScheduledCall: editedScheduledCall => set({ editedScheduledCall }),
  cancelledScheduledCall: null,
  setCancelledScheduledCall: cancelledScheduledCall => set({ cancelledScheduledCall })
}));

export const FLOW_MAPPINGS: Partial<Record<ScheduledInteractionFlow, string>> = {
  [ScheduledInteractionFlow.PipelineLowPriority]: 'Low priority',
  [ScheduledInteractionFlow.PipelineHighPriority]: 'High priority'
};

export const useScheduleCallData = ({
  personGid,
  leadGid,
  leadId
}: {
  personGid: string;
  leadGid?: string;
  leadId?: number;
}) => {
  const { data: person, refetch: refetchPerson, isFetching: isPersonFetching } = usePerson(personGid);

  const { data: callNotes } = useCallNotes(personGid);
  const { data: lead } = useLead(leadGid);
  const { data: possibleAssignees, isPending: isPendingAssignees } = useLeadAssignees({
    leadId: leadId || lead?.id,
    licensed: true
  });

  return {
    person,
    lead,
    refetchPerson,
    isPersonFetching,
    callNotes,
    possibleAssignees,
    isPendingAssignees,
    leadGid
  };
};

export const buildFollowupLocalStorageKey = (leadGid: string) => `followup-for-${leadGid}`;
export const retrieveFollowupFromLocalStorage = (leadGid: string) => {
  const key = buildFollowupLocalStorageKey(leadGid);
  const stringifiedFollowupData = localStorage.getItem(key);

  if (stringifiedFollowupData) {
    const parsedFollowup = JSON.parse(stringifiedFollowupData) as Pretty<
      FormValues & {
        timestamp: number;
        step: 1 | 2;
      }
    > | null;

    if (parsedFollowup) {
      const timestamp = parsedFollowup.timestamp || 0;
      // 5 minutes = 5 * 60 * 1000 = 300000;
      if (Date.now() - timestamp < 300000) {
        const { timestamp, step, ...rest } = parsedFollowup;

        return { step, ...rest };
      } else {
        localStorage.removeItem(key);
      }
    }
  }

  return null;
};

export const useDraftFollowup = ({ leadGid, values, step }: { leadGid: string; values: FormValues; step: 1 | 2 }) => {
  const debouncedValues = useDebouncedValue(values, 500);
  React.useEffect(() => {
    const followupData = {
      ...debouncedValues,
      step,
      timestamp: Date.now()
    };
    localStorage.setItem(buildFollowupLocalStorageKey(leadGid), JSON.stringify(followupData));
  }, [leadGid, step, debouncedValues]);

  // Cleanup followups from 2 days ago
  React.useEffect(() => {
    const keys = Object.keys(localStorage).filter(key => key.includes(buildFollowupLocalStorageKey('')));
    const twoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;
    keys.forEach(key => {
      const data = JSON.parse(localStorage.getItem(key) || '{}') as Record<string, any>;
      if ('timestamp' in data && data.timestamp < twoDaysAgo) {
        localStorage.removeItem(key);
      }
    });
  }, []);
};

export const resetIfCallIsAboutToHappen = (callLog: ICallLog, scheduledCall: IScheduledCall) => {
  if (callLog.person_gid !== scheduledCall.person_gid) {
    return scheduledCall;
  }

  if (
    scheduledCall.flow === ScheduledInteractionFlow.PipelineHighPriority &&
    moment().isAfter(moment(scheduledCall.run_at).subtract(1, 'minute'))
  ) {
    return undefined;
  }

  return scheduledCall;
};
