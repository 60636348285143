/** @jsxImportSource @emotion/react */
import { css } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

import { columns } from '../../../pages/DashboardTable/DashboardTableColumns.util';
import { spacings } from '../../../theme/variables';
import FlexBox from '../FlexBox';

const VirtualizedTablePlaceholder = () => (
  <div data-testid="lead-operations-loader">
    <FlexBox mt={spacings.px24} columnDirection>
      {Array.from({ length: 20 }).map(_ => (
        <div
          key={crypto.randomUUID()}
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            width: 100%;
          `}
        >
          {columns().map(column => (
            <Skeleton
              key={column.id}
              css={css`
                display: flex;
                margin-right: 10px;
                min-width: 145px;
                width: -webkit-fill-available;
              `}
            />
          ))}
        </div>
      ))}
    </FlexBox>
  </div>
);

export default VirtualizedTablePlaceholder;
