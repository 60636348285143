import moment, { Moment } from 'moment';

import {
  CalendarIcon,
  InboundCallIcon,
  InboundEmailIcon,
  InboundSMSIcon,
  OutboundCallIcon,
  OutboundEmailIcon,
  OutboundSMSIcon
} from '../../../../components/core/icons';
import { InteractionDirection, InteractionType } from '../../../../interfaces/IPersonInteraction';
import colors from '../../../../theme/colors';
import {
  dateFormatter,
  daysToNow,
  DISPLAY_DATE_FORMAT,
  timeFormatter,
  userTimezone
} from '../../../../utils/formatter';

export const displayedDateAndTime = (time: Moment, simplify?: boolean) => {
  if (!time) {
    return;
  }

  const day = () => {
    const diff = daysToNow(time.format(DISPLAY_DATE_FORMAT));

    if (diff < 1) {
      return 'Today';
    } else if (diff < 2) {
      return 'Yesterday';
    } else if (diff < 7) {
      return moment.weekdays(time.day());
    } else {
      return dateFormatter(time);
    }
  };

  return simplify
    ? `${day()}, ${timeFormatter(time)} ${userTimezone.abbreviation}`
    : `${dateFormatter(time)}, ${timeFormatter(time)} ${userTimezone.abbreviation}`;
};

export const callDuration = ({ start, end }: { start: string; end: string }) => {
  if (!start || !end) {
    return;
  }

  const startTimestamp = moment(start);
  const endTimestamp = moment(end);
  const duration = moment.duration(endTimestamp.diff(startTimestamp));

  if (duration.asSeconds() < 1) {
    return '0s';
  }

  const formattedDuration = [];
  if (duration.hours() > 0) {
    formattedDuration.push(`${duration.hours()}h`);
  }
  if (duration.minutes() > 0) {
    formattedDuration.push(`${duration.minutes()}m`);
  }
  if (duration.seconds() > 0) {
    formattedDuration.push(`${duration.seconds()}s`);
  }

  return formattedDuration.join(' ');
};

export const generateInteractionIcon = ({
  type,
  direction
}: {
  type: InteractionType;
  direction: InteractionDirection;
}) => {
  switch (type) {
    case InteractionType.Call:
      if (direction === InteractionDirection.Inbound) {
        return { color: colors.statusGreen, icon: <InboundCallIcon /> };
      } else if (direction === InteractionDirection.Outbound) {
        return { color: colors.statusGreen, icon: <OutboundCallIcon /> };
      } else {
        return { color: colors.statusGreen, icon: <CalendarIcon /> };
      }

    case InteractionType.SMS:
      if (direction === InteractionDirection.Inbound) {
        return { color: colors.statusOrange, icon: <InboundSMSIcon /> };
      } else {
        return { color: colors.statusOrange, icon: <OutboundSMSIcon /> };
      }

    case InteractionType.Email:
      if (direction === InteractionDirection.Inbound) {
        return { color: colors.violet, icon: <InboundEmailIcon /> };
      } else {
        return { color: colors.violet, icon: <OutboundEmailIcon /> };
      }
  }
};
