import moment from 'moment';
import { compose, concat, converge, head, join, map, split, tail, toLower, toUpper } from 'ramda';

import { INFO_NOT_PRESENT } from '../constants';

export const INTERNAL_DATE_FORMAT = 'YYYY-MM-DD';
export const INTERNAL_DATE_FORMAT_REGEXP = /^\d{4}-\d{2}-\d{2}$/;
export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT = 'll';

export const DISPLAY_TIME_FORMAT = 'h:mm a';

export const DEPRECATED_DATE_TIME_FORMAT = 'MMM DD, YYYY | HH:mm';
export const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;
export const DASHBOARD_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} [at] ${DISPLAY_TIME_FORMAT}`;
export const SCOUTING_REPORT_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} [at] ${DISPLAY_TIME_FORMAT}`;

const userTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
const userTimezoneAbbreviation =
  Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
    .formatToParts()
    .find(p => p.type === 'timeZoneName')?.value || '';

export const getTimezoneAbbreviation = (timezone: string): string => {
  const parts = new Intl.DateTimeFormat('en-US', { timeZone: timezone, timeZoneName: 'short' }).formatToParts();

  return parts.find(part => part.type === 'timeZoneName')?.value || '';
};

export const userTimezone = {
  abbreviation: userTimezoneAbbreviation,
  name: userTimezoneName,
  utc_offset_hours: -1 * (new Date().getTimezoneOffset() / 60)
};

export const fixTZName = (name: string): string => `${name === 'Europe/Kiev' ? 'Europe/Kyiv' : name}`;

const ELLIPSIS_MAX_SYMBOLS_COUNT = 25;

const MONTHS_IN_YEAR = 12;

const MONEY_TYPE_FLAT = 'flat';

export const dateFormatter = (date: any, format = DISPLAY_DATE_FORMAT) => date && moment(date).format(format);

export const timeFormatter = (time: any, format = DISPLAY_TIME_FORMAT) => time && moment(time).format(format);

export const relativeTimeFormatter = (time: any) => time && moment(time).fromNow();

export const calculateTimeWithOffset = (offset: any) => moment.utc().add(offset, 'hours').format('h:mmA');

export const dateTimeFormatter = (dateTime: any, format = DISPLAY_DATE_TIME_FORMAT) =>
  dateTime && moment(dateTime).format(format);

export const moneyFormatter = (money: any, isInteger = false, notation: any = 'standard') =>
  money &&
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation,
    maximumFractionDigits: isInteger ? 0 : 2,
    minimumFractionDigits: isInteger ? 0 : 2
  }).format(money);

export const percentMoneyFormatter = (money: any) => `${money}%`;

export const typedMoneyFormatter = (type: string | null | undefined, money: any, isInteger = false) => {
  if (!type || !money) {
    return '';
  }

  return type === MONEY_TYPE_FLAT ? moneyFormatter(money, isInteger) : percentMoneyFormatter(money);
};

export const premiumFormatter = (premium: any) =>
  premium && `${moneyFormatter(premium)} (${moneyFormatter(premium / MONTHS_IN_YEAR, true)}/mo)`;

export const phoneFormatter = (phone: any) =>
  phone && phone.replace(/[^+0-9<>em/]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const phoneFormatterBracesless = (phone: any) =>
  phone && phone.replace(/[^+0-9<>em/]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const fileSizeFormatter = (size: any) =>
  ['bytes', 'KB', 'MB'].reduce(
    (acc, item, i) => (size >= Math.pow(2, 10 * i) ? `${(size / Math.pow(2, 10 * i)).toFixed(2)} ${item}` : acc),
    INFO_NOT_PRESENT
  );

export const daysToNow = (date: any) => date && moment().diff(date, 'd');

export const minutesAndHoursToNow = (date: any): string => {
  if (!date) {
    return '';
  }
  const now = moment();
  const past = moment(date);

  const duration = moment.duration(now.diff(past));

  if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())} minute${Math.floor(duration.asMinutes()) !== 1 ? 's' : ''}`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hour${Math.floor(duration.asHours()) !== 1 ? 's' : ''}`;
  } else {
    return `${Math.floor(duration.asDays())} day${Math.floor(duration.asDays()) !== 1 ? 's' : ''}`;
  }
};

export const humanize = (str: any) => str && str.replace(/[_\s]+/g, ' ');

export const capitalize = (str: any) => str && str[0].toUpperCase() + humanize(str).slice(1);

export const ellipsis = (str: any, maxSymbols = ELLIPSIS_MAX_SYMBOLS_COUNT) => {
  if (!str) {
    return '';
  }
  if (str.length <= maxSymbols) {
    return str;
  }

  return `${str.slice(0, maxSymbols)}...`;
};

export const formatFileName = (name: string) => name.replace(/\s/g, '_');
// @ts-expect-error TODO
const capitalizeText = converge(concat(), [compose(toUpper, head), tail]);
export const titleize = compose(join(' '), map(capitalizeText), split(' '), toLower);

export const getOrdinal = (n: number) => {
  let ord = 'th';

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }

  return ord;
};
