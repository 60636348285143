/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import * as React from 'react';
import Carousel from 'react-multi-carousel';

import { IDocument, IPerson } from '../../interfaces';
import { DocumentType } from '../../queries/document_types/useDocumentTypes';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { ButtonGroup } from '../core/buttons/ButtonGroup';
import FlexBox from '../core/FlexBox';
import Text from '../core/Text';
import { getCurrentWidth } from '../core/VerticalTabs/helpers';
import { IChannel } from '../Documents/Document';
import MiniDocument from '../Documents/MiniDocument';

const OtherDocuments = ({
  person,
  documents,
  docTypes,
  setExpandedDocument,
  setExpandedDocumentChannels
}: {
  person: IPerson;
  documents: IDocument[];
  docTypes: DocumentType[];
  setExpandedDocument: (doc: IDocument | null) => void;
  setExpandedDocumentChannels: (channels: IChannel[] | null) => void;
}) => {
  const currentWidth = getCurrentWidth();
  const [items, setItems] = React.useState(currentWidth / 232); // 232 is the width of the MiniDocument + margins
  const showArrows = items < documents.length;

  return (
    <FlexBox columnDirection>
      <FlexBox gap={spacings.px8}>
        <Text bold>Other</Text>
        <Text
          ph={spacings.px4}
          color={colors.grey60}
          type="small"
          customCss={css`
            border-radius: 4px;
            border: 1px solid ${colors.grey10};
            background-color: ${colors.grey5};
            line-height: 1.5;
          `}
        >
          {documents.length}
        </Text>
      </FlexBox>
      <Global
        styles={() => css`
          .carousel-item-padding {
            padding: ${spacings.px4}px;
          }
        `}
      />
      <div
        css={css`
          position: relative;
        `}
      >
        <Carousel
          responsive={{
            desktop: {
              breakpoint: { max: 5000, min: 100 },
              items,
              partialVisibilityGutter: 0,
              slidesToSlide: items
            }
          }}
          afterChange={() => setItems(getCurrentWidth() / 230)}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={showArrows ? <ButtonGroup /> : null}
          swipeable={false}
          draggable={false}
          showDots={false}
          centerMode={false}
          ssr={false}
          infinite={false}
          autoPlay={false}
          itemClass="carousel-item-padding"
        >
          {documents.map(doc => (
            <MiniDocument
              key={doc.id}
              person={person}
              doc={doc}
              documentType={doc.document_type_key}
              docTypes={docTypes}
              onDocumentExpand={() => {
                setExpandedDocument(doc);
                setExpandedDocumentChannels(null);
              }}
            />
          ))}
        </Carousel>
      </div>
    </FlexBox>
  );
};

export default OtherDocuments;
